import React from "react";
import { useLocation } from 'react-router-dom';
import { TimelineChartDataList } from "../types";

export type GlobalStateContextType = {
  menuPcUrl: string;
  setMenuPcUrl: (menuPcUrl: string) => void;
  menuAcUrl: string;
  setMenuAcUrl: (menuAcUrl: string) => void;
  menuPfUrl: string;
  setMenuPfUrl: (menuPfUrl: string) => void;
  menuPfwtUrl: string;
  setMenuPfwtUrl: (menuPfwtUrl: string) => void;
  menuLhUrl: string;
  setMenuLhUrl: (menuLhUrl: string) => void;
  pcData: TimelineChartDataList;
  setPcData: (lhData: TimelineChartDataList) => void;
  acData: any;
  setAcData: (acData: any) => void;
  lhData: TimelineChartDataList;
  setLhData: (lhData: TimelineChartDataList) => void;
}
const GlobalStateContext = React.createContext<GlobalStateContextType>({} as GlobalStateContextType);

export const useGlobalStateContext = ():GlobalStateContextType => {
  return React.useContext<GlobalStateContextType>(GlobalStateContext);
}

type Props = {
  children: React.ReactNode
}

export const GlobalStateProvider = (props:Props) => {
  const location = useLocation();
  const [menuPcUrl, setMenuPcUrl] = React.useState<string>(location.pathname.startsWith('/pc') ? location.pathname : '/pc');
  const [menuAcUrl, setMenuAcUrl] = React.useState<string>(location.pathname.startsWith('/ac') ? location.pathname : '/ac');
  const [menuPfUrl, setMenuPfUrl] = React.useState<string>((location.pathname.startsWith('/pf') && !location.pathname.startsWith('/pfwt')) ? location.pathname : '/pf');
  const [menuPfwtUrl, setMenuPfwtUrl] = React.useState<string>(location.pathname.startsWith('/pfwt') ? location.pathname : '/pfwt');
  const [menuLhUrl, setMenuLhUrl] = React.useState<string>(location.pathname.startsWith('/lh') ? location.pathname : '/lh');
  const [pcData, setPcData] = React.useState<TimelineChartDataList>([]);
  const [acData, setAcData] = React.useState<any>([]);
  const [lhData, setLhData] = React.useState<TimelineChartDataList>([]);

  const value: GlobalStateContextType = { menuPcUrl, setMenuPcUrl, menuAcUrl, setMenuAcUrl, menuPfUrl, setMenuPfUrl, menuPfwtUrl, setMenuPfwtUrl, menuLhUrl, setMenuLhUrl, pcData, setPcData, acData, setAcData, lhData, setLhData };
  return (
    <GlobalStateContext.Provider value={value}>
      {props.children}
    </GlobalStateContext.Provider>
  );
}
