import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Title from '../Title';
import { GlobalStateContextType, useAuthUserContext, useGlobalStateContext } from "../providers";

import Map, { Marker, NavigationControl, ScaleControl, FullscreenControl, GeolocateControl, Layer, LineLayer, Source } from 'react-map-gl';
import { Expression } from 'mapbox-gl';
import { FeatureCollection, Feature } from 'geojson';
import { Box, Divider, FormControl, Toolbar } from '@mui/material';
import WeightedTreeView from '../elements/WeightedTreeView';
import { MAPBOX_TOKEN } from '../config';
import { Centrair2WallSource } from '../objects/centrair_2-wall';
import { supportingPoints, routeSupportingPoints } from '../objects/centrair_route_points';
import { isMarkerActive, MarkerProp } from '../types';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

import * as d3 from 'd3';
import { PermType } from '../types';

type WTNode = d3.layout.tree.Node & {
  data_source_id: number;
  total: number;
  children: WTNode[];
  level: number;
  parent?: WTNode;
}

type WTData = {
  id: number,
  times: Date[],
  sensorIds: number[],
  heights: number[],
}

const strokeColors = scaleOrdinal(schemeCategory10).range();

const PcounterApp = require('pcounter_app');

const columns: GridColDef[] = [
  { field: 'data_source_id', headerName: 'ソースID', maxWidth: 100 },
  { field: 'data_source_name', headerName: 'ソース名', minWidth: 200, flex: 1 },
  { field: 'data_source_group_name', headerName: 'グループ名', minWidth: 200, flex: 0.8 },
  { field: 'data_source_latitude', headerName: '緯度', minWidth: 120, flex: 0.2, hide: true },
  { field: 'data_source_longitude', headerName: '経度', minWidth: 120, flex: 0.2, hide: true },
  // { field: 'granularity_time', headerName: '時間粒度' },
  // { field: 'granularity_mesh', headerName: 'メッシュ粒度' },
];

const flowLayerStyle = {
  id: 'flow',
  type: 'line' as LineLayer["type"],
  paint: {
    'line-color': ['get', 'color'] as Expression,
    'line-width': ['get', 'width'] as Expression,
  },
  layout: {
    'line-cap': 'round' as Expression | "round" | "butt" | "square" | undefined,
    'line-join': 'round' as Expression | "round" | "miter" | "bevel" | undefined,
  }
};

const mapLineData = {
  type: 'FeatureCollection',
  features: [],
} as FeatureCollection;

const ARROW_DEG_1 = 150;
const ARROW_DEG_2 = 210;
const ARROW_COS_1 = Math.cos(ARROW_DEG_1 * Math.PI / 180); // 矢印の角度 (from→to のベクトルから何度回転するか)
const ARROW_SIN_1 = Math.sin(ARROW_DEG_1 * Math.PI / 180);
const ARROW_COS_2 = Math.cos(ARROW_DEG_2 * Math.PI / 180); // 矢印の角度 (from→to のベクトルから何度回転するか)
const ARROW_SIN_2 = Math.sin(ARROW_DEG_2 * Math.PI / 180);
const ARROW_HEAD_RATIO = 0.00005;

const SHIFT_DEG_1 = 90;
const SHIFT_COS_1 = Math.cos(SHIFT_DEG_1 * Math.PI / 180); // shift
const SHIFT_SIN_1 = Math.sin(SHIFT_DEG_1 * Math.PI / 180);
const SHIFT_RATIO = 0.005;

export default function PF() {
  let { sids, s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [wtData, setWtData] = React.useState<WTData[]>([]);
  const [mapLines, setMapLines] = React.useState(mapLineData);
  const globalState: GlobalStateContextType = useGlobalStateContext();
  const { t } = useTranslation();

  const user = useAuthUserContext().user;
  const areas = user?.perms?.filter(row => row.data_source_type === 2)!!;
  const gates = user?.perms?.filter(row => row.data_source_type === 1)!!;

  // calc latlon av, parse url sid list
  let avgLat = 0, avgLon = 0;
  const areaSelectionsForGridUi: GridSelectionModel = [];
  const markers: MarkerProp[] = [];
  const areaIdSelections = sids?.split(",").map(v => parseInt(v));
  const areaLocDic: { [name: number]: MarkerProp } = {};
  areas.forEach((area, _) => {
    avgLat += area.data_source_latitude!!;
    avgLon += area.data_source_longitude!!;
    const marker: MarkerProp = {
      id: area.id!!,
      lat: area.data_source_latitude!!,
      lon: area.data_source_longitude!!,
      name: area.data_source_name!!,
      active: -1,
    };
    if (areaIdSelections?.includes(area.data_source_id!!)) {
      areaSelectionsForGridUi.push(area.id!!);
      marker.active = areaIdSelections?.indexOf(area.data_source_id!!)!!;
    }
    markers.push(marker);
    areaLocDic[area.data_source_id!!] = marker;
  });
  const gateLocDic: { [name: number]: MarkerProp } = {};
  gates.forEach((gate, _) => {
    const marker: MarkerProp = {
      id: gate.id!!,
      lat: gate.data_source_latitude!!,
      lon: gate.data_source_longitude!!,
      name: gate.data_source_name!!,
      active: -1,
    };
    gateLocDic[gate.data_source_id!!] = marker;
  });
  Object.entries(supportingPoints).forEach(([k, v]) => {
    const marker: MarkerProp = {
      id: Number(k),
      lat: v[1],
      lon: v[0],
      name: k,
      active: -1,
    };
    gateLocDic[Number(k)] = marker;
  });
  avgLat /= areas.length;
  avgLon /= areas.length;

  // parse url date
  let sTime = dayjs().hour(0).minute(0);
  if (s_year !== void 0) sTime = sTime.year(parseInt(s_year));
  if (s_month !== void 0) sTime = sTime.month(parseInt(s_month) - 1);
  if (s_day !== void 0) sTime = sTime.date(parseInt(s_day));
  if (s_hour !== void 0) sTime = sTime.hour(parseInt(s_hour));
  if (s_minute !== void 0) sTime = sTime.minute(parseInt(s_minute));
  let eTime = dayjs().hour(23).minute(59);
  if (e_year !== void 0) eTime = eTime.year(parseInt(e_year));
  if (e_month !== void 0) eTime = eTime.month(parseInt(e_month) - 1);
  if (e_day !== void 0) eTime = eTime.date(parseInt(e_day));
  if (e_hour !== void 0) eTime = eTime.hour(parseInt(e_hour));
  if (e_minute !== void 0) eTime = eTime.minute(parseInt(e_minute));

  const gTime = 0;
  const gMesh = 0;

  const addOrUpdate = (node: WTNode, element: any, keyIndex: number) => {
    node.total++;
    if (keyIndex >= element.length)
      return;
    const match = node.children.find((item: WTNode) => item.data_source_id === element[keyIndex]);
    if (match) {
      addOrUpdate(match, element, keyIndex + 1);
    } else {
      const child = {
        data_source_id: element[keyIndex],
        parent: node,
        level: keyIndex + 1,
        total: 0,
        children: [],
      } as WTNode;
      node.children.push(child);
      addOrUpdate(child, element, keyIndex + 1);
    }
  }

  const calcTree = (area: any, wtData: WTData[]) => {
    const sensors = user?.perms?.filter(row => row.data_source_type === 1)!!;
    const sensorMap: { [key: number]: number } = {};
    sensors.forEach((sensor: PermType, i: number) => {
      sensorMap[sensor.data_source_id!!] = i;
    });
    const routes = wtData.map((pf: any) => {
      const route = [area.data_source_id];
      for (const element of pf.sensorIds) {
        const sensor = sensors[sensorMap[element]];
        if (sensor !== void 0) {
          if (sensor.data_source_opt!!.f === route[route.length - 1]) {
            route.push(sensor.data_source_opt!!.b);
          } else if (sensor.data_source_opt!!.b === route[route.length - 1]) {
            route.push(sensor.data_source_opt!!.f);
          } else {
            route.push(-1);
            break;
          }
        } else {
          console.warn("sensor", sensor, "sensors[sensorMap[element]]", sensors[sensorMap[element]], "sensorMap[element]", sensorMap[element], "element", element);
        }
      }
      return route;
    });
    console.log(routes, sensorMap);
    const _wt_root = {
      data_source_id: area.data_source_id!!,
      total: 0,
      children: [],
      level: 1,
    };
    routes.forEach((e: any) => {
      addOrUpdate(_wt_root, e, 1);
    });
    return _wt_root;
  }

  const createMapLineData = (wtData: WTData[]) => {
    const gateIds = gates.map(gate => Number(gate.data_source_id));
    const spPointsIds = Object.keys(supportingPoints).map(v => Number(v));
    const pfVolumes = gateIds.concat(spPointsIds).reduce((accumulator: any, currentValue) => {
      accumulator[currentValue] = gateIds.concat(spPointsIds).reduce((accumulator: any, currentValue) => {
        accumulator[currentValue] = 0;
        return accumulator;
      }, {});
      return accumulator;
    }, {});
    console.log(routeSupportingPoints, pfVolumes);

    wtData.forEach((pf: WTData) => {
      for (let i = 0; i < pf.sensorIds.length - 1; i++) {
        // @ts-ignore
        if (routeSupportingPoints[pf.sensorIds[i]] !== void 0 && routeSupportingPoints[pf.sensorIds[i]][pf.sensorIds[i + 1]] !== void 0) {
          const viaPoints = [
            pf.sensorIds[i],
            // @ts-ignore
            ...routeSupportingPoints[pf.sensorIds[i]][pf.sensorIds[i + 1]],
            pf.sensorIds[i + 1],
          ];
          //console.log(viaPoints)
          for (let j = 0; j < viaPoints.length - 1; j++) {
            pfVolumes[viaPoints[j]][viaPoints[j + 1]]++;
          }
        } else {
          pfVolumes[pf.sensorIds[i]][pf.sensorIds[i + 1]]++;
        }
      }
    });

    function findMaxInObject(obj: any) {
      let max = -Infinity;

      for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // もしキーの値がオブジェクトなら、再帰的に関数を呼び出す
          let maxInNestedObject = findMaxInObject(obj[key]);
          if (maxInNestedObject > max) {
            max = maxInNestedObject;
          }
        } else if (typeof obj[key] === 'number') {
          // もしキーの値が数値なら、最大値と比較する
          if (obj[key] > max) {
            max = obj[key];
          }
        }
      }

      return max;
    }

    const maxVolume = findMaxInObject(pfVolumes);

    console.log(gateIds, pfVolumes, maxVolume);

    Object.entries(pfVolumes).forEach(([fromGate, toObj]) => {
      Object.entries(toObj as any).forEach(([toGate, volume]) => {
        if (fromGate != toGate) {
          const fromNode = gateLocDic[Number(fromGate)];
          const toNode = gateLocDic[Number(toGate)];
          if (toNode && fromNode) {
            const proportion = Number(volume) / maxVolume;
            const geta = 0.25 + 0.75 * proportion;
            const x = toNode.lon - fromNode.lon;
            const y = toNode.lat - fromNode.lat;
            const abs = Math.sqrt(x ** 2 + y ** 2);
            const srotX = SHIFT_COS_1 * x - SHIFT_SIN_1 * y;
            const srotY = SHIFT_SIN_1 * x + SHIFT_COS_1 * y;
            const rotX = ARROW_COS_1 * x - ARROW_SIN_1 * y;
            const rotY = ARROW_SIN_1 * x + ARROW_COS_1 * y;
            const feature = {
              type: 'Feature',
              properties: {
                color: (fromGate < toGate ? 'rgba(255, 0, 127, ' : 'rgba(0, 255, 127, ') + String(geta) + ')',
                width: 24 * proportion,
              },
              geometry: {
                type: 'LineString',
                coordinates: [
                  [srotX * SHIFT_RATIO + fromNode.lon, srotY * SHIFT_RATIO + fromNode.lat],
                  [srotX * SHIFT_RATIO + toNode.lon, srotY * SHIFT_RATIO + toNode.lat],
                  [srotX * SHIFT_RATIO + rotX / abs * geta * ARROW_HEAD_RATIO + toNode.lon, srotY * SHIFT_RATIO + rotY / abs * geta * ARROW_HEAD_RATIO + toNode.lat],
                ]
              }
            } as Feature;
            mapLineData.features.push(feature);
          } else {
            console.warn("toNode", toNode, "fromNode", fromNode);
          }
        }
      });
    });
  }

  console.log(areaSelectionsForGridUi, sTime.format("YYYY/M/D HH:mm"), eTime.format("YYYY/M/D HH:mm"), gTime, gMesh);

  const navToCurrentSelection = (newSelectionModel: GridSelectionModel, newSTime: Dayjs, newETime: Dayjs) => {
    const sid_list_n: number[] = [];
    newSelectionModel.forEach(v => sid_list_n.push(areas.filter(row => row.id === Number(v))[0].data_source_id!!));
    const newUrl = '/pf/' + (sid_list_n.length > 0 ? sid_list_n.join(',') : '-') + '/' + newSTime.format("YYYY/M/D/H/m") + '/' + newETime.format("YYYY/M/D/H/m");
    globalState.setMenuPfwtUrl(newUrl);
    mapLineData.features = [];
    setMapLines(mapLineData);
    navigate(newUrl);
  }

  const fetchData = () => {
    setLoading(true);
    const api = new PcounterApp.PfwtApi();
    const callback = function (error: any, data: any, response: any) {
      if (error) {
        console.error(error);
      } else {
        console.log('[PF] API called successfully.') // Returned data: ')
        // console.log(response.text);
        const wtData: WTData[] = response.text.split('\n').map((d: string) => {
          const dataArray = d.split(',');
          if (dataArray.length > 3) {
            const pf: WTData = {
              id: Number(dataArray[0]),
              times: [] as Date[],
              sensorIds: [] as number[],
              heights: [] as number[],
            };
            for (let i = 1; i < dataArray.length; i += 3) {
              pf.times.push(new Date(dataArray[i]));
              pf.sensorIds.push(Number(dataArray[i + 1]));
              pf.heights.push(Number(dataArray[i + 2]));
            }
            return pf;
          } else {
            return null;
          }
        }).filter((d: any) => d);
        console.log("PF", wtData);
        const tree = calcTree(areas.filter(row => row.id === Number(areaSelectionsForGridUi[0]))[0], wtData);
        mapLineData.features = [];
        createMapLineData(wtData);
        setWtData(wtData);
        setMapLines(mapLineData);
        setLoading(false);
      }
    };
    api.getpfwt(s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute, gTime, gMesh, callback);
  };

  if (areas.length === 0) {
    return <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>表示できるデータソースがありません</Title>
      </div>
    </React.Fragment>;
  }

  const showColmuns = columns.map(col => {
    col.headerName = t("pf.columns." + col.field);
    return col;
  });

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>{t("app.menu.pf")}</Title>
        <div style={{ width: '100%', flexGrow: 1 }}>
          <Grid style={{ height: '100%' }} container spacing={3}>
            <Grid item container direction="column" spacing={2} xs={12} md={5}>
              <Grid item xs={6}>
                <DataGrid
                  style={{ height: '100%' }}
                  rows={areas}
                  rowHeight={25}
                  columns={showColmuns}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}

                  onSelectionModelChange={(newSelectionModel) => {
                    setWtData([]);
                    navToCurrentSelection(newSelectionModel, sTime, eTime);
                  }}
                  selectionModel={areaSelectionsForGridUi}
                />
              </Grid>
              <Grid item xs>
                <Map
                  initialViewState={{
                    longitude: avgLon,
                    latitude: avgLat,
                    zoom: 16,
                  }}
                  mapStyle="mapbox://styles/mapbox/dark-v10"
                  mapboxAccessToken={MAPBOX_TOKEN}
                >
                  <Centrair2WallSource />
                  {markers.map(marker => {
                    const bgColor = isMarkerActive(marker.active) ? strokeColors[marker.active % strokeColors.length] : '#222222';
                    return (
                      <Marker
                        key={Math.random()}
                        latitude={marker.lat}
                        longitude={marker.lon}
                        // scale={0.5}
                        onClick={() => {
                          navToCurrentSelection([marker.id], sTime, eTime);
                        }}
                        style={{ cursor: 'pointer', zIndex: isMarkerActive(marker.active) ? 2 : 0 }}
                      >
                        <div
                          className="areaDot"
                          style={{ backgroundColor: bgColor }}
                        >
                          <div
                            className="idTag"
                            style={{ backgroundColor: bgColor }}
                          >
                            {marker.name}
                          </div>
                        </div>
                      </Marker>
                    );
                  })}
                  <NavigationControl />
                  <ScaleControl />
                  <FullscreenControl />
                  <GeolocateControl />
                  <Source id="my-data" type="geojson" data={mapLines} key={Math.random()}>
                    <Layer {...flowLayerStyle} />
                  </Source>
                </Map>

              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2} xs={12} md={7}>
              <Grid item xs="auto">
                <Toolbar
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    '& hr': {
                      mx: 1.5,
                    },
                  }}
                >

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl sx={{ width: 160, marginRight: 1 }}>
                      <MobileDateTimePicker
                        label={t("pf.control.start_time")}
                        value={sTime}
                        onChange={(v) => {
                          const newETime = (v as dayjs.Dayjs).add(eTime.diff(sTime));
                          navToCurrentSelection(areaSelectionsForGridUi, v!!, newETime);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                    </FormControl>
                    ～
                    <FormControl sx={{ width: 160, marginLeft: 1 }}>
                      <MobileDateTimePicker
                        label={t("pf.control.end_time")}
                        value={eTime}
                        onChange={(v) => {
                          navToCurrentSelection(areaSelectionsForGridUi, sTime, v!!);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                    </FormControl>
                  </LocalizationProvider>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <LoadingButton
                    variant="contained"
                    onClick={() => fetchData()}
                    endIcon={<GetAppIcon />}
                    loading={loading}
                    loadingPosition="end"
                    disabled={!areaSelectionsForGridUi.length}
                  >
                    {t("pf.control.get")}
                  </LoadingButton>
                </Toolbar>

              </Grid>
              <Grid item xs>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <WeightedTreeView
                    area={areaSelectionsForGridUi.length ? areas.filter(row => row.id === Number(areaSelectionsForGridUi[0]))[0] : null}
                    areas={areas}
                    sensors={user?.perms?.filter(row => row.data_source_type === 1)!!}
                    wtData={wtData}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
