import React from "react";
import { UserType } from "../types"; 

export type AuthUserContextType = {
  user: UserType | null;
  appLoaded: boolean;
  signin: (user:UserType, callback:() => void) => void;
  signout: (callback:() => void) => void;
}
const AuthUserContext = React.createContext<AuthUserContextType>({} as AuthUserContextType);
const PcounterApp = require('pcounter_app');

export const useAuthUserContext = ():AuthUserContextType => {
  return React.useContext<AuthUserContextType>(AuthUserContext);
}

export const getUserFromJson = (responseJson: any) : UserType => {
  const perms = responseJson.perms ? responseJson.perms.map((perm: any) => {
    perm.data_source_opt = JSON.parse(perm.data_source_opt);
    return perm;
  }) : [];
  const user: UserType = {
    id: responseJson.id,
    username: responseJson.username,
    sub: responseJson.sub,
    email: responseJson.email,
    perms: perms,
  }
  return user;
}

type Props = {
  children: React.ReactNode
}

export const AuthUserProvider = (props:Props) => {
  const [user, setUser] = React.useState<UserType | null>(null);
  const [appLoaded, setAppLoaded] = React.useState<boolean>(false);

  const signin = (newUser: UserType, callback: () => void) => {
    setUser(newUser);
    callback();
  }

  const signout = (callback: () => void) => {
    setUser(null);
    callback();
  }

  if (!appLoaded && !user) {
    const api = new PcounterApp.UserApi();
    const callback = function(error: any, data: any, response: any) {
      if (error) {
        console.warn(error);
        setAppLoaded(true);
      } else {
        console.log('[AuthUser] API called successfully. Returned data: ');
        // console.log(response.text);
        const responseJson = JSON.parse(response.text);
        console.log(responseJson);
        if (responseJson.result) {
          const user = getUserFromJson(responseJson);
          signin(user, () => {
            setAppLoaded(true);
            //navigate(fromPathName, { replace: true })
          });    
        } else {
          window.alert("ログインできませんでした");
        }
      }
    };
    api.getUserStatus(callback);
  }

  const value:AuthUserContextType = { user, appLoaded, signin, signout };
  return (
    <AuthUserContext.Provider value={value}>
      {props.children}
    </AuthUserContext.Provider>
  );
}