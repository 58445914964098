import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Title from '../Title';
import { GlobalStateContextType, useAuthUserContext, useGlobalStateContext } from "../providers";

import Map, { Marker, NavigationControl, ScaleControl, FullscreenControl, GeolocateControl, Layer, LineLayer, Source } from 'react-map-gl';
import { Expression } from 'mapbox-gl';
import { FeatureCollection, Feature } from 'geojson';
import { Box, Divider, FormControl, Toolbar } from '@mui/material';
import WeightedTreeView from '../elements/WeightedTreeView';
import { MAPBOX_TOKEN } from '../config';
import { Centrair2WallSource } from '../objects/centrair_2-wall';
import { isMarkerActive, MarkerProp } from '../types';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

import * as d3 from 'd3';
import { PermType } from '../types';

type WTNode = d3.layout.tree.Node & {
  data_source_id: number;
  total: number;
  children: WTNode[];
  level: number;
  parent?: WTNode;
}


const strokeColors = scaleOrdinal(schemeCategory10).range();

const PcounterApp = require('pcounter_app');

const columns: GridColDef[] = [
  { field: 'data_source_id', headerName: 'ソースID', maxWidth: 100 },
  { field: 'data_source_name', headerName: 'ソース名', minWidth: 200, flex: 1 },
  { field: 'data_source_group_name', headerName: 'グループ名', minWidth: 200, flex: 0.8 },
  { field: 'data_source_latitude', headerName: '緯度', minWidth: 120, flex: 0.2, hide: true },
  { field: 'data_source_longitude', headerName: '経度', minWidth: 120, flex: 0.2, hide: true },
  // { field: 'granularity_time', headerName: '時間粒度' },
  // { field: 'granularity_mesh', headerName: 'メッシュ粒度' },
];

const flowLayerStyle = {
  id: 'flow',
  type: 'line' as LineLayer["type"],
  paint: {
    'line-color': ['get', 'color'] as Expression,
    'line-width': ['get', 'width'] as Expression,
  }
};

const mapLineData = {
  type: 'FeatureCollection',
  features: [],
} as FeatureCollection;

const ARROW_DEG_1 = 150;
const ARROW_DEG_2 = 210;
const ARROW_COS_1 = Math.cos(ARROW_DEG_1 * Math.PI / 180); // 矢印の角度 (from→to のベクトルから何度回転するか)
const ARROW_SIN_1 = Math.sin(ARROW_DEG_1 * Math.PI / 180);
const ARROW_COS_2 = Math.cos(ARROW_DEG_2 * Math.PI / 180); // 矢印の角度 (from→to のベクトルから何度回転するか)
const ARROW_SIN_2 = Math.sin(ARROW_DEG_2 * Math.PI / 180);
const ARROW_HEAD_RATIO = 0.0002;

export default function PFWT() {
  let { sids, s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [wtData, setWtData] = React.useState([]);
  const [mapLines, setMapLines] = React.useState(mapLineData);
  const globalState: GlobalStateContextType = useGlobalStateContext();
  const { t } = useTranslation();

  const user = useAuthUserContext().user;
  const rows = user?.perms?.filter(row => row.data_source_type === 2)!!;

  // calc latlon av, parse url sid list
  let avg_lat = 0, avg_lon = 0;
  const sidSelections: GridSelectionModel = [];
  const markers: MarkerProp[] = [];
  const sid_list = sids?.split(",").map(v => parseInt(v));
  const sidLocDic: { [name: number]: MarkerProp } = {};
  rows.forEach((row, _) => {
    avg_lat += row.data_source_latitude!!;
    avg_lon += row.data_source_longitude!!;
    const marker: MarkerProp = {
      id: row.id!!,
      lat: row.data_source_latitude!!,
      lon: row.data_source_longitude!!,
      name: row.data_source_name!!,
      active: -1,
    };
    if (sid_list?.includes(row.data_source_id!!)) {
      sidSelections.push(row.id!!);
      marker.active = sid_list?.indexOf(row.data_source_id!!)!!;
    }
    markers.push(marker);
    sidLocDic[row.data_source_id!!] = marker;
  });
  avg_lat /= rows.length;
  avg_lon /= rows.length;

  // parse url date
  let sTime = dayjs().hour(0).minute(0);
  if (s_year !== void 0) sTime = sTime.year(parseInt(s_year));
  if (s_month !== void 0) sTime = sTime.month(parseInt(s_month) - 1);
  if (s_day !== void 0) sTime = sTime.date(parseInt(s_day));
  if (s_hour !== void 0) sTime = sTime.hour(parseInt(s_hour));
  if (s_minute !== void 0) sTime = sTime.minute(parseInt(s_minute));
  let eTime = dayjs().hour(23).minute(59);
  if (e_year !== void 0) eTime = eTime.year(parseInt(e_year));
  if (e_month !== void 0) eTime = eTime.month(parseInt(e_month) - 1);
  if (e_day !== void 0) eTime = eTime.date(parseInt(e_day));
  if (e_hour !== void 0) eTime = eTime.hour(parseInt(e_hour));
  if (e_minute !== void 0) eTime = eTime.minute(parseInt(e_minute));

  const gTime = 0;
  const gMesh = 0;

  const addOrUpdate = (node: WTNode, element: any, keyIndex: number) => {
    node.total++;
    if (keyIndex >= element.length)
      return;
    const match = node.children.find((item: WTNode) => item.data_source_id === element[keyIndex]);
    if (match) {
      addOrUpdate(match, element, keyIndex + 1);
    } else {
      const child = {
        data_source_id: element[keyIndex],
        parent: node,
        level: keyIndex + 1,
        total: 0,
        children: [],
      } as WTNode;
      node.children.push(child);
      addOrUpdate(child, element, keyIndex + 1);
    }
  }

  const calcTree = (area: any, wtData: []) => {
    const sensors = user?.perms?.filter(row => row.data_source_type === 1)!!;
    const sensorMap: { [key: number]: number } = {};
    sensors.forEach((sensor: PermType, i: number) => {
      sensorMap[sensor.data_source_id!!] = i;
    });
    const routes = wtData.map((pfwt: any) => {
      const route = [area.data_source_id];
      for (const element of pfwt.sensorIds) {
        const sensor = sensors[sensorMap[element]];
        if (sensor !== void 0) {
          if (sensor.data_source_opt!!.f === route[route.length - 1]) {
            route.push(sensor.data_source_opt!!.b);
          } else if (sensor.data_source_opt!!.b === route[route.length - 1]) {
            route.push(sensor.data_source_opt!!.f);
          } else {
            route.push(-1);
            break;
          }
        } else {
          console.warn("sensor", sensor, "sensors[sensorMap[element]]", sensors[sensorMap[element]], "sensorMap[element]", sensorMap[element], "element", element);
        }
      }
      return route;
    });
    const _wt_root = {
      data_source_id: area.data_source_id!!,
      total: 0,
      children: [],
      level: 1,
    };
    routes.forEach((e: any) => {
      addOrUpdate(_wt_root, e, 1);
    });
    return _wt_root;
  }

  const createMapLineData = (node: WTNode, total: number) => {
    node.children.forEach(child => createMapLineData(child as WTNode, total));
    if (node.parent && node.data_source_id !== -1) {
      const fromNode = sidLocDic[node.parent.data_source_id];
      const toNode = sidLocDic[node.data_source_id];
      const proportion = node.total / total;
      const geta = 0.25 + 0.75 * proportion;
      const feature = {
        type: 'Feature',
        properties: {
          color: 'rgba(255, 255, 0, ' + String(geta) + ')', // red
          width: 16 * proportion,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [fromNode.lon, fromNode.lat],
            [toNode.lon, toNode.lat],
          ]
        }
      } as Feature;
      mapLineData.features.push(feature);
      const x = toNode.lon - fromNode.lon;
      const y = toNode.lat - fromNode.lat;
      const abs = Math.sqrt(x ** 2 + y ** 2);
      const rotX1 = ARROW_COS_1 * x - ARROW_SIN_1 * y;
      const rotY1 = ARROW_SIN_1 * x + ARROW_COS_1 * y;
      const rotX2 = ARROW_COS_2 * x - ARROW_SIN_2 * y;
      const rotY2 = ARROW_SIN_2 * x + ARROW_COS_2 * y;
      console.log([rotY1 / abs * proportion * 0.001 + toNode.lat, rotX1 / abs * proportion * 0.1 + toNode.lon],
        [toNode.lon, toNode.lat],
        [rotY2 / abs * proportion * 0.001 + toNode.lat, rotX2 / abs * proportion * 0.1 + toNode.lon])
      const feature2 = {
        type: 'Feature',
        properties: {
          color: 'rgba(255, 255, 0, ' + String(geta) + ')', // red
          width: 16 * proportion,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [rotX1 / abs * geta * ARROW_HEAD_RATIO + toNode.lon, rotY1 / abs * geta * ARROW_HEAD_RATIO + toNode.lat],
            [toNode.lon, toNode.lat],
            [rotX2 / abs * geta * ARROW_HEAD_RATIO + toNode.lon, rotY2 / abs * geta * ARROW_HEAD_RATIO + toNode.lat],
          ]
        }
      } as Feature;
      mapLineData.features.push(feature2);
    }
  }

  console.log(sidSelections, sTime.format("YYYY/M/D HH:mm"), eTime.format("YYYY/M/D HH:mm"), gTime, gMesh);

  const navToCurrentSelection = (newSelectionModel: GridSelectionModel, newSTime: Dayjs, newETime: Dayjs) => {
    const sid_list_n: number[] = [];
    newSelectionModel.forEach(v => sid_list_n.push(rows.filter(row => row.id === Number(v))[0].data_source_id!!));
    const newUrl = '/pfwt/' + (sid_list_n.length > 0 ? sid_list_n.join(',') : '-') + '/' + newSTime.format("YYYY/M/D/H/m") + '/' + newETime.format("YYYY/M/D/H/m");
    globalState.setMenuPfwtUrl(newUrl);
    mapLineData.features = [];
    setMapLines(mapLineData);
    navigate(newUrl);
  }

  const fetchData = () => {
    setLoading(true);
    const api = new PcounterApp.PfwtApi();
    const callback = function (error: any, data: any, response: any) {
      if (error) {
        console.error(error);
      } else {
        console.log('[PFWT] API called successfully.') // Returned data: ')
        // console.log(response.text);
        const wtData = response.text.split('\n').map((d: string) => {
          const dataArray = d.split(',');
          if (dataArray.length > 3) {
            const pfwt = {
              id: Number(dataArray[0]),
              times: [] as Date[],
              sensorIds: [] as number[],
              heights: [] as number[],
            }
            for (let i = 1; i < dataArray.length; i += 3) {
              pfwt.times.push(new Date(dataArray[i]));
              pfwt.sensorIds.push(Number(dataArray[i + 1]));
              pfwt.heights.push(Number(dataArray[i + 2]));
            }
            return pfwt;
          } else {
            return null;
          }
        }).filter((d: any) => d);
        console.log("PFWT", wtData);
        const tree = calcTree(rows.filter(row => row.id === Number(sidSelections[0]))[0], wtData);
        mapLineData.features = [];
        createMapLineData(tree, tree.total);
        setWtData(wtData);
        setMapLines(mapLineData);
        setLoading(false);
      }
    };
    api.getpfwtById(sid_list!![0], s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute, gTime, gMesh, callback);
  };

  if (rows.length === 0) {
    return <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>表示できるデータソースがありません</Title>
      </div>
    </React.Fragment>;
  }

  const showColmuns = columns.map(col => {
    col.headerName = t("pfwt.columns." + col.field);
    return col;
  });

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>{t("app.menu.pfwt")}</Title>
        <div style={{ width: '100%', flexGrow: 1 }}>
          <Grid style={{ height: '100%' }} container spacing={3}>
            <Grid item container direction="column" spacing={2} xs={12} md={5}>
              <Grid item xs={6}>
                <DataGrid
                  style={{ height: '100%' }}
                  rows={rows}
                  rowHeight={25}
                  columns={showColmuns}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}

                  onSelectionModelChange={(newSelectionModel) => {
                    setWtData([]);
                    navToCurrentSelection(newSelectionModel, sTime, eTime);
                  }}
                  selectionModel={sidSelections}
                />
              </Grid>
              <Grid item xs>
                <Map
                  initialViewState={{
                    longitude: avg_lon,
                    latitude: avg_lat,
                    zoom: 16,
                  }}
                  mapStyle="mapbox://styles/mapbox/dark-v10"
                  mapboxAccessToken={MAPBOX_TOKEN}
                >
                  <Centrair2WallSource />
                  {markers.map(marker => {
                    const bgColor = isMarkerActive(marker.active) ? strokeColors[marker.active % strokeColors.length] : '#222222';
                    return (
                      <Marker
                        key={Math.random()}
                        latitude={marker.lat}
                        longitude={marker.lon}
                        // scale={0.5}
                        onClick={() => {
                          navToCurrentSelection([marker.id], sTime, eTime);
                        }}
                        style={{ cursor: 'pointer', zIndex: isMarkerActive(marker.active) ? 2 : 0 }}
                      >
                        <div
                          className="areaDot"
                          style={{ backgroundColor: bgColor }}
                        >
                          <div
                            className="idTag"
                            style={{ backgroundColor: bgColor }}
                          >
                            {marker.name}
                          </div>
                        </div>
                      </Marker>
                    );
                  })}
                  <NavigationControl />
                  <ScaleControl />
                  <FullscreenControl />
                  <GeolocateControl />
                  <Source id="my-data" type="geojson" data={mapLines} key={Math.random()}>
                    <Layer {...flowLayerStyle} />
                  </Source>
                </Map>

              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2} xs={12} md={7}>
              <Grid item xs="auto">
                <Toolbar
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    '& hr': {
                      mx: 1.5,
                    },
                  }}
                >

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl sx={{ width: 160, marginRight: 1 }}>
                      <MobileDateTimePicker
                        label={t("pfwt.control.start_time")}
                        value={sTime}
                        onChange={(v) => {
                          const newETime = (v as dayjs.Dayjs).add(eTime.diff(sTime));
                          navToCurrentSelection(sidSelections, v!!, newETime);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                    </FormControl>
                    ～
                    <FormControl sx={{ width: 160, marginLeft: 1 }}>
                      <MobileDateTimePicker
                        label={t("pfwt.control.end_time")}
                        value={eTime}
                        onChange={(v) => {
                          navToCurrentSelection(sidSelections, sTime, v!!);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                    </FormControl>
                  </LocalizationProvider>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <LoadingButton
                    variant="contained"
                    onClick={() => fetchData()}
                    endIcon={<GetAppIcon />}
                    loading={loading}
                    loadingPosition="end"
                    disabled={!sidSelections.length}
                  >
                    {t("pfwt.control.get")}
                  </LoadingButton>
                </Toolbar>

              </Grid>
              <Grid item xs>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <WeightedTreeView
                    area={sidSelections.length ? rows.filter(row => row.id === Number(sidSelections[0]))[0] : null}
                    areas={rows}
                    sensors={user?.perms?.filter(row => row.data_source_type === 1)!!}
                    wtData={wtData}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
