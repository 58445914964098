import * as React from 'react';
import Title from '../Title';
import { useAuthUserContext } from "../providers";

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GetDataSourceTypeName } from '../types';
import { useTranslation } from 'react-i18next';

const columns: GridColDef[] = [
  { field: 'id', headerName: '権限ID', maxWidth: 100 },
  { field: 'data_source_id', headerName: 'ソースID', maxWidth: 100 },
  { field: 'data_source_type', headerName: 'ソース種別', minWidth: 100, valueGetter: GetDataSourceTypeName },
  { field: 'data_source_name', headerName: 'ソース名', minWidth: 200, flex: 1.2 },
  { field: 'data_source_group_name', headerName: 'グループ名', minWidth: 160, flex: 0.8 },
  { field: 'data_source_owner_username', headerName: 'ソース所有者名', minWidth: 150 },
  { field: 'data_source_latitude', headerName: '緯度', minWidth: 120, flex: 0.2 },
  { field: 'data_source_longitude', headerName: '経度', minWidth: 120, flex: 0.2 },
  { field: 'data_source_radius', headerName: '範囲', minWidth: 120, flex: 0.2 },
  { field: 'granularity_time', headerName: '時間粒度' },
  { field: 'granularity_mesh', headerName: 'メッシュ粒度' },
  { field: 'opt', headerName: '権限', minWidth: 150, flex: 0.5 },
];

export default function DataSources() {
  const { t } = useTranslation();
  const user = useAuthUserContext().user;
  const rows = user?.perms!!;

  const showColmuns = columns.map(col => {
    col.headerName = t("datasources.columns." + col.field);
    return col;
  });

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>{t("app.menu.datasources")}</Title>
        <div style={{width: '100%', flexGrow: 1}}>
          <DataGrid
            rows={rows}
            rowHeight={25}
            columns={showColmuns}
            checkboxSelection
          />
        </div>
      </div>
    </React.Fragment>
  );
}
