export const colmunKeys: { [key: string]: string; } = {
  lat: '緯度',
  lon: '経度',
  alt: '高度',
  spd: '速度',
  acc: '加速度',
  dir_s: '速度方向',
  dir_a: '加速度方向',
  rssi: 'RSSI',
};
