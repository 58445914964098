import { Layer, Source } from 'react-map-gl';

const PolylineOverlay = (props) => {

  const layerStyle = {
    type: 'line',
    paint: {
      'line-color': props.color || 'red',
      'line-opacity': 0.8,
    }
  };

  const layerStylePoint = {
    type: 'circle',
    paint: {
      'circle-color': props.color || 'red',
      'circle-opacity': 0.9,
      'circle-radius': 2.5,
    }
  };

  const routesGeojson = {
    "type": "FeatureCollection",
    "name": String(props.id),
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
      {
        "type": "Feature",
        "properties": { "id": String(props.id) },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [[props.points.map(point => [point.lon, point.lat])]]
        }
      }
    ]
  };

  return (
    <Source id={String(props.id)} type="geojson" data={routesGeojson}>
      <Layer {...layerStyle} />
      <Layer {...layerStylePoint} />
    </Source>
  );
}

export default PolylineOverlay;
