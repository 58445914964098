import * as React from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function NotFound() {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      height='100vh'
    >
      <Typography component="h2" variant="h2" color="primary" align='center' gutterBottom>
        Not Found
      </Typography>
      <Link to="/"><Button variant="text">HOME</Button></Link>
    </Stack>
  );
}

export default NotFound;
