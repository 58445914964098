export const supportingPoints = {
    1052.9: [136.816135,34.859596],  // 名鉄西角
    1051.8: [136.816274,34.859589],  // 名鉄改札内
    1051.7: [136.816292,34.859498],  // 名鉄改札外
    1008.5: [136.816310,34.859423],  // インフォ北
    1051.9: [136.816398,34.859630],  // 名鉄東角
    1008.6: [136.816360,34.859204],  // インフォ南
    1008.4: [136.816206,34.859293],  // インフォ西
    1008.8: [136.816467,34.859336],  // インフォ東
    1008.9: [136.816610,34.859360],  // インフォ東2
    1020.9: [136.816161,34.859077],  // ホテル方面口
    1008.7: [136.816386,34.859099],  // インフォ南2
    1008.2: [136.815216,34.859185],  // T1-3F通路北
    1008.3: [136.815245,34.859072],  // T1-3F通路南
    9014: [136.817786,34.855867],  // FOD角
    // 9015: [],
    // 9016: [],
    // 9017: [],
    // 9018: [],
    // 9019: [],
    // 9020: [],
    // 9021: [],
    // 9022: [],
    // 9023: [],
    // 9024: [],
    // 9025: [],
    // 9026: [],
    // 9027: [],
    // 9028: [],
    // 9029: [],
    // 9030: [],
    // 9031: [],
    // 9032: [],
    // 9033: [],
    // 9034: [],
    // 9035: [],
    // 9036: [],
    // 9037: [],
    // 9038: [],
    // 9039: [],
    // 9040: [],
    // 9041: [],
    // 9042: [],
    // 9043: [],
    // 9044: [],
    // 9045: [],
    // 9046: [],
    // 9047: [],
    // 9048: [],
    // 9049: [],
    // 9050: [],
};

export const routeSupportingPoints = {
    1001: {
        1009: [1008.6,1008.9],
        1010: [1008.6,1008.9],
        1011: [1008.6,1008.9],
        1012: [1008.7],
        1013: [1008.7],
        1014: [1008.7],
        1015: [1008.7],
        1019: [1008.7],
        1020: [1008.7],
        1016: [1008.6],
        1017: [1008.6],
        1018: [1008.6],
        1021: [1020.9],
        1052: [1008.4,1008.5,1051.7,1051.8,1051.9],
        1053: [1008.4,1008.5,1051.7,1051.8,1052.9],
    },
    1002: {
        1009: [1008.6,1008.9],
        1010: [1008.6,1008.9],
        1011: [1008.6,1008.9],
        1012: [1008.7],
        1013: [1008.7],
        1014: [1008.7],
        1015: [1008.7],
        1019: [1008.7],
        1020: [1008.7],
        1016: [1008.6],
        1017: [1008.6],
        1018: [1008.6],
        1021: [1020.9],
        1052: [1008.4,1008.5,1051.7,1051.8,1051.9],
        1053: [1008.4,1008.5,1051.7,1051.8,1052.9],
    },
    1003: {
        1009: [1008.5,1008.9],
        1010: [1008.5,1008.9],
        1011: [1008.5,1008.9],
        1012: [1008.4,1008.6,1008.7],
        1013: [1008.4,1008.6,1008.7],
        1014: [1008.4,1008.6,1008.7],
        1015: [1008.4,1008.6,1008.7],
        1019: [1008.4,1008.6,1008.7],
        1020: [1008.4,1008.6,1008.7],
        1016: [1008.4,1008.6],
        1017: [1008.4,1008.6],
        1018: [1008.4,1008.6],
        1021: [1020.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1004: {
        1009: [1008.5,1008.9],
        1010: [1008.5,1008.9],
        1011: [1008.5,1008.9],
        1012: [1008.4,1008.6,1008.7],
        1013: [1008.4,1008.6,1008.7],
        1014: [1008.4,1008.6,1008.7],
        1015: [1008.4,1008.6,1008.7],
        1019: [1008.4,1008.6,1008.7],
        1020: [1008.4,1008.6,1008.7],
        1016: [1008.4,1008.6],
        1017: [1008.4,1008.6],
        1018: [1008.4,1008.6],
        1021: [1020.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1005: {
        1009: [1008.6,1008.9],
        1010: [1008.6,1008.9],
        1011: [1008.6,1008.9],
        1012: [1008.6,1008.7],
        1013: [1008.6,1008.7],
        1014: [1008.6,1008.7],
        1015: [1008.6,1008.7],
        1019: [1008.6,1008.7],
        1020: [1008.6,1008.7],
        1016: [1008.6],
        1017: [1008.6],
        1018: [1008.6],
        1021: [1020.9],
        1040: [1008.3],
        1041: [1008.3],
        1042: [1008.3],
        1043: [1008.3],
        1044: [1008.3],
        1052: [1008.4,1008.5,1051.7,1051.8,1051.9],
        1053: [1008.4,1008.5,1051.7,1051.8,1052.9],
    },
    1006: {
        1009: [1008.6,1008.9],
        1010: [1008.6,1008.9],
        1011: [1008.6,1008.9],
        1012: [1008.6,1008.7],
        1013: [1008.6,1008.7],
        1014: [1008.6,1008.7],
        1015: [1008.6,1008.7],
        1019: [1008.6,1008.7],
        1020: [1008.6,1008.7],
        1016: [1008.6],
        1017: [1008.6],
        1018: [1008.6],
        1021: [1020.9],
        1040: [1008.3],
        1041: [1008.3],
        1042: [1008.3],
        1043: [1008.3],
        1044: [1008.3],
        1052: [1008.4,1008.5,1051.7,1051.8,1051.9],
        1053: [1008.4,1008.5,1051.7,1051.8,1052.9],
    },
    1007: {
        1009: [1008.5,1008.9],
        1010: [1008.5,1008.9],
        1011: [1008.5,1008.9],
        1012: [1008.4,1008.6,1008.7],
        1013: [1008.4,1008.6,1008.7],
        1014: [1008.4,1008.6,1008.7],
        1015: [1008.4,1008.6,1008.7],
        1019: [1008.4,1008.6,1008.7],
        1020: [1008.4,1008.6,1008.7],
        1016: [1008.4,1008.6],
        1017: [1008.4,1008.6],
        1018: [1008.4,1008.6],
        1021: [1020.9],
        1040: [1008.2],
        1041: [1008.2],
        1042: [1008.2],
        1043: [1008.2],
        1044: [1008.2],
        1052: [1008.5,1051.7,1051.8,1051.9],
        1053: [1008.5,1051.7,1051.8,1052.9],
    },
    1008: {
        1009: [1008.5,1008.9],
        1010: [1008.5,1008.9],
        1011: [1008.5,1008.9],
        1012: [1008.4,1008.6,1008.7],
        1013: [1008.4,1008.6,1008.7],
        1014: [1008.4,1008.6,1008.7],
        1015: [1008.4,1008.6,1008.7],
        1019: [1008.4,1008.6,1008.7],
        1020: [1008.4,1008.6,1008.7],
        1016: [1008.4,1008.6],
        1017: [1008.4,1008.6],
        1018: [1008.4,1008.6],
        1021: [1020.9],
        1040: [1008.2],
        1041: [1008.2],
        1042: [1008.2],
        1043: [1008.2],
        1044: [1008.2],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1009: {
        1001: [1008.9,1008.6],
        1002: [1008.9,1008.6],
        1003: [1008.9,1008.5],
        1004: [1008.9,1008.5],
        1005: [1008.9,1008.6],
        1006: [1008.9,1008.6],
        1007: [1008.9,1008.5],
        1008: [1008.9,1008.5],
        1012: [1008.9,1008.6,1008.7],
        1013: [1008.9,1008.6,1008.7],
        1014: [1008.9,1008.6,1008.7],
        1015: [1008.9,1008.6,1008.7],
        1019: [1008.9,1008.6,1008.7],
        1020: [1008.9,1008.6,1008.7],
        1021: [1008.9,1008.6,1020.9],
        1052: [1008.9,1008.5,1051.7,1051.8,1051.9],
        1053: [1008.9,1008.5,1051.7,1051.8,1052.9],
    },
    1010: {
        1001: [1008.9,1008.6],
        1002: [1008.9,1008.6],
        1003: [1008.9,1008.5],
        1004: [1008.9,1008.5],
        1005: [1008.9,1008.6],
        1006: [1008.9,1008.6],
        1007: [1008.9,1008.5],
        1008: [1008.9,1008.5],
        1012: [1008.9,1008.6,1008.7],
        1013: [1008.9,1008.6,1008.7],
        1014: [1008.9,1008.6,1008.7],
        1015: [1008.9,1008.6,1008.7],
        1019: [1008.9,1008.6,1008.7],
        1020: [1008.9,1008.6,1008.7],
        1021: [1008.9,1008.6,1020.9],
        1052: [1008.9,1008.5,1051.7,1051.8,1051.9],
        1053: [1008.9,1008.5,1051.7,1051.8,1052.9],
    },
    1011: {
        1001: [1008.9,1008.6],
        1002: [1008.9,1008.6],
        1003: [1008.9,1008.5],
        1004: [1008.9,1008.5],
        1005: [1008.9,1008.6],
        1006: [1008.9,1008.6],
        1007: [1008.9,1008.5],
        1008: [1008.9,1008.5],
        1012: [1008.9,1008.6,1008.7],
        1013: [1008.9,1008.6,1008.7],
        1014: [1008.9,1008.6,1008.7],
        1015: [1008.9,1008.6,1008.7],
        1019: [1008.9,1008.6,1008.7],
        1020: [1008.9,1008.6,1008.7],
        1021: [1008.9,1008.6,1020.9],
        1052: [1008.9,1008.5,1051.7,1051.8,1051.9],
        1053: [1008.9,1008.5,1051.7,1051.8,1052.9],
    },
    1012: {
        1001: [1008.7],
        1002: [1008.7],
        1003: [1008.4,1008.6,1008.7],
        1004: [1008.4,1008.6,1008.7],
        1005: [1008.6,1008.7],
        1006: [1008.6,1008.7],
        1007: [1008.4,1008.6,1008.7],
        1008: [1008.4,1008.6,1008.7],
        1009: [1008.7,1008.6,1008.9],
        1010: [1008.7,1008.6,1008.9],
        1011: [1008.7,1008.6,1008.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1013: {
        1001: [1008.7],
        1002: [1008.7],
        1003: [1008.4,1008.6,1008.7],
        1004: [1008.4,1008.6,1008.7],
        1005: [1008.6,1008.7],
        1006: [1008.6,1008.7],
        1007: [1008.4,1008.6,1008.7],
        1008: [1008.4,1008.6,1008.7],
        1009: [1008.7,1008.6,1008.9],
        1010: [1008.7,1008.6,1008.9],
        1011: [1008.7,1008.6,1008.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1014: {
        1001: [1008.7],
        1002: [1008.7],
        1003: [1008.4,1008.6,1008.7],
        1004: [1008.4,1008.6,1008.7],
        1005: [1008.6,1008.7],
        1006: [1008.6,1008.7],
        1007: [1008.4,1008.6,1008.7],
        1008: [1008.4,1008.6,1008.7],
        1009: [1008.7,1008.6,1008.9],
        1010: [1008.7,1008.6,1008.9],
        1011: [1008.7,1008.6,1008.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1015: {
        1001: [1008.7],
        1002: [1008.7],
        1003: [1008.4,1008.6,1008.7],
        1004: [1008.4,1008.6,1008.7],
        1005: [1008.6,1008.7],
        1006: [1008.6,1008.7],
        1007: [1008.4,1008.6,1008.7],
        1008: [1008.4,1008.6,1008.7],
        1009: [1008.7,1008.6,1008.9],
        1010: [1008.7,1008.6,1008.9],
        1011: [1008.7,1008.6,1008.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1016: {
        1001: [1008.6],
        1002: [1008.6],
        1003: [1008.6,1008.4],
        1004: [1008.6,1008.4],
        1005: [1008.6],
        1006: [1008.6],
        1007: [1008.6,1008.4],
        1008: [1008.6,1008.4],
        1021: [1008.6,1020.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1017: {
        1001: [1008.6],
        1002: [1008.6],
        1003: [1008.6,1008.4],
        1004: [1008.6,1008.4],
        1005: [1008.6],
        1006: [1008.6],
        1007: [1008.6,1008.4],
        1008: [1008.6,1008.4],
        1021: [1008.6,1020.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1018: {
        1001: [1008.6],
        1002: [1008.6],
        1003: [1008.6,1008.4],
        1004: [1008.6,1008.4],
        1005: [1008.6],
        1006: [1008.6],
        1007: [1008.6,1008.4],
        1008: [1008.6,1008.4],
        1021: [1008.6,1020.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1019: {
        1001: [1008.7],
        1002: [1008.7],
        1003: [1008.4,1008.6,1008.7],
        1004: [1008.4,1008.6,1008.7],
        1005: [1008.6,1008.7],
        1006: [1008.6,1008.7],
        1007: [1008.4,1008.6,1008.7],
        1008: [1008.4,1008.6,1008.7],
        1009: [1008.7,1008.6,1008.9],
        1010: [1008.7,1008.6,1008.9],
        1011: [1008.7,1008.6,1008.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1020: {
        1001: [1008.7],
        1002: [1008.7],
        1003: [1008.4,1008.6,1008.7],
        1004: [1008.4,1008.6,1008.7],
        1005: [1008.6,1008.7],
        1006: [1008.6,1008.7],
        1007: [1008.4,1008.6,1008.7],
        1008: [1008.4,1008.6,1008.7],
        1009: [1008.7,1008.6,1008.9],
        1010: [1008.7,1008.6,1008.9],
        1011: [1008.7,1008.6,1008.9],
        1052: [1051.7,1051.8,1051.9],
        1053: [1051.7,1051.8,1052.9],
    },
    1021: {
        1001: [1020.9],
        1002: [1020.9],
        1003: [1020.9],
        1004: [1020.9],
        1005: [1020.9],
        1006: [1020.9],
        1007: [1020.9],
        1008: [1020.9],
        1009: [1020.9,1008.6,1008.9],
        1010: [1020.9,1008.6,1008.9],
        1011: [1020.9,1008.6,1008.9],
        1016: [1020.9,1008.6],
        1017: [1020.9,1008.6],
        1018: [1020.9,1008.6],
        1052: [1020.9,1008.5,1051.7,1051.8,1051.9],
        1053: [1020.9,1008.5,1051.7,1051.8,1052.9],
    },
    1022: {
        1049: [9014],
    },
    1023: {
        1049: [9014],
    },
    1024: {
        1049: [9014],
    },
    1049: {
        1022: [9014],
        1023: [9014],
        1024: [9014],
    },
    1052: {  // 名鉄東
        1001: [1051.9,1051.8,1051.7,1008.5,1008.4],
        1002: [1051.9,1051.8,1051.7,1008.5,1008.4],
        1003: [1051.9,1051.8,1051.7],
        1004: [1051.9,1051.8,1051.7],
        1005: [1051.9,1051.8,1051.7,1008.5,1008.4],
        1006: [1051.9,1051.8,1051.7,1008.5,1008.4],
        1007: [1051.9,1051.8,1051.7,1008.5],
        1008: [1051.9,1051.8,1051.7],
        1009: [1051.9,1051.8,1051.7,1008.5,1008.9],
        1010: [1051.9,1051.8,1051.7,1008.5,1008.9],
        1011: [1051.9,1051.8,1051.7,1008.5,1008.9],
        1012: [1051.9,1051.8,1051.7],
        1013: [1051.9,1051.8,1051.7],
        1014: [1051.9,1051.8,1051.7],
        1015: [1051.9,1051.8,1051.7],
        1016: [1051.9,1051.8,1051.7],
        1017: [1051.9,1051.8,1051.7],
        1018: [1051.9,1051.8,1051.7],
        1019: [1051.9,1051.8,1051.7],
        1020: [1051.9,1051.8,1051.7],
        1021: [1051.9,1051.8,1051.7,1008.5,1020.9],
    },
    1053: {  // 名鉄西
        1001: [1052.9,1051.8,1051.7,1008.5],
        1002: [1052.9,1051.8,1051.7,1008.5],
        1003: [1052.9,1051.8,1051.7],
        1004: [1052.9,1051.8,1051.7],
        1005: [1052.9,1051.8,1051.7,1008.5],
        1006: [1052.9,1051.8,1051.7,1008.5],
        1007: [1052.9,1051.8,1051.7,1008.5],
        1008: [1052.9,1051.8,1051.7],
        1009: [1052.9,1051.8,1051.7,1008.5,1008.9],
        1010: [1052.9,1051.8,1051.7,1008.5,1008.9],
        1011: [1052.9,1051.8,1051.7,1008.5,1008.9],
        1012: [1052.9,1051.8,1051.7],
        1013: [1052.9,1051.8,1051.7],
        1014: [1052.9,1051.8,1051.7],
        1015: [1052.9,1051.8,1051.7],
        1016: [1052.9,1051.8,1051.7],
        1017: [1052.9,1051.8,1051.7],
        1018: [1052.9,1051.8,1051.7],
        1019: [1052.9,1051.8,1051.7],
        1020: [1052.9,1051.8,1051.7],
        1021: [1052.9,1051.8,1051.7,1008.5,1020.9],
    },
}

