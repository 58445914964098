import { GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

export type PermType = {
    id?: number;
    user_id?: number;
    data_source_id?: number;
    data_source_name?: string;
    data_source_owner_id?: number;
    data_source_owner_username?: string;
    data_source_type?: number;
    data_source_latitude?: number;
    data_source_longitude?: number;
    data_source_radius?: number;
    data_source_opt?: any;
    granularity_time?: number;
    granularity_mesh?: number;
    opt?: string;
};

export type UserType = {
    id: number;
    sub: string;
    username: string;
    email: string;
    perms: PermType[];
}

export const GetDataSourceTypeName = (params: GridValueGetterParams) => {
    const { t } = useTranslation();
    const type = params.row.data_source_type;
    const types = [t("datasources.data_source_types.unknown"), t("datasources.data_source_types.passing"), t("datasources.data_source_types.staying"), t("datasources.data_source_types.path")];
    return types[type];
};

export type TimelineChartData = { [key: string | number]: any; };
export type TimelineChartDataList = TimelineChartData[];

export type MarkerProp = {
    id: number;
    lat: number;
    lon: number;
    name: string;
    active: number;
    time?: string;
}
export const isMarkerActive = (v: any) => v !== void 0 && v !== -1;

