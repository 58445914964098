import { Layer, Source } from 'react-map-gl';

const centrair_2Wall_geojson = {
  "type": "FeatureCollection",
  "name": "centrair_2-wall",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  "features": [
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813464589023909, 34.858888643801777], [136.81341671218587, 34.858881653738543], [136.813416280863009, 34.858885104529328], [136.813463402886015, 34.858892271555874], [136.813464589023909, 34.858888643801777]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813325749949797, 34.858837555372418], [136.81332536655168, 34.858841173297456], [136.813489748491207, 34.858866833033368], [136.813490611133858, 34.858865397660999], [136.813325749949797, 34.858837555372418]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813528950944118, 34.858846836136323], [136.813529909439353, 34.858844319319097], [136.813330734125117, 34.858811128784708], [136.81333015902797, 34.858813960205218], [136.813528950944118, 34.858846836136323]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813329045813447, 34.858827414366935], [136.813328183167727, 34.858829360968166], [136.813438004427127, 34.858847455184943], [136.813437523514096, 34.858845287703794], [136.813329045813447, 34.858827414366935]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812076950445885, 34.858596196155531], [136.811925124797284, 34.858573721692977], [136.811924477812994, 34.85857631716928], [136.812075656477276, 34.858599499488086], [136.812076950445885, 34.858596196155531]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812079322721644, 34.858587053002225], [136.812079897818762, 34.85858363169298], [136.811928575380193, 34.858560449369691], [136.811928000283046, 34.858563516751261], [136.812079322721644, 34.858587053002225]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812085361241714, 34.858574665502559], [136.812086080113147, 34.858571126216603], [136.811931738414529, 34.858546764127446], [136.811931235204497, 34.858549831509528], [136.812085361241714, 34.858574665502559]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816237291113907, 34.858610563029124], [136.816228600756915, 34.858609304616905], [136.816119971294626, 34.859057927352836], [136.816129172849088, 34.859059185758184], [136.816237291113907, 34.858610563029124]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816818394837725, 34.859553003506953], [136.816804720305413, 34.859550381844848], [136.816790534575574, 34.859612043315451], [136.816803058913592, 34.859614874708406], [136.816818394837725, 34.859553003506953]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816658397277763, 34.859558703489839], [136.816665681439019, 34.859531535036922], [136.816598799594857, 34.859520124284018], [136.81659250872832, 34.859548107794268], [136.816658397277763, 34.859558703489839]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816017923500027, 34.859476398505777], [136.816019457092409, 34.859470525977081], [136.815305058628496, 34.859355172649707], [136.815304036233528, 34.85936209385391], [136.816017923500027, 34.859476398505777]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815997748211572, 34.859398739851045], [136.815998826518751, 34.859396439338234], [136.815379015561348, 34.859296101524663], [136.815377290269879, 34.859298225077545], [136.815997748211572, 34.859398739851045]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816041419650503, 34.859384804051139], [136.816042282296252, 34.859379849099554], [136.815326609832084, 34.859263230688583], [136.815325100202045, 34.859269424386795], [136.816041419650503, 34.859384804051139]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815380201698133, 34.859043088654843], [136.816095335008669, 34.85915829167363], [136.816095766331529, 34.859152628856201], [136.815381280005283, 34.85903724886618], [136.815380201698133, 34.859043088654843]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816116361999548, 34.859067465344111], [136.816118087290988, 34.859061802520436], [136.815404247948464, 34.858945493344571], [136.815401875672706, 34.858951156176261], [136.816116361999548, 34.859067465344111]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81646911916647, 34.859562651222781], [136.816468607969028, 34.859566216682694], [136.816689956473425, 34.859601871273306], [136.816690595470249, 34.859598410681372], [136.81646911916647, 34.859562651222781]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816873142671852, 34.859436266768199], [136.816878357469108, 34.859415890399021], [136.816873556544664, 34.859415075344153], [136.816868507296533, 34.859435519634751], [136.816873142671852, 34.859436266768199]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816884925428212, 34.859387546970559], [136.816888915164725, 34.859368700457324], [136.816886003735391, 34.859367904125676], [136.816881690506733, 34.859387193045478], [136.816884925428212, 34.859387546970559]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81699608388422, 34.859453675225822], [136.816995061489308, 34.859461015888144], [136.818558963498788, 34.859719807100554], [136.818557919440707, 34.859712953452927], [136.81699608388422, 34.859453675225822]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817519577559466, 34.859482258514632], [136.817521320492801, 34.859479789370184], [136.817090068499652, 34.859408347730891], [136.817090048303726, 34.859411179082343], [136.817519577559466, 34.859482258514632]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81655073826991, 34.859100905616764], [136.816546531604416, 34.859120849473413], [136.816640013059697, 34.859136190898326], [136.816644294702968, 34.859115700659871], [136.81655073826991, 34.859100905616764]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816427605490077, 34.859564778694612], [136.816387408464294, 34.859557108022486], [136.816381823281148, 34.859580194431011], [136.816421703499515, 34.859586604271634], [136.816427605490077, 34.859564778694612]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815308925594707, 34.859356490038074], [136.815312591839074, 34.859342333027882], [136.815309572578997, 34.859341802139951], [136.815306121996088, 34.859356843963297], [136.815308925594707, 34.859356490038074]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815315826760553, 34.859326052463146], [136.81532337491069, 34.859299508057205], [136.815319708666351, 34.859299685019948], [136.815314101469085, 34.859325698537795], [136.815315826760553, 34.859326052463146]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815326394170739, 34.859283758372314], [136.815328982107928, 34.859270663126395], [136.815326394170739, 34.859270132238002], [136.81532337491069, 34.859283758372314], [136.815326394170739, 34.859283758372314]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815384730588192, 34.859037956719369], [136.81538818117113, 34.859024153581004], [136.815385808895371, 34.859023268764368], [136.815381926989573, 34.859037779756072], [136.815384730588192, 34.859037956719369]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81539271006119, 34.859007342063208], [136.815398532919858, 34.858981505408117], [136.815396376305557, 34.858980974517856], [136.81538969080114, 34.859007165099847], [136.81539271006119, 34.859007342063208]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815402630487085, 34.858965401735411], [136.815405218424303, 34.858951421621391], [136.815403277471404, 34.858950890730938], [136.815399826888466, 34.858965224771957], [136.815402630487085, 34.858965401735411]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815331785706434, 34.859264027021233], [136.815421824354615, 34.859279334302954], [136.815423549646027, 34.859264734872475], [136.815335128458656, 34.859251993549201], [136.815331785706434, 34.859264027021233]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815264290630296, 34.858927734728169], [136.815267102216382, 34.858927944462721], [136.815290617299979, 34.858834612531069], [136.815286016522748, 34.858833354122275], [136.815264290630296, 34.858927734728169]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815254322279628, 34.858831256774245], [136.815218794055482, 34.858825174464663], [136.815218794055482, 34.858827481547664], [136.815253555483423, 34.858833144387482], [136.815254322279628, 34.858831256774245]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815288828108834, 34.8588369196138], [136.815289850503774, 34.858835241735456], [136.815272214191054, 34.858831466509059], [136.815272214191054, 34.858833773591883], [136.815288828108834, 34.8588369196138]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815341098050169, 34.85846631740327], [136.815341609247639, 34.858464324913754], [136.815306336622228, 34.858458242577044], [136.815305953224112, 34.858460444802454], [136.815341098050169, 34.85846631740327]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815516151234846, 34.858731573693483], [136.815559954468085, 34.858547766925177], [136.815557078982323, 34.858547530972707], [136.815512700651936, 34.858731101789587], [136.815516151234846, 34.858731573693483]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815608709925584, 34.858370468116121], [136.815614972094579, 34.858345299794635], [136.815612671705964, 34.858344565718475], [136.81560640953694, 34.858369734040188], [136.815608709925584, 34.858370468116121]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812002259703036, 34.858596550084037], [136.812003984994504, 34.858589353537681], [136.81200082196014, 34.85858876365679], [136.811998952894413, 34.858596078179374], [136.812002259703036, 34.858596550084037]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815477287502802, 34.858126924362359], [136.81550569088671, 34.858131509337753], [136.815506156515937, 34.858126160199767], [136.815479150019769, 34.858122339386711], [136.815477287502802, 34.858126924362359]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816110920022453, 34.859918392713027], [136.816108042068549, 34.85991805535437], [136.816098585934299, 34.85995432140232], [136.816101669456373, 34.859954911779724], [136.816110920022453, 34.859918392713027]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816394363288651, 34.859863542295116], [136.816399003546735, 34.859862780780063], [136.816439298635203, 34.859696073056568], [136.816434776136219, 34.859694723618276], [136.816394363288651, 34.859863542295116]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813212922931172, 34.858815007671211], [136.813223813401123, 34.858817124167068], [136.813226106131623, 34.858808893349568], [136.8132152156617, 34.858806541687265], [136.813212922931172, 34.858815007671211]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813275655902515, 34.85884699503643], [136.813281587397029, 34.858847818063758], [136.813282442279899, 34.858843568176326], [136.813276858640336, 34.858842644409059], [136.813275655902515, 34.85884699503643]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81630162699858, 34.859372760745572], [136.816342084248447, 34.859378971889441], [136.816380192367632, 34.859338706534608], [136.81638436859987, 34.859315575364413], [136.816291447432491, 34.859301653823643], [136.816285705113188, 34.859324570820249], [136.81630162699858, 34.859372760745572]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816288315258305, 34.859315575364413], [136.816382541498285, 34.859326926772816], [136.816387500774056, 34.859301011290938], [136.816370795845103, 34.8592551772782], [136.81633320975493, 34.859249394480386], [136.816294579606677, 34.859287732280528], [136.816288315258305, 34.859315575364413]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816282354921782, 34.859541093194331], [136.816286410875193, 34.859537187728932], [136.816272504749179, 34.859534742567902], [136.816274491338618, 34.859539972495583], [136.816282354921782, 34.859541093194331]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816272463361884, 34.859534742567902], [136.816286452262432, 34.859537221689507], [136.816284297622531, 34.859531923380679], [136.81627667969272, 34.859530715389951], [136.816272463361884, 34.859534742567902]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816262199316441, 34.859551094580922], [136.816261950992782, 34.859552758648533], [136.816280492494116, 34.859555305690712], [136.816280782205069, 34.859553947268225], [136.816262199316441, 34.859551094580922]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816280717989088, 34.859547983849183], [136.816283085457115, 34.859545068756404], [136.816273049535482, 34.859543284037407], [136.816273693699912, 34.859546794359666], [136.816280717989088, 34.859547983849183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816273095778968, 34.859543313478682], [136.816283029046645, 34.85954510882781], [136.816282462217231, 34.859541830106707], [136.816275643236025, 34.859540619356828], [136.816273095778968, 34.859543313478682]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816275670231846, 34.85956760720525], [136.816277973111397, 34.859564657482075], [136.816267899522074, 34.85956302187752], [136.816268620892544, 34.859566522073351], [136.816275670231846, 34.85956760720525]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816267946406441, 34.859563050628225], [136.816277917592657, 34.859564698383437], [136.816277278645373, 34.85956142860406], [136.816270434112255, 34.859560319170782], [136.816267946406441, 34.859563050628225]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81683517936807, 34.859503207785458], [136.81684916826859, 34.859505686908015], [136.81684701362866, 34.859500388597169], [136.816839395698878, 34.859499180605972], [136.81683517936807, 34.859503207785458]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81684507092794, 34.859509558414324], [136.816849126881323, 34.859505652947433], [136.816835220755308, 34.859503207785458], [136.816837207344776, 34.859508437715142], [136.81684507092794, 34.859509558414324]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816886528695221, 34.859289835019879], [136.816900517595741, 34.85929231414886], [136.81689836295584, 34.859287015824272], [136.816890745026058, 34.859285807829941], [136.816886528695221, 34.859289835019879]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816896420255119, 34.859296185665215], [136.816900476208531, 34.8592922801882], [136.816886570082517, 34.859289835019879], [136.816888556671955, 34.859295064963128], [136.816896420255119, 34.859296185665215]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816216065432343, 34.85953143831032], [136.816212092253494, 34.859530759098874], [136.816203447336932, 34.859567344540388], [136.816207751614058, 34.859568159593749], [136.816216065432343, 34.85953143831032]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816139465338409, 34.859524758159615], [136.816143107419038, 34.859511581455628], [136.816138140945441, 34.859511038086339], [136.816134995512186, 34.859523943105813], [136.816139465338409, 34.859524758159615]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816142727172547, 34.859525732934053], [136.816211098958775, 34.859536464474779], [136.816211761155245, 34.859533611786873], [136.816143720467267, 34.859522744403471], [136.816142727172547, 34.859525732934053]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816142727172547, 34.859525732934053], [136.816211098958775, 34.859536464474779], [136.816211761155245, 34.859533611786873], [136.816143720467267, 34.859522744403471], [136.816142727172547, 34.859525732934053]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816031361763606, 34.859507574107099], [136.816135492159532, 34.859524214790412], [136.816136154356002, 34.859521362102079], [136.816031858410952, 34.859504585575856], [136.816031361763606, 34.859507574107099]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816139465338409, 34.859524758159615], [136.816143107419038, 34.859511581455628], [136.816138140945441, 34.859511038086339], [136.816134995512186, 34.859523943105813], [136.816139465338409, 34.859524758159615]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816035334942455, 34.85950791371291], [136.816038977023084, 34.85949473700623], [136.816034010549487, 34.859494193636834], [136.816030865116232, 34.859507098658945], [136.816035334942455, 34.85950791371291]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816006529395708, 34.859504925181682], [136.816015303499029, 34.859470217458806], [136.81601133032018, 34.859469402404471], [136.816002059569485, 34.859504110127688], [136.816006529395708, 34.859504925181682]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816031692861856, 34.859507574107099], [136.816032355058326, 34.859504449733528], [136.816006529395708, 34.859500781990491], [136.816005867199237, 34.859504042206524], [136.816031692861856, 34.859507574107099]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816045732878536, 34.85936551513074], [136.816048536477183, 34.859354366486635], [136.816045732878536, 34.859353835598796], [136.816043360602777, 34.859365161205552], [136.816045732878536, 34.85936551513074]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816088811249699, 34.859183376180482], [136.816091560932307, 34.859170457881177], [136.81608983564081, 34.859170457881177], [136.816087032042873, 34.859183199217497], [136.816088811249699, 34.859183376180482]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816054143674421, 34.859332423119241], [136.816059103887341, 34.859309417969705], [136.81605586896589, 34.85930924100699], [136.816051124414372, 34.859331538305909], [136.816054143674421, 34.859332423119241]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816078513416272, 34.859228545969891], [136.816084120613539, 34.859205363828373], [136.816081963999181, 34.859205186865438], [136.816076141140542, 34.859228015081229], [136.816078513416272, 34.859228545969891]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816063417116027, 34.859288890292383], [136.816073121880493, 34.859249604550811], [136.816070749604734, 34.859249604550811], [136.816061476163128, 34.859288536366869], [136.816063417116027, 34.859288890292383]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81644648573544, 34.858944520045], [136.816496519187808, 34.858952483402049], [136.816496087864948, 34.858951067694186], [136.816447995365479, 34.858943281300505], [136.81644648573544, 34.858944520045]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816452642884713, 34.858946849032385], [136.816448669705863, 34.858946577345883], [136.816435591325444, 34.858999963727008], [136.816438240111381, 34.859000235413326], [136.816452642884713, 34.858946849032385]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816407235355229, 34.858994866145125], [136.816470191091327, 34.859005533296568], [136.816470191091327, 34.859003903178689], [136.816407990170234, 34.858993008029508], [136.816407235355229, 34.858994866145125]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816470522189604, 34.858993035725298], [136.816473170975485, 34.858993850784351], [136.816483148179003, 34.8589496519863], [136.816480128918926, 34.85894912109584], [136.816470522189604, 34.858993035725298]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816594977141904, 34.859071445519824], [136.816595636467582, 34.85906841582721], [136.81655805490368, 34.859062248238338], [136.816557263712866, 34.859065386134489], [136.816594977141904, 34.859071445519824]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816557791173409, 34.859062464644978], [136.816548824344125, 34.859099902984248], [136.816553044028495, 34.859100660407123], [136.81656201085778, 34.859063005661554], [136.816557791173409, 34.859062464644978]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816548296883553, 34.859100985016916], [136.816676469796278, 34.859121543635013], [136.816677524717392, 34.859118297537769], [136.816549088074368, 34.859096873292685], [136.816548296883553, 34.859100985016916]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816672984155531, 34.859117413201538], [136.816675248600546, 34.859119094351222], [136.816720860993541, 34.858932309562917], [136.816719721561071, 34.858930023677942], [136.816672984155531, 34.859117413201538]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816719674855676, 34.858932751971736], [136.816720753162855, 34.858928681810575], [136.816596295793261, 34.85890795016271], [136.816595504602475, 34.858911629082328], [136.816719674855676, 34.858932751971736]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816579680786077, 34.858975685538695], [136.816582845549362, 34.858976767573012], [136.81661334075406, 34.85883558910875], [136.816611615462591, 34.858835746409845], [136.816579680786077, 34.858975685538695]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816612573957855, 34.858837004818596], [136.816613724152148, 34.858835274506554], [136.816518976896077, 34.858819858997705], [136.816518497648474, 34.858821903912329], [136.816612573957855, 34.858837004818596]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816579944516377, 34.858976334759284], [136.816617394215143, 34.858982826964869], [136.816617394215143, 34.858980013675847], [136.81658047197692, 34.858973737876902], [136.816579944516377, 34.858976334759284]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816633745492084, 34.859097197902493], [136.816634536682898, 34.859094601023934], [136.816610009767487, 34.859090272892821], [136.816609218576701, 34.859093086178063], [136.816633745492084, 34.859097197902493]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81661146028398, 34.85909351899118], [136.816651151690081, 34.858935325648858], [136.816647195735982, 34.85893489283491], [136.816608295520723, 34.859093194381352], [136.81661146028398, 34.85909351899118]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816647063870874, 34.858937165108074], [136.816671854516528, 34.858941060433338], [136.816672381977071, 34.858938679956808], [136.816647327601117, 34.858934568224463], [136.816647063870874, 34.858937165108074]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816646800140575, 34.85894679521742], [136.816646404545168, 34.858948418269449], [136.816668426022972, 34.858952097187256], [136.816668821618379, 34.858950365931832], [136.816646800140575, 34.85894679521742]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816646800140575, 34.85894679521742], [136.816646404545168, 34.858948418269449], [136.816668426022972, 34.858952097187256], [136.816668821618379, 34.858950365931832], [136.816646800140575, 34.85894679521742]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816644690298375, 34.858957399156743], [136.816644294702996, 34.858959022208559], [136.816666316180772, 34.858962701125897], [136.816666711776179, 34.858960969870701], [136.816644690298375, 34.858957399156743]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816641921130497, 34.858967894891258], [136.816641525535118, 34.858969517942874], [136.816663547012922, 34.858973196859729], [136.816663942608329, 34.85897146560476], [136.816641921130497, 34.858967894891258]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816639547558054, 34.85897893164158], [136.816639151962647, 34.858980554692977], [136.816661173440451, 34.858984233609341], [136.816661569035858, 34.858982502354593], [136.816639547558054, 34.85897893164158]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81663691025534, 34.858989427373352], [136.816636514659933, 34.858991050424535], [136.816658536137709, 34.858994729340438], [136.816658931733116, 34.858992998085903], [136.81663691025534, 34.858989427373352]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816679634559563, 34.858985532050376], [136.816679238964156, 34.85898715510163], [136.81670126044196, 34.85899083401771], [136.816701656037367, 34.858989102763104], [136.816679634559563, 34.858985532050376]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816681348806327, 34.858974819911246], [136.81668095321092, 34.858976442962714], [136.816702974688752, 34.858980121879263], [136.816703370284159, 34.858978390624443], [136.816681348806327, 34.858974819911246]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816683986109069, 34.858963891363821], [136.816683590513662, 34.858965514415509], [136.816705611991466, 34.858969193332541], [136.816706007586873, 34.858967462077494], [136.816683986109069, 34.858963891363821]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816687414602626, 34.858952962814946], [136.816687019007219, 34.858954585866854], [136.816709040484994, 34.858958264784377], [136.816709436080401, 34.858956533529096], [136.816687414602626, 34.858952962814946]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816694139724575, 34.85900122154456], [136.816702315363074, 34.85900219537514], [136.816716029337272, 34.858946470607016], [136.816708644889616, 34.858945172165363], [136.816694139724575, 34.85900122154456]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816676074200899, 34.858996893408531], [136.816675678605492, 34.858998516459579], [136.816697700083267, 34.85900219537514], [136.816698095678703, 34.859000464120776], [136.816676074200899, 34.858996893408531]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81668939257969, 34.858942250671575], [136.816688996984283, 34.858943873723689], [136.816711018462058, 34.858947552641695], [136.816711414057494, 34.858945821386186], [136.81668939257969, 34.858942250671575]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816612251474794, 34.858997218018736], [136.816611545929305, 34.859000488148538], [136.816700114708084, 34.859014887522676], [136.816700996711688, 34.85901182547687], [136.816612251474794, 34.858997218018736]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816686491546676, 34.85901041883286], [136.816701128576824, 34.859012474697167], [136.816703502149295, 34.859001978968344], [136.816688996984283, 34.859000031307176], [136.816686491546676, 34.85901041883286]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816691106826454, 34.858943224502859], [136.816692689208082, 34.858936083073253], [136.816690843096183, 34.858935866666286], [136.816688996984283, 34.858943224502859], [136.816691106826454, 34.858943224502859]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816713260169365, 34.858946687013955], [136.816716424932679, 34.858946903420893], [136.816718271044579, 34.858938247142895], [136.816715370011565, 34.858937706125481], [136.816713260169365, 34.858946687013955]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816639547558054, 34.85897893164158], [136.816639151962647, 34.858980554692977], [136.816661173440451, 34.858984233609341], [136.816661569035858, 34.858982502354593], [136.816639547558054, 34.85897893164158]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81663691025534, 34.858989427373352], [136.816636514659933, 34.858991050424535], [136.816658536137709, 34.858994729340438], [136.816658931733116, 34.858992998085903], [136.81663691025534, 34.858989427373352]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816646800140575, 34.85894679521742], [136.816646404545168, 34.858948418269449], [136.816668426022972, 34.858952097187256], [136.816668821618379, 34.858950365931832], [136.816646800140575, 34.85894679521742]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816644690298375, 34.858957399156743], [136.816644294702996, 34.858959022208559], [136.816666316180772, 34.858962701125897], [136.816666711776179, 34.858960969870701], [136.816644690298375, 34.858957399156743]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816641921130497, 34.858967894891258], [136.816641525535118, 34.858969517942874], [136.816663547012922, 34.858973196859729], [136.816663942608329, 34.85897146560476], [136.816641921130497, 34.858967894891258]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8166224050903, 34.859047099772468], [136.816622009494921, 34.859048722822514], [136.816644030972697, 34.859052401735838], [136.816644426568104, 34.859050670482532], [136.8166224050903, 34.859047099772468]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816619767787557, 34.859057595495536], [136.816619372192179, 34.859059218545376], [136.816641393669954, 34.85906289745823], [136.816641789265361, 34.859061166205144], [136.816619767787557, 34.859057595495536]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81662965767282, 34.859014963374939], [136.816629262077413, 34.859016586425625], [136.816651283555217, 34.859020265340384], [136.816651679150624, 34.859018534086402], [136.81662965767282, 34.859014963374939]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81662754783062, 34.85902556730548], [136.816627152235242, 34.859027190355953], [136.816649173713046, 34.859030869270235], [136.816649569308453, 34.859029138016474], [136.81662754783062, 34.85902556730548]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816624778662771, 34.859036063031297], [136.816624383067364, 34.859037686081564], [136.816646404545168, 34.859041364995377], [136.816646800140575, 34.859039633741844], [136.816624778662771, 34.859036063031297]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816662755822108, 34.859053808379123], [136.816662360226701, 34.859055431429042], [136.816684381704476, 34.859059110342059], [136.816684777299884, 34.859057379088895], [136.816662755822108, 34.859053808379123]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816660118519366, 34.859064304101338], [136.816659722923958, 34.859065927151043], [136.816681744401734, 34.859069606063599], [136.816682139997141, 34.859067874810648], [136.816660118519366, 34.859064304101338]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8166700084046, 34.859021671984216], [136.816669612809193, 34.859023295034774], [136.816691634286997, 34.859026973949227], [136.816692029882404, 34.859025242695381], [136.8166700084046, 34.859021671984216]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8166678985624, 34.859032275913897], [136.816664997529386, 34.859042663435517], [136.816687414602569, 34.859046342349117], [136.816689920040233, 34.859035846624593], [136.8166678985624, 34.859032275913897]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816665129394551, 34.859042771638862], [136.816664733799144, 34.859044394688986], [136.816686755276947, 34.859048073602494], [136.816687150872355, 34.859046342349103], [136.816665129394551, 34.859042771638862]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817015390804102, 34.859311220566369], [136.817029379704678, 34.859313699694717], [136.817027225064749, 34.8593084013715], [136.817019607134938, 34.859307193377482], [136.817015390804102, 34.859311220566369]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817025282364, 34.859317571210056], [136.817029338317411, 34.859313665734049], [136.817015432191397, 34.859311220566369], [136.817017418780836, 34.859316450508267], [136.817025282364, 34.859317571210056]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817040773962646, 34.859204302597874], [136.817054762863194, 34.859206781729434], [136.817052608223264, 34.859201483399332], [136.817044990293454, 34.859200275403744], [136.817040773962646, 34.859204302597874]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817050665522515, 34.859210653249811], [136.817054721475927, 34.859206747768724], [136.817040815349912, 34.859204302597874], [136.817042801939351, 34.859209532546558], [136.817050665522515, 34.859210653249811]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816965131632145, 34.859523321411579], [136.816979120532665, 34.859525800533525], [136.816976965892735, 34.85952050222398], [136.816969347962981, 34.859519294233067], [136.816965131632145, 34.859523321411579]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816975023192043, 34.859529672038889], [136.816979079145426, 34.859525766572951], [136.816965173019412, 34.859523321411579], [136.816967159608879, 34.859528551339984], [136.816975023192043, 34.859529672038889]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816939813525948, 34.859630578362477], [136.816953802426468, 34.85963305748119], [136.816951647786539, 34.859627759178544], [136.816944029856757, 34.859626551189223], [136.816939813525948, 34.859630578362477]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81694970508579, 34.859636928981509], [136.816953761039201, 34.859633023520658], [136.816939854913187, 34.859630578362477], [136.816941841502626, 34.859635808284061], [136.81694970508579, 34.859636928981509]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816598141905189, 34.859051968922522], [136.816597746309782, 34.859053591972462], [136.816619767787557, 34.859057270885572], [136.816620163382993, 34.859055539632358], [136.816598141905189, 34.859051968922522]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816652206611167, 34.859098063528656], [136.81665181101576, 34.8590996865777], [136.816673832493535, 34.859103365488743], [136.816674228088971, 34.859101634236502], [136.816652206611167, 34.859098063528656]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816651547285488, 34.859104880334435], [136.816653129667117, 34.859097738918855], [136.816651283555217, 34.859097522512315], [136.816649437443317, 34.859104880334435], [136.816651547285488, 34.859104880334435]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816670535865086, 34.85910282447243], [136.816678711503585, 34.859104122911589], [136.81669941433006, 34.859014205951283], [136.816692029882404, 34.859012907510703], [136.816670535865086, 34.85910282447243]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816566889867744, 34.859034439981016], [136.816574933641107, 34.859004034832878], [136.816572560068664, 34.859003818426089], [136.816565571216387, 34.859032708727334], [136.816566889867744, 34.859034439981016]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816587724559355, 34.85905175251586], [136.816587988289598, 34.85904958844921], [136.816561747127452, 34.859045476722407], [136.816561351532044, 34.859047424382496], [136.816587724559355, 34.85905175251586]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816585878447455, 34.859040607571977], [136.8165726919338, 34.859038659911718], [136.816570713956736, 34.859047316179165], [136.81658363674012, 34.85904958844921], [136.816585878447455, 34.859040607571977]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816568604114565, 34.859046883365806], [136.816572164473257, 34.859047424382496], [136.816575329236514, 34.859034115370953], [136.816571900742957, 34.859033357947474], [136.816568604114565, 34.859046883365806]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816574933641107, 34.859035522014551], [136.816575329236514, 34.859033682557538], [136.816565834946687, 34.859032275913904], [136.81656517562098, 34.859034007167601], [136.816574933641107, 34.859035522014551]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816583241144713, 34.85904958844921], [136.816585482852048, 34.859049804855879], [136.816599064961082, 34.858995270357482], [136.816596955118911, 34.858994837543854], [136.816583241144713, 34.85904958844921]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81662491052785, 34.859031410287045], [136.816626163246639, 34.859025891915593], [136.81659247170424, 34.859020589950518], [136.816591021187747, 34.859025891915593], [136.81662491052785, 34.859031410287045]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816601306668417, 34.858997650832364], [136.81660157039866, 34.858995486764293], [136.816575329236485, 34.858991375034783], [136.816574933641078, 34.858993322696151], [136.816601306668417, 34.858997650832364]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816574274315428, 34.85899721801875], [136.816576252292464, 34.858997434425561], [136.816577307213549, 34.85899169964501], [136.816575592966757, 34.858991158627958], [136.816574274315428, 34.85899721801875]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81657361498975, 34.859008903985469], [136.816572560068664, 34.859010310629493], [136.816584296065798, 34.859012150087032], [136.816584691661205, 34.859010527036268], [136.81657361498975, 34.859008903985469]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816611328418787, 34.859038822216753], [136.816611196553652, 34.859039687843527], [136.816619108461822, 34.859040932182019], [136.816619372192122, 34.859040066555238], [136.816611328418787, 34.859038822216753]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81662491052785, 34.859016153612131], [136.816625174258121, 34.859015071578341], [136.816617394215058, 34.859014097747917], [136.816617130484786, 34.85901485517158], [136.81662491052785, 34.859016153612131]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816623196281085, 34.859022970424675], [136.816626097314099, 34.859023619644894], [136.81662978953787, 34.859008362968524], [136.816626888504913, 34.859008146561742], [136.816623196281085, 34.859022970424675]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816616998619651, 34.859048506415867], [136.816619372192122, 34.859049372042548], [136.816623130348489, 34.859033898964242], [136.816620558978343, 34.859033249744122], [136.816616998619651, 34.859048506415867]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816714013743109, 34.858928947255869], [136.816720106178565, 34.85893195563586], [136.816747530089913, 34.858818285986388], [136.81674158741933, 34.858816870276215], [136.816714013743109, 34.858928947255869]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816746763293708, 34.858819387094286], [136.81674791348803, 34.858817971384127], [136.8165286097734, 34.858783050525965], [136.816528034676253, 34.858784780839109], [136.816746763293708, 34.858819387094286]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816522044080926, 34.858841251948064], [136.816492570351784, 34.858969137630723], [136.816496212633751, 34.858969924134925], [136.81652472786763, 34.858842195754562], [136.816522044080926, 34.858841251948064]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816528418074341, 34.858789342573573], [136.816528418074341, 34.858783050525965], [136.816365026581821, 34.858756800884606], [136.816361703798265, 34.858762883199233], [136.816528418074341, 34.858789342573573]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816258441909383, 34.858754965703369], [136.816261828592587, 34.858755332739619], [136.816293267236972, 34.85861874270811], [136.816291478045855, 34.858617903766721], [136.816258441909383, 34.858754965703369]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816364259785615, 34.858772321272788], [136.816366943572348, 34.858756905752095], [136.816261956391969, 34.858741595096035], [136.816258761407795, 34.858755280305864], [136.816364259785615, 34.858772321272788]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816260450474573, 34.858739884386956], [136.816261755547146, 34.858737742596617], [136.816255491198774, 34.858736671701429], [136.816255817466924, 34.858739081215589], [136.816260450474573, 34.858739884386956]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816255474885367, 34.858736658315237], [136.816261690293516, 34.858737742596617], [136.816261559786255, 34.858735333082421], [136.816256926778607, 34.858734583455771], [136.816255474885367, 34.858736658315237]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816255474885367, 34.858736658315237], [136.816261690293516, 34.858737742596617], [136.816261559786255, 34.858735333082421], [136.816256926778607, 34.858734583455771], [136.816255474885367, 34.858736658315237]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816260450474573, 34.858739884386956], [136.816261755547146, 34.858737742596617], [136.816255491198774, 34.858736671701429], [136.816255817466924, 34.858739081215589], [136.816260450474573, 34.858739884386956]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816260564668397, 34.858715561677158], [136.816266780076546, 34.858716645958815], [136.816266649569286, 34.858714236444001], [136.816262016561637, 34.858713486817159], [136.816260564668397, 34.858715561677158]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816265540257604, 34.858718787749702], [136.816266845330176, 34.858716645958815], [136.816260580981805, 34.85871557506335], [136.816260907249955, 34.858717984578128], [136.816265540257604, 34.858718787749702]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816265654451456, 34.858693179958756], [136.816271869859577, 34.858694264240711], [136.816271739352317, 34.858691854725237], [136.816267106344696, 34.858691105098195], [136.816265654451456, 34.858693179958756]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816270630040634, 34.858696406032188], [136.816271935113207, 34.858694264240711], [136.816265670764835, 34.858693193344955], [136.816265997033014, 34.858695602860394], [136.816270630040634, 34.858696406032188]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816271135756239, 34.858671119503086], [136.816277351164388, 34.858672203785332], [136.816277220657128, 34.858669794269218], [136.816272587649507, 34.858669044641964], [136.816271135756239, 34.858671119503086]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816276111345445, 34.85867434557737], [136.816277416418018, 34.858672203785332], [136.816271152069646, 34.858671132889285], [136.816271478337825, 34.858673542405363], [136.816276111345445, 34.85867434557737]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816276878075598, 34.858649273220749], [136.816283093483719, 34.858650357503286], [136.816282962976459, 34.858647947986533], [136.816278329968839, 34.858647198359087], [136.816276878075598, 34.858649273220749]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816281853664776, 34.858652499295907], [136.816283158737349, 34.858650357503286], [136.816276894389006, 34.858649286606962], [136.816277220657128, 34.85865169612368], [136.816281853664776, 34.858652499295907]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816281054307836, 34.858626998573982], [136.816287269715957, 34.858628082856811], [136.816287139208725, 34.858625673339404], [136.816282506201077, 34.858624923711751], [136.816281054307836, 34.858626998573982]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816286029897014, 34.858630224650007], [136.816287334969587, 34.858628082856811], [136.816281070621216, 34.858627011960195], [136.816281396889394, 34.858629421477566], [136.816286029897014, 34.858630224650007]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816256306701092, 34.858925153260913], [136.816283167801089, 34.858812337498073], [136.816283330837365, 34.858801572739793], [136.816253178878867, 34.858924483720514], [136.816256306701092, 34.858925153260913]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816315910889301, 34.85893282146548], [136.81631916977318, 34.8589331360673], [136.816348316815805, 34.858810224303191], [136.816345728878616, 34.858809909700902], [136.816315910889301, 34.85893282146548]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816301826240874, 34.858930899510327], [136.816305037199953, 34.858931214112147], [136.816333795612621, 34.858809005219307], [136.816330584653514, 34.858808808592876], [136.816301826240874, 34.858930899510327]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816322149895285, 34.858925290009807], [136.816324354434386, 34.858925604611656], [136.81636058555506, 34.858771213610133], [136.816357901768328, 34.858770820357087], [136.816322149895285, 34.858925290009807]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816322245744828, 34.858925604611656], [136.816374674273817, 34.858935787719957], [136.816375170921162, 34.85893402175742], [136.816322629142917, 34.858923874301482], [136.816322245744828, 34.858925604611656]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816373915663831, 34.858935317942766], [136.816371854914564, 34.858935166511834], [136.816358776534202, 34.858988552900364], [136.816361425320082, 34.858988824586724], [136.816373915663831, 34.858935317942766]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816360271500514, 34.858985913871649], [136.816359940402293, 34.858988630735361], [136.816387752654293, 34.858991619285341], [136.816388166527219, 34.858989377872952], [136.816360271500514, 34.858985913871649]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816509823266387, 34.858972283647475], [136.816512890451207, 34.858972440948314], [136.816542747578438, 34.858843611464295], [136.816539872092704, 34.858843611464295], [136.816509823266387, 34.858972283647475]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816540878512683, 34.858973542054137], [136.816542795503182, 34.858974800460786], [136.816573036028558, 34.858849274303005], [136.816570543940884, 34.858848173195511], [136.816540878512683, 34.858973542054137]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816547770428087, 34.858976117508298], [136.816583860136035, 34.858830493464424], [136.816579224760716, 34.858829678403751], [136.816542803954491, 34.858975574135478], [136.816547770428087, 34.858976117508298]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816690403772725, 34.859268991811369], [136.816690403772725, 34.859270643464157], [136.816864562360735, 34.859298682231412], [136.816864945758795, 34.859297345179613], [136.816690403772725, 34.859268991811369]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816677096662914, 34.859301828235466], [136.816677128613378, 34.859303165287308], [136.816857900818746, 34.85933202986925], [136.816858523840608, 34.859330810793068], [136.816677096662914, 34.859301828235466]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816680387497286, 34.859290974520761], [136.81667888585406, 34.859291970755415], [136.816859817809274, 34.859321569408834], [136.816860105357819, 34.859320389657583], [136.816680387497286, 34.859290974520761]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816665436005366, 34.859156256598645], [136.816667700450409, 34.859155637227985], [136.816676111246295, 34.859118474980285], [136.816673850632384, 34.859118119832964], [136.816665436005366, 34.859156256598645]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816667710764875, 34.859154473472515], [136.816667048568377, 34.85915664695905], [136.816722010875935, 34.859165340904575], [136.816722673072434, 34.859162895732489], [136.816667710764875, 34.859154473472515]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816722341974184, 34.859164797533012], [136.816726646251311, 34.859146594583386], [136.816723997465402, 34.859146051211688], [136.816720024286525, 34.859164254161442], [136.816722341974184, 34.859164797533012]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816791173572369, 34.859157760784512], [136.816789895578722, 34.859155453710784], [136.816670786568267, 34.859136367916499], [136.816668486179651, 34.859138045788697], [136.816791173572369, 34.859157760784512]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817388910952474, 34.857685544675469], [136.817395946789986, 34.857683031536716], [136.817533931980876, 34.857150855697085], [136.817525985623121, 34.857149768927322], [136.817388910952474, 34.857685544675469]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817083064369001, 34.858966242311489], [136.817089534211959, 34.858966242311489], [136.817220872024478, 34.858419157981139], [136.81721483350438, 34.858418804051873], [136.817083064369001, 34.858966242311489]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816931497277864, 34.859184711595624], [136.816932519672775, 34.859179782848727], [136.816787807126104, 34.859157207578683], [136.816787083992637, 34.859161745729907], [136.816931497277864, 34.859184711595624]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81692466104667, 34.859180279327923], [136.816930268243937, 34.859181871994835], [136.817322232897709, 34.857541674189541], [136.817316410039098, 34.857541320256502], [136.81692466104667, 34.859180279327923]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816853403202344, 34.859518625720128], [136.816869047594196, 34.85945359118935], [136.816864081120627, 34.859452911977264], [136.816848105630527, 34.859519169089353], [136.816853403202344, 34.859518625720128]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816853403202344, 34.859517267297029], [136.816824597655625, 34.859512920342979], [136.816824597655625, 34.859515365504663], [136.816853072104095, 34.859519712458585], [136.816853403202344, 34.859517267297029]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816824597655625, 34.859513735396881], [136.816814664708488, 34.859551771236681], [136.816818306789088, 34.859553129659204], [136.816827577539755, 34.859514007081515], [136.816824597655625, 34.859513735396881]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816955970856753, 34.859641510770601], [136.817000684661451, 34.859453465492606], [136.816993783495604, 34.859452836292945], [136.816951050581082, 34.859640671839635], [136.816955970856753, 34.859641510770601]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816956673753282, 34.859637473415262], [136.816921592826901, 34.859631863064017], [136.816920889930373, 34.8596360577192], [136.816955970856753, 34.859641668070147], [136.816956673753282, 34.859637473415262]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817062372589987, 34.859199309845849], [136.817028600569671, 34.859193604446546], [136.81702760727498, 34.85919958153152], [136.817061048197019, 34.85920474355909], [136.817062372589987, 34.859199309845849]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817010141814649, 34.859385721634965], [136.81701474259188, 34.859385092434785], [136.817061772759104, 34.859198848970621], [136.817057938778106, 34.859199478172222], [136.817010141814649, 34.859385721634965]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817012649394655, 34.85938011061441], [136.817010653012119, 34.859385092434785], [136.818508883378257, 34.859627532350018], [136.818507795440127, 34.85961860522049], [136.817012649394655, 34.85938011061441]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816869072002504, 34.859875078560258], [136.816869072002504, 34.859880554074557], [136.816971168317252, 34.859896433063959], [136.816976506686672, 34.85988931489667], [136.816869072002504, 34.859875078560258]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8167377534362, 34.860405563591755], [136.816745421398252, 34.86040682197649], [136.81687040659483, 34.859876173663146], [136.81686440092929, 34.859876173663146], [136.8167377534362, 34.860405563591755]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816886384101139, 34.859816008224755], [136.816929708086747, 34.859637263682515], [136.816921656726578, 34.859632387395926], [136.816881016527731, 34.859815169295572], [136.816886384101139, 34.859816008224755]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816826957395307, 34.859621166692385], [136.816828107589629, 34.859614874708399], [136.816649188475225, 34.85958488291147], [136.816649699672695, 34.859591594363479], [136.816826957395307, 34.859621166692385]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816652382921376, 34.859589259178826], [136.816666123498266, 34.859534650599642], [136.816663309163232, 34.859534243072794], [136.816648740840748, 34.859590074231981], [136.816652382921376, 34.859589259178826]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816652382921376, 34.859589259178826], [136.816666123498266, 34.859534650599642], [136.816663309163232, 34.859534243072794], [136.816648740840748, 34.859590074231981], [136.816652382921376, 34.859589259178826]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816687272686693, 34.859595474421425], [136.816701458416503, 34.859535805402018], [136.816699541425976, 34.859535700535517], [136.816685227896841, 34.859595159822142], [136.816687272686693, 34.859595474421425]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816721650716545, 34.859600088544148], [136.81673647544315, 34.859541363326564], [136.81673353605774, 34.859541153593561], [136.816718966929841, 34.859599039879917], [136.816721650716545, 34.859600088544148]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816756733454582, 34.859549058690085], [136.816757395651052, 34.859546885213931], [136.816734880970841, 34.859543353315047], [136.816733887676122, 34.859545526791301], [136.816756733454582, 34.859549058690085]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816757817937514, 34.859603339403186], [136.816771109071738, 34.859546711517758], [136.816769319880621, 34.859546606651264], [136.816755900947015, 34.859602919937515], [136.816757817937514, 34.859603339403186]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816803564167316, 34.859556719458553], [136.816804226363786, 34.859554545982597], [136.816793713994798, 34.859552576269991], [136.816792555150954, 34.859554885588246], [136.816803564167316, 34.859556719458553]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816305065750669, 34.861739212858275], [136.816316280145173, 34.861740982433119], [136.816828107589572, 34.859615294174006], [136.816820184028813, 34.859613826044338], [136.816305065750669, 34.861739212858275]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816420591843752, 34.859588420247427], [136.816423240629661, 34.85958950698496], [136.816430152305117, 34.859560708435282], [136.816427337970083, 34.859560436750812], [136.816420591843752, 34.859588420247427]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816353047803148, 34.859577009502416], [136.816356027687306, 34.859577417029051], [136.816363436010079, 34.859549976897718], [136.816359959478575, 34.859549161844178], [136.816353047803148, 34.859577009502416]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816352675317319, 34.859576330291276], [136.816351847571724, 34.859579182977697], [136.816423033692956, 34.859590322038024], [136.816423695889426, 34.859587333509793], [136.816352675317319, 34.859576330291276]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81646897357362, 34.859572187103694], [136.81646963577009, 34.859568247679221], [136.816450432072259, 34.859565394992416], [136.816449438777568, 34.859569606101466], [136.81646897357362, 34.859572187103694]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816446293344285, 34.859580609320894], [136.81644778328635, 34.8595808810053], [136.816451590916103, 34.859565530834651], [136.816449604326692, 34.859565123307952], [136.816446293344285, 34.859580609320894]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816448776581097, 34.859568791048126], [136.816449438777568, 34.859566889256953], [136.816428745137671, 34.859563629043393], [136.816428414039422, 34.859565666676879], [136.816448776581097, 34.859568791048126]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816463510452735, 34.859603159123985], [136.816473277850747, 34.859564036570092], [136.816470132417521, 34.859563493201158], [136.816460199470356, 34.859603702492663], [136.816463510452735, 34.859603159123985]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816564613701843, 34.859577110111616], [136.816619907107622, 34.85958580401271], [136.81662354918825, 34.859568959578503], [136.816568917978913, 34.85955972230667], [136.816564613701843, 34.859577110111616]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816778566250321, 34.859553187560088], [136.816779228446791, 34.859551014084047], [136.816768716077831, 34.859549044371349], [136.816767557233987, 34.85955135368971], [136.816778566250321, 34.859553187560088]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816894131939392, 34.859347209331958], [136.816909308113708, 34.859280330538077], [136.816907007725092, 34.859280015937586], [136.816891064754543, 34.859346265531229], [136.816894131939392, 34.859347209331958]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816907155740836, 34.859281788427282], [136.816907983486431, 34.859279886629466], [136.816880952629589, 34.859276188298281], [136.816880377532442, 34.859277957926118], [136.816907155740836, 34.859281788427282]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816884515036406, 34.859277184533106], [136.816909052514944, 34.85917588310884], [136.816906496527622, 34.859176092842773], [136.816882033662097, 34.859276320758468], [136.816884515036406, 34.859277184533106]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816266861530409, 34.859624501200521], [136.81626916440996, 34.859621551479385], [136.816259090820665, 34.859619915875953], [136.816259812191134, 34.859623416069383], [136.816266861530409, 34.859624501200521]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816259137705032, 34.859619944626651], [136.81626910889122, 34.859621592380719], [136.816268469943935, 34.859618322603609], [136.816261625410846, 34.859617213171099], [136.816259137705032, 34.859619944626651]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816352716704898, 34.859578639608941], [136.816347904538702, 34.859593351471752], [136.81635201590143, 34.859593688831744], [136.816356949536669, 34.85957817027073], [136.816352716704898, 34.859578639608941]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816417797704901, 34.85960212283107], [136.816420675658833, 34.859602460191027], [136.816423737277006, 34.859587876878656], [136.816420591843752, 34.859587605194264], [136.816417797704901, 34.85960212283107]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816678914844374, 34.860381495341727], [136.816793934207709, 34.859901464271708], [136.816791274450821, 34.859901577317885], [136.816676758230045, 34.86038043357928], [136.816678914844374, 34.860381495341727]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816689482254645, 34.860383441906187], [136.816804645459655, 34.859902993009385], [136.816801841861007, 34.859902816047956], [136.816686894317456, 34.860383441906187], [136.816689482254645, 34.860383441906187]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816334058367232, 34.859665942060374], [136.816334058367232, 34.859663768587311], [136.816180428784634, 34.859637143537547], [136.816179435489914, 34.859639588695529], [136.816334058367232, 34.859665942060374]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816333385224652, 34.859683737368968], [136.816336530657907, 34.85968441657915], [136.816337523952626, 34.859681428054337], [136.816334378519343, 34.859680748844134], [136.816333385224652, 34.859683737368968]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816399810363549, 34.859725855264486], [136.816401134756489, 34.859719606533808], [136.816391532907573, 34.859718248114035], [136.816389877416412, 34.859724225160875], [136.816399810363549, 34.859725855264486]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816207420515809, 34.859567887909293], [136.816208082712279, 34.859565171064716], [136.816188216817977, 34.859561910851092], [136.816187223523286, 34.859564356011312], [136.816207420515809, 34.859567887909293]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816188879014447, 34.859561367482144], [136.816184574737377, 34.859561639166614], [136.816181594853219, 34.859573864967068], [136.816185568032068, 34.859574951704801], [136.816188879014447, 34.859561367482144]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816182257049689, 34.859574136651496], [136.816182174275156, 34.859571759412681], [136.816106021680355, 34.859558990242952], [136.816105359483885, 34.85956197877222], [136.816182257049689, 34.859574136651496]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816108008269737, 34.859559397769686], [136.816105193934703, 34.859558990242959], [136.816101717403257, 34.859576106363626], [136.816104366189165, 34.859576378048054], [136.816108008269737, 34.859559397769686]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816104697287415, 34.859574204572617], [136.816095260987595, 34.859572846150442], [136.816094764340193, 34.859575019625915], [136.816104200640012, 34.859576242205847], [136.816104697287415, 34.859574204572617]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816098406420878, 34.859574204572617], [136.816095095438499, 34.859573389519319], [136.816091784456091, 34.859591864058878], [136.816094764340249, 34.859592135743249], [136.816098406420878, 34.859574204572617]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816092281103437, 34.859590233952609], [136.816091618906938, 34.859592271585434], [136.816099399715569, 34.859593494165104], [136.816100061912039, 34.8595915923745], [136.816092281103437, 34.859590233952609]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816100061912039, 34.859592407427627], [136.816097578675226, 34.859591184847936], [136.8160859902369, 34.85963805038962], [136.816088307924588, 34.859638457915949], [136.816100061912039, 34.859592407427627]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816087778911509, 34.859638470067729], [136.816088376682018, 34.859636998557512], [136.816066458430328, 34.859633565033555], [136.816066458430328, 34.859635363546118], [136.816087778911509, 34.859638470067729]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816068052484979, 34.859634219038128], [136.816066059916665, 34.859634219038128], [136.816061875523161, 34.859653675671815], [136.816064067348321, 34.859653839172921], [136.816068052484979, 34.859634219038128]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816064067348321, 34.859654002674034], [136.816064665118802, 34.859652449413531], [136.816041650954531, 34.859648443636324], [136.816040953555643, 34.859650323899118], [136.816064067348321, 34.859654002674034]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81604434092182, 34.85964926114189], [136.816041451697714, 34.859648280135204], [136.816035673249559, 34.859673132300912], [136.816038662102045, 34.859673541053567], [136.81604434092182, 34.85964926114189]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816035673249559, 34.859673050550377], [136.81603507547905, 34.859675584816856], [136.816051314910993, 34.859678037332735], [136.816051713424656, 34.859675584816856], [136.816035673249559, 34.859673050550377]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81605101602571, 34.859678037332728], [136.816057691129629, 34.859678854838002], [136.816058886670618, 34.859673459303039], [136.816052311195136, 34.85967256004718], [136.81605101602571, 34.859678037332728]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816057392244375, 34.859678037332742], [136.816055798189723, 34.85967738332851], [136.815662818574253, 34.861320062870973], [136.81567253201203, 34.861322719619011], [136.816057392244375, 34.859678037332742]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816090002871874, 34.859674911795686], [136.816091327264814, 34.859668663061143], [136.816081725415899, 34.859667304640524], [136.816080069924709, 34.859673281691073], [136.816090002871874, 34.859674911795686]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816179795846267, 34.859637932999782], [136.816173526018133, 34.859665006119997], [136.816176815108321, 34.859666018199079], [136.816183907209023, 34.859637764319878], [136.816179795846267, 34.859637932999782]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816053822879923, 34.859839880502641], [136.816055147272891, 34.859833631780617], [136.816045545423975, 34.859832273362727], [136.816043889932786, 34.859838250401289], [136.816053822879923, 34.859839880502641]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816071090603344, 34.859758239588807], [136.816072414996313, 34.859751990860595], [136.816062813147397, 34.859750632441354], [136.816061157656179, 34.859756609485849], [136.816071090603344, 34.859758239588807]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816170164124685, 34.859655567835794], [136.816173309557968, 34.859656247046203], [136.816174302852659, 34.859653258520368], [136.816171157419404, 34.85965257930993], [136.816170164124685, 34.859655567835794]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816017215978292, 34.860007674317046], [136.816018540371232, 34.860001425607777], [136.816008938522316, 34.860000067192651], [136.816007283031126, 34.860006044219027], [136.816017215978292, 34.860007674317046]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816036950519305, 34.859925021493886], [136.816038274912273, 34.859918772778336], [136.816028673063357, 34.859917414361846], [136.816027017572168, 34.859923391394219], [136.816036950519305, 34.859925021493886]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816110920022453, 34.859918392713027], [136.816108042068549, 34.85991805535437], [136.816098585934299, 34.85995432140232], [136.816101669456373, 34.859954911779724], [136.816110920022453, 34.859918392713027]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816125720928255, 34.859859017567679], [136.816122842974323, 34.859858680208781], [136.816113386840101, 34.859894946282914], [136.816116470362147, 34.859895536660737], [136.816125720928255, 34.859859017567679]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816140521834029, 34.859800317097772], [136.816137643880126, 34.859799979738625], [136.816128187745875, 34.859836245838636], [136.816131271267949, 34.859836836216886], [136.816140521834029, 34.859800317097772]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816096941389219, 34.859976418381841], [136.816094063435315, 34.859976081023419], [136.816084607301065, 34.860012347045789], [136.816087690823139, 34.860012937422766], [136.816096941389219, 34.859976418381841]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816157789557451, 34.859729471647256], [136.816154911603519, 34.859729134287818], [136.816145455469297, 34.859765400419064], [136.816148538991342, 34.859765990797825], [136.816157789557451, 34.859729471647256]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81622367414505, 34.859645975145092], [136.816220590623004, 34.859646143824975], [136.816189447050391, 34.85977248496377], [136.816192530572437, 34.859773075342467], [136.81622367414505, 34.859645975145092]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816146483309922, 34.859765737778353], [136.81619170829984, 34.859772822323023], [136.81619253057238, 34.8597704608082], [136.816146483309922, 34.859764050981902], [136.816146483309922, 34.859765737778353]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816170945918145, 34.859680217155223], [136.816168067964242, 34.859679879795593], [136.816158611829991, 34.859716145948553], [136.816161695352037, 34.859716736327663], [136.816170945918145, 34.859680217155223]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816146072173666, 34.859763882302268], [136.816143194219762, 34.859777545352472], [136.816146072173666, 34.859777882711711], [136.816148538991285, 34.859765400419064], [136.816146072173666, 34.859763882302268]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816337902270334, 34.859974275839321], [136.816339226663302, 34.859968027127522], [136.816329624814358, 34.859966668711849], [136.816327969323197, 34.85997264574064], [136.816337902270334, 34.859974275839321]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816376975989613, 34.859808168752345], [136.816378300382581, 34.859801920027927], [136.816368698533637, 34.859800561609511], [136.816367043042476, 34.859806538650368], [136.816376975989613, 34.859808168752345]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816356419176032, 34.859890484334159], [136.816357743569, 34.859884235615993], [136.816348141720084, 34.859882877198942], [136.816346486228866, 34.859888854233816], [136.816356419176032, 34.859890484334159]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816318167729293, 34.860056928612998], [136.816319492122261, 34.860050679907467], [136.816309890273345, 34.860049321493165], [136.816308234782127, 34.860055298515952], [136.816318167729293, 34.860056928612998]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816228488511854, 34.860381205320706], [136.816229516352536, 34.860378169109815], [136.81622643283049, 34.860377494396261], [136.816225404989808, 34.860380361928804], [136.816228488511854, 34.860381205320706]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816272547376599, 34.860221095267342], [136.816273871769567, 34.860214846574287], [136.816264269920623, 34.860213488162692], [136.816262614429434, 34.86021946517355], [136.816272547376599, 34.860221095267342]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81629474873526, 34.860138780016385], [136.816296073128228, 34.860132531317078], [136.816286471279312, 34.860131172904126], [136.816284815788094, 34.860137149920952], [136.81629474873526, 34.860138780016385]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816251563653253, 34.860302862293238], [136.816252888046222, 34.860296613606394], [136.816243286197306, 34.860295255196149], [136.816241630706088, 34.860301232201074], [136.816251563653253, 34.860302862293238]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815962123717867, 34.86025293342999], [136.815963448110836, 34.860246684739359], [136.815953846261891, 34.86024532632829], [136.815952190770702, 34.860251303336831], [136.815962123717867, 34.86025293342999]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816005308799873, 34.860088851053533], [136.816006633192842, 34.860082602350438], [136.815997031343926, 34.860081243936655], [136.815995375852708, 34.860087220957126], [136.816005308799873, 34.860088851053533]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815983107441212, 34.860171166354455], [136.815984431834153, 34.860164917657613], [136.815974829985237, 34.860163559245187], [136.815973174494047, 34.860169536259676], [136.815983107441212, 34.860171166354455]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81605336094438, 34.860152519288896], [136.816050482990505, 34.860152181931184], [136.816041026856226, 34.86018844787592], [136.816044110378328, 34.860189038251633], [136.81605336094438, 34.860152519288896]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816082140483445, 34.860035118726081], [136.816079262529541, 34.860034781367901], [136.816069806395262, 34.860071047364393], [136.816072889917336, 34.860071637740951], [136.816082140483445, 34.860035118726081]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816067339577643, 34.860094493744306], [136.816064461623711, 34.86009415638636], [136.81605500548946, 34.860130422356676], [136.816058089011534, 34.860131012732815], [136.816067339577643, 34.860094493744306]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816023759132833, 34.860270594398564], [136.8160208811789, 34.86027025704135], [136.81601142504465, 34.86030652293401], [136.816014508566724, 34.860307113308885], [136.816023759132833, 34.860270594398564]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816038560038635, 34.860211219507455], [136.816035682084731, 34.86021088215], [136.81602622595048, 34.860247148068844], [136.816029309472526, 34.860247738444144], [136.816038560038635, 34.860211219507455]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816009780499598, 34.860328619818915], [136.816006902545695, 34.860328282461936], [136.815997446411416, 34.860364548329017], [136.816000529933518, 34.860365138703472], [136.816009780499598, 34.860328619818915]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816443075483278, 34.859667759060805], [136.81645284288129, 34.859628636537636], [136.816449697448064, 34.859628093169135], [136.816439764500899, 34.859668302429057], [136.816443075483278, 34.859667759060805]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816440943180282, 34.859667397488082], [136.816439298635203, 34.859669421646181], [136.816459444312528, 34.859672457883242], [136.816459855448812, 34.859670096365541], [136.816440943180282, 34.859667397488082]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81643395386368, 34.859694048899122], [136.8164323093186, 34.859696073056568], [136.816452454995897, 34.859699109292642], [136.816452866132153, 34.8596967477757], [136.81643395386368, 34.859694048899122]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816451221587073, 34.859626914315555], [136.816449577041993, 34.85962893847465], [136.816469722719319, 34.859631974713203], [136.816470133855574, 34.859629613194343], [136.816451221587073, 34.859626914315555]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816457388631164, 34.859602961762427], [136.816455744086085, 34.859604985922118], [136.81647588976341, 34.859608022161552], [136.816476300899694, 34.859605660641996], [136.816457388631164, 34.859602961762427]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81642796777021, 34.859736205047994], [136.816427200974005, 34.859738512105437], [136.816648677277726, 34.859774376353776], [136.816649188475225, 34.859771440100111], [136.81642796777021, 34.859736205047994]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816648677277726, 34.859774586086168], [136.81669021207216, 34.859599878811302], [136.816686633689869, 34.859598410681372], [136.816645098895464, 34.859772279029741], [136.816648677277726, 34.859774586086168]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816314432477014, 34.859754436241545], [136.816311554523111, 34.859754098882213], [136.81630209838886, 34.859790365002453], [136.816305181910906, 34.859790955381023], [136.816314432477014, 34.859754436241545]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816327588837709, 34.859705181764461], [136.816324710883805, 34.859704844404924], [136.816315254749554, 34.859741110546885], [136.816318338271628, 34.859741700925817], [136.816327588837709, 34.859705181764461]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816333560811955, 34.859664921780066], [136.816327290983821, 34.859691994891399], [136.816330580074009, 34.859693006970154], [136.816337672174683, 34.859664753100226], [136.816333560811955, 34.859664921780066]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816259956920959, 34.859783955177704], [136.816305181910906, 34.859791039720811], [136.816306004183446, 34.859788678206506], [136.816259956920959, 34.859782268381636], [136.816259956920959, 34.859783955177704]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816294389583817, 34.859659132175295], [136.816291922766169, 34.859658626135712], [136.81625934021659, 34.859782943100079], [136.816262423738664, 34.859783533478698], [136.816294389583817, 34.859659132175295]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816302715093229, 34.859790196322855], [136.816299837139326, 34.859803859368682], [136.816302715093229, 34.859804196727815], [136.816305181910877, 34.859791714439176], [136.816302715093229, 34.859790196322855]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816253173172527, 34.860002732334451], [136.816250295218623, 34.860002394976128], [136.816240839084372, 34.860038660986895], [136.816243922606446, 34.860039251363688], [136.816253173172527, 34.860002732334451]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8162971583439, 34.85982497060899], [136.816294280389968, 34.85982463324995], [136.816284824255746, 34.85986089933909], [136.816287907777792, 34.859861489717161], [136.8162971583439, 34.85982497060899]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816282357438098, 34.859883671061311], [136.816279479484223, 34.859883333702506], [136.816270023349944, 34.85991959976576], [136.816273106872046, 34.859920190143413], [136.816282357438098, 34.859883671061311]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816267730322465, 34.859941933261702], [136.816264897236437, 34.859941601162639], [136.816254576708786, 34.859980456745319], [136.816257612158097, 34.859981120943154], [136.816267730322465, 34.859941933261702]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816238372266724, 34.860061432659904], [136.816235494312821, 34.86006109530183], [136.816226245848526, 34.86009901597393], [136.816229078934555, 34.860099348072367], [136.816238372266724, 34.860061432659904]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816166012282878, 34.860355608372572], [136.816163134328974, 34.860355271015706], [136.816153678194723, 34.860391536870885], [136.816156761716798, 34.860392127245149], [136.816166012282878, 34.860355608372572]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816194791821914, 34.860238208099638], [136.81619191386801, 34.860237870742289], [136.816182737741713, 34.860275526105525], [136.81618516610115, 34.860276024252123], [136.816194791821914, 34.860238208099638]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81617999091614, 34.860297582971256], [136.816177112962208, 34.860297245614156], [136.816167656827986, 34.860333511494922], [136.816170740350032, 34.860334101869604], [136.81617999091614, 34.860297582971256]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81622357136095, 34.860121482374787], [136.816220693407018, 34.860121145016961], [136.816211237272768, 34.860157410975368], [136.816214320794842, 34.860158001351309], [136.81622357136095, 34.860121482374787]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816209592727688, 34.860180215743689], [136.816206714773784, 34.860179878386106], [136.816197258639534, 34.86021614431862], [136.816200342161608, 34.860216734694148], [136.816209592727688, 34.860180215743689]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816255157456823, 34.859048721757489], [136.816212762549412, 34.859041930248452], [136.816209267891111, 34.859053737926466], [136.816252279502919, 34.859060360753446], [136.816255157456823, 34.859048721757489]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816258771179264, 34.859035227267363], [136.816260826860628, 34.859026624528752], [136.816218107320964, 34.859019495655545], [136.816216257207742, 34.859028773119519], [136.816258771179264, 34.859035227267363]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816219237945688, 34.859055171715831], [136.816227563455215, 34.859021098126675], [136.816218518457219, 34.859019495655545], [136.816209267891111, 34.859053569245368], [136.816219237945688, 34.859055171715831]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816260775468606, 34.859026799889293], [136.816262985326063, 34.859017564595398], [136.816220574138583, 34.859010892915293], [136.816218724025362, 34.859020170380226], [136.816260775468606, 34.859026799889293]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81627012323753, 34.858991718962258], [136.816227563455186, 34.858984747326652], [136.816224068796885, 34.858996555012865], [136.816267245283655, 34.85900335796628], [136.81627012323753, 34.858991718962258]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816228077375513, 34.859021266807844], [136.816236402885067, 34.858986181117196], [136.816226535614533, 34.858984578645384], [136.816218107320935, 34.859019664336721], [136.816228077375513, 34.859021266807844]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816516404932429, 34.859101342326447], [136.816518449722309, 34.859089597215082], [136.816471419555057, 34.859082256519613], [136.816468352370237, 34.859094211366177], [136.816516404932429, 34.859101342326447]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816525028716512, 34.859064005411305], [136.816477298325964, 34.859057088415916], [136.816474561739142, 34.859068686311197], [136.816521945194467, 34.85907581308615], [136.816525028716512, 34.859064005411305]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816516404932429, 34.859101132592336], [136.816524617580228, 34.859064342773465], [136.816514647525679, 34.859062782473458], [136.816505669785528, 34.859099664453502], [136.816516404932429, 34.859101132592336]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816880297657264, 34.859284420344359], [136.81688810662942, 34.859248004149279], [136.816878136574871, 34.859246443852768], [136.816869708281246, 34.859281529432401], [136.816880297657264, 34.859284420344359]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816888517765705, 34.859247666787873], [136.816841545446636, 34.859240540027812], [136.816838050788334, 34.859252347677327], [136.816885434243659, 34.859259474436364], [136.816888517765705, 34.859247666787873]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816879419037207, 34.859284485886228], [136.816882006974367, 34.859272884992897], [136.816834864982155, 34.859265701613957], [136.81683205339607, 34.859277656433868], [136.816879419037207, 34.859284485886228]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816888157318402, 34.859250810520066], [136.816889690910841, 34.859241477368293], [136.816841956582891, 34.859233961479489], [136.81684010646967, 34.859243238919277], [136.816888157318402, 34.859250810520066]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816895441882366, 34.859215050910343], [136.816848945899523, 34.859207815961746], [136.816845451241221, 34.859219623615957], [136.816892758095634, 34.859227005737608], [136.816895441882366, 34.859215050910343]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816888157318402, 34.859250495919461], [136.816896400377601, 34.859216571480566], [136.816885537027758, 34.859213719789054], [136.816877108734161, 34.859248805382634], [136.816888157318402, 34.859250495919461]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815952357306259, 34.860561641773735], [136.815949479352327, 34.860561304417715], [136.815940023218104, 34.860597570182051], [136.81594310674015, 34.860598160554837], [136.815952357306259, 34.860561641773735]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815981136845267, 34.860444241794873], [136.815978258891363, 34.860443904438362], [136.815968802757112, 34.860480170254469], [136.815971886279186, 34.8604807606281], [136.815981136845267, 34.860444241794873]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815966335939493, 34.860503616517768], [136.815963457985589, 34.860503279161506], [136.815954001851338, 34.860539544951429], [136.815957085373384, 34.860540135324634], [136.815966335939493, 34.860503616517768]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815995937751069, 34.860385541742602], [136.815993059797137, 34.860385204385864], [136.815983603662886, 34.860421470227841], [136.81598668718496, 34.860422060601884], [136.815995937751069, 34.860385541742602]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815939558615781, 34.860617706839598], [136.815936680661878, 34.860617369483805], [136.815927224527627, 34.860653635223429], [136.815930308049701, 34.860654225595816], [136.815939558615781, 34.860617706839598]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815909956804234, 34.860735781281491], [136.815907078850302, 34.860735443926188], [136.815897622716079, 34.860771709613743], [136.815900706238125, 34.860772299985278], [136.815909956804234, 34.860735781281491]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815924757710007, 34.860676406726185], [136.815921879756104, 34.860676069370633], [136.815912423621853, 34.860712335084379], [136.815915507143927, 34.860712925456333], [136.815924757710007, 34.860676406726185]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815895978170971, 34.860793806373678], [136.815893100217068, 34.860793469018603], [136.815883644082817, 34.860829734680578], [136.815886727604891, 34.860830325051701], [136.815895978170971, 34.860793806373678]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815823938164812, 34.861088508833419], [136.815821060210908, 34.861088171479551], [136.815811604076657, 34.86112443701159], [136.815814687598731, 34.861125027380595], [136.815823938164812, 34.861088508833419]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815852717703848, 34.86097110960656], [136.815849839749944, 34.860970772252223], [136.815840383615694, 34.861007037836018], [136.815843467137739, 34.861007628205869], [136.815852717703848, 34.86097110960656]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815838779443794, 34.861030130031111], [136.815835901489891, 34.861029792677009], [136.81582644535564, 34.861066058234783], [136.815829528877714, 34.861066648604208], [136.815838779443794, 34.861030130031111]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815867518609622, 34.860912409930293], [136.815864640655718, 34.860912072575715], [136.815855184521467, 34.860948338185395], [136.815858268043542, 34.860948928555665], [136.815867518609622, 34.860912409930293]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815882340932973, 34.860853024111421], [136.815879462979041, 34.860852686756587], [136.81587000684479, 34.860888952392457], [136.815873090366864, 34.86088954276314], [136.815882340932973, 34.860853024111421]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815809911323385, 34.861147588151532], [136.815807033369452, 34.861147250797913], [136.815797577235202, 34.861183516303903], [136.815800660757276, 34.861184106672475], [136.815809911323385, 34.861147588151532]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815795110417582, 34.861206962366708], [136.815792232463679, 34.861206625013331], [136.815782776329399, 34.861242890493138], [136.815785859851502, 34.86124348086129], [136.815795110417582, 34.861206962366708]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815781131784348, 34.861264987126489], [136.815778253830445, 34.861264649773361], [136.815768797696194, 34.861300915227581], [136.815771881218268, 34.861301505595321], [136.815781131784348, 34.861264987126489]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816427075450264, 34.859724754479352], [136.81642548139564, 34.859724100475496], [136.816030773898547, 34.861370278979017], [136.81603401171111, 34.861371939445533], [136.816427075450264, 34.859724754479352]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816108496805498, 34.86059126761635], [136.816105618851594, 34.86059093026045], [136.816096162717344, 34.860627196011734], [136.816099246239418, 34.860627786384299], [136.816108496805498, 34.86059126761635]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816136871617942, 34.860473535582855], [136.81613399366401, 34.860473198226465], [136.816124537529788, 34.860509464029654], [136.816127621051834, 34.860510054403072], [136.816136871617942, 34.860473535582855]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816123082528577, 34.860532578187907], [136.816120204574702, 34.860532240831766], [136.816110748440423, 34.860568506608914], [136.816113831962497, 34.860569096981912], [136.816123082528577, 34.860532578187907]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816151470160435, 34.860414669502902], [136.816148592206531, 34.860414332146277], [136.81613913607228, 34.860450597975415], [136.816142219594354, 34.860451188349252], [136.816151470160435, 34.860414669502902]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816094483935302, 34.86064915919124], [136.816091605981399, 34.860648821835582], [136.816082149847148, 34.860685087561336], [136.816085233369222, 34.860685677933489], [136.816094483935302, 34.86064915919124]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816066501030036, 34.860766403348627], [136.816063623076133, 34.860766065993452], [136.816054166941882, 34.860802331667507], [136.816057250463956, 34.860802922038822], [136.816066501030036, 34.860766403348627]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816080897209247, 34.860708191151367], [136.816078019255372, 34.860707853795944], [136.816068563121092, 34.860744119495671], [136.816071646643138, 34.860744709867404], [136.816080897209247, 34.860708191151367]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816052320033521, 34.860824760514724], [136.816049442079617, 34.860824423159784], [136.816039985945366, 34.86086068880811], [136.81604306946744, 34.860861279179005], [136.816052320033521, 34.860824760514724]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815985743836109, 34.861118964722067], [136.815982865882205, 34.861118627368327], [136.815973409747954, 34.861154892886937], [136.815976493270028, 34.861155483255722], [136.815985743836109, 34.861118964722067]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816012145490333, 34.8610009903921], [136.816009312404304, 34.861000824344707], [136.816000610782936, 34.861038849190415], [136.816003039142373, 34.861040011521659], [136.816012145490333, 34.8610009903921]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815998913016188, 34.861060607764713], [136.815996035062284, 34.861060270410746], [136.815987052442665, 34.861096799685654], [136.815990492618567, 34.86109762992168], [136.815998913016188, 34.861060607764713]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816025277015171, 34.860942201694094], [136.816022399061239, 34.86094186433963], [136.816012942927017, 34.860978129936179], [136.816016026449063, 34.860978720306228], [136.816025277015171, 34.860942201694094]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81603807570562, 34.860883147991991], [136.816035197751717, 34.860882810637285], [136.816025741617466, 34.860919076259876], [136.81602882513954, 34.860919666630352], [136.81603807570562, 34.860883147991991]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815972279922732, 34.861176170211735], [136.815969446836704, 34.861176170211735], [136.815959382906499, 34.86121397215738], [136.815962466428573, 34.861214562525745], [136.815972279922732, 34.861176170211735]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81595710267618, 34.861236777361043], [136.815954471953432, 34.861236279220272], [136.815944582000697, 34.861273346324637], [136.815947665522771, 34.861273936692569], [136.81595710267618, 34.861236777361043]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815942937455645, 34.86129544294981], [136.815940059501742, 34.861295105596788], [136.815930603367462, 34.861331371037593], [136.815933686889537, 34.861331961405099], [136.815942937455645, 34.86129544294981]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816034416437702, 34.861371607352233], [136.816035630617449, 34.861367290139199], [136.815895494040859, 34.861348858957861], [136.815893875134549, 34.861351515704968], [136.816034416437702, 34.861371607352233]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815928164726074, 34.86135682919894], [136.815936259257569, 34.861321959388562], [136.815933021444977, 34.861321295201556], [136.815924117460298, 34.861356165012218], [136.815928164726074, 34.86135682919894]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815800383295766, 34.861338896155445], [136.815802811655203, 34.861334911034149], [136.815660752627366, 34.861315649611768], [136.815659133721056, 34.861318306359948], [136.815800383295766, 34.861338896155445]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815766274096063, 34.861331590099581], [136.815774368627586, 34.861296720278496], [136.815771130814966, 34.861296056091284], [136.815762226830316, 34.86133092591264], [136.815766274096063, 34.861331590099581]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815372792293203, 34.859466750779852], [136.815375859477996, 34.85945500572064], [136.815323461737336, 34.859446406658371], [136.815321161348749, 34.859458571185222], [136.815372792293203, 34.859466750779852]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815382253011506, 34.859429223716525], [136.815330107304476, 34.859421658132689], [136.81532729571839, 34.859431096130656], [136.815380204656492, 34.859438856261498], [136.815382253011506, 34.859429223716525]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815372536694468, 34.859466750779852], [136.815381841875222, 34.859429561077199], [136.815371871820673, 34.859428000784128], [136.815363443527076, 34.859463086286318], [136.815372536694468, 34.859466750779852]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815380971452697, 34.859429627997713], [136.815382880453569, 34.859418741533226], [136.815332407693063, 34.859411590933675], [136.815329851705741, 34.85942186786599], [136.815380971452697, 34.859429627997713]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815389561417931, 34.859392385223678], [136.815338286463998, 34.859384535330214], [136.815335219279177, 34.859397119332925], [136.815386477895885, 34.859404192851393], [136.815389561417931, 34.859392385223678]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815381482650196, 34.859429837730993], [136.815389150281646, 34.859392722584495], [136.815379180227097, 34.859391162290734], [136.81537100310203, 34.859427320931601], [136.815381482650196, 34.859429837730993]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815494964638418, 34.858938934931977], [136.815497174495846, 34.85892969962822], [136.815448705117518, 34.858921862160294], [136.815446915926401, 34.858931509950153], [136.815494964638418, 34.858938934931977]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815503147648059, 34.858906027198408], [136.815454759612578, 34.858898666823954], [136.815452283499837, 34.85890770507531], [136.815500771938218, 34.858915150859886], [136.815503147648059, 34.858906027198408]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815495016030411, 34.85893880842098], [136.815503067773477, 34.858906309029294], [136.815493474269431, 34.858902120221749], [136.815485045975805, 34.858937205948251], [136.815495016030411, 34.85893880842098]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815503075760915, 34.858906335246125], [136.815505526960351, 34.858897144484189], [136.815456884277069, 34.858889772764151], [136.815454711687806, 34.858898686486562], [136.815503075760915, 34.858906335246125]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815512207924712, 34.858870788007522], [136.815462890847328, 34.858863451060593], [136.815460334859978, 34.858875091336913], [136.815509124402666, 34.858882595710099], [136.815512207924712, 34.858870788007522]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815503083748382, 34.858906295920882], [136.815511796788456, 34.858871125370477], [136.815501826733907, 34.858869565066811], [136.81549339844031, 34.858904650807197], [136.815503083748382, 34.858906295920882]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815451154309471, 34.858930186000606], [136.815450938648013, 34.858927177620558], [136.815440586899228, 34.858925938875807], [136.815440155576368, 34.858928593328827], [136.815451154309471, 34.858930186000606]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815443062422787, 34.858925789837592], [136.815441449544977, 34.858926469766423], [136.815438861607788, 34.858937441504999], [136.815440713292162, 34.858937355478318], [136.815443062422787, 34.858925789837592]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815439408219589, 34.858939497263449], [136.815441233883547, 34.858937087577964], [136.815408248981186, 34.858932612588106], [136.815409962975792, 34.858934610088724], [136.815439408219589, 34.858939497263449]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815476421606263, 34.858828353258069], [136.815471629129974, 34.858827094849175], [136.815462810973628, 34.858864375204305], [136.815467795148948, 34.858865476311571], [136.815476421606263, 34.858828353258069]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81554720648117, 34.858546193908701], [136.815544714393496, 34.858544856844681], [136.815499281718331, 34.858729056872725], [136.815502348903152, 34.858729843379216], [136.81554720648117, 34.858546193908701]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815336241674117, 34.859391246798566], [136.815338030865263, 34.859387261864306], [136.815329706302322, 34.859386040471357], [136.815328923258789, 34.859390109840881], [136.815336241674117, 34.859391246798566]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815336231665185, 34.859378544263819], [136.815331794418427, 34.859378115909088], [136.815329184273281, 34.859389253131518], [136.81533388253456, 34.859390109840881], [136.815336231665185, 34.859378544263819]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815333295336615, 34.859379672137003], [136.815334959087352, 34.859377473376973], [136.815305464447107, 34.859372975652064], [136.815304612366106, 34.859375248072823], [136.815333295336615, 34.859379672137003]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815310423722906, 34.859361195895183], [136.815307847287556, 34.859360737140662], [136.815304828027536, 34.859375425035402], [136.815306768980406, 34.859375778960541], [136.815310423722906, 34.859361195895183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815291384096213, 34.858806298328616], [136.815293173287358, 34.858808185942415], [136.815301232999929, 34.858773312780492], [136.815298361840291, 34.85877277733313], [136.815291384096213, 34.858806298328616]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815306339558873, 34.85877492356655], [136.815306861587885, 34.858772995956059], [136.815296943036316, 34.85877149670344], [136.815296159992783, 34.858773317224482], [136.815306339558873, 34.85877492356655]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815296421007275, 34.858773210135006], [136.815298639630669, 34.858773638492899], [136.815311820863684, 34.85872073627695], [136.81530947173303, 34.858720415008328], [136.815296421007275, 34.858773210135006]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815318346226547, 34.858723520604947], [136.81531912927008, 34.858721485903722], [136.81530947173303, 34.85872030791878], [136.815308949704018, 34.858722128440959], [136.815318346226547, 34.858723520604947]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815297518465854, 34.858807976207558], [136.815225439622594, 34.858796231054285], [136.815224928425124, 34.858798118668318], [136.815298540860795, 34.858809863821314], [136.815297518465854, 34.858807976207558]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815200646545321, 34.858911585165508], [136.81522927360362, 34.858796021319399], [136.815224928425124, 34.858796021319399], [136.815197834959207, 34.858911165696306], [136.815200646545321, 34.858911585165508]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815197323761765, 34.858911585165508], [136.815268635808792, 34.858923540036876], [136.815268635808792, 34.858921023221988], [136.815200390946558, 34.858909697554083], [136.815197323761765, 34.858911585165508]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815265977366352, 34.858922395645678], [136.815270033319763, 34.85891849015092], [136.815256127193749, 34.858916044971487], [136.815258113783187, 34.858921274938503], [136.815265977366352, 34.858922395645678]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815256085806453, 34.858916044971487], [136.815270074707001, 34.858918524111736], [136.815267920067072, 34.858913225763068], [136.81526030213729, 34.858912017763252], [136.815256085806453, 34.858916044971487]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815038253561994, 34.859878117024984], [136.815042309515405, 34.859874211575594], [136.815028403389391, 34.859871766424575], [136.81503038997883, 34.859876996330826], [136.815038253561994, 34.859878117024984]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815028362002124, 34.859871766424575], [136.815042350902672, 34.859874245536027], [136.815040196262743, 34.859868947248913], [136.815032578332932, 34.859867739263123], [136.815028362002124, 34.859871766424575]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815166716029694, 34.859897856772257], [136.815170771983077, 34.859893951323791], [136.815156865857091, 34.859891506173369], [136.81515885244653, 34.859896736078362], [136.815166716029694, 34.859897856772257]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815156824469796, 34.859891506173369], [136.815170813370344, 34.859893985284216], [136.815168658730414, 34.859888686998374], [136.815161040800632, 34.85988747901289], [136.815156824469796, 34.859891506173369]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815244721501074, 34.859578311159353], [136.815248777454485, 34.859574405695732], [136.815234871328471, 34.859571960535796], [136.81523685791791, 34.859577190461117], [136.815244721501074, 34.859578311159353]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815234829941176, 34.859571960535796], [136.815248818841752, 34.859574439656285], [136.815246664201794, 34.859569141349866], [136.815239046272012, 34.859567933359671], [136.815234829941176, 34.859571960535796]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815192327946846, 34.85979082202185], [136.815196383900258, 34.859786916568318], [136.815182477774243, 34.859784471414706], [136.815184464363682, 34.859789701326505], [136.815192327946846, 34.85979082202185]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815182436386948, 34.859784471414706], [136.815196425287496, 34.859786950528786], [136.815194270647567, 34.859781652236052], [136.815186652717784, 34.859780444248976], [136.815182436386948, 34.859784471414706]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815067443861437, 34.859772760108143], [136.81507149981482, 34.859768854653765], [136.815057593688806, 34.859766409499606], [136.815059580278273, 34.859771639412564], [136.815067443861437, 34.859772760108143]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815057552301539, 34.859766409499606], [136.815071541202087, 34.859768888614234], [136.815069386562158, 34.859763590320334], [136.815061768632347, 34.859762382333003], [136.815057552301539, 34.859766409499606]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815210625954592, 34.85967566977672], [136.81522461485514, 34.859678148894076], [136.81522246021521, 34.859672850594336], [136.815214842285428, 34.859671642605662], [136.815210625954592, 34.85967566977672]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815095633429081, 34.859663958454675], [136.815099689382464, 34.85966005299511], [136.815085783256478, 34.859657607837732], [136.815087769845917, 34.859662837757604], [136.815095633429081, 34.859663958454675]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815085741869183, 34.859657607837732], [136.815099730769703, 34.859660086955635], [136.815097576129801, 34.85965478865473], [136.815089958200019, 34.8596535806658], [136.815085741869183, 34.859657607837732]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81522051751449, 34.859682020392263], [136.815224573467873, 34.859678114933558], [136.815210667341887, 34.85967566977672], [136.815212653931326, 34.859680899695441], [136.81522051751449, 34.859682020392263]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815116957158693, 34.860111634713839], [136.815121013112076, 34.860107729275541], [136.81510710698609, 34.860105284131464], [136.815109093575529, 34.860110514022871], [136.815116957158693, 34.860111634713839]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815107065598824, 34.860105284131464], [136.815121054499343, 34.860107763235874], [136.815118899859414, 34.860102464963809], [136.815111281929632, 34.860101256981451], [136.815107065598824, 34.860105284131464]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815014729760975, 34.85998307211883], [136.815018785714358, 34.859979166674421], [136.815004879588344, 34.859976721526529], [136.815006866177782, 34.859981951426107], [136.815014729760975, 34.85998307211883]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815004838201105, 34.859976721526529], [136.815018827101625, 34.859979200634811], [136.815016672461695, 34.859973902354469], [136.815009054531913, 34.859972694370221], [136.815004838201105, 34.859976721526529]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815018429645392, 34.859985766679998], [136.815018968798967, 34.85998355466424], [136.814945166932148, 34.859971527721449], [136.814944996927551, 34.859974352678037], [136.815018429645392, 34.859985766679998]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814937640837456, 34.860013060045112], [136.814947559389026, 34.859972366649039], [136.814943383156788, 34.859971509945751], [136.814933251732668, 34.86001287166723], [136.814937640837456, 34.860013060045112]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815008332444791, 34.860025500011396], [136.815031369331336, 34.859927192482623], [136.815027703086969, 34.859926750079161], [136.81500363418354, 34.860025071660026], [136.815008332444791, 34.860025500011396]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81519515117256, 34.859278305297202], [136.815270169401202, 34.858992123212708], [136.815266846617646, 34.858992542681499], [136.815192722984563, 34.859278200430374], [136.81519515117256, 34.859278305297202]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815327618186188, 34.859450722005178], [136.815323963982991, 34.859450507828001], [136.815314045431421, 34.859489916419804], [136.815318221663659, 34.859490130596882], [136.815327618186188, 34.859450722005178]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815275760624274, 34.859746947284215], [136.815296080723641, 34.859664129133158], [136.815293588635967, 34.859663421285347], [136.815272789288969, 34.859746554035837], [136.815275760624274, 34.859746947284215]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815277485915743, 34.859830945095851], [136.815280744799594, 34.859831574292613], [136.815298476961829, 34.859753868455456], [136.815295601476066, 34.859753239258097], [136.815277485915743, 34.859830945095851]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815284387081562, 34.859752295462044], [136.815281703294829, 34.859751980863358], [136.815264258681111, 34.85982850695833], [136.815267517564962, 34.859829214804719], [136.815284387081562, 34.859752295462044]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815281607445257, 34.859661612340922], [136.81527882780901, 34.859661219092132], [136.815258699408702, 34.859744981042276], [136.815261479044949, 34.859745531590029], [136.815281607445257, 34.859661612340922]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815331931360163, 34.85963736719259], [136.81532878281115, 34.859636662586198], [136.815275829941726, 34.859858378432996], [136.81527897849071, 34.859861901455403], [136.815331931360163, 34.85963736719259]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815332156325951, 34.859640605083591], [136.815333301252849, 34.859637316920498], [136.815272906358501, 34.859628391905758], [136.815271475199864, 34.859631445200392], [136.815332156325951, 34.859640605083591]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815274337517167, 34.859631210331571], [136.815296091128403, 34.859546892384174], [136.815293515042868, 34.859545952907951], [136.815270616504705, 34.859631445200392], [136.815274337517167, 34.859631210331571]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815131507087557, 34.859501985947389], [136.815132273883762, 34.859499469150208], [136.81505917264559, 34.859488982494454], [136.815058405849413, 34.859491918758202], [136.815131507087557, 34.859501985947389]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815035313927126, 34.859627670807619], [136.815065562613995, 34.859489401960708], [136.81505968384306, 34.85948835329507], [136.81502729943881, 34.859627201069976], [136.815035313927126, 34.859627670807619]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815100273158777, 34.859639983654283], [136.815101090838084, 34.859637473415319], [136.815005752510018, 34.859623211587099], [136.815004985713784, 34.859625099182153], [136.815100273158777, 34.859639983654283]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814972342947243, 34.859775638033653], [136.815007323081318, 34.859624234034577], [136.815005166466989, 34.859622818338273], [136.814968335703099, 34.859773759086409], [136.814972342947243, 34.859775638033653]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815064059631823, 34.859791978276114], [136.815065204558721, 34.859788690119082], [136.814967885771608, 34.859773658542395], [136.81496674084471, 34.859776946700038], [136.815064059631823, 34.859791978276114]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815035375193133, 34.859912046189798], [136.815065143292742, 34.859788035785982], [136.815060563585092, 34.859787800917623], [136.815032226644149, 34.859911341585757], [136.815035375193133, 34.859912046189798]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815006460435853, 34.860025140550562], [136.815007107420143, 34.860023017016438], [136.814746480578805, 34.859981608090322], [136.81474658840952, 34.859982758338553], [136.815006460435853, 34.860025140550562]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815003441175804, 34.860042394263175], [136.815003872498693, 34.860040713132364], [136.81474376632957, 34.859999014470347], [136.814741628196572, 34.860000365982515], [136.815003441175804, 34.860042394263175]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814744431795191, 34.860000631424349], [136.814749068515994, 34.859981696570955], [136.81474658840952, 34.859981165687152], [136.814741412535113, 34.860000189021285], [136.814744431795191, 34.860000631424349]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815026948271964, 34.85992781184747], [136.815029998056303, 34.859926440299347], [136.814958475767128, 34.859915424549719], [136.814958367936413, 34.859916663279584], [136.815026948271964, 34.85992781184747]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814960093227853, 34.859917636567317], [136.814995650249102, 34.859780352291722], [136.81499164300493, 34.85977941281817], [136.814958044444239, 34.859916574798874], [136.814960093227853, 34.859917636567317]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815061776558366, 34.860123874995303], [136.815061400185755, 34.860125433234579], [136.815138337401862, 34.860137422338603], [136.815138445232577, 34.860135741209724], [136.815061776558366, 34.860123874995303]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815054876427439, 34.860152552496892], [136.815056601718879, 34.860154233625423], [136.815058973994667, 34.86014503165832], [136.81505724870317, 34.860144500775569], [136.815054876427439, 34.860152552496892]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815058930473128, 34.860135573071418], [136.815060968862895, 34.860135564248793], [136.815063664630799, 34.860124238748107], [136.815061776558366, 34.860123874995303], [136.815058930473128, 34.860135573071418]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815022742873964, 34.860150428966072], [136.815024360334718, 34.860139899791598], [136.815021880228215, 34.860139545869757], [136.815016743255541, 34.860150268390029], [136.815022742873964, 34.860150428966072]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815028996020601, 34.860121200918506], [136.815029571117719, 34.860118369542988], [136.814984521840699, 34.860111291103763], [136.814982604850172, 34.860113965180879], [136.815028996020601, 34.860121200918506]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815029187719659, 34.860118369542988], [136.815026887331015, 34.860117897647058], [136.815022478252843, 34.8601345713016], [136.815025545437663, 34.860134728600208], [136.815029187719659, 34.860118369542988]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814102722179626, 34.860003036785805], [136.815133296278589, 34.860168934486246], [136.815134318673529, 34.860163691201215], [136.814104096023698, 34.859996266381039], [136.814102722179626, 34.860003036785805]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815004266843033, 34.860040821275554], [136.815000432862007, 34.860041135873139], [136.814976452625302, 34.860139380424002], [136.814979601174258, 34.860139850158731], [136.815004266843033, 34.860040821275554]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815102645572352, 34.860164245004043], [136.815106701525735, 34.860160339568239], [136.815092795399721, 34.860157894425733], [136.815094781989188, 34.860163124313786], [136.815102645572352, 34.860164245004043]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815092754012454, 34.860157894425733], [136.815106742913002, 34.860160373528551], [136.815104588273073, 34.860155075259868], [136.815096970343291, 34.860153867278292], [136.815092754012454, 34.860157894425733]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814975558685688, 34.860145455618543], [136.814979614639071, 34.860141550181858], [136.814965708513057, 34.860139105038783], [136.814967695102524, 34.860144334928037], [136.814975558685688, 34.860145455618543]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81496566712579, 34.860139105038783], [136.814979656026338, 34.86014158414217], [136.814977501386409, 34.860136285872287], [136.814969883456627, 34.860135077890426], [136.81496566712579, 34.860139105038783]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815240647747288, 34.860004295176708], [136.815242692537169, 34.860000100540319], [136.815194895573768, 34.859992759926101], [136.815191828388919, 34.859996115635539], [136.815240647747288, 34.860004295176708]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81519346998445, 34.859974674716007], [136.815196618533435, 34.859975379319508], [136.815207209107342, 34.859931693891312], [136.815204919253517, 34.859931224155403], [136.81519346998445, 34.859974674716007]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815198261143877, 34.859975460130052], [136.815200837229412, 34.85997405092305], [136.815157616238679, 34.859966770019852], [136.815157043775258, 34.859969353566221], [136.815198261143877, 34.859975460130052]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815158474933867, 34.859969353566221], [136.815166203190444, 34.859940699683726], [136.815163054641459, 34.859940699683726], [136.815156471311752, 34.859968648962678], [136.815158474933867, 34.859969353566221]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815166489422211, 34.859926137870765], [136.815209996644683, 34.859933418777572], [136.815210569108103, 34.859931774701899], [136.81516734811737, 34.859923554323046], [136.815166489422211, 34.859926137870765]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8152016959246, 34.859931070098028], [136.81522201837717, 34.859847926798999], [136.815219156059925, 34.8598474570626], [136.815198261143877, 34.859930130626189], [136.8152016959246, 34.859931070098028]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815278692259085, 34.859860844548706], [136.815279550954244, 34.859857791262591], [136.815219728523374, 34.859848866271768], [136.815218869828186, 34.859852389294581], [136.815278692259085, 34.859860844548706]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815132273883762, 34.859502195680491], [136.81517751485984, 34.85929980299511], [136.815175214471225, 34.85929980299511], [136.815130484692645, 34.859501147015003], [136.815132273883762, 34.859502195680491]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815177770458575, 34.859301061396764], [136.81517802605731, 34.859298544593443], [136.815086521710214, 34.859283443771851], [136.815084732519097, 34.859285750841998], [136.815177770458575, 34.859301061396764]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815195917968765, 34.859280192900165], [136.8151961735675, 34.859277990696704], [136.815090866888738, 34.859261212001684], [136.815090100092533, 34.859263309338743], [136.815195917968765, 34.859280192900165]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815088822098829, 34.859286170309275], [136.815093934073531, 34.859262470403934], [136.815090866888738, 34.859261212001684], [136.815085243716567, 34.85928596057564], [136.815088822098829, 34.859286170309275]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815269147006262, 34.858994220556639], [136.815269913802439, 34.858991703743918], [136.815252533088511, 34.858988347993517], [136.815251766292306, 34.858991494009516], [136.815269147006262, 34.858994220556639]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815252021891041, 34.858991074540725], [136.815267613413823, 34.858926895789928], [136.815264801827766, 34.858925847117114], [136.815247932311252, 34.858991074540725], [136.815252021891041, 34.858991074540725]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815421727622095, 34.858838991319701], [136.815425783575478, 34.858835085820985], [136.815411877449463, 34.858832640639072], [136.815413864038931, 34.858837870611396], [136.815421727622095, 34.858838991319701]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815411836062225, 34.858832640639072], [136.815425824962745, 34.858835119781837], [136.815423670322815, 34.858829821427797], [136.815416052393033, 34.858828613426752], [136.815411836062225, 34.858832640639072]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81544658861452, 34.858734767412997], [136.815450644567903, 34.858730861909322], [136.815436738441917, 34.858728416724333], [136.815438725031356, 34.858733646703271], [136.81544658861452, 34.858734767412997]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81543669705465, 34.858728416724333], [136.81545068595517, 34.858730895870231], [136.815448531315241, 34.858725597509476], [136.815440913385459, 34.858724389506904], [136.81543669705465, 34.858728416724333]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815317560343146, 34.858711590552723], [136.815321616296558, 34.858707685047946], [136.815307710170543, 34.858705239862253], [136.815309696759982, 34.858710469842677], [136.815317560343146, 34.858711590552723]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815307668783277, 34.858705239862253], [136.815321657683796, 34.858707719008862], [136.815319503043895, 34.858702420646615], [136.815311885114085, 34.858701212643687], [136.815307668783277, 34.858705239862253]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815341971404109, 34.858605168585349], [136.815346027357521, 34.858601263075535], [136.815332121231506, 34.858598817886673], [136.815334107820945, 34.858604047873861], [136.815341971404109, 34.858605168585349]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815332079844239, 34.858598817886673], [136.815346068744759, 34.858601297036486], [136.815343914104858, 34.858595998667383], [136.815336296175047, 34.858594790662899], [136.815332079844239, 34.858598817886673]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815471777006508, 34.858629075227014], [136.815475832959891, 34.858625169718316], [136.815461926833876, 34.858622724530179], [136.815463913423315, 34.858627954515846], [136.815471777006508, 34.858629075227014]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815461885446609, 34.858622724530179], [136.815475874347129, 34.858625203679267], [136.8154737197072, 34.858619905311699], [136.815466101777417, 34.85861869730757], [136.815461885446609, 34.858622724530179]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815344804231813, 34.858600967635532], [136.815348893811574, 34.858601177370922], [136.815375220481258, 34.858489807802336], [136.815372408895172, 34.858488759123951], [136.815344804231813, 34.858600967635532]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815366198667107, 34.858499576778421], [136.81537025462049, 34.858495671263583], [136.815356348494504, 34.85849322607158], [136.815358335083943, 34.85849845606549], [136.815366198667107, 34.858499576778421]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815356307107237, 34.85849322607158], [136.815370296007757, 34.858495705224577], [136.815368141367827, 34.858490406848674], [136.815360523438045, 34.858489198842641], [136.815356307107237, 34.85849322607158]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815498014475821, 34.858521110675134], [136.815502070429233, 34.85851720516132], [136.815488164303218, 34.858514759969964], [136.815490150892657, 34.858519989962495], [136.815498014475821, 34.858521110675134]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815488122915923, 34.858514759969964], [136.815502111816471, 34.858517239122314], [136.815499957176542, 34.858511940747789], [136.815492339246759, 34.858510732742076], [136.815488122915923, 34.858514759969964]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815524920258127, 34.858416827448316], [136.815528976211539, 34.858412921929556], [136.815515070085524, 34.858410476735095], [136.815517056674963, 34.858415706734263], [136.815524920258127, 34.858416827448316]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815515028698229, 34.858410476735095], [136.815529017598777, 34.858412955890593], [136.815526862958848, 34.858407657509353], [136.815519245029066, 34.858406449502105], [136.815515028698229, 34.858410476735095]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815391581391822, 34.858393255082468], [136.815395637345205, 34.858389349562586], [136.81538173121919, 34.858386904367435], [136.815383717808658, 34.858392134368096], [136.815391581391822, 34.858393255082468]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815381689831923, 34.858386904367435], [136.815395678732443, 34.858389383523637], [136.815393524092542, 34.858384085140877], [136.815385906162732, 34.858382877133288], [136.815381689831923, 34.858386904367435]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815401930549058, 34.858362183544472], [136.815404742135144, 34.858363546828478], [136.815452794697279, 34.858164297389116], [136.815448705117518, 34.858164926598626], [136.815401930549058, 34.858362183544472]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815407425921876, 34.858352955159894], [136.815407042523759, 34.858350228591526], [136.815335219279234, 34.858339532053201], [136.815335091479881, 34.858341105073627], [136.815407425921876, 34.858352955159894]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815369213910998, 34.858390917371651], [136.815379693459136, 34.858346348474839], [136.815377265271138, 34.858346033870774], [136.815366530124294, 34.858390393031833], [136.815369213910998, 34.858390917371651]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815395284981975, 34.858392595259055], [136.815367296920499, 34.858387876200638], [136.815366530124294, 34.858390183295903], [136.815396179577533, 34.858395112090108], [136.815395284981975, 34.858392595259055]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815587367432386, 34.858342959926752], [136.815587846679989, 34.858339656583901], [136.815544139296321, 34.858331870132339], [136.815543372500088, 34.858334622918321], [136.815587367432386, 34.858342959926752]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815572143331622, 34.858174082907098], [136.815573421325325, 34.858171566069281], [136.815493674520013, 34.858158981879065], [136.815493418921278, 34.858161079244233], [136.815572143331622, 34.858174082907098]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815447649370611, 34.858176270011874], [136.815466540664573, 34.858180027979273], [136.815468258054949, 34.858175330519991], [136.815445931980236, 34.858172042298335], [136.815447649370611, 34.858176270011874]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815573174838505, 34.858201817403639], [136.815577230791916, 34.858197911874662], [136.815563324665902, 34.858195466673813], [136.815565311255341, 34.858200696686652], [136.815573174838505, 34.858201817403639]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815563283278635, 34.858195466673813], [136.815577272179183, 34.858197945835784], [136.815575117539254, 34.858192647440703], [136.815567499609472, 34.8581914394303], [136.815563283278635, 34.858195466673813]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815444503628243, 34.858180888576811], [136.815448559581654, 34.858176983046839], [136.81543465345564, 34.858174537845379], [136.815436640045078, 34.858179767859539], [136.815444503628243, 34.858180888576811]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815434612068373, 34.858174537845379], [136.815448600968921, 34.858177017007968], [136.815446446328991, 34.858171718611537], [136.815438828399209, 34.858170510600829], [136.815434612068373, 34.858174537845379]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815497252902276, 34.858161708453778], [136.815506040642873, 34.858126946673771], [136.815501317819383, 34.85812565487165], [136.815493163322543, 34.858160659771208], [136.815497252902276, 34.858161708453778]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815469402981847, 34.8581809674711], [136.815483857684029, 34.858123071267372], [136.815479134860567, 34.858123188703935], [136.815463678347328, 34.858177679249664], [136.815469402981847, 34.8581809674711]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815564002567527, 34.858189775206434], [136.815568009811727, 34.858190714698146], [136.81557316572659, 34.858173034224684], [136.815567723579989, 34.858172864353762], [136.815564002567527, 34.858189775206434]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815579987018879, 34.858383386540567], [136.815536087936152, 34.858375757395123], [136.815535129440889, 34.858378982085547], [136.815579220222702, 34.858387083136563], [136.815579987018879, 34.858383386540567]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815576760083729, 34.858386408049071], [136.815588006428015, 34.858339846657294], [136.815584779495197, 34.858339184677753], [136.81557327755209, 34.85838558876798], [136.815576760083729, 34.858386408049071]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815592591231479, 34.858325106143518], [136.815594676992589, 34.858325499398369], [136.815599313713392, 34.858302847896979], [136.815597588421923, 34.858302228519904], [136.815592591231479, 34.858325106143518]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815549834097084, 34.858296759756314], [136.815598451067643, 34.858305059957935], [136.815599529374822, 34.858302405484778], [136.815550692792243, 34.858294176157408], [136.815549834097084, 34.858296759756314]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815597480591208, 34.858303113344292], [136.815598882390503, 34.858303201826743], [136.815610851599985, 34.858254005575894], [136.815609018477801, 34.858253563163437], [136.815597480591208, 34.858303113344292]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815602656465586, 34.858291610626395], [136.815602332973413, 34.858287451951057], [136.815553268877778, 34.858280083798306], [136.81555212395088, 34.858283372015656], [136.815602656465586, 34.858291610626395]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815556989890212, 34.85828266739766], [136.815581748934591, 34.858191184444003], [136.815578314153868, 34.858190949571075], [136.815552982646068, 34.858282432525002], [136.815556989890212, 34.85828266739766]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815564002567527, 34.858191654189852], [136.815580031544243, 34.858193768046142], [136.815580890239431, 34.858191419316931], [136.815565147494453, 34.858189070587649], [136.815564002567527, 34.858191654189852]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815303141638026, 34.858488339652595], [136.815336734576505, 34.858339389126556], [136.815334963680499, 34.858339532053201], [136.815299179857647, 34.858489178595306], [136.815303141638026, 34.858488339652595]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815299563255763, 34.85848750070987], [136.815299307657, 34.858489807802343], [136.81536879252991, 34.858501450993614], [136.815369364993359, 34.858498632529091], [136.815299563255763, 34.85848750070987]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81533723621061, 34.858493700215938], [136.815340098527855, 34.858484305332922], [136.815336663747161, 34.858483600716653], [136.815334946356785, 34.858493700215938], [136.81533723621061, 34.858493700215938]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815347826784489, 34.858486419181702], [136.815348399247938, 34.858485009949192], [136.815338094905798, 34.858482896100391], [136.815338094905798, 34.858484540205019], [136.815347826784489, 34.858486419181702]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815375092681904, 34.858488654256114], [136.81535873436286, 34.858486032560073], [136.815358606563478, 34.858488339652588], [136.815374198086317, 34.858490541877195], [136.815375092681904, 34.858488654256114]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815536375484726, 34.858379060736532], [136.815539959102495, 34.858363111245886], [136.815536663033299, 34.858362465377148], [136.815532829052273, 34.858378746132601], [136.815536375484726, 34.858379060736532]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815544235145836, 34.858346105967449], [136.815547398180172, 34.858332342038516], [136.815544235145836, 34.858331791481312], [136.815540688713384, 34.858345398108305], [136.815544235145836, 34.858346105967449]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815355912102206, 34.858469508390009], [136.815355539378658, 34.858471141325047], [136.815376881873021, 34.858474811700141], [136.815377648669255, 34.858473343550116], [136.815355912102206, 34.858469508390009]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815376626274286, 34.858474706832276], [136.815378032067315, 34.858475126303709], [136.815402952943998, 34.858370572984107], [136.815400908154118, 34.858370782720087], [136.815376626274286, 34.858474706832276]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815370894603404, 34.859043257123687], [136.815384883503924, 34.859045736260107], [136.815382728863995, 34.859040437919631], [136.815375110934241, 34.859039229921677], [136.815370894603404, 34.859043257123687]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815380786163274, 34.859049607788059], [136.815384842116686, 34.859045702299341], [136.815370935990671, 34.859043257123687], [136.81537292258011, 34.859048487082617], [136.815380786163274, 34.859049607788059]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8152948919697, 34.859369861794264], [136.815298947923111, 34.859365956320751], [136.815285041797097, 34.859363511154612], [136.815287028386535, 34.859368741093185], [136.8152948919697, 34.859369861794264]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815285000409801, 34.859363511154612], [136.815298989310321, 34.859365990281383], [136.81529683467042, 34.859360691961534], [136.815289216740638, 34.859359483968284], [136.815285000409801, 34.859363511154612]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813525548289107, 34.858836159325612], [136.813533887197821, 34.85880058960786], [136.813526410934855, 34.858799704788815], [136.813517712590368, 34.858834979567391], [136.813525548289107, 34.858836159325612]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813525332627648, 34.858836336289343], [136.813527920564866, 34.858825482513105], [136.813493702284205, 34.858820291576137], [136.813491330008446, 34.858831145353058], [136.813525332627648, 34.858836336289343]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813533743423562, 34.858800707583733], [136.813500771183584, 34.858795555970104], [136.813498446835752, 34.858806016497795], [136.813531371147775, 34.858811325411516], [136.813533743423562, 34.858800707583733]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813409768503362, 34.85891259943989], [136.813413824456774, 34.85890869394467], [136.813399918330759, 34.858906248764939], [136.813401904920198, 34.858911478732587], [136.813409768503362, 34.85891259943989]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813399876943492, 34.858906248764939], [136.813413865844041, 34.858908727905494], [136.813411711204111, 34.858903429556193], [136.813404093274301, 34.858902221556221], [136.813399876943492, 34.858906248764939]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813537254670763, 34.858933405730838], [136.813541310624174, 34.858929500236592], [136.81352740449816, 34.858927055057492], [136.813529391087599, 34.858932285023812], [136.813537254670763, 34.858933405730838]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813527363110893, 34.858927055057492], [136.813541352011413, 34.858929534197408], [136.813539197371483, 34.858924235849457], [136.813531579441701, 34.858923027849798], [136.813527363110893, 34.858927055057492]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813571085847542, 34.858801168467998], [136.813575141800925, 34.858797262967478], [136.813561235674939, 34.858794817784457], [136.813563222264378, 34.858800047759175], [136.813571085847542, 34.858801168467998]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813561194287644, 34.858794817784457], [136.813575183188192, 34.858797296928358], [136.813573028548262, 34.858791998571874], [136.81356541061848, 34.858790790570275], [136.813561194287644, 34.858794817784457]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813444276432023, 34.858782190009848], [136.813448332385434, 34.858778284508432], [136.81343442625942, 34.858775839324828], [136.813436412848858, 34.858781069300768], [136.813444276432023, 34.858782190009848]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813434384872153, 34.858775839324828], [136.813448373772701, 34.858778318469312], [136.813446219132771, 34.858773020111613], [136.813438601202961, 34.85877181210973], [136.813434384872153, 34.858775839324828]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813412110375481, 34.858902697661776], [136.813477527676724, 34.858912843573563], [136.813477815225269, 34.858911073937882], [136.813412541698341, 34.858900220171499], [136.813412110375481, 34.858902697661776]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813477240128123, 34.858912489646428], [136.813479396742451, 34.858912607622138], [136.813490179814067, 34.858865653275586], [136.8134887420712, 34.858865653275586], [136.813477240128123, 34.858912489646428]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813413907554065, 34.858910720010726], [136.813416064168393, 34.858901576892379], [136.813413979441208, 34.858901753855974], [136.813412254149739, 34.858910366083585], [136.813413907554065, 34.858910720010726]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813262909331172, 34.858885437754168], [136.81327689823172, 34.858887916895341], [136.813274743591791, 34.858882618544698], [136.813267125662009, 34.858881410544427], [136.813262909331172, 34.858885437754168]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813135662787602, 34.858862861814366], [136.813149651688178, 34.858865340956214], [136.813147497048249, 34.858860042604128], [136.813139879118438, 34.858858834603524], [136.813135662787602, 34.858862861814366]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81327280089107, 34.858891788430718], [136.813276856844482, 34.858887882934503], [136.813262950718467, 34.858885437754168], [136.813264937307906, 34.858890667723131], [136.81327280089107, 34.858891788430718]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8131455543475, 34.858869212492664], [136.813149610300911, 34.858865306995369], [136.813135704174897, 34.858862861814366], [136.813137690764336, 34.858868091784764], [136.8131455543475, 34.858869212492664]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813174213478959, 34.858741752392802], [136.813178269432342, 34.858737846889454], [136.813164363306356, 34.858735401704656], [136.813166349895795, 34.858740631683162], [136.813174213478959, 34.858741752392802]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813164321919061, 34.858735401704656], [136.81317831081958, 34.858737880850356], [136.813176156179651, 34.858732582490049], [136.813168538249897, 34.858731374487576], [136.813164321919061, 34.858735401704656]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81330275399111, 34.858761143015826], [136.813306809944521, 34.858757237513402], [136.813292903818507, 34.858754792329179], [136.813294890407946, 34.858760022306456], [136.81330275399111, 34.858761143015826]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813292862431211, 34.858754792329179], [136.81330685133176, 34.858757271474303], [136.81330469669183, 34.85875197311524], [136.813297078762048, 34.858750765113044], [136.813292862431211, 34.858754792329179]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813286832963797, 34.858796904638204], [136.813292764458311, 34.858797727666023], [136.813293619341181, 34.858793477776018], [136.813288035701618, 34.85879255400819], [136.813286832963797, 34.858796904638204]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813319790964954, 34.858854285185743], [136.813325722459467, 34.858855108212985], [136.813326577342337, 34.858850858325944], [136.813320993702774, 34.858849934558755], [136.813319790964954, 34.858854285185743]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813331541208839, 34.858803489293237], [136.813337472703353, 34.858804312320991], [136.813338327586223, 34.858800062431321], [136.81333274394666, 34.858799138663571], [136.813331541208839, 34.858803489293237]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813147836176341, 34.858827241080213], [136.813153767670855, 34.858828064107726], [136.813154622553725, 34.858823814219285], [136.813149038914162, 34.858822890451798], [136.813147836176341, 34.858827241080213]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813159299828925, 34.858775974838373], [136.813165231323438, 34.858776797866398], [136.813166086206309, 34.858772547975306], [136.813160502566745, 34.85877162420725], [136.813159299828925, 34.858775974838373]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813108554924753, 34.85886657741878], [136.813110855313369, 34.858863431397985], [136.813656993680297, 34.858954727451916], [136.813654273834914, 34.858950728809226], [136.813108554924753, 34.85886657741878]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813600071292115, 34.858797679535897], [136.813598729398763, 34.858795634620691], [136.813143060753987, 34.858721860337809], [136.813143444152075, 34.85872563556925], [136.813600071292115, 34.858797679535897]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813643691935482, 34.858814490054584], [136.813640292128753, 34.858811700299135], [136.81346799064579, 34.858783522429533], [136.81346626535435, 34.858785881947433], [136.813643691935482, 34.858814490054584]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813523199972565, 34.858846364233102], [136.813525476401963, 34.858846659173011], [136.813537769103618, 34.858795339681386], [136.813535202939278, 34.85879525292183], [136.813523199972565, 34.858846364233102]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813466457053408, 34.858786196549808], [136.813469620090871, 34.858786373514043], [136.813471776705228, 34.858776345562575], [136.813468949141054, 34.858775500068177], [136.813466457053408, 34.858786196549808]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813132416617975, 34.858762570302936], [136.813138148444267, 34.858763510968359], [136.813149038914219, 34.858723062345206], [136.813143307087927, 34.858722121679314], [136.813132416617975, 34.858762570302936]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813108916130204, 34.858868395096238], [136.813114074773864, 34.858867454432001], [136.813122099330656, 34.858826535527605], [136.813117513869628, 34.858826065195245], [136.813108916130204, 34.858868395096238]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813122118668673, 34.858830113064066], [136.81312095296542, 34.858827005859951], [136.812962754559891, 34.858801607909207], [136.812962754559891, 34.858805840901539], [136.813122118668673, 34.858830113064066]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813137002079003, 34.858764451633782], [136.813138148444267, 34.858760688972048], [136.812978230490842, 34.858735526167251], [136.812979376856106, 34.858739053663712], [136.813137002079003, 34.858764451633782]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812992846647859, 34.858838999333976], [136.812997432108887, 34.858840410330799], [136.813005456665678, 34.858808898062527], [136.81300087120465, 34.858807957397623], [136.812992846647859, 34.858838999333976]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812996858926255, 34.85883758833711], [136.812934955202365, 34.85882724102612], [136.81293380883713, 34.858829592687826], [136.812996858926255, 34.858840410330799], [136.812996858926255, 34.85883758833711]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812933235654469, 34.858838529001694], [136.812942406576525, 34.858799961745454], [136.81292578428031, 34.858797139750379], [136.812913174262462, 34.858834766343335], [136.812933235654469, 34.858838529001694]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812944126124449, 34.858731293171303], [136.812958455690136, 34.858734585501502], [136.812967626612192, 34.858692725864785], [136.812952150681241, 34.858691785198545], [136.812944126124449, 34.858731293171303]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812955196719884, 34.858830643955308], [136.812959028872768, 34.858817364046239], [136.812938806451029, 34.858814186325517], [136.812936110683097, 34.858827370125987], [136.812955196719884, 34.858830643955308]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812981669586634, 34.858723062345206], [136.812985681865001, 34.858707071023588], [136.812962754559891, 34.858705424857945], [136.812959315464099, 34.858719064515093], [136.812981669586634, 34.858723062345206]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813025804649016, 34.858715301851277], [136.81302695101428, 34.858713420519301], [136.812984248908435, 34.858707776523133], [136.812984822091067, 34.858710128188257], [136.813025804649016, 34.858715301851277]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813017493500894, 34.858741875660769], [136.813019786231422, 34.858744932824145], [136.813026664422949, 34.858714361185292], [136.813022938735884, 34.858713185352812], [136.813017493500894, 34.858741875660769]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812909828831948, 34.858693071735239], [136.812923817732496, 34.85869555088221], [136.812921663092567, 34.858690252519175], [136.812914045162756, 34.85868904451609], [136.812909828831948, 34.858693071735239]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812891061260387, 34.858826882592098], [136.81289511721377, 34.8588229770928], [136.812881211087785, 34.858820531910531], [136.812883197677223, 34.858825761883629], [136.812891061260387, 34.858826882592098]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812919720391818, 34.858699422426646], [136.812923776345229, 34.858695516921294], [136.812909870219215, 34.858693071735239], [136.812911856808654, 34.858698301716437], [136.812919720391818, 34.858699422426646]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812881169700518, 34.858820531910531], [136.812895158601037, 34.858823011053666], [136.812893003961108, 34.858817712698844], [136.812885386031326, 34.858816504697621], [136.812881169700518, 34.858820531910531]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812781435923114, 34.858672377075592], [136.812795424823662, 34.858674856223189], [136.812793270183732, 34.858669557858825], [136.81278565225395, 34.858668349855428], [136.812781435923114, 34.858672377075592]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812761805705833, 34.858808311531646], [136.812765861659244, 34.858804406031467], [136.81275195553323, 34.858801960848645], [136.812753942122669, 34.858807190822915], [136.812761805705833, 34.858808311531646]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812791327482984, 34.858678727768606], [136.812795383436395, 34.858674822262266], [136.812781477310381, 34.858672377075592], [136.812783463899819, 34.858677607058105], [136.812791327482984, 34.858678727768606]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812751914145935, 34.858801960848645], [136.812765903046511, 34.858804439992333], [136.812763748406553, 34.858799141636318], [136.812756130476771, 34.858797933634825], [136.812751914145935, 34.858801960848645]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812653043014308, 34.858651682410745], [136.812667031914827, 34.85865416155896], [136.812664877274898, 34.858648863193267], [136.812657259345116, 34.858647655189564], [136.812653043014308, 34.858651682410745]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812633412796998, 34.858787616900997], [136.81263746875041, 34.858783711399838], [136.812623562624395, 34.858781266216397], [136.812625549213834, 34.858786496191989], [136.812633412796998, 34.858787616900997]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812662934574178, 34.858658033105343], [136.812666990527561, 34.85865412759803], [136.812653084401575, 34.858651682410745], [136.812655070991013, 34.858656912394572], [136.812662934574178, 34.858658033105343]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812623521237128, 34.858781266216397], [136.812637510137677, 34.858783745360718], [136.812635355497747, 34.858778447003367], [136.812627737567965, 34.858777239001569], [136.812623521237128, 34.858781266216397]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812523503740238, 34.858630047073746], [136.812537492640757, 34.858632526222621], [136.812535338000828, 34.858627227855528], [136.812527720071046, 34.858626019851499], [136.812523503740238, 34.858630047073746]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812504736168648, 34.85876704338304], [136.81250879212206, 34.858763137880892], [136.812494885996045, 34.858760692696855], [136.812496872585484, 34.858765922673747], [136.812504736168648, 34.85876704338304]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812533395300107, 34.85863639777002], [136.812537451253519, 34.85863249226167], [136.812523545127505, 34.858630047073746], [136.812525531716943, 34.858635277058951], [136.812533395300107, 34.85863639777002]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812494844608779, 34.858760692696855], [136.812508833509327, 34.85876317184178], [136.812506678869397, 34.858757873483107], [136.812499060939615, 34.85875666548101], [136.812494844608779, 34.858760692696855]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812397403561903, 34.858610293065425], [136.812411392462451, 34.858612772214897], [136.812409237822521, 34.858607473846526], [136.812401619892739, 34.858606265842212], [136.812397403561903, 34.858610293065425]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81237863599037, 34.858746581551927], [136.812382691943753, 34.858742676048813], [136.812368785817739, 34.858740230864164], [136.812370772407178, 34.858745460842357], [136.81237863599037, 34.858746581551927]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812407295121801, 34.85861664376322], [136.812411351075212, 34.858612738253946], [136.812397444949198, 34.858610293065425], [136.812399431538637, 34.858615523051881], [136.812407295121801, 34.85861664376322]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812368744430501, 34.858740230864164], [136.81238273333102, 34.858742710009707], [136.812380578691091, 34.858737411649713], [136.812372960761309, 34.858736203647311], [136.812368744430501, 34.858740230864164]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812265571557305, 34.858591479719756], [136.812279560457853, 34.85859395886979], [136.812277405817923, 34.858588660500217], [136.812269787888141, 34.858587452495627], [136.812265571557305, 34.858591479719756]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812246803985772, 34.858725290741887], [136.812250859939155, 34.858721385237757], [136.812236953813141, 34.858718940052469], [136.812238940402608, 34.858724170032019], [136.812246803985772, 34.858725290741887]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812275463117174, 34.858597830419008], [136.812279519070586, 34.858593924908831], [136.812265612944572, 34.858591479719756], [136.81226759953401, 34.858596709707413], [136.812275463117174, 34.858597830419008]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812236912425874, 34.858718940052469], [136.812250901326394, 34.858721419198666], [136.812248746686492, 34.858716120837293], [136.81224112875671, 34.858714912834579], [136.812236912425874, 34.858718940052469]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812136615038469, 34.858567255174393], [136.812150603939045, 34.858569734325151], [136.812148449299116, 34.858564435954015], [136.812140831369305, 34.858563227949077], [136.812136615038469, 34.858567255174393]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812114109335425, 34.858704251588257], [136.812118165288837, 34.858700346083133], [136.812104259162822, 34.858697900897219], [136.812106245752261, 34.858703130878112], [136.812114109335425, 34.858704251588257]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812146506598367, 34.858573605875506], [136.812150562551778, 34.858569700364178], [136.812136656425764, 34.858567255174393], [136.812138643015203, 34.858572485163585], [136.812146506598367, 34.858573605875506]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812104217775556, 34.858697900897219], [136.812118206676104, 34.858700380044056], [136.812116052036174, 34.858695081681326], [136.812108434106392, 34.858693873678305], [136.812104217775556, 34.858697900897219]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812008218300605, 34.858546670604362], [136.812022207201153, 34.858549149755738], [136.812020052561223, 34.858543851383281], [136.812012434631441, 34.858542643378037], [136.812008218300605, 34.858546670604362]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811987725437575, 34.858683431100431], [136.811991781390986, 34.858679525594319], [136.811977875264972, 34.858677080407787], [136.811979861854411, 34.858682310390002], [136.811987725437575, 34.858683431100431]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812018109860475, 34.858553021307067], [136.812022165813886, 34.858549115794766], [136.812008259687872, 34.858546670604362], [136.81201024627731, 34.858551900594861], [136.812018109860475, 34.858553021307067]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811977833877705, 34.858677080407787], [136.811991822778253, 34.858679559555249], [136.811989668138324, 34.858674261191183], [136.811982050208542, 34.85867305318785], [136.811977833877705, 34.858677080407787]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812951577498552, 34.858695547863441], [136.812956162959608, 34.858691785198552], [136.811920995132198, 34.858525287104882], [136.811919848766962, 34.858530931113577], [136.812951577498552, 34.858695547863441]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812755549039565, 34.858674853204413], [136.812758988135329, 34.858661683873237], [136.812694218498308, 34.858651806873468], [136.812691352585148, 34.858664035539675], [136.812755549039565, 34.858674853204413]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812886234678899, 34.858694607197229], [136.812889673774663, 34.858681437869215], [136.812824904137642, 34.858671560871819], [136.812822038224482, 34.858683789535085], [136.812886234678899, 34.858694607197229]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81263976614855, 34.858791966092511], [136.812636900235418, 34.858788203432034], [136.811888323722371, 34.858669209205587], [136.811889470087635, 34.858672971871506], [136.81263976614855, 34.858791966092511]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812855282816969, 34.858823008034889], [136.812858721912761, 34.858809838727439], [136.812793952275712, 34.858799961745454], [136.812791086362552, 34.858812190389635], [136.812855282816969, 34.858823008034889]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812724597177635, 34.85880325407291], [136.812728266975569, 34.858793923970097], [136.812663568545787, 34.858783660067758], [136.812660400723217, 34.858792436425063], [136.812724597177635, 34.85880325407291]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812660400723161, 34.858792436425063], [136.812658681175293, 34.858796199085333], [136.812925784280253, 34.858837118004836], [136.812921772001857, 34.858833355346412], [136.812660400723161, 34.858792436425063]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812595057903536, 34.85878161877578], [136.812599301438865, 34.858772334380916], [136.812534171686195, 34.858762070475883], [136.812530861449119, 34.858770801125075], [136.812595057903536, 34.85878161877578]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812626009765467, 34.85865321787351], [136.812629448861259, 34.858640048538867], [136.812564679224209, 34.858630171536504], [136.812561813311049, 34.858642400205916], [136.812626009765467, 34.85865321787351]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812445566758583, 34.858759842480765], [136.812448338436013, 34.858747205507704], [136.811889344002651, 34.858658723498628], [136.811886713695657, 34.858671655046109], [136.812445566758583, 34.858759842480765]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812370215540653, 34.858614665598282], [136.812373654636417, 34.858601496257464], [136.812308884999396, 34.858591619250468], [136.812306019086265, 34.858603847925622], [136.812370215540653, 34.858614665598282]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812240009492115, 34.858589530501213], [136.81224105926276, 34.858578364879953], [136.812176289625739, 34.85856848787018], [136.812174448416584, 34.858579620501935], [136.812240009492115, 34.858589530501213]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81207860385021, 34.858570005442687], [136.812079754044504, 34.858567881871011], [136.812010167288918, 34.858557028059359], [136.812008441997449, 34.85855891567887], [136.81207860385021, 34.858570005442687]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812543852263218, 34.85872027192314], [136.81254442544585, 34.858718508174526], [136.812541846124049, 34.858718037841562], [136.812541272941388, 34.858719919173424], [136.812543852263218, 34.85872027192314]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812446301695246, 34.858706420508689], [136.812457192165169, 34.858708537007331], [136.812459484895697, 34.858700306178974], [136.812448594425746, 34.858697954513566], [136.812446301695246, 34.858706420508689]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813147836176341, 34.858827241080213], [136.813153767670855, 34.858828064107726], [136.813154622553725, 34.858823814219285], [136.813149038914162, 34.858822890451798], [136.813147836176341, 34.858827241080213]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812570052770468, 34.858726174548025], [136.812575984264981, 34.858726997576547], [136.812576839147852, 34.858722747682876], [136.812571255508288, 34.858721823914259], [136.812570052770468, 34.858726174548025]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81250814904655, 34.858715827223016], [136.812514080541064, 34.858716650251637], [136.812514935423962, 34.858712400357447], [136.812509351784399, 34.858711476588702], [136.81250814904655, 34.858715827223016]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812483954678498, 34.858710159763902], [136.812484527861159, 34.858708396015068], [136.812481948539357, 34.85870792568204], [136.812481375356697, 34.858709807014144], [136.812483954678498, 34.858710159763902]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812363586326484, 34.85868899477542], [136.812364159509144, 34.858687231026131], [136.812361580187343, 34.858686760692983], [136.812361007004682, 34.858688642025569], [136.812363586326484, 34.85868899477542]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812423483911203, 34.858699106937259], [136.812424057093835, 34.85869734318819], [136.812421477772006, 34.858696872855099], [136.812420904589374, 34.858698754187458], [136.812423483911203, 34.858699106937259]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812386061146668, 34.858694897402543], [136.812391992641182, 34.858695720431385], [136.812392847524052, 34.858691470536101], [136.812387263884489, 34.858690546767122], [136.812386061146668, 34.858694897402543]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812324157422751, 34.858684550073605], [136.812330088917264, 34.858685373102539], [136.812330943800163, 34.858681123206729], [136.8123253601606, 34.858680199437643], [136.812324157422751, 34.858684550073605]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81225193641157, 34.858673262076906], [136.812257867906084, 34.85867408510596], [136.812258722788954, 34.858669835209561], [136.812253139149391, 34.85866891144034], [136.81225193641157, 34.858673262076906]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812291365315303, 34.858677706779339], [136.812291938497935, 34.858675943029809], [136.812289359176134, 34.858675472696596], [136.812288785993474, 34.858677354029439], [136.812291365315303, 34.858677706779339]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812222583399858, 34.858666889114971], [136.81222315658249, 34.858665125365206], [136.812220577260689, 34.858664655031937], [136.812220004078057, 34.858666536365028], [136.812222583399858, 34.858666889114971]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812184930416578, 34.858663149857797], [136.812195820886529, 34.858665266357548], [136.812198113617058, 34.85865703552485], [136.812187223147077, 34.858654683858219], [136.812184930416578, 34.858663149857797]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811991045308531, 34.858643622560294], [136.811994639665727, 34.858629937331877], [136.811991189082818, 34.858629701379641], [136.811987882274167, 34.858642206847115], [136.811991045308531, 34.858643622560294]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811986276794613, 34.858629662054256], [136.811994711552842, 34.858595291671584], [136.811985701697466, 34.858593875957574], [136.811977219014437, 34.858628167690092], [136.811986276794613, 34.858629662054256]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81201229994079, 34.858633791218253], [136.812014983727494, 34.858623016066055], [136.811979591290168, 34.858617608826719], [136.811977195052009, 34.858628226678142], [136.81201229994079, 34.858633791218253]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812020782623819, 34.858599342186544], [136.811985701697466, 34.858593875957574], [136.811983449233594, 34.858603314050526], [136.812018338460916, 34.858609055556535], [136.812020782623819, 34.858599342186544]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810650973087746, 34.858445796069645], [136.810653081777303, 34.858433998430705], [136.810618000850923, 34.858429436676531], [136.810615942572127, 34.858440440204568], [136.810650973087746, 34.858445796069645]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810660174642209, 34.858410245845846], [136.810624782002009, 34.858405016901322], [136.810621287343679, 34.858416824670776], [136.810657107457359, 34.858422672695731], [136.810660174642209, 34.858410245845846]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810625295922335, 34.858441536639901], [136.81063362143189, 34.858406450701985], [136.810623754161355, 34.858404848218889], [136.810615325867758, 34.858439934157488], [136.810625295922335, 34.858441536639901]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810650589689629, 34.858445953371486], [136.810660174642209, 34.858410245845846], [136.810650925166527, 34.858408849791857], [136.810642154934925, 34.858444242714413], [136.810650589689629, 34.858445953371486]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810641058444617, 34.858381098627667], [136.810649631194394, 34.858344179017429], [136.810638512649405, 34.858342920601146], [136.810630077891147, 34.858379257363886], [136.810641058444617, 34.858381098627667]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810665733918199, 34.858385254501684], [136.810674552071049, 34.858347796964175], [136.81066458372041, 34.858346695849967], [136.810655382169443, 34.858383720807588], [136.810665733918199, 34.858385254501684]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810665302595339, 34.858385254501684], [136.810668034306815, 34.858373692807035], [136.810632378279763, 34.858367459715403], [136.810630461289264, 34.858379414665855], [136.810665302595339, 34.858385254501684]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810674360372019, 34.858348268870266], [136.810638512649405, 34.858342763299099], [136.810637362455111, 34.858354875555158], [136.810671868284317, 34.858359594615479], [136.810674360372019, 34.858348268870266]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815224289428244, 34.859981014942015], [136.81523016819915, 34.859956895772989], [136.815228379008033, 34.859955847113298], [136.815222244638363, 34.859981014942015], [136.815224289428244, 34.859981014942015]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815372549776725, 34.855950352447806], [136.815379597168629, 34.855920580547561], [136.815369939631552, 34.855919081242931], [136.815363153254168, 34.85594906733003], [136.815372549776725, 34.855950352447806]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818550225028247, 34.859633334983684], [136.818558384564426, 34.859634897231132], [136.818573343714093, 34.859565711958183], [136.818568176007858, 34.859563480174216], [136.818550225028247, 34.859633334983684]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818645098288755, 34.859667976376201], [136.818663369304829, 34.859578022148959], [136.818656582927446, 34.859577165441557], [136.818636223795266, 34.859666691316498], [136.818645098288755, 34.859667976376201]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818541214511754, 34.859779348140293], [136.818547478860097, 34.859779348140293], [136.818564705818119, 34.859716380276815], [136.818558441469719, 34.859714666864889], [136.818541214511754, 34.859779348140293]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818577756543874, 34.859785345077157], [136.818581410747072, 34.859786201782384], [136.818594983501868, 34.859724090630031], [136.818590807269629, 34.859723662277098], [136.818577756543874, 34.859785345077157]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818591329298641, 34.859726660747612], [136.818964058026239, 34.859782774961417], [136.818963535997199, 34.859778063082338], [136.818591851327653, 34.859722805571216], [136.818591329298641, 34.859726660747612]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818633613650121, 34.859666691316498], [136.818976586722954, 34.859721948865321], [136.818979196868099, 34.859716808629791], [136.818645620317795, 34.859664121197049], [136.818633613650121, 34.859666691316498]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818665979449975, 34.859575452026732], [136.818568176007858, 34.859561248390186], [136.818567632038793, 34.859566158314969], [136.818664413362882, 34.859579307210034], [136.818665979449975, 34.859575452026732]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818656983333, 34.859578488889056], [136.818614044862898, 34.859572133809991], [136.818612302518943, 34.859580338622663], [136.818655189603476, 34.859586694373306], [136.818656983333, 34.859578488889056]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818613627225488, 34.859571801870203], [136.81860569179986, 34.859610453951476], [136.81862013544503, 34.8596131329369], [136.818628064317267, 34.859574143539668], [136.818613627225488, 34.859571801870203]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818657138308652, 34.85957852903713], [136.818645849658623, 34.859576863164044], [136.818637662220482, 34.859615771591628], [136.818648996521802, 34.859617141639369], [136.818657138308652, 34.85957852903713]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818617145195873, 34.859572978937024], [136.818605856545872, 34.859571313063803], [136.818597669107731, 34.85961022149403], [136.818609003409023, 34.859611591541878], [136.818617145195873, 34.859572978937024]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81857363411271, 34.859566251769635], [136.818565698687081, 34.859604903853523], [136.818580142332252, 34.859607582839132], [136.818588071204488, 34.859568593439278], [136.81857363411271, 34.859566251769635]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818616990220193, 34.859572938788951], [136.818574051750147, 34.859566583709459], [136.818572309406164, 34.859574788522693], [136.818615196490697, 34.85958114427374], [136.818616990220193, 34.859572938788951]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81509086688871, 34.859637473415319], [136.815100324041907, 34.859638731811813], [136.815118982749539, 34.859556306801117], [136.815111940822447, 34.859553981011004], [136.81509086688871, 34.859637473415319]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815100920143067, 34.859605829980744], [136.815103588357942, 34.859603241685839], [136.815077197385477, 34.859599105421587], [136.81507696487742, 34.859601956625134], [136.815100920143067, 34.859605829980744]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815037515681894, 34.859595035293417], [136.815058866163724, 34.859598751497415], [136.815059081825154, 34.859596097066003], [136.815037084359034, 34.859592557823994], [136.815037515681894, 34.859595035293417]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815047209222683, 34.859563404794557], [136.815066414313861, 34.859567075277027], [136.815067061298151, 34.859564243882431], [136.81504786743065, 34.859560881601226], [136.815047209222683, 34.859563404794557]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815109115277522, 34.859573799838806], [136.815109762261812, 34.859569906671517], [136.815084098551324, 34.859566367428386], [136.815083451567034, 34.859569729709371], [136.815109115277522, 34.859573799838806]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815058003517976, 34.85952053421601], [136.815105017710323, 34.859528497517289], [136.815105233371753, 34.859526904857098], [136.815057787856546, 34.859518587631129], [136.815058003517976, 34.85952053421601]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814965592594376, 34.85988781856517], [136.815017782661073, 34.8598957818309], [136.815017674830386, 34.859894366139265], [136.814965794357136, 34.859885508425705], [136.814965592594376, 34.85988781856517]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814977993126462, 34.859845082359222], [136.815001068899733, 34.859848444628817], [136.815000853238303, 34.859847028936372], [136.814977561803573, 34.859842958820465], [136.814977993126462, 34.859845082359222]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815020262767234, 34.859852160821362], [136.815035143406106, 34.859854461321426], [136.815035574728967, 34.859852868667545], [136.815021341074441, 34.859849860321241], [136.815020262767234, 34.859852160821362]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8150148542544, 34.859879092031861], [136.815039911647887, 34.859882090496711], [136.815039911647887, 34.859879948736115], [136.815016942370534, 34.859876093566896], [136.8150148542544, 34.859879092031861]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815025816864051, 34.859878235327606], [136.815039389618846, 34.859881233792493], [136.815047742083323, 34.859844395502066], [136.815036221713257, 34.859842604897338], [136.815025816864051, 34.859878235327606]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816840090914184, 34.859668382021802], [136.81684586586033, 34.859668582812382], [136.816845229637437, 34.859666789083235], [136.816841118658829, 34.859666574906612], [136.816840090914184, 34.859668382021802]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816844642354795, 34.859670537173947], [136.816845882173737, 34.859668582812382], [136.81684012354097, 34.859668382021802], [136.816840727137077, 34.85967043008565], [136.816844642354795, 34.859670537173947]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816883680338179, 34.859675664026334], [136.816889455284326, 34.859675864816893], [136.816888819061461, 34.859674071087902], [136.816884708082853, 34.8596738569113], [136.816883680338179, 34.859675664026334]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81688823177879, 34.859677819178287], [136.816889471597761, 34.859675864816893], [136.816883712964994, 34.859675664026334], [136.816884316561072, 34.859677712089997], [136.81688823177879, 34.859677819178287]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816819481599026, 34.859749314078179], [136.816825256545172, 34.859749514868561], [136.816824620322279, 34.859747721141169], [136.816820509343671, 34.859747506964766], [136.816819481599026, 34.859749314078179]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816824033039666, 34.8597514692282], [136.81682527285858, 34.859749514868561], [136.816819514225813, 34.859749314078179], [136.816820117821919, 34.859751362140003], [136.816824033039666, 34.8597514692282]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81686333203757, 34.859756167722772], [136.816869106983717, 34.859756368513139], [136.816868470760824, 34.859754574785903], [136.816864359782215, 34.859754360609507], [136.81686333203757, 34.859756167722772]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816867883478182, 34.859758322872629], [136.816869123297124, 34.859756368513139], [136.816863364664357, 34.859756167722772], [136.816863968260463, 34.859758215784431], [136.816867883478182, 34.859758322872629]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816801439102818, 34.859827327578998], [136.816807214048936, 34.859827528369202], [136.816806577826071, 34.859825734643508], [136.816802466847491, 34.859825520467304], [136.816801439102818, 34.859827327578998]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816805990543429, 34.859829482726987], [136.8168072303624, 34.859827528369202], [136.816801471729633, 34.859827327578998], [136.816802075325711, 34.859829375638888], [136.816805990543429, 34.859829482726987]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816782225021569, 34.859907599398831], [136.816787999967687, 34.859907800188836], [136.816787363744822, 34.859906006464904], [136.816783252766214, 34.859905792288906], [136.816782225021569, 34.859907599398831]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816786776462209, 34.859909754544724], [136.816788016281151, 34.859907800188836], [136.816782257648384, 34.859907599398831], [136.816782861244462, 34.859909647456725], [136.816786776462209, 34.859909754544724]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816763034902721, 34.859986770048081], [136.816768809848838, 34.859986970837888], [136.816768173625974, 34.859985177115682], [136.816764062647366, 34.85998496293989], [136.816763034902721, 34.859986770048081]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816767586343332, 34.859988925191892], [136.816768826162303, 34.859986970837888], [136.816763067529536, 34.859986770048081], [136.816763671125585, 34.859988818104], [136.816767586343332, 34.859988925191892]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816743379211886, 34.86006664846547], [136.816749154158032, 34.86006684925507], [136.816748517935167, 34.860065055534612], [136.816744406956559, 34.860064841359026], [136.816743379211886, 34.86006664846547]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816747930652525, 34.860068803607177], [136.816749170471468, 34.86006684925507], [136.816743411838701, 34.86006664846547], [136.816744015434779, 34.860068696519392], [136.816747930652525, 34.860068803607177]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816729387517938, 34.860147266257634], [136.816730627336881, 34.860145311907388], [136.816724868704142, 34.860145111117973], [136.81672547230022, 34.860147159169948], [136.816729387517938, 34.860147266257634]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816825825549557, 34.859914276068835], [136.816831600495703, 34.859914476858819], [136.81683096427281, 34.859912683135029], [136.816826853294202, 34.859912468959045], [136.816825825549557, 34.859914276068835]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816830376990168, 34.859916431214536], [136.816831616809111, 34.859914476858819], [136.816825858176344, 34.859914276068835], [136.81682646177245, 34.859916324126559], [136.816830376990168, 34.859916431214536]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816806635430709, 34.859994331517861], [136.816812410376855, 34.859994532307638], [136.81681177415399, 34.859992738585596], [136.816807663175382, 34.859992524409833], [136.816806635430709, 34.859994331517861]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81681118687132, 34.859996486661473], [136.816812426690291, 34.859994532307638], [136.816806668057524, 34.859994331517861], [136.816807271653602, 34.859996379573587], [136.81681118687132, 34.859996486661473]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816787195401332, 34.860073325122556], [136.81679297034745, 34.860073525912142], [136.816792334124585, 34.860071732191827], [136.816788223146006, 34.860071518016262], [136.816787195401332, 34.860073325122556]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816791746841943, 34.860075480264094], [136.816792986660886, 34.860073525912142], [136.816787228028147, 34.860073325122556], [136.816787831624225, 34.860075373176315], [136.816791746841943, 34.860075480264094]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816769730573924, 34.860152141690492], [136.81677550552007, 34.860152342479893], [136.816774869297205, 34.860150548761283], [136.816770758318597, 34.860150334585931], [136.816769730573924, 34.860152141690492]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816774282014563, 34.860154296829968], [136.816775521833506, 34.860152342479893], [136.816769763200739, 34.860152141690492], [136.816770366796817, 34.860154189742289], [136.816774282014563, 34.860154296829968]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816844022379257, 34.859834993482487], [136.816849797325375, 34.859835194272669], [136.816849161102482, 34.859833400547146], [136.816845050123902, 34.859833186370956], [136.816844022379257, 34.859834993482487]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816848573819868, 34.859837148630277], [136.816849813638811, 34.859835194272669], [136.816844055006044, 34.859834993482487], [136.81684465860215, 34.859837041542193], [136.816848573819868, 34.859837148630277]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816304634427809, 34.861739566773238], [136.816539761340067, 34.861778071604171], [136.816539761340067, 34.861771501138541], [136.816305065750669, 34.861734258048514], [136.816304634427809, 34.861739566773238]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816579527692596, 34.861078923825232], [136.816585270011927, 34.861079352171117], [136.816733129284927, 34.860472424593517], [136.816725182927229, 34.860471337867644], [136.816579527692596, 34.861078923825232]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816438700934242, 34.861691152666374], [136.816445985095498, 34.861691696021261], [136.816576520575865, 34.861157780201403], [136.816568574218138, 34.861156693484581], [136.816438700934242, 34.861691152666374]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815424843614693, 34.859266239056332], [136.815473583098424, 34.8590700755516], [136.815469701192626, 34.859068659845768], [136.815421824354587, 34.859265796649318], [136.815424843614693, 34.859266239056332]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816855864045237, 34.859179895770275], [136.81685991999862, 34.859175990287731], [136.816846013872606, 34.859173545115958], [136.816848000462073, 34.859178775066603], [136.816855864045237, 34.859179895770275]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816845972485339, 34.859173545115958], [136.816859961385859, 34.859176024248448], [136.816857806745929, 34.859170725916364], [136.816850188816147, 34.859169517920321], [136.816845972485339, 34.859173545115958]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816651206249361, 34.859142294754044], [136.816665195149881, 34.859144773887486], [136.816663040509951, 34.859139475553377], [136.816655422580169, 34.859138267556879], [136.816651206249361, 34.859142294754044]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816661097809231, 34.859148645410777], [136.816665153762614, 34.859144739926748], [136.816651247636628, 34.859142294754044], [136.816653234226067, 34.859147524706685], [136.816661097809231, 34.859148645410777]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816545743929368, 34.859020031325144], [136.816549799882779, 34.859016125835012], [136.816535893756765, 34.859013680658485], [136.816537880346203, 34.859018910619298], [136.816545743929368, 34.859020031325144]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816510477631937, 34.859120268638783], [136.816524466532485, 34.859122747772879], [136.816522311892555, 34.859117449437363], [136.816514693962773, 34.859116241440539], [136.816510477631937, 34.859120268638783]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816520369191835, 34.859126619297207], [136.816524425145246, 34.859122713812141], [136.816510519019232, 34.859120268638783], [136.816512505608671, 34.859125498592817], [136.816520369191835, 34.859126619297207]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816561424823277, 34.858908063917603], [136.816575413723825, 34.858910543058094], [136.816573259083896, 34.858905244708907], [136.816565641154085, 34.858904036708978], [136.816561424823277, 34.858908063917603]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816571316383147, 34.858914414592405], [136.816575372336558, 34.858910509097271], [136.816561466210544, 34.858908063917603], [136.816563452799983, 34.858913293885131], [136.816571316383147, 34.858914414592405]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816651206249361, 34.859142294754044], [136.816665195149881, 34.859144773887486], [136.816663040509951, 34.859139475553377], [136.816655422580169, 34.859138267556879], [136.816651206249361, 34.859142294754044]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816661097809231, 34.859148645410777], [136.816665153762614, 34.859144739926748], [136.816651247636628, 34.859142294754044], [136.816653234226067, 34.859147524706685], [136.816661097809231, 34.859148645410777]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816535852369469, 34.859013680658485], [136.816549841269989, 34.859016159795793], [136.816547686630088, 34.859010861453413], [136.816540068700306, 34.859009653455033], [136.816535852369469, 34.859013680658485]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816685890886504, 34.859042519940246], [136.816689946839915, 34.85903861445118], [136.816676040713901, 34.859036169275328], [136.81667802730334, 34.859041399234712], [136.816685890886504, 34.859042519940246]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816702070397611, 34.85892962419058], [136.81671605929813, 34.858932103330432], [136.816713904658201, 34.85892680498263], [136.816706286728419, 34.858925596983006], [136.816702070397611, 34.85892962419058]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816711961957481, 34.858935974863734], [136.816716017910892, 34.858932069369608], [136.816702111784878, 34.85892962419058], [136.816704098374316, 34.858934854156743], [136.816711961957481, 34.858935974863734]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816675999326606, 34.859036169275328], [136.816689988227182, 34.859038648411961], [136.816687833587252, 34.85903335007103], [136.816680215657442, 34.859032142072969], [136.816675999326606, 34.859036169275328]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816737521830959, 34.858830688058049], [136.81674157778437, 34.858826782558928], [136.816727671658356, 34.858824337376781], [136.816729658247795, 34.85882956734963], [136.816737521830959, 34.858830688058049]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816727630271089, 34.858824337376781], [136.816741619171637, 34.858826816519795], [136.816739464531707, 34.858821518165222], [136.816731846601925, 34.858820310164049], [136.816727630271089, 34.858824337376781]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81658645480465, 34.858801908078512], [136.81660044370517, 34.858804387222207], [136.81659828906524, 34.858799088866178], [136.816590671135458, 34.858797880864678], [136.81658645480465, 34.858801908078512]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816596346364491, 34.85880825876152], [136.816600402317903, 34.858804353261334], [136.816586496191889, 34.858801908078512], [136.816588482781327, 34.858807138052782], [136.816596346364491, 34.85880825876152]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816310980369281, 34.858872231521069], [136.816315036322692, 34.858868326023916], [136.816301130196678, 34.858865880842998], [136.816303116786116, 34.858871110813205], [136.816310980369281, 34.858872231521069]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81616102070268, 34.858843229490432], [136.816175009603256, 34.858845708632877], [136.816172854963327, 34.85884041027952], [136.816165237033516, 34.858839202278631], [136.81616102070268, 34.858843229490432]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816301088809439, 34.858865880842998], [136.816315077709959, 34.858868359984761], [136.816312923070029, 34.858863061632867], [136.816305305140247, 34.858861853632312], [136.816301088809439, 34.858865880842998]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816170912262578, 34.858849580170244], [136.816174968215989, 34.858845674672025], [136.816161062089975, 34.858843229490432], [136.816163048679414, 34.858848459462081], [136.816170912262578, 34.858849580170244]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816186069378716, 34.85873710362587], [136.816200058279264, 34.858739582771513], [136.816197903639335, 34.858734284411319], [136.816190285709553, 34.858733076408868], [136.816186069378716, 34.85873710362587]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816195960938586, 34.858743454313874], [136.816200016891997, 34.858739548810611], [136.816186110765983, 34.85873710362587], [136.816188097355422, 34.858742333604262], [136.816195960938586, 34.858743454313874]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816250669078642, 34.859077788408285], [136.81626465797919, 34.859080267543661], [136.81626250333926, 34.859074969205416], [136.816254885409478, 34.859073761207959], [136.816250669078642, 34.859077788408285]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81626056063854, 34.859084139069985], [136.816264616591951, 34.859080233582908], [136.816250710465937, 34.859077788408285], [136.816252697055376, 34.85908301836502], [136.81626056063854, 34.859084139069985]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816301088809439, 34.858865880842998], [136.816315077709959, 34.858868359984761], [136.816312923070029, 34.858863061632867], [136.816305305140247, 34.858861853632312], [136.816301088809439, 34.858865880842998]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816310980369281, 34.858872231521069], [136.816315036322692, 34.858868326023916], [136.816301130196678, 34.858865880842998], [136.816303116786116, 34.858871110813205], [136.816310980369281, 34.858872231521069]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816111107549744, 34.859055076633183], [136.816125096450293, 34.85905755576924], [136.816122941810363, 34.859052257429518], [136.816115323880553, 34.859051049431741], [136.816111107549744, 34.859055076633183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816120999109643, 34.859061427296638], [136.816125055063026, 34.859057521808474], [136.816111148937011, 34.859055076633183], [136.816113135526479, 34.859060306591353], [136.816120999109643, 34.859061427296638]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81616102070268, 34.858843229490432], [136.816175009603256, 34.858845708632877], [136.816172854963327, 34.85884041027952], [136.816165237033516, 34.858839202278631], [136.81616102070268, 34.858843229490432]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816170912262578, 34.858849580170244], [136.816174968215989, 34.858845674672025], [136.816161062089975, 34.858843229490432], [136.816163048679414, 34.858848459462081], [136.816170912262578, 34.858849580170244]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816186069378716, 34.85873710362587], [136.816200058279264, 34.858739582771513], [136.816197903639335, 34.858734284411319], [136.816190285709553, 34.858733076408868], [136.816186069378716, 34.85873710362587]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816195960938586, 34.858743454313874], [136.816200016891997, 34.858739548810611], [136.816186110765983, 34.85873710362587], [136.816188097355422, 34.858742333604262], [136.816195960938586, 34.858743454313874]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816145421124162, 34.858955189019909], [136.816149477077545, 34.8589512835267], [136.816135570951559, 34.858948838348255], [136.816137557540998, 34.858954068313189], [136.816145421124162, 34.858955189019909]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816135529564264, 34.858948838348255], [136.816149518464812, 34.858951317487517], [136.816147363824882, 34.858946019140959], [136.8161397458951, 34.858944811141626], [136.816135529564264, 34.858948838348255]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8162763644672, 34.858971699406183], [136.816290353367719, 34.858974178544756], [136.81628819872779, 34.858968880199669], [136.816280580798008, 34.85896767220067], [136.8162763644672, 34.858971699406183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816286256027041, 34.858978050076075], [136.816290311980453, 34.858974144583954], [136.816276405854438, 34.858971699406183], [136.816278392443877, 34.858976929369661], [136.816286256027041, 34.858978050076075]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816225655011436, 34.859183163991148], [136.816239643911985, 34.859185643123354], [136.816237489272055, 34.859180344791888], [136.816229871342244, 34.859179136795987], [136.816225655011436, 34.859183163991148]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816235546571306, 34.859189514644719], [136.816239602524718, 34.859185609162644], [136.816225696398703, 34.859183163991148], [136.816227682988142, 34.859188393941189], [136.816235546571306, 34.859189514644719]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816084707173275, 34.859161291181003], [136.816098696073823, 34.859163770313863], [136.816096541433893, 34.859158471980983], [136.816088923504111, 34.85915726398477], [136.816084707173275, 34.859161291181003]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816094598733173, 34.859167641836258], [136.816098654686556, 34.859163736353132], [136.81608474856057, 34.859161291181003], [136.816086735150009, 34.859166521132423], [136.816094598733173, 34.859167641836258]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816487878657796, 34.859226607783818], [136.816501867558316, 34.859229086914702], [136.816499712918386, 34.859223788586036], [136.816492094988604, 34.859222580590774], [136.816487878657796, 34.859226607783818]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816497770217666, 34.859232958434035], [136.816501826171049, 34.859229052954007], [136.816487920045034, 34.859226607783818], [136.816489906634501, 34.859231837731087], [136.816497770217666, 34.859232958434035]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816434714120902, 34.859437599713381], [136.816448703021422, 34.859440078837913], [136.816446548381492, 34.85943478052284], [136.81643893045171, 34.859433572530676], [136.816434714120902, 34.859437599713381]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816444605680772, 34.859443950347305], [136.816448661634155, 34.85944004487731], [136.816434755508141, 34.859437599713381], [136.816436742097608, 34.859442829647236], [136.816444605680772, 34.859443950347305]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816174514608804, 34.859396282250906], [136.816188503509352, 34.859398761376681], [136.816186348869422, 34.859393463058943], [136.816178730939612, 34.859392255066169], [136.816174514608804, 34.859396282250906]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816184406168702, 34.85940263288802], [136.816188462122085, 34.859398727416057], [136.816174555996071, 34.859396282250906], [136.816176542585509, 34.859401512187389], [136.816184406168702, 34.85940263288802]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816033935304603, 34.859373421310963], [136.816047924205122, 34.859375900437435], [136.816045769565193, 34.859370602118219], [136.816038151635439, 34.859369394125117], [136.816033935304603, 34.859373421310963]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816043826864501, 34.859379771949847], [136.816047882817855, 34.859375866476796], [136.81603397669187, 34.859373421310963], [136.816035963281337, 34.859378651248903], [136.816043826864501, 34.859379771949847]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816008375431096, 34.85947912688826], [136.816022364331644, 34.859481606011542], [136.816020209691715, 34.85947630769914], [136.816012591761933, 34.859475099707588], [136.816008375431096, 34.85947912688826]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816018266990994, 34.85948547751898], [136.816022322944406, 34.859481572050946], [136.816008416818391, 34.85947912688826], [136.81601040340783, 34.859484356819479], [136.816018266990994, 34.85948547751898]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816549222354126, 34.859566166100933], [136.816563211254675, 34.859568645221593], [136.816561056614745, 34.859563346914797], [136.816553438684963, 34.859562138924524], [136.816549222354126, 34.859566166100933]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816559113914025, 34.859572516724931], [136.816563169867408, 34.859568611261039], [136.816549263741393, 34.859566166100933], [136.816551250330861, 34.859571396026617], [136.816559113914025, 34.859572516724931]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816574782227633, 34.859460041169086], [136.816588771128153, 34.859462520292929], [136.816586616488223, 34.859457221979305], [136.816578998558441, 34.859456013987476], [136.816574782227633, 34.859460041169086]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816584673787503, 34.85946639180127], [136.816588729740914, 34.859462486332333], [136.8165748236149, 34.859460041169086], [136.816576810204339, 34.859465271101506], [136.816584673787503, 34.85946639180127]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816626157573324, 34.859248000628369], [136.816640146473901, 34.859250479758607], [136.816637991833971, 34.859245181431326], [136.816630373904161, 34.859243973436385], [136.816626157573324, 34.859248000628369]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816636049133223, 34.859254351276931], [136.816640105086634, 34.859250445797926], [136.81662619896062, 34.859248000628369], [136.816628185550059, 34.859253230574275], [136.816636049133223, 34.859254351276931]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816744499787603, 34.859597626034649], [136.816758488688123, 34.859600105154364], [136.816756334048193, 34.859594806849593], [136.816748716118411, 34.859593598859782], [136.816744499787603, 34.859597626034649]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816754391347473, 34.859603976656224], [136.816758447300856, 34.859600071193817], [136.816744541174842, 34.859597626034649], [136.816746527764309, 34.859602855958329], [136.816754391347473, 34.859603976656224]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815291779892902, 34.858817510302245], [136.815295835846285, 34.858813604802499], [136.815281929720271, 34.858811159619954], [136.815283916309738, 34.858816389593642], [136.815291779892902, 34.858817510302245]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815281888333033, 34.858811159619954], [136.815295877233552, 34.858813638763365], [136.815293722593623, 34.85880834040794], [136.815286104663841, 34.858807132406575], [136.815281888333033, 34.858811159619954]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81538695580079, 34.858938492500556], [136.81540094470131, 34.858940971640138], [136.815398790061408, 34.858935673292912], [136.815391172131598, 34.858934465293416], [136.81538695580079, 34.858938492500556]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81539684736066, 34.85894484317302], [136.815400903314071, 34.858940937679321], [136.815386997188057, 34.858938492500556], [136.815388983777495, 34.858943722466151], [136.81539684736066, 34.85894484317302]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815344765752286, 34.859150480427012], [136.815358754652806, 34.859152959560191], [136.815356600012876, 34.859147661226629], [136.815348982083123, 34.859146453230252], [136.815344765752286, 34.859150480427012]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815316576184642, 34.859248145519651], [136.815330565085191, 34.859250624649889], [136.815328410445261, 34.859245326322608], [136.81532079251545, 34.859244118327666], [136.815316576184642, 34.859248145519651]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815326467744512, 34.859254496168198], [136.815330523697924, 34.8592505906892], [136.815316617571909, 34.859248145519651], [136.815318604161348, 34.859253375465549], [136.815326467744512, 34.859254496168198]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815354657312184, 34.859156831083105], [136.815358713265539, 34.859152925599467], [136.815344807139553, 34.859150480427012], [136.815346793728992, 34.85915571037912], [136.815354657312184, 34.859156831083105]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815271132667334, 34.859471243705947], [136.815275188620745, 34.859467338237245], [136.815261282494731, 34.859464893074133], [136.81526326908417, 34.859470123006261], [136.815271132667334, 34.859471243705947]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815261241107436, 34.859464893074133], [136.815275230007984, 34.859467372197848], [136.815273075368054, 34.859462073884522], [136.815265457438272, 34.859460865892764], [136.815261241107436, 34.859464893074133]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815115899738714, 34.859559854662031], [136.815119955692097, 34.859555949197528], [136.815106049566083, 34.859553504037052], [136.81510803615555, 34.859558733963539], [136.815115899738714, 34.859559854662031]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815106008178816, 34.859553504037052], [136.815119997079364, 34.859555983158096], [136.815117842439435, 34.859550684850475], [136.815110224509652, 34.859549476860025], [136.815106008178816, 34.859553504037052]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815140995796952, 34.860005426389023], [136.815145051750335, 34.86000152094568], [136.815131145624349, 34.859999075798449], [136.815133132213788, 34.860004305696606], [136.815140995796952, 34.860005426389023]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815131104237054, 34.859999075798449], [136.815145093137573, 34.860001554906056], [136.815142938497672, 34.859996256627142], [136.81513532056789, 34.859995048643228], [136.815131104237054, 34.859999075798449]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814016904904435, 34.860374156399907], [136.814105821315167, 34.859996423679924], [136.814101028838877, 34.859995794484419], [136.814011121983867, 34.860371423345384], [136.814016904904435, 34.860374156399907]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815220215824155, 34.860108066481246], [136.815221043561195, 34.86010495000113], [136.81516152100582, 34.860095748028513], [136.815160597419208, 34.860097999366232], [136.815220215824155, 34.860108066481246]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81521985742333, 34.86010804681861], [136.815258667455993, 34.859942371834876], [136.81525406667879, 34.859940903711049], [136.815214897210382, 34.860107338974622], [136.81521985742333, 34.86010804681861]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815257389462346, 34.859945727546368], [136.815258923054756, 34.859941113443035], [136.815207036511538, 34.859932514431584], [136.815208058906478, 34.859937547999365], [136.815257389462346, 34.859945727546368]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815172019484862, 34.860099460932148], [136.81519796275856, 34.859992969657945], [136.815193106382623, 34.859992340462419], [136.81516792990513, 34.860099670663715], [136.815172019484862, 34.860099460932148]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815212020689785, 34.859933032208374], [136.815206844815378, 34.859933346806372], [136.815195534571387, 34.859981480285228], [136.815200039499075, 34.859982345429209], [136.815212020689785, 34.859933032208374]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815330746299225, 34.85953758813227], [136.815336530259572, 34.859511332178208], [136.815334589306673, 34.859509739517677], [136.81532793471311, 34.85953737839926], [136.815330746299225, 34.85953758813227]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815363462937285, 34.859499626449704], [136.81536525212843, 34.859496270720015], [136.815314643578915, 34.859487671662066], [136.815313621183975, 34.859492495523952], [136.815363462937285, 34.859499626449704]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8152982852599, 34.859549542912646], [136.815297262864988, 34.859554157037955], [136.815348893809386, 34.859562126890133], [136.815350427401825, 34.85955667383346], [136.8152982852599, 34.859549542912646]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815302886037131, 34.85955080131049], [136.815313965792825, 34.859504880722646], [136.815310050575135, 34.859504452368569], [136.815298540858606, 34.859549752645634], [136.815302886037131, 34.85955080131049]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815349405006856, 34.859562126890133], [136.81536474093096, 34.859496480453124], [136.815359373357524, 34.859495222054463], [136.815345026520163, 34.85955756825738], [136.815349405006856, 34.859562126890133]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815293812284125, 34.859548966147301], [136.815300713449972, 34.859549805079205], [136.815301224647413, 34.859546659084543], [136.815294067882832, 34.859546659084543], [136.815293812284125, 34.859548966147301]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815639829069596, 34.858332663196592], [136.815640340267066, 34.858330146363627], [136.815594843692253, 34.858322805600395], [136.815594332494783, 34.858325322433579], [136.815639829069596, 34.858332663196592]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815629605120193, 34.858395164523706], [136.815629093922752, 34.858391389277116], [136.815580018965647, 34.858383209575543], [136.815579252169414, 34.858387194558468], [136.815629605120193, 34.858395164523706]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81553995386588, 34.858773684550023], [136.8156447493472, 34.858330723138252], [136.815639637372499, 34.858329254985669], [136.815537909075999, 34.858772426140298], [136.81553995386588, 34.858773684550023]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815503882495591, 34.858676596872577], [136.815573277552147, 34.858385274164107], [136.815570210367298, 34.858384487654327], [136.815500623611712, 34.858675967666969], [136.815503882495591, 34.858676596872577]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815518036272294, 34.858837076914575], [136.815522381450762, 34.858839174262449], [136.815540784559687, 34.858770171489162], [136.815534650190045, 34.858769122814351], [136.815518036272294, 34.858837076914575]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815522381450762, 34.858835399036231], [136.815472284098746, 34.858826799909188], [136.815470494907601, 34.858832043279442], [136.815521614654557, 34.858840222936365], [136.815522381450762, 34.858835399036231]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815479185264593, 34.858815474228301], [136.815490687207671, 34.858766605994745], [136.815485830831705, 34.858766396259782], [136.815474840086097, 34.858814845023765], [136.815479185264593, 34.858815474228301]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815538484171071, 34.858777931682361], [136.815541040158422, 34.858770171489162], [136.815487620022822, 34.858761991825276], [136.815485319634206, 34.858769961754206], [136.815538484171071, 34.858777931682361]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815503786644882, 34.858816470469144], [136.81551017661323, 34.858790882814304], [136.815506534329216, 34.858790096308063], [136.815500719460061, 34.85881521206008], [136.815503786644882, 34.858816470469144]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814244643375389, 34.857944860276788], [136.814339726104748, 34.857557265266664], [136.814333591735107, 34.857555587362214], [136.81424157619054, 34.857943182380247], [136.814244643375389, 34.857944860276788]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813599879592971, 34.858627322194167], [136.81382186709439, 34.858394358351482], [136.813818033113364, 34.858391841520415], [136.813596429010062, 34.858627007591174], [136.813599879592971, 34.858627322194167]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813477847170816, 34.859063799910778], [136.813478869565728, 34.859061492834414], [136.812656145163828, 34.85892886860568], [136.812654611571418, 34.858930127013004], [136.813477847170816, 34.859063799910778]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813746768993042, 34.860021748795099], [136.813768718531321, 34.859930207379641], [136.813764788703878, 34.859928313238484], [136.813742935012044, 34.860021434197442], [136.813746768993042, 34.860021748795099]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813768207333624, 34.85992936845161], [136.813477847170816, 34.859060234429101], [136.813469668011294, 34.859060234429101], [136.813764117753863, 34.85992936845161], [136.813768207333624, 34.85992936845161]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813598010529091, 34.858629485089914], [136.81360452829685, 34.858622406522557], [136.812955818707422, 34.858517643654501], [136.812953710017865, 34.858525666041011], [136.813598010529091, 34.858629485089914]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812958071169447, 34.858522873935755], [136.812955003984655, 34.858521300918802], [136.812947719420691, 34.858553075855433], [136.812950786605484, 34.858553390458709], [136.812958071169447, 34.858522873935755]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812948869615013, 34.858555278078306], [136.812949636411219, 34.85855213204561], [136.812108460974372, 34.858416852525764], [136.812108460974372, 34.858419369356078], [136.812948869615013, 34.858555278078306]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812081431408188, 34.858414021091576], [136.812080089514836, 34.858412448072549], [136.811828580359617, 34.858370291151196], [136.811828580359617, 34.858373437190849], [136.812081431408188, 34.858414021091576]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811831839243467, 34.858372650680934], [136.811836440020699, 34.858347482360109], [136.811833372835878, 34.858347482360109], [136.811830305651057, 34.858370133849206], [136.811831839243467, 34.858372650680934]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811835673224493, 34.858349369984438], [136.811837206816904, 34.858347482360109], [136.811763594381233, 34.858333639780383], [136.811763594381233, 34.858337415029624], [136.811835673224493, 34.858349369984438]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811760527196412, 34.858361954145529], [136.811767428362259, 34.858333639780383], [136.81176436117741, 34.858333010572153], [136.811758993604002, 34.858361324937526], [136.811760527196412, 34.858361954145529]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811758226807825, 34.858363212561542], [136.811760527196412, 34.858361324937526], [136.811203833151609, 34.858270718934023], [136.811202299559199, 34.858273864977491], [136.811758226807825, 34.858363212561542]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811173161303429, 34.858268831307882], [136.811173161303429, 34.858266943681706], [136.810624902016883, 34.858178854411918], [136.810624902016883, 34.858181371249501], [136.811173161303429, 34.858268831307882]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810266089320237, 34.858264387521572], [136.810381012901445, 34.85821232048081], [136.810381396299562, 34.858209489039581], [136.810264939125943, 34.858261988663152], [136.810266089320237, 34.858264387521572]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810368456613617, 34.858533688426867], [136.810234171425918, 34.858398782468306], [136.810232637833479, 34.858400460355547], [136.810365964525943, 34.858536362555277], [136.810368456613617, 34.858533688426867]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81023468262336, 34.858398362996482], [136.810267399261448, 34.858262034542548], [136.810265354471568, 34.858262034542548], [136.810231104241097, 34.858395426693704], [136.81023468262336, 34.858398362996482]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810376068824951, 34.858213285269358], [136.810390057725499, 34.858215764430796], [136.810387903085569, 34.858210466036851], [136.810380285155787, 34.858209258026712], [136.810376068824951, 34.858213285269358]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810385960384849, 34.858219635997813], [136.81039001633826, 34.858215730469674], [136.810376110212246, 34.858213285269358], [136.810378096801685, 34.85821851528106], [136.810385960384849, 34.858219635997813]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810263605381635, 34.858264460930428], [136.810277594282155, 34.85826694009031], [136.810275439642226, 34.85826164169967], [136.810267821712472, 34.858260433690283], [136.810263605381635, 34.858264460930428]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810323594293521, 34.858483903393058], [136.810327650246933, 34.858479997877481], [136.810313744120918, 34.858477552685017], [136.810315730710357, 34.858482782679914], [136.810323594293521, 34.858483903393058]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810273496941505, 34.858270811654926], [136.810277552894917, 34.858266906129217], [136.810263646768902, 34.858264460930428], [136.810265633358341, 34.858269690938869], [136.810273496941505, 34.858270811654926]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810231399941017, 34.85839617519035], [136.810245388841565, 34.85839865434626], [136.810243234201636, 34.858393355964104], [136.810235616271854, 34.85839214795665], [136.810231399941017, 34.85839617519035]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810241291500915, 34.85840252590468], [136.810245347454327, 34.858398620385223], [136.810231441328312, 34.85839617519035], [136.810233427917751, 34.858401405190406], [136.810241291500915, 34.85840252590468]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810313702733652, 34.858477552685017], [136.8103276916342, 34.858480031838489], [136.81032553699427, 34.85847473346157], [136.810317919064488, 34.85847352545531], [136.810313702733652, 34.858477552685017]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810524316091204, 34.858581581530039], [136.810538304991752, 34.858584060680379], [136.810536150351822, 34.85857876231016], [136.810528532422012, 34.858577554305434], [136.810524316091204, 34.858581581530039]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810534207651074, 34.858587932230051], [136.810538263604485, 34.858584026719413], [136.810524357478471, 34.858581581530039], [136.81052634406791, 34.858586811518322], [136.810534207651074, 34.858587932230051]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810489171265203, 34.858162004708177], [136.810503160165723, 34.858164483871157], [136.810501005525794, 34.858159185473916], [136.81049338759604, 34.858157977463023], [136.810489171265203, 34.858162004708177]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810499062825102, 34.85816835544059], [136.810503118778485, 34.858164449910021], [136.810489212652499, 34.858162004708177], [136.810491199241937, 34.858167234723147], [136.810499062825102, 34.85816835544059]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810161033498161, 34.858321987280199], [136.810165089451544, 34.858318081756927], [136.81015118332553, 34.858315636559645], [136.810153169914997, 34.858320866564846], [136.810161033498161, 34.858321987280199]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810151141938292, 34.858315636559645], [136.810165130838811, 34.858318115717992], [136.810162976198882, 34.858312817330656], [136.8101553582691, 34.858311609322016], [136.810151141938292, 34.858315636559645]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810619398820563, 34.858182244279106], [136.810633387721083, 34.858184723441468], [136.810631233081153, 34.858179425045535], [136.8106236151514, 34.858178217034933], [136.810619398820563, 34.858182244279106]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810629290380461, 34.858188595009949], [136.810633346333844, 34.858184689480346], [136.810619440207859, 34.858182244279106], [136.810621426797297, 34.858187474292777], [136.810629290380461, 34.858188595009949]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810345013578683, 34.858346677499625], [136.810359002479203, 34.858349156657027], [136.810356847839302, 34.858343858271695], [136.810349229909491, 34.858342650263516], [136.810345013578683, 34.858346677499625]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810354905138581, 34.858353028217778], [136.810358961091964, 34.858349122695969], [136.81034505496595, 34.858346677499625], [136.810347041555417, 34.858351907502843], [136.810354905138581, 34.858353028217778]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810534036666581, 34.858587210303384], [136.810532503074199, 34.85858909792222], [136.81101941866433, 34.858666490256873], [136.811019802062418, 34.85866460263982], [136.810534036666581, 34.858587210303384]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811759760400236, 34.858786668453376], [136.811759760400236, 34.858784151634303], [136.811103382848813, 34.858679074369448], [136.811104916441224, 34.858680961986174], [136.811759760400236, 34.858786668453376]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811752859234389, 34.858811836639823], [136.811759760400236, 34.858783522429526], [136.81175746001162, 34.858785410043851], [136.811751325642007, 34.858811836639823], [136.811752859234389, 34.858811836639823]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811752092438184, 34.858810892832963], [136.811750175447685, 34.858812465844387], [136.811810368949779, 34.858821589310004], [136.811810368949779, 34.858819387094258], [136.811752092438184, 34.858810892832963]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811809602153573, 34.858821589310004], [136.811816886717509, 34.858795477319504], [136.811814586328893, 34.858795477319504], [136.811808451959251, 34.858820645503265], [136.811809602153573, 34.858821589310004]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811815736523187, 34.858795791921843], [136.811852542741036, 34.858802083968484], [136.811852926139125, 34.858800510956875], [136.811815353125098, 34.858794218910113], [136.811815736523187, 34.858795791921843]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811881680996834, 34.858805544593935], [136.812625856713765, 34.858924464179871], [136.812625856713765, 34.858918172142594], [136.811882831191156, 34.85879673572888], [136.811881680996834, 34.858805544593935]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813839120008993, 34.858375482116571], [136.814245905395694, 34.85794321515155], [136.81424322160899, 34.857941327517899], [136.813835286027995, 34.858372650680963], [136.813839120008993, 34.858375482116571]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814256121297632, 34.857956425270238], [136.814260177251015, 34.857952519729608], [136.814246271125029, 34.857950074521469], [136.814248257714468, 34.857955304549904], [136.814256121297632, 34.857956425270238]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814246229737734, 34.857950074521469], [136.814260218638282, 34.857952553690829], [136.814258063998352, 34.857947255279953], [136.81425044606857, 34.857946047265933], [136.814246229737734, 34.857950074521469]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813823264839982, 34.858408197605378], [136.813827320793365, 34.858404292086199], [136.813813414667351, 34.858401846891496], [136.813815401256818, 34.858407076891204], [136.813823264839982, 34.858408197605378]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813813373280112, 34.858401846891496], [136.813827362180632, 34.858404326047243], [136.813825207540702, 34.858399027665449], [136.81381758961092, 34.85839781965808], [136.813813373280112, 34.858401846891496]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814344838079592, 34.857558523694969], [136.814570835287583, 34.856599051723876], [136.814567001306585, 34.856599051723876], [136.814341770894771, 34.857558523694969], [136.814344838079592, 34.857558523694969]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814588679272532, 34.857598793390807], [136.814691222291771, 34.857168495202032], [136.814687388310745, 34.857167708680613], [136.814585100890213, 34.857597534963112], [136.814588679272532, 34.857598793390807]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814689880398419, 34.857170068244855], [136.814690647194595, 34.857167865984898], [136.814613584175987, 34.857155596249747], [136.814613200777899, 34.857157483901425], [136.814689880398419, 34.857170068244855]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814616843059895, 34.857156697379914], [136.814742022540372, 34.85663224315082], [136.814738763656436, 34.856631771234888], [136.814613775875046, 34.857155910858381], [136.814616843059895, 34.857156697379914]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814814293082691, 34.856649074817177], [136.814816976869395, 34.856645928711579], [136.814736846665966, 34.856634288119814], [136.814736846665966, 34.856635861172848], [136.814814293082691, 34.856649074817177]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814422899472248, 34.857245099036888], [136.81442695542566, 34.857241193462478], [136.814413049299645, 34.8572387482332], [136.814415035889084, 34.857243978306862], [136.814422899472248, 34.857245099036888]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81441300791235, 34.8572387482332], [136.814426996812927, 34.857241227424005], [136.814424842172997, 34.857235928967299], [136.814417224243186, 34.857234720942841], [136.81441300791235, 34.8572387482332]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81439836199371, 34.857351751172835], [136.814402417947093, 34.857347845603492], [136.814388511821107, 34.857345400377383], [136.814390498410546, 34.857350630444259], [136.81439836199371, 34.857351751172835]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814388470433812, 34.857345400377383], [136.81440245933436, 34.857347879564969], [136.814400304694431, 34.857342581115141], [136.814392686764648, 34.857341373092247], [136.814388470433812, 34.857345400377383]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814498428898446, 34.856928917113301], [136.814502484851857, 34.856925011523892], [136.814488578725843, 34.856922566285206], [136.814490565315282, 34.856927796378969], [136.814498428898446, 34.856928917113301]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814488537338548, 34.856922566285206], [136.814502526239124, 34.856925045485539], [136.814500371599195, 34.856919747008469], [136.814492753669384, 34.856918538979372], [136.814488537338548, 34.856922566285206]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814526033561833, 34.85682006215989], [136.814530089515216, 34.856816156565309], [136.814516183389202, 34.85681371132339], [136.814518169978641, 34.856818941424073], [136.814526033561833, 34.85682006215989]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814516142001963, 34.85681371132339], [136.814530130902483, 34.856816190526999], [136.814527976262553, 34.856810892042922], [136.814520358332771, 34.856809684012227], [136.814516142001963, 34.85681371132339]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814549804244166, 34.856714982386137], [136.814553860197577, 34.856711076786574], [136.814539954071563, 34.85670863154153], [136.814541940661002, 34.856713861648892], [136.814549804244166, 34.856714982386137]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814539912684296, 34.85670863154153], [136.814553901584844, 34.856711110748314], [136.814551746944915, 34.856705812257459], [136.814544129015133, 34.856704604225222], [136.814539912684296, 34.85670863154153]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814575108518937, 34.856608014813929], [136.814579164472349, 34.856604109209286], [136.814565258346335, 34.856601663961058], [136.814567244935773, 34.85660689407522], [136.814575108518937, 34.856608014813929]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814565216959068, 34.856601663961058], [136.814579205859587, 34.856604143171062], [136.814577051219686, 34.856598844673321], [136.814569433289876, 34.856597636639513], [136.814565216959068, 34.856601663961058]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81473838025849, 34.85588645529937], [136.814821961044828, 34.855539750524244], [136.814818893859979, 34.855538492065051], [136.814735313073669, 34.855885196845477], [136.81473838025849, 34.85588645529937]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815106388385914, 34.854383094310343], [136.815110444339297, 34.854379188600056], [136.815096538213311, 34.854376743285705], [136.81509852480275, 34.854381973541315], [136.815106388385914, 34.854383094310343]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815096496826015, 34.854376743285705], [136.815110485726564, 34.854379222562756], [136.815108331086634, 34.85437392392172], [136.815100713156852, 34.854372715855249], [136.815096496826015, 34.854376743285705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815141794934817, 34.854601164025759], [136.815172034499426, 34.854470318721411], [136.815169230900779, 34.854471557533266], [136.815139494546202, 34.854600954280173], [136.815141794934817, 34.854601164025759]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815222564135041, 34.854613748760222], [136.815223586529982, 34.854612070795746], [136.815140005743672, 34.854599276315419], [136.815139494546202, 34.854600534788986], [136.815222564135041, 34.854613748760222]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815147929304459, 34.854930673688514], [136.815223586529982, 34.854611231813486], [136.815221797338836, 34.854611441559051], [136.815144862119638, 34.854929205475244], [136.815147929304459, 34.854930673688514]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815221541740101, 34.854941790159529], [136.815144862119638, 34.854929205475244], [136.815143839724698, 34.854931722412246], [136.815224097727423, 34.854944097351435], [136.815221541740101, 34.854941790159529]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815170421993116, 34.855164958241097], [136.815224608924922, 34.854941790159536], [136.815221286141366, 34.854941580414803], [136.815167354808324, 34.855164119264487], [136.815170421993116, 34.855164958241097]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815170421993145, 34.855165587473557], [136.81517067759188, 34.855163909520329], [136.815092208780271, 34.855150905381649], [136.815091441984066, 34.855153212567693], [136.815170421993145, 34.855165587473557]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815094764767622, 34.855152163846768], [136.815091697582773, 34.855151324870015], [136.814967987795171, 34.855674005725731], [136.814970543782493, 34.85567442521144], [136.815094764767622, 34.855152163846768]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815043133823195, 34.855687429267377], [136.815044667415606, 34.855684912353482], [136.814967987795114, 34.85567232778287], [136.814967476597644, 34.855674844697148], [136.815043133823195, 34.855687429267377]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814993547668593, 34.855907658934676], [136.815045178613076, 34.855685331839133], [136.815042622625697, 34.855685331839133], [136.81498996928633, 34.855906400481118], [136.814993547668593, 34.855907658934676]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814994058866091, 34.855907658934676], [136.8149904804838, 34.855905561512053], [136.814914823258277, 34.855893815944377], [136.814913289665867, 34.855895493882727], [136.814994058866091, 34.855907658934676]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814914567659571, 34.855894864655859], [136.814789068680767, 34.856415862859841], [136.814792135865588, 34.856415862859841], [136.814917123646921, 34.85589423542897], [136.814914567659571, 34.855894864655859]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8148662594987, 34.856428447316702], [136.814868815486051, 34.85642676938923], [136.814790091075707, 34.856413765450171], [136.814789068680795, 34.856416282341762], [136.8148662594987, 34.856428447316702]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814814628554274, 34.856647836038789], [136.814867793091111, 34.856426349907359], [136.81486421470882, 34.856426349907359], [136.814811050171983, 34.856646997077291], [136.814814628554274, 34.856647836038789]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815088422726092, 34.854425062748746], [136.815084972143154, 34.854424748129695], [136.814980112762129, 34.854859865139716], [136.814982988247891, 34.854861438226692], [136.815088422726092, 34.854425062748746]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814942683522389, 34.855035421459675], [136.814973355370569, 34.854904029544898], [136.814970863282895, 34.854902967711766], [136.814939041240393, 34.855034477609493], [136.814942683522389, 34.855035421459675]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814974170091517, 34.854904068872059], [136.81495135790442, 34.854899821539384], [136.814950016011068, 34.854901394625585], [136.814974361790576, 34.854905641958183], [136.814974170091517, 34.854904068872059]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814981454655481, 34.854860179757118], [136.814982604849803, 34.854857348200511], [136.814960942856999, 34.854854831261228], [136.814959984361735, 34.854857033583095], [136.814981454655481, 34.854860179757118]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814950782807273, 34.854901866551437], [136.814953849992094, 34.854901709242817], [136.814963818342761, 34.854854831261228], [136.814960942856999, 34.854854673952509], [136.814950782807273, 34.854901866551437]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814933290268897, 34.855071445066827], [136.814930223084076, 34.855071445066827], [136.814827472392608, 34.85549617636341], [136.814832073169839, 34.855497434823249], [136.814933290268897, 34.855071445066827]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814732389663192, 34.855910837839744], [136.814728939080254, 34.855909893999595], [136.814574046246889, 34.856573410944137], [136.814576346635505, 34.856573410944137], [136.814732389663192, 34.855910837839744]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81459436634637, 34.857982226237297], [136.814683314706116, 34.85760784489338], [136.814680247521295, 34.857609103320918], [136.814590532365344, 34.857981597026388], [136.81459436634637, 34.857982226237297]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814681301866585, 34.857610125793329], [136.814683506405657, 34.857607608938252], [136.814587513105749, 34.85759160331127], [136.814586842159031, 34.857594631403096], [136.814681301866585, 34.857610125793329]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815616566628506, 34.858143048456185], [136.814591527839184, 34.857977408840966], [136.814591096516295, 34.857984487463817], [136.815615488321356, 34.858148357412695], [136.815616566628506, 34.858143048456185]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816542939277497, 34.858843768765382], [136.816512698752121, 34.85897071063912], [136.816515574237911, 34.858970867939959], [136.816546006462318, 34.858843926066456], [136.816542939277497, 34.858843768765382]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816489119768846, 34.858954665952126], [136.816601072014748, 34.858486222632955], [136.816598379065681, 34.858485892718839], [136.816486819380231, 34.858954351350391], [136.816489119768846, 34.858954665952126]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816283633142348, 34.858928627150519], [136.816302611348419, 34.858931301266075], [136.816303473994139, 34.858929190582806], [136.81628392069095, 34.85892620186533], [136.816283633142348, 34.858928627150519]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816312181544589, 34.858806173798619], [136.816283608760784, 34.858927706186101], [136.81628562160077, 34.858928099438394], [136.816314146459888, 34.858806370425064], [136.816312181544589, 34.858806173798619]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81687817299337, 34.859298013705498], [136.816876256002843, 34.859297856405291], [136.81686705444838, 34.859338597150064], [136.816868971438879, 34.859338911750335], [136.81687817299337, 34.859298013705498]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816868779739877, 34.859337496049115], [136.816681298067749, 34.859306665216678], [136.816681298067749, 34.859308867419379], [136.816868012943644, 34.859338754450199], [136.816868779739877, 34.859337496049115]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816877358272421, 34.859298996831825], [136.816878268842913, 34.859297974380461], [136.816693950205121, 34.859267654759073], [136.816692800010827, 34.859268913161209], [136.816877358272421, 34.859298996831825]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81668551544692, 34.859289283543312], [136.81668551544692, 34.859290935195695], [136.816859674034902, 34.859318973956029], [136.81686005743299, 34.859317636904564], [136.81668551544692, 34.859289283543312]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81635339684064, 34.858795359343702], [136.816353492690183, 34.858793629030778], [136.8162843851822, 34.858783011200707], [136.816284289332657, 34.858784820164438], [136.81635339684064, 34.858795359343702]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816285918774582, 34.85878403365848], [136.816284385182172, 34.858782932550106], [136.816250487517863, 34.858923983168289], [136.816252404508361, 34.858924061818755], [136.816285918774582, 34.85878403365848]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815299531306636, 34.859750093271224], [136.815319851406002, 34.859667275123314], [136.815317359318328, 34.859666567275546], [136.81529655997133, 34.859749700022839], [136.815299531306636, 34.859750093271224]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815305378127618, 34.859664758331192], [136.815302598491371, 34.859664365082416], [136.815282470091063, 34.859748127029356], [136.81528524972731, 34.85974867757708], [136.815305378127618, 34.859664758331192]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815253715233354, 34.859827877761539], [136.815256974117233, 34.85982850695833], [136.815274706279439, 34.859750801118274], [136.815271830793705, 34.859750171920886], [136.815253715233354, 34.859827877761539]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8152606163992, 34.859749228124798], [136.815257932612496, 34.859748913526104], [136.815240487998722, 34.859825439623926], [136.815243746882629, 34.859826147470343], [136.8152606163992, 34.859749228124798]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815152463225644, 34.860139722830667], [136.81516324629726, 34.860095217145449], [136.81516152100582, 34.860094951703914], [136.815150737934175, 34.860139191947887], [136.815152463225644, 34.860139722830667]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815153110209934, 34.860137953221376], [136.815138229571176, 34.860135564248793], [136.815137798248287, 34.860137510819065], [136.815152571056359, 34.860139634350205], [136.815153110209934, 34.860137953221376]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815133485019572, 34.860166797848066], [136.81514085241696, 34.860136694832825], [136.815138168630227, 34.860135908339785], [136.81513107576535, 34.860166424264314], [136.815133485019572, 34.860166797848066]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815549493287591, 34.858307343637883], [136.815553549241002, 34.858303438113907], [136.815539643114988, 34.858300992916206], [136.815541629704427, 34.858306222922323], [136.815549493287591, 34.858307343637883]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815539601727721, 34.858300992916206], [136.815553590628241, 34.858303472074986], [136.81555143598834, 34.858298173686698], [136.815543818058529, 34.858296965677845], [136.815539601727721, 34.858300992916206]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815570593765415, 34.858427431077772], [136.815592830855337, 34.858338162214459], [136.815589763670488, 34.85833721840217], [136.815567334881536, 34.858429004096529], [136.815570593765415, 34.858427431077772]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815600307118331, 34.858432857992327], [136.815597815030657, 34.858432386086719], [136.815523915043229, 34.858732524055021], [136.815526726629315, 34.858732524055021], [136.815600307118331, 34.858432857992327]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815611713211837, 34.858435138869353], [136.815609987920368, 34.858434195058173], [136.815538228572365, 34.858734201935469], [136.815540784559715, 34.85873546034577], [136.815611713211837, 34.858435138869353]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815587750830446, 34.85843120632272], [136.815586025538948, 34.85843026251149], [136.815559571069912, 34.85853683446004], [136.815562446555674, 34.858537542317542], [136.815587750830446, 34.85843120632272]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815576344736911, 34.858428925445573], [136.815573852649237, 34.858428453539958], [136.815547014782055, 34.858535104141687], [136.815549602719244, 34.858535654697533], [136.815576344736911, 34.858428925445573]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815623598553032, 34.858397543715824], [136.815625132145414, 34.858394869582909], [136.815580466266482, 34.858387476391464], [136.815579507771218, 34.858390937034358], [136.815623598553032, 34.858397543715824]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815572319056884, 34.858429633304006], [136.815582191557951, 34.858390307826568], [136.815580274567424, 34.858389993222673], [136.81556963527018, 34.858429161398391], [136.815572319056884, 34.858429633304006]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815575769639793, 34.858429004096521], [136.815568676774888, 34.858427902983394], [136.815567909978682, 34.858429633304006], [136.815575002843588, 34.858430734417105], [136.815575769639793, 34.858429004096521]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815614876246258, 34.858436554586099], [136.815614301149111, 34.858434195058173], [136.815610083769968, 34.858434037756311], [136.815609508672793, 34.858435768076802], [136.815614876246258, 34.858436554586099]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815598773525949, 34.858433565850724], [136.815599156924065, 34.858432150133929], [136.815586504786666, 34.858430105209628], [136.815586696485724, 34.858431363624589], [136.815598773525949, 34.858433565850724]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815623598553032, 34.858396442602285], [136.815621298164416, 34.858396442602285], [136.815557845778443, 34.858668259897954], [136.815561488057256, 34.858668554837713], [136.815623598553032, 34.858396442602285]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81551394669583, 34.858675417112067], [136.815502828150869, 34.858673844098028], [136.815502061354636, 34.858675731714868], [136.815512796501537, 34.858677304728872], [136.81551394669583, 34.858675417112067]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814406692973563, 34.860391197031205], [136.814485768834686, 34.860063747571267], [136.8144773340764, 34.860061230791274], [136.814398354067265, 34.86038935533351], [136.814406692973563, 34.860391197031205]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814455480384595, 34.860059343206224], [136.814447045626309, 34.860058399413695], [136.814368353163331, 34.860384905106123], [136.814377554717794, 34.860385744029493], [136.814455480384595, 34.860059343206224]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813735650448081, 34.860053208554596], [136.813483757894716, 34.861114968381372], [136.813485674885243, 34.861115597568322], [136.813738717632901, 34.860055725334824], [136.813735650448081, 34.860053208554596]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813358770113325, 34.861644112902098], [136.81347992391369, 34.861138248295134], [136.813475323136458, 34.861138248295134], [136.813355702928476, 34.861641596170458], [136.813358770113325, 34.861644112902098]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813354169336094, 34.861669280214237], [136.813349568558863, 34.861668021848807], [136.813226881166088, 34.862173883197464], [136.81322994835088, 34.862173883197464], [136.813354169336094, 34.861669280214237]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813225347573649, 34.862195275275475], [136.813222280388828, 34.862197791990177], [136.813101126588464, 34.862703650080498], [136.813105727365695, 34.862703650080498], [136.813225347573649, 34.862195275275475]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812924763461325, 34.863447331325155], [136.813099592996025, 34.862725042020728], [136.813094992218794, 34.862725042020728], [136.812921696276476, 34.863446072986932], [136.812924763461325, 34.863447331325155]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812917095499245, 34.863473127254423], [136.812910194333398, 34.86350647319977], [136.812912494722013, 34.86350647319977], [136.812920162684065, 34.863474385592227], [136.812917095499245, 34.863473127254423]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812910194333398, 34.86350647319977], [136.812913261518219, 34.863508360705715], [136.812959269290502, 34.863475014761129], [136.812958502494325, 34.863473127254423], [136.812910194333398, 34.86350647319977]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813015053713741, 34.863437421911115], [136.813010261238134, 34.863439781295547], [136.812874730009298, 34.864016255520802], [136.812882397970327, 34.864011851367295], [136.813015053713741, 34.863437421911115]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812976169792222, 34.863460769982034], [136.812964445164226, 34.863460071998503], [136.812841757772503, 34.863981651452107], [136.81284865893835, 34.863984797277148], [136.812976169792222, 34.863460769982034]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813139434445986, 34.863317309491947], [136.813190841744415, 34.863103175104534], [136.813187774559594, 34.863103175104534], [136.813136878458636, 34.863317309491947], [136.813139434445986, 34.863317309491947]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813138412051075, 34.863317728938682], [136.813138923248516, 34.863315631705007], [136.813054735418007, 34.863301993133433], [136.813054735418007, 34.863305138984458], [136.813138412051075, 34.863317728938682]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813027482200141, 34.863432132954969], [136.81305764285085, 34.863304201780494], [136.81305457566603, 34.863303362886896], [136.813024415015292, 34.863431713508824], [136.813027482200141, 34.863432132954969]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81302713075462, 34.863431602096014], [136.813025980560326, 34.863429400003696], [136.81301102803431, 34.863438837541793], [136.813012945024838, 34.863440095880129], [136.81302713075462, 34.863431602096014]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813188924753888, 34.863104748033862], [136.813263303985792, 34.863116387709972], [136.813263303985792, 34.863114185609213], [136.813189691550093, 34.863103175104534], [136.813188924753888, 34.863104748033862]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813261003597177, 34.86311512936669], [136.813263303985792, 34.863116073124154], [136.813316212923922, 34.862894604408922], [136.81331352913719, 34.862893660648908], [136.813261003597177, 34.86311512936669]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813316979720099, 34.862895862755572], [136.813315829525806, 34.862893660648908], [136.813239916701519, 34.862882020941285], [136.813239916701519, 34.862883908461548], [136.813316979720099, 34.862895862755572]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813243750682602, 34.862883593874848], [136.813343434189164, 34.862467080017581], [136.813340367004372, 34.862467080017581], [136.813239916701548, 34.862883593874848], [136.813243750682602, 34.862883593874848]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813341517198666, 34.86246865295908], [136.813415896430541, 34.862480292725209], [136.813415896430541, 34.862478090607418], [136.813342283994871, 34.862467080017581], [136.813341517198666, 34.86246865295908]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813413596041926, 34.862479034372193], [136.813415896430541, 34.86247997813696], [136.8134688053687, 34.862258507708859], [136.813466121581968, 34.862257563941554], [136.813413596041926, 34.862479034372193]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813469572164877, 34.86225976606525], [136.813468421970583, 34.862257563941554], [136.813392509146325, 34.862245924143899], [136.813392509146325, 34.86224781167877], [136.813469572164877, 34.86225976606525]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813396343127351, 34.862247497089626], [136.813496026633942, 34.861830980011014], [136.813492959449121, 34.861830980011014], [136.813392509146325, 34.862247497089626], [136.813396343127351, 34.862247497089626]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813494109643415, 34.861832552964678], [136.813568488875291, 34.861844192820833], [136.813568488875291, 34.86184199068601], [136.813494876439648, 34.861830980011014], [136.813494109643415, 34.861832552964678]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813566188486675, 34.861842934458082], [136.813568488875291, 34.861843878230147], [136.813621397813421, 34.861622406089189], [136.813618714026745, 34.861621462314581], [136.813566188486675, 34.861842934458082]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813622164609654, 34.861623664455315], [136.813621014415361, 34.861621462314581], [136.813545101591075, 34.861609822426914], [136.813545101591075, 34.86161170997638], [136.813622164609654, 34.861623664455315]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813548168775952, 34.861612653751102], [136.813647852282543, 34.86119613345754], [136.813644785097694, 34.86119613345754], [136.813544334794926, 34.861612653751102], [136.813548168775952, 34.861612653751102]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81364421000066, 34.861197234533641], [136.813720314523891, 34.861209346369343], [136.813720314523891, 34.861207144217516], [136.81364421000066, 34.861194245898574], [136.81364421000066, 34.861197234533641]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813718014135276, 34.861208087996879], [136.813721464718299, 34.861208717183139], [136.813775715549809, 34.86098692873793], [136.813773223462135, 34.860985355768086], [136.813718014135276, 34.861208087996879]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813774086107884, 34.86098842305924], [136.813775523850751, 34.86098629955], [136.813697118938791, 34.860973086602371], [136.813696735540702, 34.860975760651471], [136.813774086107884, 34.86098842305924]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813699802725438, 34.860974816869465], [136.81379871943588, 34.860560180919457], [136.813796419047264, 34.860560180919457], [136.813698269133027, 34.860974187681435], [136.813699802725438, 34.860974816869465]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813871565075374, 34.860573393933414], [136.813873098667756, 34.860570247977897], [136.813796419047264, 34.860559551728258], [136.813796419047264, 34.860561439301826], [136.813871565075374, 34.860573393933414]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813872331871579, 34.860571506360117], [136.813874632260195, 34.860572764742315], [136.813926007605943, 34.860357581106321], [136.813923707217299, 34.860356322720833], [136.813872331871579, 34.860571506360117]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814014955965717, 34.86037394011597], [136.814018789946743, 34.860371423345455], [136.813922940421094, 34.860356951913566], [136.813922940421094, 34.860360727069967], [136.814014955965717, 34.86037394011597]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814564272608152, 34.855072432490957], [136.814639661329153, 34.85508173042841], [136.81463970317381, 34.855078169996389], [136.814564173830632, 34.855069141012095], [136.814564272608152, 34.855072432490957]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814567287329425, 34.855058256674738], [136.814627204810051, 34.855067867261639], [136.814629369846017, 34.855063589041769], [136.814569297837124, 34.855053124873095], [136.814567287329425, 34.855058256674738]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814583886963447, 34.85497786222188], [136.814570247298434, 34.855041021312367], [136.814583081327243, 34.855042368860047], [136.814596355292593, 34.854980074175813], [136.814583886963447, 34.85497786222188]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814571516460489, 34.855056873174036], [136.814576239173931, 34.855040578846172], [136.814571516460489, 34.855039952271298], [136.814567601242771, 34.855056444796801], [136.814571516460489, 34.855056873174036]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814636560164104, 34.855080051496408], [136.814638866310787, 34.855080475389435], [136.814644949241625, 34.855054179349303], [136.814641127123053, 34.855052971066527], [136.814636560164104, 34.855080051496408]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814895813106233, 34.855092327740437], [136.814896963300555, 34.855084776943919], [136.814595420692598, 34.855038370991636], [136.814594681498733, 34.85504503925192], [136.814895813106233, 34.855092327740437]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814954473015945, 34.855041359850432], [136.814955623210238, 34.855036640599764], [136.814587177633427, 34.854978436485801], [136.814589478022043, 34.854984728824434], [136.814954473015945, 34.855041359850432]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814933002722199, 34.855070619198557], [136.81493453631461, 34.855059922233785], [136.814647371135862, 34.855015875893592], [136.814646604339657, 34.855024685163521], [136.814933002722199, 34.855070619198557]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814646987737746, 34.855024055929988], [136.814647371135862, 34.85502185361257], [136.814636252590873, 34.855022797462901], [136.814637402785166, 34.855024999780277], [136.814646987737746, 34.855024055929988]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814615932491449, 34.855041674467138], [136.814620916666797, 34.855033809049232], [136.814617849481948, 34.855033179815777], [136.81461248190854, 34.855040730617027], [136.814615932491449, 34.855041674467138]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814890062134708, 34.855085091560454], [136.814878943589747, 34.855127564781746], [136.814886611551771, 34.855128508630855], [136.814896963300555, 34.855086035410046], [136.814890062134708, 34.855085091560454]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814883259829401, 34.853803888645906], [136.81495864855043, 34.853813186726732], [136.814958690395031, 34.853809626239794], [136.81488316105191, 34.85380059711629], [136.814883259829401, 34.853803888645906]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814886274550673, 34.853789712611103], [136.814946192031272, 34.853799323346195], [136.814948357067237, 34.853795045060359], [136.814888285058373, 34.853784580730334], [136.814886274550673, 34.853789712611103]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814902874184696, 34.853709316918625], [136.814889234519683, 34.853772476982975], [136.814902068548491, 34.853773824551439], [136.814915342513871, 34.853711528906672], [136.814902874184696, 34.853709316918625]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814890503681767, 34.853788329089063], [136.81489522639518, 34.853772034509944], [136.814890503681767, 34.853771407925436], [136.814886588464049, 34.853787900705228], [136.814890503681767, 34.853788329089063]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814955547385352, 34.853811507768832], [136.814957853532036, 34.853811931668396], [136.814963936462874, 34.853785635222799], [136.814960114344302, 34.853784426921386], [136.814955547385352, 34.853811507768832]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815234576314822, 34.853827195876967], [136.81523888954348, 34.853819055049243], [136.814914407913847, 34.853769826621381], [136.814913668719981, 34.853776494984473], [136.815234576314822, 34.853827195876967]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815322566179361, 34.853780474593869], [136.815321487872183, 34.853775165355486], [136.814906164854648, 34.853709891191407], [136.814908465243263, 34.85371618362705], [136.815322566179361, 34.853780474593869]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815282021830001, 34.853806666831602], [136.815283747121498, 34.853795694408696], [136.814966358357111, 34.853747331176478], [136.814965591560878, 34.853756140582234], [136.815282021830001, 34.853806666831602]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814965974958994, 34.853755511338996], [136.814966358357111, 34.853753308987628], [136.814955239812122, 34.853754252852511], [136.814956390006415, 34.853756455203857], [136.814965974958994, 34.853755511338996]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814934919712698, 34.853773130147815], [136.814939903888018, 34.853765264608626], [136.814936836703197, 34.853764635365465], [136.814931469129789, 34.853772186283152], [136.814934919712698, 34.853773130147815]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814716098256724, 34.854435646061297], [136.814791486977725, 34.854444944070721], [136.814791528822383, 34.854441383611139], [136.814715999479233, 34.854432354556955], [136.814716098256724, 34.854435646061297]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814719112977997, 34.854421470135357], [136.814779030458624, 34.854431080796637], [136.814781195494589, 34.854426802543657], [136.814721123485697, 34.854416338293987], [136.814719112977997, 34.854421470135357]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81473571261202, 34.854341075060219], [136.814722072947006, 34.854404234639574], [136.814734906975815, 34.854405582197693], [136.814748180941194, 34.854343287031277], [136.81473571261202, 34.854341075060219]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814723342109062, 34.854420086623946], [136.814728064822503, 34.854403792169954], [136.814723342109062, 34.854403165590234], [136.814719426891344, 34.854419658243387], [136.814723342109062, 34.854420086623946]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814788385812705, 34.854443265125717], [136.814790691959359, 34.854443689022034], [136.814796774890198, 34.854417392778366], [136.814792952771626, 34.854416184486233], [136.814788385812705, 34.854443265125717]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815047638754805, 34.85445554146478], [136.815048788949127, 34.854447990609806], [136.81474724634117, 34.854401584298323], [136.814746507147305, 34.854408252610227], [136.815047638754805, 34.85445554146478]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815106298664517, 34.854404573180254], [136.815107448858811, 34.854399853893064], [136.814739003282, 34.854341649328596], [136.814741303670615, 34.854347941715929], [136.815106298664517, 34.854404573180254]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815084828370772, 34.854433832754857], [136.815086361963182, 34.854423135707286], [136.814799196784435, 34.854379089026168], [136.81479842998823, 34.854387898364287], [136.815084828370772, 34.854433832754857]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814798813386318, 34.85438726912588], [136.814799196784435, 34.854385066791416], [136.814788078239445, 34.854386010649051], [136.814789228433739, 34.85438821298348], [136.814798813386318, 34.85438726912588]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814767758140022, 34.854404887799404], [136.81477274231537, 34.854397022320619], [136.81476967513052, 34.854396393082283], [136.814764307557112, 34.854403943941989], [136.814767758140022, 34.854404887799404]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815041887783281, 34.854448305228772], [136.815030769238319, 34.854490778778818], [136.815038437200343, 34.854491722635231], [136.815048788949127, 34.854449249085683], [136.815041887783281, 34.854448305228772]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81436107161386, 34.855922207494473], [136.814436460334861, 34.855931505335889], [136.814436502179518, 34.855927944940639], [136.81436097283634, 34.855918916049603], [136.81436107161386, 34.855922207494473]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814364086335132, 34.855908031824683], [136.814424003815759, 34.855917642312306], [136.814426168851725, 34.855913364136626], [136.814366096842832, 34.855902900076046], [136.814364086335132, 34.855908031824683]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814380685969127, 34.855827638202243], [136.814367046304113, 34.855890796640338], [136.814379880332922, 34.855892144174099], [136.814393154298301, 34.855829850133325], [136.814380685969127, 34.855827638202243]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814368315466197, 34.855906648338276], [136.814373038179639, 34.855890354178712], [136.814368315466197, 34.855889727610318], [136.814364400248479, 34.855906219965455], [136.814368315466197, 34.855906648338276]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814433359169783, 34.855929826421225], [136.814435665316495, 34.855930250309875], [136.814441748247305, 34.855903954541361], [136.814437926128761, 34.855902746271063], [136.814433359169783, 34.855929826421225]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814692612111941, 34.855942102538457], [136.814693762306263, 34.855934551819928], [136.814392219698306, 34.855888146346985], [136.81439148050444, 34.855894814538388], [136.814692612111941, 34.855942102538457]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814737083949865, 34.855888408874996], [136.814738443872557, 34.85588394510745], [136.814383976639135, 34.855828212460239], [136.814386277027722, 34.855834504733863], [136.814737083949865, 34.855888408874996]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814729801727907, 34.855920394220803], [136.814731335320289, 34.855909697366528], [136.814444170141542, 34.855865651481302], [136.814443403345336, 34.855874460660232], [136.814729801727907, 34.855920394220803]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814443786743425, 34.855873831433193], [136.814444170141542, 34.855871629138534], [136.814433051596581, 34.855872572979109], [136.814434201790874, 34.855874775273747], [136.814443786743425, 34.855873831433193]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814412731497157, 34.855891449788359], [136.814417715672477, 34.855883584451696], [136.814414648487656, 34.855882955224736], [136.814409280914219, 34.855890505948004], [136.814412731497157, 34.855891449788359]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814686861140416, 34.855934866433216], [136.814675742595455, 34.855977339215777], [136.814683410557478, 34.855978283055137], [136.814693762306263, 34.855935810273053], [136.814686861140416, 34.855934866433216]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814197744022209, 34.856610893199552], [136.81427313274321, 34.856620190963135], [136.814273174587868, 34.856616630597692], [136.814197645244718, 34.856607601782244], [136.814197744022209, 34.856610893199552]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814200758743482, 34.856596717648429], [136.814260676224109, 34.856606328055612], [136.814262841260074, 34.856602049915743], [136.814202769251182, 34.856591585942766], [136.814200758743482, 34.856596717648429]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814217358377505, 34.856516324699015], [136.814203718712491, 34.856579482608375], [136.8142165527413, 34.856580830130859], [136.814229826706651, 34.856518536611574], [136.814217358377505, 34.856516324699015]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814204987874547, 34.856595334173605], [136.814209710587988, 34.85657904015045], [136.814204987874547, 34.8565784135873], [136.814201072656829, 34.856594905804378], [136.814204987874547, 34.856595334173605]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814270031578189, 34.856618512062532], [136.814272337724844, 34.85661893594763], [136.814278420655654, 34.856592640399249], [136.814274598537111, 34.856591432139069], [136.814270031578189, 34.856618512062532]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81452928452029, 34.856630788076991], [136.814530434714612, 34.856623237421665], [136.814228892106655, 34.856576832337211], [136.81422815291279, 34.856583500472787], [136.81452928452029, 34.856630788076991]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814587944430002, 34.856579821140116], [136.814589094624296, 34.856575101977697], [136.814220649047485, 34.856516898952194], [136.8142229494361, 34.856523191173139], [136.814587944430002, 34.856579821140116]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814566474136257, 34.856609079941066], [136.814568007728667, 34.856598383176333], [136.81428084254992, 34.856554337659844], [136.814280075753715, 34.856563146765033], [136.814566474136257, 34.856609079941066]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814280459151774, 34.856562517543253], [136.81428084254992, 34.856560315267032], [136.81426972400493, 34.856561259099706], [136.814270874199224, 34.856563461375906], [136.814280459151774, 34.856562517543253]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814249403905507, 34.856580135750939], [136.814254388080826, 34.856572270480115], [136.814251320896005, 34.856571641258419], [136.814245953322597, 34.856579191918478], [136.814249403905507, 34.856580135750939]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814523533548765, 34.856623552032325], [136.814512415003804, 34.856666024459322], [136.814520082965856, 34.856666968290789], [136.814530434714612, 34.856624495864267], [136.814523533548765, 34.856623552032325]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814047835364136, 34.857246089750625], [136.814123224085137, 34.857255387442407], [136.814123265929794, 34.857251827104456], [136.814047736586616, 34.857242798358726], [136.814047835364136, 34.857246089750625]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814050850085408, 34.857231914308962], [136.814110767566035, 34.857241524641935], [136.814112932602001, 34.857237246535099], [136.814052860593108, 34.857226782642911], [136.814050850085408, 34.857231914308962]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814067449719431, 34.857151521980292], [136.814053810054418, 34.857214679401984], [136.814066644083226, 34.85721602691406], [136.814079918048606, 34.857153733875776], [136.814067449719431, 34.857151521980292]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814055079216502, 34.857230530844816], [136.814059801929886, 34.857214236947478], [136.814055079216502, 34.857213610389167], [136.814051163998755, 34.857230102478901], [136.814055079216502, 34.857230530844816]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814120122920087, 34.857253708554772], [136.814122429066771, 34.857254132436601], [136.814128511997609, 34.857227837091258], [136.814124689879037, 34.857226628840408], [136.814120122920087, 34.857253708554772]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814379375862217, 34.857265984474438], [136.814380526056539, 34.857258433877419], [136.814078983448582, 34.857212029151285], [136.814078244254716, 34.857218697235368], [136.814379375862217, 34.857265984474438]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8144380357719, 34.857215017931104], [136.814439185966222, 34.857210298805128], [136.814070740389383, 34.857152096229044], [136.814073040777998, 34.857158388401409], [136.8144380357719, 34.857215017931104]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814416565478183, 34.857244276506144], [136.814418099070593, 34.857233579824005], [136.814130933891818, 34.85718953464761], [136.814130167095641, 34.857198343684772], [136.814416565478183, 34.857244276506144]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814130550493729, 34.857197714467866], [136.814130933891818, 34.857195512208634], [136.814119815346857, 34.857196456034025], [136.81412096554115, 34.857198658293221], [136.814130550493729, 34.857197714467866]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814099495247433, 34.857215332539496], [136.814104479422781, 34.857207467329417], [136.814101412237932, 34.857206838112575], [136.814096044664495, 34.857214388714326], [136.814099495247433, 34.857215332539496]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814373624890692, 34.857258748485648], [136.814362506345702, 34.857301220584695], [136.814370174307783, 34.857302164408878], [136.814380526056539, 34.857259692310301], [136.814373624890692, 34.857258748485648]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813370754315173, 34.860056752023603], [136.813446143036174, 34.860066049397702], [136.813446184880803, 34.860062489181409], [136.813370655537682, 34.860053460744169], [136.813370754315173, 34.860056752023603]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813373769036446, 34.860042577066288], [136.813433686517044, 34.860052187070892], [136.81343585155301, 34.860047909110236], [136.813375779544145, 34.860037445575578], [136.813373769036446, 34.860042577066288]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813390368670468, 34.859962187484477], [136.813376729005455, 34.860025342748202], [136.813389563034235, 34.860026690214227], [136.813402836999643, 34.859964399304395], [136.813390368670468, 34.859962187484477]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813377998167539, 34.860041193649415], [136.813382720880952, 34.860024900308815], [136.813377998167539, 34.860024273771913], [136.813374082949792, 34.86004076529813], [136.813377998167539, 34.860041193649415]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813443041871125, 34.860064370567429], [136.813445348017808, 34.860064794434777], [136.813451430948618, 34.860038499987901], [136.813447608830074, 34.860037291778326], [136.813443041871125, 34.860064370567429]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813702294813254, 34.860076646067654], [136.813703445007576, 34.860069095728619], [136.813401902399619, 34.860022692588053], [136.813401163205754, 34.860029360444308], [136.813702294813254, 34.860076646067654]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813760954722937, 34.860025681265753], [136.813762104917231, 34.860020962301014], [136.81339365934042, 34.859962761713611], [136.813395959729036, 34.85996905367098], [136.813760954722937, 34.860025681265753]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813739484429192, 34.860054938841074], [136.813741018021602, 34.860044242524424], [136.813453852842855, 34.860000198852966], [136.81345308604665, 34.860009007589149], [136.813739484429192, 34.860054938841074]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813453469444767, 34.860008378393736], [136.813453852842855, 34.860006176209758], [136.813442734297894, 34.860007120002905], [136.813443884492187, 34.860009322186855], [136.813453469444767, 34.860008378393736]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813422414198442, 34.860025995863388], [136.81342739837379, 34.86001813092205], [136.813424331188969, 34.860017501726709], [136.813418963615533, 34.860025052070483], [136.813422414198442, 34.860025995863388]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813696543841729, 34.860069410326098], [136.813685425296768, 34.860111880973953], [136.81369309325882, 34.860112824765878], [136.813703445007576, 34.860070354118506], [136.813696543841729, 34.860069410326098]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813116177975132, 34.861116309650399], [136.813191566696133, 34.861125606904736], [136.813191608540791, 34.861122046734295], [136.813116079197613, 34.861113018413363], [136.813116177975132, 34.861116309650399]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813119192696405, 34.861102134875686], [136.813179110177032, 34.861111744756492], [136.813181275212997, 34.861107466850946], [136.813121203204105, 34.861097003451071], [136.813119192696405, 34.861102134875686]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813135792330399, 34.861021746329435], [136.813122152665386, 34.861084900779595], [136.813134986694195, 34.861086248228283], [136.813148260659574, 34.861023958120853], [136.813135792330399, 34.861021746329435]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81312342182747, 34.861100751476634], [136.813128144540912, 34.861084458345914], [136.81312342182747, 34.861083831817076], [136.813119506609752, 34.861100323130863], [136.81312342182747, 34.861100751476634]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813188465531084, 34.861123928096077], [136.813190771677768, 34.861124351957969], [136.813196854608577, 34.861098057849809], [136.813193032490034, 34.861096849655809], [136.813188465531084, 34.861123928096077]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813483566195941, 34.861141551525691], [136.813483566195941, 34.861134630471206], [136.813147326059578, 34.861082250653588], [136.813146586865713, 34.861088918423953], [136.813483566195941, 34.861141551525691]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813462095902196, 34.861078632827216], [136.813462862698401, 34.86107297014199], [136.813139083000408, 34.861022320551164], [136.813141383389024, 34.861028612427482], [136.813462095902196, 34.861078632827216]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81348490808918, 34.861114496491233], [136.813486441681562, 34.861103800312371], [136.813199276502814, 34.861059757208267], [136.813198509706609, 34.861068565830976], [136.81348490808918, 34.861114496491233]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813179801743757, 34.861035111164639], [136.813177098934574, 34.861034938017376], [136.813178957679668, 34.861043982224821], [136.813181562433073, 34.861042899571906], [136.813179801743757, 34.861035111164639]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813203213734852, 34.861059414694047], [136.813193329713471, 34.861055836211328], [136.813192760597786, 34.861058388040576], [136.81320228651947, 34.861062300587818], [136.813203213734852, 34.861059414694047]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813464971387845, 34.861033960522064], [136.813453852842855, 34.861076430671879], [136.813461520804935, 34.861077374452734], [136.813471872553663, 34.861034904303402], [136.813464971387845, 34.861033960522064]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812990423397508, 34.861647341711134], [136.813065812118538, 34.861656638905444], [136.813065853963138, 34.861653078757996], [136.812990324620017, 34.86164405049535], [136.812990423397508, 34.861647341711134]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812993438118781, 34.861633167027946], [136.813053355599408, 34.861642776846701], [136.813055520635345, 34.86163849896878], [136.812995448626481, 34.861628035636464], [136.812993438118781, 34.861633167027946]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813010037752804, 34.861552779000704], [136.81299639808779, 34.861615933043126], [136.813009232116599, 34.861617280483109], [136.813022506081978, 34.86155499077784], [136.813010037752804, 34.861552779000704]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812997667249874, 34.861631783637819], [136.813002389963287, 34.861615490612287], [136.812997667249874, 34.861614864087507], [136.812993752032156, 34.861631355294818], [136.812997667249874, 34.861631783637819]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81306271095346, 34.861654960107629], [136.813065017100172, 34.861655383966784], [136.813071100030982, 34.861629090028394], [136.81306727791241, 34.861627881842182], [136.81306271095346, 34.861654960107629]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813357811618289, 34.861672583423463], [136.813357811618289, 34.861665662413664], [136.813021571481954, 34.861613282934229], [136.813020832288117, 34.861619950661535], [136.813357811618289, 34.861672583423463]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813336341324572, 34.861609665131205], [136.813337108120777, 34.861604002482537], [136.813013328422784, 34.861553353218731], [136.813015628811399, 34.861559645054427], [136.813336341324572, 34.861609665131205]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813359153511556, 34.861645528563677], [136.813360687103966, 34.861634832453873], [136.813073521925219, 34.861590789634128], [136.813072755128985, 34.861599598199966], [136.813359153511556, 34.861645528563677]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813054047166133, 34.861566143749634], [136.813051344356978, 34.861565970603472], [136.813053203102015, 34.861575014752525], [136.813055807855449, 34.861573932106602], [136.813054047166133, 34.861566143749634]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813077459157228, 34.861590447122119], [136.813067575135847, 34.861586868662506], [136.813067006020162, 34.861589420475276], [136.813076531941846, 34.861593332997266], [136.813077459157228, 34.861590447122119]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81333768321781, 34.861563734747463], [136.81332656467282, 34.861606204623726], [136.813334232634872, 34.861607148398505], [136.813344584383657, 34.861564678522726], [136.81333768321781, 34.861563734747463]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812864668819913, 34.862173336912683], [136.812940057540914, 34.862182634047542], [136.812940099385543, 34.862179073922853], [136.812864570042393, 34.862170045717946], [136.812864668819913, 34.862173336912683]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812867683541185, 34.862159162320147], [136.812927601021784, 34.862168772077453], [136.812929766057749, 34.862164494226874], [136.812869694048885, 34.862154030961477], [136.812867683541185, 34.862159162320147]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81288428317518, 34.862078774806996], [136.812870643510166, 34.862141928445538], [136.812883477538975, 34.862143275876903], [136.812896751504354, 34.862080986569993], [136.81288428317518, 34.862078774806996]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81287191267225, 34.862157778938865], [136.812876635385692, 34.862141486017535], [136.81287191267225, 34.862140859496755], [136.812867997454532, 34.862157350598601], [136.81287191267225, 34.862157778938865]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812936956375836, 34.862180955260463], [136.812939262522519, 34.862181379116898], [136.812945345453358, 34.862155085346657], [136.812941523334786, 34.86215387716819], [136.812936956375836, 34.862180955260463]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813232057040693, 34.862198578463584], [136.813232057040693, 34.862191657498052], [136.81289581690433, 34.862139278353588], [136.812895077710465, 34.862145946038261], [136.813232057040693, 34.862198578463584]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813210586746948, 34.862135660573699], [136.813211353543181, 34.862129997961247], [136.81288757384516, 34.862079349021357], [136.812889874233775, 34.862085640816815], [136.813210586746948, 34.862135660573699]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813233398933932, 34.862171523776816], [136.813234932526342, 34.862160827735416], [136.812947767347595, 34.862116785197344], [136.812947000551389, 34.86212559370685], [136.813233398933932, 34.862171523776816]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812928292588538, 34.862092139470448], [136.812925589779354, 34.862091966325409], [136.81292744852442, 34.862101010416623], [136.812930053277853, 34.862099927777621], [136.812928292588538, 34.862092139470448]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812951704579632, 34.862116442687523], [136.812941820558251, 34.86211286425079], [136.812941251442567, 34.862115416047246], [136.81295077736425, 34.86211932854421], [136.812951704579632, 34.862116442687523]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813211928640186, 34.862089730483682], [136.813200810095225, 34.862132200088354], [136.813208478057277, 34.862133143857093], [136.813218829806033, 34.862090674252919], [136.813211928640186, 34.862089730483682]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812559483930329, 34.86344930172524], [136.81263487265133, 34.863458598715852], [136.812634914495987, 34.8634550386464], [136.812559385152838, 34.863446010581562], [136.812559483930329, 34.86344930172524]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812562498651602, 34.863435127352602], [136.812622416132228, 34.863444736960822], [136.812624581168194, 34.863440459176616], [136.812564509159301, 34.863429996073542], [136.812562498651602, 34.863435127352602]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812579098285624, 34.863354741086596], [136.812565458620583, 34.863417893745364], [136.812578292649391, 34.863419241155825], [136.81259156661477, 34.863356952815273], [136.812579098285624, 34.863354741086596]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812566727782666, 34.863433743992786], [136.812571450496108, 34.863417451324224], [136.812566727782666, 34.863416824813164], [136.812562812564948, 34.863433315659165], [136.812566727782666, 34.863433743992786]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812631771486281, 34.863456919954828], [136.812634077632964, 34.863457343804683], [136.812640160563774, 34.863431050442365], [136.81263633844523, 34.863429842282635], [136.812631771486281, 34.863456919954828]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81293562544289, 34.863475988005874], [136.812936703750069, 34.863471564162026], [136.812590632014775, 34.863415243694533], [136.81258989282091, 34.863421911275758], [136.81293562544289, 34.863475988005874]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812905401857392, 34.863411625970762], [136.812906168653598, 34.863405963446169], [136.812582388955605, 34.86335531529204], [136.81258468934422, 34.863361606989891], [136.812905401857392, 34.863411625970762]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812928214044376, 34.86344748861751], [136.812929747636787, 34.863436792742036], [136.812642582458039, 34.863392750887243], [136.812641815661834, 34.86340155926009], [136.812928214044376, 34.86344748861751]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812623107698954, 34.863368105542705], [136.81262040488977, 34.863367932400351], [136.812622263634864, 34.863376976351248], [136.812624868388269, 34.863375893729049], [136.812623107698954, 34.863368105542705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812646519690048, 34.86339240838273], [136.812636635668667, 34.863388830001519], [136.812636066552983, 34.863391381758383], [136.812645592474695, 34.863395294194653], [136.812646519690048, 34.86339240838273]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812906743750602, 34.86336569659332], [136.812895625205641, 34.863408165539106], [136.812903293167693, 34.863409109293201], [136.812913644916449, 34.863366640347905], [136.812906743750602, 34.86336569659332]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81245366605404, 34.86398346454807], [136.812529054775069, 34.863992761478301], [136.812529096619699, 34.86398920143197], [136.812453567276549, 34.863980173425766], [136.81245366605404, 34.86398346454807]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812456680775313, 34.863969290267484], [136.812516598255939, 34.863978899813297], [136.812518763291905, 34.86397462205688], [136.812458691283013, 34.863964159021769], [136.812456680775313, 34.863969290267484]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812473280409364, 34.863888904523584], [136.812459640744322, 34.863952056772185], [136.812472474773131, 34.863953404173891], [136.81248574873851, 34.863891116237902], [136.812473280409364, 34.863888904523584]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812460909906406, 34.863967906916663], [136.812465632619819, 34.863951614353923], [136.812460909906406, 34.863950987846927], [136.81245699468866, 34.863967478585828], [136.812460909906406, 34.863967906916663]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81252595361002, 34.86399108272817], [136.812528259756704, 34.863991506575282], [136.812534342687513, 34.863965213383743], [136.81253052056897, 34.863964005231857], [136.81252595361002, 34.86399108272817]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812883164767527, 34.864019401344507], [136.812883164767527, 34.864012480532203], [136.812484814138514, 34.86394940673857], [136.812484074944649, 34.863956074276487], [136.812883164767527, 34.864019401344507]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812823354663578, 34.863949564029959], [136.812823354663578, 34.863943272377085], [136.812476571079316, 34.863889478725305], [136.812478871467931, 34.863895770382292], [136.812823354663578, 34.863949564029959]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812850959326965, 34.863986055607121], [136.812854026511758, 34.863975988966764], [136.812536764581751, 34.863926914077361], [136.812535997785545, 34.863935722393002], [136.812850959326965, 34.863986055607121]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812517289822665, 34.8639022688929], [136.812514587013482, 34.863902095751669], [136.812516445758575, 34.863911139643825], [136.81251905051198, 34.863910057028662], [136.812517289822665, 34.8639022688929]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812540701813759, 34.863926571575085], [136.812530817792378, 34.863922993217109], [136.812530248676694, 34.863925544957404], [136.812539774598406, 34.863929457368251], [136.812540701813759, 34.863926571575085]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812826805246488, 34.863905837032561], [136.81281894558515, 34.863948620282045], [136.812822971265462, 34.863949878612601], [136.812833323014246, 34.863906780780951], [136.812826805246488, 34.863905837032561]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812764218517032, 34.862584818852056], [136.812839607238061, 34.862594115940396], [136.81283964908269, 34.862590555833513], [136.812764119739541, 34.86258152767379], [136.812764218517032, 34.862584818852056]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812762632461101, 34.86259895723709], [136.812822549941728, 34.86260856694301], [136.812824714977694, 34.862604289115311], [136.812764642968801, 34.862593825905861], [136.812762632461101, 34.86259895723709]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812755461412763, 34.862612317355691], [136.81274182174775, 34.862675470584556], [136.812754655776558, 34.862676818007166], [136.812767929741938, 34.862614529104334], [136.812755461412763, 34.862612317355691]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812763027611169, 34.862612044900878], [136.812767750324554, 34.862595752069531], [136.812763027611169, 34.862595125552211], [136.812759112393394, 34.862611616562965], [136.812763027611169, 34.862612044900878]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812831905295781, 34.86262075006087], [136.812834211442464, 34.862621173915045], [136.812840294373274, 34.862594880285414], [136.81283647225473, 34.862593672113398], [136.812831905295781, 34.86262075006087]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813103235278277, 34.862732120235094], [136.813103235278277, 34.862725199314468], [136.812742841061976, 34.862668573578318], [136.812740924071477, 34.862675809091222], [136.813103235278277, 34.862732120235094]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813081764984531, 34.862669202753366], [136.813082531780736, 34.862663540177657], [136.812778497085503, 34.862615093680596], [136.812777346891181, 34.862621385435069], [136.813081764984531, 34.862669202753366]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813104577171515, 34.862705065723837], [136.813106110763925, 34.862694369751829], [136.812818945585178, 34.862650327499459], [136.812818178788973, 34.862659135951816], [136.813104577171515, 34.862705065723837]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812799470826093, 34.862625681932442], [136.812796768016909, 34.862625508788526], [136.812798626762003, 34.862634552821063], [136.812801231515408, 34.862633470189095], [136.812799470826093, 34.862625681932442]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812822882817215, 34.862649984991855], [136.812812998795806, 34.862646406578342], [136.812812429680122, 34.862648958358243], [136.812821955601834, 34.862652870829827], [136.812822882817215, 34.862649984991855]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813083106877741, 34.862623272961308], [136.81307198833278, 34.862665742290467], [136.813079656294832, 34.862666686053089], [136.813090008043588, 34.862624216724413], [136.813083106877741, 34.862623272961308]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813481153976653, 34.858271404132552], [136.813543210211378, 34.858307740265396], [136.813545214809153, 34.858304582554815], [136.813482884629082, 34.858268433808803], [136.813481153976653, 34.858271404132552]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813491673776042, 34.858259891417568], [136.813539767782288, 34.858290749956517], [136.813544061385159, 34.858287742465897], [136.813496301290854, 34.858256065666652], [136.813491673776042, 34.858259891417568]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813550890569587, 34.858194414933095], [136.813503835312105, 34.858245631472634], [136.81351452966706, 34.858251607539962], [136.813560781354539, 34.858201025353246], [136.813550890569587, 34.858194414933095]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813496207636831, 34.858260231851773], [136.813509420337851, 34.858247466465457], [136.81350555720681, 34.858245150882652], [136.813492954711421, 34.858258393353388], [136.813496207636831, 34.858260231851773]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813541373837893, 34.858305090072932], [136.813543195080086, 34.858306325900756], [136.813563146544539, 34.858285152451671], [136.813560407531554, 34.85828265349199], [136.813541373837893, 34.858305090072932]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813765662122762, 34.858412489339415], [136.813770859449249, 34.858406187548013], [136.813527736715599, 34.858252635392347], [136.813523393356661, 34.858258303537617], [136.813765662122762, 34.858412489339415]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813839743033355, 34.858379689944648], [136.813841660023854, 34.858376543905237], [136.813553506228459, 34.858196151085529], [136.813552079757756, 34.858202615097795], [136.813839743033355, 34.858379689944648]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813810804094288, 34.858406978153717], [136.813818081542991, 34.858398014953302], [136.813586469574147, 34.858251915116661], [136.813580918609233, 34.858259481191865], [136.813810804094288, 34.858406978153717]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813581608011049, 34.858259063027376], [136.813583166613171, 34.858257242832465], [136.813572735162808, 34.858253947268231], [136.813572543447634, 34.858256338053899], [136.813581608011049, 34.858259063027376]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813544193507965, 34.858263211306721], [136.813552981891746, 34.858258055574495], [136.813550595797977, 34.858256353580956], [136.813541639532502, 34.85826108625956], [136.813544193507965, 34.858263211306721]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813765684812353, 34.858404214903999], [136.813732306475089, 34.858437932876001], [136.813738619389511, 34.858441627039475], [136.813771314283343, 34.85840762377417], [136.813765684812353, 34.858404214903999]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811903166613064, 34.859062129137264], [136.811917576646579, 34.85900197417493], [136.811913359267464, 34.859002288776487], [136.811899155001498, 34.85906218915963], [136.811903166613064, 34.859062129137264]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811885911293786, 34.859059564858917], [136.811898005532754, 34.859010461331465], [136.811892805947593, 34.85900865749057], [136.811879670551434, 34.859057882362883], [136.811885911293786, 34.859059564858917]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811788049117865, 34.859045430501006], [136.811864927280567, 34.859057025960048], [136.811866651394325, 34.859046503686358], [136.811790824254018, 34.859035213821045], [136.811788049117865, 34.859045430501006]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811884252387443, 34.859056085832783], [136.811864426376843, 34.85905210653695], [136.811863632661641, 34.859055977727039], [136.811883705353978, 34.859059295703403], [136.811884252387443, 34.859056085832783]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811914797010331, 34.85900338988192], [136.811916905699889, 34.859001344971816], [136.811879811933494, 34.858995524842797], [136.811879045137289, 34.858998198956186], [136.811914797010331, 34.85900338988192]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811918726840901, 34.858834920579412], [136.811909908684555, 34.858833976772821], [136.811863517514126, 34.859038153345843], [136.81186926848568, 34.859040040954319], [136.811918726840901, 34.858834920579412]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81185316576537, 34.858800943535407], [136.811847798191934, 34.858800628933096], [136.811788769937124, 34.859042734030751], [136.811796452440291, 34.859040886661873], [136.81185316576537, 34.858800943535407]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811894189362363, 34.85879874131912], [136.811880770428758, 34.85879716830744], [136.811834777968386, 34.858993581800256], [136.811845507996935, 34.858994267272053], [136.811894189362363, 34.85879874131912]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811844743665972, 34.858993948656789], [136.811842062383647, 34.858993619991253], [136.811843141515908, 34.859002749280705], [136.811845832596646, 34.859001819565691], [136.811844743665972, 34.858993948656789]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811866015103334, 34.859019543424061], [136.811856462191315, 34.859015403436793], [136.811855675813888, 34.859017916177322], [136.811864842886052, 34.859022368749443], [136.811866015103334, 34.859019543424061]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811911442276966, 34.858841527225231], [136.811958600243543, 34.858849706881216], [136.811962441697062, 34.858842981904402], [136.811914126063641, 34.858834920579412], [136.811911442276966, 34.858841527225231]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812094915572118, 34.858160793071647], [136.812083238435747, 34.858222611330525], [136.812087577012022, 34.858222662035118], [136.812098927046179, 34.85816072715081], [136.812094915572118, 34.858160793071647]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812111431183496, 34.858164460287497], [136.812099444181655, 34.858213582022238], [136.81210464769714, 34.858215378233169], [136.812117675589576, 34.858166133623889], [136.812111431183496, 34.858164460287497]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812209434558611, 34.858178334678499], [136.812132531200604, 34.858166852141366], [136.81213083006503, 34.858177377045493], [136.812206681735091, 34.858188555528507], [136.812209434558611, 34.858178334678499]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812113498894973, 34.858167555800748], [136.812133333562542, 34.858171505984551], [136.812134118823707, 34.85816763359211], [136.812114038918793, 34.858164345096213], [136.812113498894973, 34.858167555800748]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812084553373012, 34.85822120263839], [136.812084026211267, 34.858223093021742], [136.812116042788034, 34.85822820532443], [136.812117532782167, 34.858225074601222], [136.812084553373012, 34.85822120263839]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812073715523837, 34.858413037955046], [136.812081766884006, 34.858413981766461], [136.812136592812664, 34.858185893692209], [136.812127007860113, 34.858185264482849], [136.812073715523837, 34.858413037955046]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812149244950035, 34.858398880782474], [136.812157296310176, 34.858400139197911], [136.812209821850274, 34.858179286993732], [136.812202153888194, 34.858178657784329], [136.812149244950035, 34.858398880782474]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812105920964427, 34.858419015427174], [136.812118573101827, 34.858420903049861], [136.812162818994892, 34.858230252511127], [136.812152087486822, 34.858229582809464], [136.812105920964427, 34.858419015427174]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81219294455974, 34.858214385614275], [136.812193167999794, 34.858212168592551], [136.812182137317876, 34.858213652222467], [136.812183444766902, 34.858215794557289], [136.81219294455974, 34.858214385614275]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812163218314026, 34.858233484821305], [136.812167624838395, 34.858225390840474], [136.812164517551054, 34.858224912114324], [136.812159705549817, 34.858232710716976], [136.812163218314026, 34.858233484821305]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812203394550664, 34.858401519284321], [136.812151687471669, 34.858392200604513], [136.812150502035848, 34.858398488276336], [136.812202212640187, 34.858407177754401], [136.812203394550664, 34.858401519284321]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811188562457886, 34.858016074748988], [136.811176885321487, 34.858077893116629], [136.811181223897762, 34.858077943821307], [136.811192573931947, 34.858016008828038], [136.811188562457886, 34.858016074748988]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811205078069264, 34.858019741971297], [136.811193091067395, 34.858068863792454], [136.811198294582908, 34.858070660006547], [136.811211322475316, 34.858021415310624], [136.811205078069264, 34.858019741971297]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811303081444379, 34.858033616386706], [136.811226178086343, 34.858022133829365], [136.81122447695077, 34.858032658752016], [136.81130032862086, 34.858043837254698], [136.811303081444379, 34.858033616386706]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811207145780713, 34.85802283748999], [136.811226980448311, 34.858026787680743], [136.811227765709475, 34.858022915281495], [136.811207685804533, 34.858019626779807], [136.811207145780713, 34.85802283748999]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81117820025878, 34.858076484422007], [136.811177673097035, 34.858078374808684], [136.811209689673802, 34.858083487120361], [136.811211179667936, 34.85808035639166], [136.81117820025878, 34.858076484422007]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811167362409606, 34.858268320076164], [136.811175413769774, 34.858269263889241], [136.811230239698432, 34.858041175413703], [136.811220654745881, 34.858040546203249], [136.811167362409606, 34.858268320076164]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811242891835803, 34.858254162878687], [136.811250943195915, 34.858255421296342], [136.811303468736014, 34.858034568703609], [136.811295800773962, 34.858033939493097], [136.811242891835803, 34.858254162878687]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811199567850196, 34.858274297558808], [136.811212219987596, 34.858276185184813], [136.811256465880632, 34.858085534310661], [136.811245734372591, 34.858084864607832], [136.811199567850196, 34.858274297558808]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811286591445509, 34.858069667385898], [136.811286814885563, 34.858067450360274], [136.811275784203644, 34.858068933992811], [136.81127709165267, 34.858071076331392], [136.811286591445509, 34.858069667385898]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811256865199795, 34.85808876662653], [136.811261271724163, 34.858080672631459], [136.811258164436822, 34.858080193904463], [136.811253352435585, 34.858087992520844], [136.811256865199795, 34.85808876662653]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811297041436404, 34.858256801385174], [136.811245334357437, 34.858247482688981], [136.811244148921617, 34.858253770371853], [136.811295859525956, 34.858262459865202], [136.811297041436404, 34.858256801385174]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810460106063175, 34.857897783061368], [136.810445544628095, 34.857959181384381], [136.810447593411709, 34.857959314108591], [136.810464117537236, 34.857897717140318], [136.810460106063175, 34.857897783061368]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810476621674553, 34.857901450288942], [136.810464630664882, 34.857948563446591], [136.810468296909221, 34.85794909434351], [136.810478864319407, 34.857902552367037], [136.810476621674553, 34.857901450288942]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810574625049668, 34.857915324724296], [136.810497721691632, 34.857903842150456], [136.810496020556059, 34.857914367088235], [136.810571872226149, 34.857925545606989], [136.810574625049668, 34.857915324724296]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810478689386002, 34.857904545812083], [136.8104985240536, 34.85790849600852], [136.810499309314764, 34.857904623603702], [136.81047922940985, 34.857901335097282], [136.810478689386002, 34.857904545812083]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810447000342776, 34.857958340797687], [136.810445328966665, 34.857959535315615], [136.810482203755527, 34.857965549460886], [136.810484094109199, 34.857964578835556], [136.810447000342776, 34.857958340797687]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810480696408064, 34.857974164472445], [136.810489131166321, 34.857975422894391], [136.810501783303721, 34.857922883762178], [136.81049219835117, 34.857922254550807], [136.810480696408064, 34.857974164472445]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81055507563994, 34.857967243151464], [136.810561210009581, 34.857971018417523], [136.810575012341303, 34.857916277042584], [136.810567344379251, 34.857915647831163], [136.81055507563994, 34.857967243151464]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81055984518747, 34.857948563446598], [136.810560060848871, 34.857945908961945], [136.810545611532888, 34.857945378065004], [136.810546258517206, 34.857947324687103], [136.81055984518747, 34.857948563446598]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810522320098187, 34.857963959455937], [136.810528933423655, 34.857953532755687], [136.810525826136313, 34.857953054027945], [136.810520810468148, 34.857962012834228], [136.810522320098187, 34.857963959455937]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810702007581369, 34.858160532380815], [136.810650300502374, 34.858151213673708], [136.810649115066582, 34.858157501363941], [136.810700825670949, 34.858166190867472], [136.810702007581369, 34.858160532380815]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810587281080558, 34.858180545412559], [136.810487597573911, 34.857972906050499], [136.810481846602357, 34.857972591444998], [136.810581411330759, 34.8581809190052], [136.810587281080558, 34.858180545412559]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810630221668021, 34.858183062250085], [136.810520810468148, 34.857960774074932], [136.810517502625913, 34.857969130784518], [136.810625620890789, 34.858192500390174], [136.810630221668021, 34.858183062250085]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810650925165561, 34.858150972565724], [136.810561210009581, 34.857967243151464], [136.810555842436173, 34.857968501573502], [136.810647091184507, 34.858157264661685], [136.810650925165561, 34.858150972565724]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.809954020567915, 34.857866951689303], [136.809942343431516, 34.857928770169011], [136.809946682007819, 34.857928820873774], [136.809958032041976, 34.857866885768232], [136.809954020567915, 34.857866951689303]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.809970536179293, 34.857870618918255], [136.809958549177452, 34.857919740828457], [136.809963752692937, 34.857921537045812], [136.809976780585373, 34.857872292260616], [136.809970536179293, 34.857870618918255]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810030587035783, 34.857879782772422], [136.809991636196372, 34.857873010780665], [136.809989935060798, 34.857883535722394], [136.810028670045284, 34.857889850158919], [136.810030587035783, 34.857879782772422]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.809972603890742, 34.857873714442569], [136.809992438558339, 34.857877664640469], [136.809993223819504, 34.857873792234201], [136.80997314391459, 34.857870503726559], [136.809972603890742, 34.857873714442569]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.809943658368809, 34.857927361471837], [136.809943131207064, 34.85792925186194], [136.809975147783831, 34.857934364182881], [136.809976637777936, 34.857931233448511], [136.809943658368809, 34.857927361471837]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.809979978486268, 34.857920681522401], [136.809988413244525, 34.857921310733772], [136.809995697808461, 34.857892052399528], [136.80998611285591, 34.85789142318793], [136.809979978486268, 34.857920681522401]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.809979211690035, 34.857933580354711], [136.809982278874884, 34.857920681522401], [136.809978828291975, 34.857920681522401], [136.809974610912832, 34.857933580354711], [136.809979211690035, 34.857933580354711]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810021385481321, 34.857919423099617], [136.810029820239578, 34.857880411984112], [136.810023685869936, 34.857881670407487], [136.810015251111679, 34.857919108493924], [136.810021385481321, 34.857919423099617]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810148290253267, 34.858109130115345], [136.810020618685115, 34.857916591648312], [136.810016401306001, 34.857919108493924], [136.810142539281713, 34.858112276164988], [136.810148290253267, 34.858109130115345]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810127203357609, 34.858131467065192], [136.809988413244497, 34.857919737705316], [136.809980361884328, 34.857921310733772], [136.810121452386056, 34.858136186138324], [136.810127203357609, 34.858131467065192]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810403633389484, 34.85817488252782], [136.810122602580378, 34.858129579435854], [136.810122219182261, 34.858134927718851], [136.810402099797074, 34.858182118436027], [136.810403633389484, 34.85817488252782]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810483763592913, 34.858166702804724], [136.810487597573911, 34.858161983733346], [136.810143689476035, 34.858106298670563], [136.810144456272241, 34.858111332350106], [136.810483763592913, 34.858166702804724]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812680697964879, 34.859186711167737], [136.812695107998394, 34.859126556296509], [136.81269089061928, 34.85912687089759], [136.812676686353313, 34.859186771190004], [136.812680697964879, 34.859186711167737]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812663442645601, 34.85918414689327], [136.812675536884569, 34.859135043440176], [136.81267033729938, 34.859133239602038], [136.812657201903249, 34.859182464399787], [136.812663442645601, 34.85918414689327]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812565580469681, 34.859170012556767], [136.812642458632382, 34.859181607998252], [136.812644182746169, 34.859171085740492], [136.812568355605833, 34.859159795892282], [136.812565580469681, 34.859170012556767]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812661783739259, 34.859180667872401], [136.812641957728687, 34.8591766885826], [136.812641164013456, 34.85918055976682], [136.812661236705793, 34.859183877738161], [136.812661783739259, 34.859180667872401]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812692328362175, 34.859127972001353], [136.812694437051704, 34.859125927094347], [136.812657343285309, 34.859120106974139], [136.812656576489076, 34.859122781083471], [136.812692328362175, 34.859127972001353]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812696258192716, 34.858959502954001], [136.81268744003637, 34.858958559148846], [136.81264104886597, 34.859162735412625], [136.812646799837495, 34.859164623018252], [136.812696258192716, 34.858959502954001]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812630697117214, 34.85892552596146], [136.812625329543778, 34.858925211359612], [136.812566301288911, 34.859167316090591], [136.812573983792106, 34.859165468724512], [136.812630697117214, 34.85892552596146]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812671720714178, 34.858923323748492], [136.812658301780573, 34.858921750739199], [136.812612309320201, 34.859118163934546], [136.81262303934875, 34.859118849405299], [136.812671720714178, 34.858923323748492]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812622275017787, 34.859118530790518], [136.812619593735462, 34.85911820212548], [136.812620672867695, 34.859127331401119], [136.812623363948461, 34.859126401687497], [136.812622275017787, 34.859118530790518]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812643546455149, 34.859144125519023], [136.81263399354313, 34.859139985538029], [136.812633207165732, 34.859142498274757], [136.812642374237896, 34.859146950840142], [136.812643546455149, 34.859144125519023]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812688973628752, 34.858966109589808], [136.812736131595358, 34.858974289233402], [136.812739973048878, 34.858967564266777], [136.812691657415456, 34.858959502954001], [136.812688973628752, 34.858966109589808]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811128702446069, 34.858936288510897], [136.811143112479584, 34.858876133456533], [136.81113889510047, 34.858876448058574], [136.811124690834504, 34.858936348533355], [136.811128702446069, 34.858936288510897]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811111447126791, 34.858933724228635], [136.81112354136576, 34.858884620626043], [136.811118341780571, 34.858882816782405], [136.81110520638444, 34.858932041730014], [136.811111447126791, 34.858933724228635]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811013584950871, 34.858919589849087], [136.811090463113572, 34.858931185325872], [136.811092187227331, 34.858920663036088], [136.811016360087024, 34.858909373153494], [136.811013584950871, 34.858919589849087]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811109788220449, 34.858930245197165], [136.811089962209877, 34.858926265895256], [136.811089168494647, 34.858930137091249], [136.811109241186983, 34.858933455072695], [136.811109788220449, 34.858930245197165]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811140332843365, 34.85887754916569], [136.811142441532894, 34.85887550425246], [136.8111053477665, 34.858869684114538], [136.811104580970266, 34.858872358232013], [136.811140332843365, 34.85887754916569]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811144262673906, 34.858709079605454], [136.811135444517561, 34.858708135797414], [136.811089053347132, 34.85891231268279], [136.811094804318685, 34.858914200294159], [136.811144262673906, 34.858709079605454]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811074675918604, 34.858690832648279], [136.811070075141402, 34.858689574237282], [136.811014305770101, 34.858916893374719], [136.811021988273268, 34.858915046003006], [136.811074675918604, 34.858690832648279]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81111761650601, 34.858681394565373], [136.811104580970493, 34.858679506948661], [136.811060313801391, 34.858867741069027], [136.811071043829912, 34.858868426541861], [136.81111761650601, 34.858681394565373]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811070279498978, 34.858868107926114], [136.811067598216653, 34.858867779260073], [136.811068677348914, 34.858876908563502], [136.811071368429651, 34.858875978847053], [136.811070279498978, 34.858868107926114]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811091550936339, 34.858893702732537], [136.811081998024321, 34.858889562738945], [136.811081211646922, 34.858892075483318], [136.811090378719086, 34.85889652806226], [136.811091550936339, 34.858893702732537]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811136978109943, 34.858715686261363], [136.811184136076548, 34.858723865929875], [136.811187977530068, 34.858717140942773], [136.811139661896647, 34.858709079605454], [136.811136978109943, 34.858715686261363]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810395645274127, 34.858821773373599], [136.810410055307671, 34.858761618235491], [136.810405837928528, 34.858761932837965], [136.81039163366259, 34.858821833396135], [136.810395645274127, 34.858821773373599]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810378389954877, 34.858819209087748], [136.810390484193846, 34.858770105416809], [136.810385284608628, 34.858768301570663], [136.810372149212526, 34.858817526586805], [136.810378389954877, 34.858819209087748]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810280527778957, 34.85880507468854], [136.810357405941659, 34.858816670181469], [136.810359130055417, 34.858806147877026], [136.81028330291511, 34.858794857978722], [136.810280527778957, 34.85880507468854]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810376731048535, 34.858815730051454], [136.810356905037935, 34.858811750743989], [136.810356111322704, 34.858815621945382], [136.81037618401507, 34.858818939931453], [136.810376731048535, 34.858815730051454]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810407275671423, 34.858763033946609], [136.81040938436098, 34.858760989030536], [136.810372290594557, 34.858755168884507], [136.810371523798352, 34.858757843005705], [136.810407275671423, 34.858763033946609]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810373057391104, 34.858755640788289], [136.810363089040436, 34.858761932837986], [136.810355996175218, 34.858797797512118], [136.810361747146771, 34.858799685126115], [136.810373057391104, 34.858755640788289]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810308646509924, 34.858727955763847], [136.810300978547843, 34.858731730994997], [136.810281248598187, 34.858802378210406], [136.810288931101354, 34.858800530836128], [136.810308646509924, 34.858727955763847]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810337222327064, 34.858753592693894], [136.810334541044739, 34.858753264027399], [136.810335620176971, 34.858762393343532], [136.810338311257738, 34.85876146362579], [136.810337222327064, 34.858753592693894]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810358493764426, 34.858779187535951], [136.810348940852379, 34.858775047536597], [136.81034815447498, 34.858777560284459], [136.810357321547144, 34.858782012869597], [136.810358493764426, 34.858779187535951]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810539452167546, 34.858621305412171], [136.810591402610129, 34.858629485090027], [136.810595244063649, 34.858622760095209], [136.81054021896378, 34.85861532795473], [136.810539452167546, 34.858621305412171]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811073142326222, 34.85869397867566], [136.811073142326222, 34.858690832648279], [136.811014099018422, 34.8586807653598], [136.811014099018422, 34.858683911387587], [136.811073142326222, 34.85869397867566]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811015632610832, 34.858683911387587], [136.811021000184269, 34.858666293630478], [136.811017166203243, 34.858665664424791], [136.811013332222217, 34.858682023770932], [136.811015632610832, 34.858683911387587]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810304045732693, 34.858734247815676], [136.810491910802881, 34.858576317169259], [136.810487310025678, 34.858573800343741], [136.810299444955433, 34.858731101789822], [136.810304045732693, 34.858734247815676]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810333950784667, 34.858757528403252], [136.810533317797962, 34.858587013676789], [136.810526416632115, 34.858580721613698], [136.810328583211231, 34.858749348738087], [136.810333950784667, 34.858757528403252]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810540602361868, 34.858620676206144], [136.810540218963808, 34.858615957160794], [136.810369990206311, 34.858760045223129], [136.810373057391104, 34.858761932837986], [136.810540602361868, 34.858620676206144]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812010742386065, 34.85855832579778], [136.812011605031813, 34.858551719129224], [136.812009879740344, 34.85855101127185], [136.812008585771736, 34.858557971869118], [136.812010742386065, 34.85855832579778]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812048698798179, 34.858549595557072], [136.812046129864171, 34.858558561749511], [136.812110828293953, 34.858568471751319], [136.812113109679416, 34.858559033655098], [136.812048698798179, 34.858549595557072]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812000965734455, 34.858591123180283], [136.812071846458622, 34.858602330915907], [136.812072134007224, 34.858599853416585], [136.812001109508714, 34.858589235561496], [136.812000965734455, 34.858591123180283]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811990613985643, 34.85864385851248], [136.811990613985643, 34.858641027086101], [136.811894860309565, 34.858626162096044], [136.811894860309565, 34.858628049713978], [136.811990613985643, 34.85864385851248]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811888534240865, 34.858669341345461], [136.811896873149578, 34.858626869952779], [136.811893997663816, 34.858627105905029], [136.81188450856078, 34.858669341345461], [136.811888534240865, 34.858669341345461]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811944422662123, 34.858811451253317], [136.811958411562642, 34.858813930396728], [136.811956256922713, 34.858808632041317], [136.811948638992931, 34.858807424039966], [136.811944422662123, 34.858811451253317]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811954314221992, 34.858817801935587], [136.811958370175404, 34.858813896435862], [136.81194446404939, 34.858811451253317], [136.811946450638828, 34.85881668122699], [136.811954314221992, 34.858817801935587]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812073244424511, 34.858832424736242], [136.81208723332503, 34.858834903879007], [136.812085078685101, 34.858829605524953], [136.812077460755319, 34.858828397523908], [136.812073244424511, 34.858832424736242]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812083135984381, 34.858838775416892], [136.812087191937763, 34.858834869918148], [136.812073285811749, 34.858832424736242], [136.812075272401188, 34.858837654708573], [136.812083135984381, 34.858838775416892]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812201043791902, 34.858853398213803], [136.81221503269245, 34.858855877355957], [136.81221287805252, 34.858850579003239], [136.812205260122738, 34.858849371002499], [136.812201043791902, 34.858853398213803]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8122109353518, 34.858859748892833], [136.812214991305183, 34.858855843395098], [136.812201085179197, 34.858853398213803], [136.812203071768636, 34.858858628184812], [136.8122109353518, 34.858859748892833]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81233293273911, 34.858872693808458], [136.81234692163963, 34.858875172950007], [136.812344766999701, 34.858869874598547], [136.812337149069918, 34.858868666598092], [136.81233293273911, 34.858872693808458]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81234282429898, 34.858879044485995], [136.812346880252392, 34.858875138989163], [136.812332974126377, 34.858872693808458], [136.812334960715816, 34.858877923778223], [136.81234282429898, 34.858879044485995]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812461754501498, 34.858895345152902], [136.812475743402018, 34.858897824293784], [136.812473588762089, 34.858892525943773], [136.812465970832307, 34.858891317943652], [136.812461754501498, 34.858895345152902]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81247164606134, 34.858901695828692], [136.812475702014751, 34.858897790332954], [136.812461795888737, 34.858895345152902], [136.812463782478176, 34.85890057512124], [136.81247164606134, 34.858901695828692]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811814578504794, 34.858789638825407], [136.811828567405314, 34.858792117969472], [136.811826412765384, 34.858786819612661], [136.811818794835631, 34.858785611610983], [136.811814578504794, 34.858789638825407]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811824470064693, 34.85879598950936], [136.811828526018076, 34.858792084008599], [136.81181461989209, 34.858789638825407], [136.811816606481528, 34.858794868800466], [136.811824470064693, 34.85879598950936]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812589553868918, 34.858914640737709], [136.812603542769438, 34.858917119878008], [136.812601388129508, 34.85891182152924], [136.812593770199754, 34.858910613529403], [136.812589553868918, 34.858914640737709]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812599445428816, 34.858920991412006], [136.812603501382199, 34.858917085917177], [136.812589595256213, 34.858914640737709], [136.812591581845652, 34.858919870704817], [136.812599445428816, 34.858920991412006]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813541866670761, 34.858925761912907], [136.813541866670761, 34.858923992277496], [136.813509948778744, 34.85891915527386], [136.813509373681597, 34.858920924909363], [136.813541866670761, 34.858925761912907]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813522313367571, 34.858879161501143], [136.813522025818997, 34.858877745792022], [136.81343432350306, 34.858863116796307], [136.813434035954458, 34.858865004408798], [136.813522313367571, 34.858879161501143]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813510236327346, 34.858918093492534], [136.81351138652164, 34.858916205881258], [136.813474580303819, 34.858910071144308], [136.813474867852392, 34.858912430658563], [136.813510236327346, 34.858918093492534]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81303944742001, 34.858814923675105], [136.813036284385703, 34.858815395578517], [136.813026795282667, 34.8588538556969], [136.813029383219856, 34.85885456355166], [136.81303944742001, 34.858814923675105]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813027945476961, 34.858851496180954], [136.812933054446603, 34.858836159325641], [136.812932191800854, 34.858838518842035], [136.813029383219856, 34.85885456355166], [136.813027945476961, 34.858851496180954]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813052387105955, 34.858748385267937], [136.813054975043173, 34.858750744786839], [136.81306360150046, 34.858709925100236], [136.813061013563271, 34.858709453196219], [136.813052387105955, 34.858748385267937]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81306273885474, 34.858712284620239], [136.813063889049033, 34.858710397004245], [136.812966985178662, 34.858694588218526], [136.812965547435766, 34.858696947738977], [136.81306273885474, 34.858712284620239]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814463123161033, 34.857028521281613], [136.814477112061581, 34.857031000478749], [136.814474957421623, 34.857025702008507], [136.81446733949187, 34.857024493980965], [136.814463123161033, 34.857028521281613]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814473014720932, 34.857034872101529], [136.814477070674315, 34.857030966517144], [136.8144631645483, 34.857028521281613], [136.814465151137767, 34.857033751368633], [136.814473014720932, 34.857034872101529]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814447454847425, 34.857139741727501], [136.814451510800836, 34.857135836148096], [136.814437604674822, 34.857133390915678], [136.814439591264261, 34.857138620996039], [136.814447454847425, 34.857139741727501]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814437563287555, 34.857133390915678], [136.814451552188075, 34.857135870109651], [136.814449397548174, 34.857130571646159], [136.814441779618363, 34.857129363620167], [136.814437563287555, 34.857133390915678]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815392847632751, 34.855956651198838], [136.815400886192975, 34.855923741903119], [136.815391953037192, 34.855922148610453], [136.815384157275759, 34.855955183003879], [136.815392847632751, 34.855956651198838]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815400515594774, 34.85592351193489], [136.815369287563584, 34.855918455069116], [136.815368182354831, 34.855926867810325], [136.815397576209335, 34.855932006494328], [136.815400515594774, 34.85592351193489]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815392906720007, 34.855956569831008], [136.815394636823896, 34.855945744607112], [136.815364476173158, 34.85594165463484], [136.815362714119402, 34.855951968612793], [136.815392906720007, 34.855956569831008]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815226523924849, 34.856372492349152], [136.815271920656414, 34.856182383147228], [136.815270087534259, 34.856182206177792], [136.815224798633182, 34.856371740230692], [136.815226523924849, 34.856372492349152]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815208138787852, 34.856368908726182], [136.815210079740751, 34.856369439633305], [136.81525191805855, 34.856189992832832], [136.815250084936366, 34.856189992832832], [136.815208138787852, 34.856368908726182]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815220701066266, 34.856371519019476], [136.815222642019194, 34.856372049926563], [136.815264857744523, 34.856191408588209], [136.815263348114456, 34.856191143134076], [136.815220701066266, 34.856371519019476]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815240595833359, 34.856374748704269], [136.815286208226354, 34.856184661628745], [136.815284321188813, 34.85618426344751], [136.815238870541691, 34.856373996585837], [136.815240595833359, 34.856374748704269]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815961624921783, 34.859273361809159], [136.815962703228934, 34.859270707367251], [136.815653660395981, 34.859221511695047], [136.815653121242406, 34.859224254619996], [136.815961624921783, 34.859273361809159]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815963781536112, 34.859256019453809], [136.815964859843263, 34.859253365011341], [136.81565581701031, 34.85920416932877], [136.815655277856763, 34.859206912254294], [136.815963781536112, 34.859256019453809]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815965075504693, 34.859248587014683], [136.815966153811871, 34.859245932571973], [136.815657110978918, 34.859196736884961], [136.815656571825315, 34.859199479810734], [136.815965075504693, 34.859248587014683]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815966800796161, 34.859231952505631], [136.815967879103312, 34.859229298062388], [136.815658836270359, 34.859180102365421], [136.815658297116812, 34.859182845291755], [136.815966800796161, 34.859231952505631]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81600055181022, 34.85938228233492], [136.81600163011737, 34.859379981821647], [136.815381819159967, 34.859279643987996], [136.815380093868527, 34.859281767541304], [136.81600055181022, 34.85938228233492]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816052310554028, 34.859128871563378], [136.816053388861206, 34.859126571043028], [136.815433577903804, 34.859026232900263], [136.815431852612363, 34.859028356460108], [136.816052310554028, 34.859128871563378]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816049506955437, 34.859145329130207], [136.816050585262587, 34.859143028610312], [136.815430774305213, 34.85904269048762], [136.815429049013716, 34.859044814047039], [136.816049506955437, 34.859145329130207]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813327320521978, 34.858835731662786], [136.813326457876258, 34.858837678263818], [136.813436279135658, 34.858855772478769], [136.813435798222628, 34.858853604997833], [136.813327320521978, 34.858835731662786]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813329584967022, 34.858825202320034], [136.813328722321302, 34.858827148921314], [136.813438543580702, 34.858845243138568], [136.813438062667672, 34.858843075657369], [136.813329584967022, 34.858825202320034]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813332172904211, 34.858814407530254], [136.813331310258491, 34.858816354131797], [136.813441131517891, 34.858834448351431], [136.813440650604861, 34.85883228086994], [136.813332172904211, 34.858814407530254]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813414879063629, 34.858901473663124], [136.813419192292287, 34.858882892483962], [136.813417251339416, 34.858882184629437], [136.813412506787898, 34.85890165062672], [136.813414879063629, 34.858901473663124]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810391413608613, 34.858184281342808], [136.8104884612533, 34.858199677308036], [136.8104884612533, 34.858197022831476], [136.810391629270043, 34.858183042586859], [136.810391413608613, 34.858184281342808]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810385806411347, 34.858210295213397], [136.810393570222942, 34.858180211144635], [136.810390982285753, 34.858180034179483], [136.810383218474158, 34.858210649143551], [136.810385806411347, 34.858210295213397]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810579901700748, 34.858182334726322], [136.810581411330759, 34.8581809190052], [136.810484579347502, 34.858165523036476], [136.810483716701782, 34.858166407862342], [136.810579901700748, 34.858182334726322]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810450828333217, 34.858605457283495], [136.810434653725764, 34.858602979784273], [136.810434438064334, 34.858604395498119], [136.810448887380318, 34.858606165140401], [136.810450828333217, 34.858605457283495]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810468728232109, 34.85858917657292], [136.810447162088849, 34.858572364966214], [136.810445221135978, 34.85857342675201], [136.810467002940669, 34.858590592287008], [136.810468728232109, 34.85858917657292]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81044457415166, 34.858572364966214], [136.810384188950536, 34.858563162822151], [136.810384188950536, 34.858564224608052], [136.810446299443129, 34.858573603716302], [136.81044457415166, 34.858572364966214]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810384835934798, 34.858564224608052], [136.810387316041272, 34.858563782197251], [136.810361436669353, 34.858537591474082], [136.810359064393595, 34.858538830224695], [136.810384835934798, 34.858564224608052]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810359927039343, 34.858539184153436], [136.810373729371008, 34.858531043791992], [136.810372435402428, 34.858529805041272], [136.810358848732164, 34.858537591474082], [136.810359927039343, 34.858539184153436]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816802273185516, 34.859158512877535], [136.816814781548601, 34.859105070022665], [136.816813487580021, 34.859104716096354], [136.816800979216907, 34.859157451099293], [136.816802273185516, 34.859158512877535]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816811330965663, 34.859105423948968], [136.816813918902852, 34.859105070022665], [136.816684953366149, 34.859083126588757], [136.816684953366149, 34.85908560407352], [136.816811330965663, 34.859105423948968]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816659073994259, 34.858803524257098], [136.816685384689038, 34.858692390914499], [136.81668020881466, 34.858692744842578], [136.816655623411322, 34.858803524257098], [136.816659073994259, 34.858803524257098]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816682796751849, 34.858696638051363], [136.816684953366178, 34.858692390914499], [136.816560732380992, 34.858673986652178], [136.816559869735272, 34.85867681807742], [136.816682796751849, 34.858696638051363]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816536578300514, 34.858785473947343], [136.816562888995321, 34.858675756292961], [136.816559438412384, 34.858675756292961], [136.816533990363325, 34.858784766091972], [136.816536578300514, 34.858785473947343]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816448157113143, 34.858769193272387], [136.816450313727472, 34.858770608983377], [136.816484819556706, 34.858625498479832], [136.816481800296629, 34.858624790623089], [136.816448157113143, 34.858769193272387]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816280803841408, 34.858743710470335], [136.816307545859075, 34.858633992760261], [136.816304095276138, 34.858632930975261], [136.816278215904248, 34.858742294758876], [136.816280803841408, 34.858743710470335]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816384752651942, 34.858645318466145], [136.816304526599026, 34.858632930975261], [136.816304526599026, 34.85863505454526], [136.816384752651942, 34.858647442035824], [136.816384752651942, 34.858645318466145]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816383890006222, 34.858648503820639], [136.816392947786397, 34.858610633486968], [136.81639035984918, 34.858609571701663], [136.816380870746144, 34.858645672394431], [136.816383890006222, 34.858648503820639]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816482231619517, 34.858627268121658], [136.816481368973768, 34.858624790623089], [136.816389928526348, 34.858608155987895], [136.81638863455774, 34.858612049200687], [136.816482231619517, 34.858627268121658]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814779800270145, 34.860108135299129], [136.814803091704846, 34.860009037082172], [136.814800503767657, 34.860009744927012], [136.814777643655788, 34.86010707353315], [136.814779800270145, 34.860108135299129]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815135641633844, 34.860158392206365], [136.815132406712365, 34.860166001524149], [136.815284016699508, 34.860190422120667], [136.815285310668116, 34.860182812805142], [136.815135641633844, 34.860158392206365]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815284879345171, 34.860187679227948], [136.815474661405858, 34.859388874189783], [136.815470779500089, 34.859386750639239], [136.815280134793653, 34.860187325306299], [136.815284879345171, 34.860187679227948]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815497952840303, 34.8592910138455], [136.815551436875609, 34.85906927921706], [136.81554884893842, 34.859068040474448], [136.815494502257366, 34.859291367771], [136.815497952840303, 34.8592910138455]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815575375294856, 34.858976373468877], [136.815766882647068, 34.858170124130808], [136.815762569418411, 34.858170124130808], [136.815570199420506, 34.858975665615148], [136.815575375294856, 34.858976373468877]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815763432064131, 34.858177910597675], [136.8157651573556, 34.858170124130808], [136.815614625675607, 34.858146764725788], [136.815612900384167, 34.858152781542856], [136.815763432064131, 34.858177910597675]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815968741747952, 34.859664581368946], [136.816007560805815, 34.859503545837391], [136.816004541545766, 34.859502837988202], [136.815965291165043, 34.85966387352115], [136.815968741747952, 34.859664581368946]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816036890760671, 34.859674137313661], [136.815967016456483, 34.859663165673339], [136.815967016456483, 34.859665643140637], [136.816037753406391, 34.859674845161372], [136.816036890760671, 34.859674137313661]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815119898349138, 34.859555218811508], [136.815128093483594, 34.859518764593396], [136.815125289884975, 34.859518587631129], [136.815117957396268, 34.859554334000578], [136.815119898349138, 34.859555218811508]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815120760994887, 34.859549202097007], [136.815120976656317, 34.859547786399418], [136.815098763528766, 34.859544601079747], [136.815098547867308, 34.859546016777394], [136.815120760994887, 34.859549202097007]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815105017710323, 34.859527966630559], [136.815108468293232, 34.859513632687616], [136.815106958663193, 34.859513455725327], [136.815103508080256, 34.859527081819344], [136.815105017710323, 34.859527966630559]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815088196118552, 34.859524427385615], [136.815091215378601, 34.859510624328891], [136.815089058764272, 34.859511332178009], [136.815086902149943, 34.859523719536611], [136.815088196118552, 34.859524427385615]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815012930278527, 34.859908611535104], [136.815014655570025, 34.859908965457954], [136.815017890491504, 34.859895516388654], [136.815016165200035, 34.859895339427204], [136.815012930278527, 34.859908611535104]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814997186993963, 34.859904718383675], [136.814999774931152, 34.859892331082456], [136.814997833978254, 34.859892508043913], [136.814994599056774, 34.859905249267975], [136.814997186993963, 34.859904718383675]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816045301556301, 34.859369939195489], [136.816044870233441, 34.859365692093384], [136.815628212345587, 34.85929809235531], [136.815627781022727, 34.859303755162728], [136.816045301556301, 34.859369939195489]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81608897299509, 34.859171519659242], [136.81609026696367, 34.859165856842715], [136.815676197013033, 34.859099672645762], [136.815674040398704, 34.859104981540526], [136.81608897299509, 34.859171519659242]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815474230082742, 34.859071579739023], [136.815471642145525, 34.859067332621514], [136.815379339052356, 34.859054591267665], [136.815378476406607, 34.859058130532816], [136.815474230082742, 34.859071579739023]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815379986036675, 34.859058130532802], [136.815382573973864, 34.859056714826757], [136.815386455879633, 34.859043619544686], [136.815383005296724, 34.859042557764965], [136.815379986036675, 34.859058130532802]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815408884668642, 34.858947528424657], [136.81541233525158, 34.858932309562938], [136.815410825621541, 34.858932309562938], [136.815407159377173, 34.858947882351643], [136.815408884668642, 34.858947528424657]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815331030891457, 34.859262168911677], [136.815359498200536, 34.859139710552476], [136.815352597034689, 34.859138294847831], [136.815326717662799, 34.859262168911677], [136.815331030891457, 34.859262168911677]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815302455751578, 34.858785960597771], [136.815289947388464, 34.858783837031673], [136.815283908868366, 34.858818168010281], [136.815294691940011, 34.858819583720425], [136.815302455751578, 34.858785960597771]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815272047489572, 34.858816221408787], [136.81527269447389, 34.858814628734798], [136.815256304205008, 34.858812505169439], [136.815255872882148, 34.858813920879683], [136.815272047489572, 34.858816221408787]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815256735527868, 34.858817814082734], [136.815260186110805, 34.8588020643056], [136.815258245157906, 34.858801887341805], [136.81525457891351, 34.858817814082734], [136.815256735527868, 34.858817814082734]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815280889608289, 34.858863824650349], [136.815280889608289, 34.858861524122574], [136.815257166850728, 34.858857807885272], [136.815256951189298, 34.858859931449473], [136.815280889608289, 34.858863824650349]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815214034564207, 34.858853206829338], [136.815239698274667, 34.858857276994222], [136.815239482613237, 34.858854445575197], [136.815215328532787, 34.858850906301271], [136.815214034564207, 34.858853206829338]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815275066749649, 34.858888245633466], [136.815275066749649, 34.858885591179131], [136.815251990976321, 34.858882228870186], [136.815251343992031, 34.858884883324627], [136.815275066749649, 34.858888245633466]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81520821170551, 34.858877450851963], [136.815233228431708, 34.858881874942909], [136.815233659754568, 34.858879397452021], [136.81520778038265, 34.858874796397288], [136.81520821170551, 34.858877450851963]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815566748837, 34.858248873591201], [136.815610204615666, 34.858256129155677], [136.815610635938555, 34.858254005575894], [136.815565023545531, 34.858245953668728], [136.815566748837, 34.858248873591201]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816613084192625, 34.858835687421646], [136.816621926311342, 34.858798878957032], [136.81662041668136, 34.858798878957032], [136.816611574562586, 34.85883462563924], [136.816613084192625, 34.858835687421646]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816607045672527, 34.858859577522082], [136.816606614349666, 34.858861170195205], [136.816726522106194, 34.858879928342908], [136.816727384751914, 34.858878158706517], [136.816607045672527, 34.858859577522082]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814384870270317, 34.857385368365591], [136.814396515987653, 34.857386430166699], [136.814432747108356, 34.857235300337628], [136.814420670068131, 34.857234592468927], [136.814384870270317, 34.857385368365591]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814584572756957, 34.856599985724245], [136.814569045133823, 34.856598569975894], [136.81453281401312, 34.856749347038296], [136.814546616344813, 34.856750054911174], [136.814584572756957, 34.856599985724245]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814819643718494, 34.85554117612228], [136.814820075041354, 34.855539406414067], [136.814798724559552, 34.855535866997513], [136.814798077575233, 34.855537990647463], [136.814819643718494, 34.85554117612228]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814832152081578, 34.855496756434491], [136.814833446050187, 34.855494809754397], [136.814809076308308, 34.855491270335925], [136.814808429324017, 34.855493570957947], [136.814832152081578, 34.855496756434491]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814800449850992, 34.855536751851666], [136.814811017261206, 34.855491801248704], [136.814808644985447, 34.855491624277775], [136.814798077575233, 34.855536574880837], [136.814800449850992, 34.855536751851666]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814950765869554, 34.855062468661579], [136.814934806923532, 34.855061052886775], [136.814899438448577, 34.855209709108777], [136.814913240780243, 34.855212186710197], [136.814950765869554, 34.855062468661579]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815093965060782, 34.854398113655336], [136.815101728872378, 34.854363780832088], [136.815099140935189, 34.854363426885378], [136.815091808446482, 34.854396697869092], [136.815093965060782, 34.854398113655336]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815134078087311, 34.854418642553121], [136.815134832902316, 34.854415634008078], [136.815100003580937, 34.854363426885378], [136.815098278289469, 34.854364134778791], [136.815134078087311, 34.854418642553121]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815131921472982, 34.854426429375081], [136.815141518406733, 34.854428995486693], [136.815286766381519, 34.853797110205285], [136.815279002569952, 34.853795694408696], [136.815131921472982, 34.854426429375081]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815162221904188, 34.854460408225897], [136.815165672487126, 34.854462177957316], [136.815324291470802, 34.85377481140624], [136.815320840887864, 34.85377374955852], [136.815162221904188, 34.854460408225897]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815170093546527, 34.854471203587018], [136.815172465822286, 34.854470495694535], [136.815164270687859, 34.854457576655527], [136.815162329734932, 34.854459346387024], [136.815170093546527, 34.854471203587018]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813760206930624, 34.860048941824658], [136.81374467930749, 34.860046818291153], [136.813708448186816, 34.860196881189985], [136.813721387872761, 34.860196881189985], [136.813760206930624, 34.860048941824658]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816386316195718, 34.858873911578804], [136.816388257148645, 34.858873557651506], [136.816389335455796, 34.858870372305717], [136.816387394502897, 34.858869841414744], [136.816386316195718, 34.858873911578804]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816374454816952, 34.858933725270887], [136.81637553312413, 34.858927531547394], [136.816373807832633, 34.858927531547394], [136.816371866879763, 34.858932309562704], [136.816374454816952, 34.858933725270887]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816463738650043, 34.858884175469889], [136.816464169972903, 34.858882228869952], [136.816459856744274, 34.858881521015419], [136.816459425421414, 34.858883113688108], [136.816463738650043, 34.858884175469889]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816448642349769, 34.858944520045], [136.816450151979808, 34.858939388103387], [136.816448211026909, 34.858939565066898], [136.816447564042619, 34.858944343081504], [136.816448642349769, 34.858944520045]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816699402680541, 34.860384857589388], [136.816814565885551, 34.859904408700871], [136.816811762286903, 34.859904231739435], [136.816696814743352, 34.860384857589388], [136.816699402680541, 34.860384857589388]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816709970090756, 34.860386804153769], [136.816825133295765, 34.859906355276614], [136.816822329697118, 34.859906178315185], [136.816707382153567, 34.860386804153769], [136.816709970090756, 34.860386804153769]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816966011095616, 34.859858498666298], [136.816967544687998, 34.859849165583483], [136.816919810360105, 34.859841649750201], [136.816917960246883, 34.85985092712145], [136.816966011095616, 34.859858498666298]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816973295659551, 34.859822739320762], [136.816926799676736, 34.859815504425619], [136.816923305018406, 34.859827311992589], [136.816970611872819, 34.859834694059707], [136.816973295659551, 34.859822739320762]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816966011095616, 34.859858184068017], [136.816974254154786, 34.859824259879758], [136.816963390804972, 34.859821408209299], [136.816954962511346, 34.859856493543688], [136.816966011095616, 34.859858184068017]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816724859080523, 34.86014543521226], [136.816730634026669, 34.860145636001675], [136.816729997803776, 34.860143842282916], [136.816725886825168, 34.860143628107551], [136.816724859080523, 34.86014543521226]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816705668961646, 34.860224605632581], [136.816711443907792, 34.860224806421805], [136.816710807684927, 34.860223012704786], [136.816706696706319, 34.860222798529612], [136.816705668961646, 34.860224605632581]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816710220402285, 34.860226760770153], [136.816711460221228, 34.860224806421805], [136.816705701588461, 34.860224605632581], [136.816706305184539, 34.860226653682574], [136.816710220402285, 34.860226760770153]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816686013270839, 34.860304483818993], [136.816691788216986, 34.860304684608025], [136.816691151994121, 34.860302890892747], [136.816687041015513, 34.860302676717779], [136.816686013270839, 34.860304483818993]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816690564711479, 34.860306638954476], [136.816691804530421, 34.860304684608025], [136.816686045897654, 34.860304483818993], [136.816686649493732, 34.860306531866996], [136.816690564711479, 34.860306638954476]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816672021576892, 34.860385101378057], [136.816673261395835, 34.86038314703346], [136.816667502763096, 34.860382946244634], [136.816668106359174, 34.860384994290676], [136.816672021576892, 34.860385101378057]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816749269489662, 34.860232167080497], [136.81675504443578, 34.860232367869692], [136.816754408212915, 34.860230574152844], [136.816750297234336, 34.860230359977685], [136.816749269489662, 34.860232167080497]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816753820930302, 34.860234322217877], [136.816755060749244, 34.860232367869692], [136.816749302116477, 34.860232167080497], [136.816749905712555, 34.860234215130298], [136.816753820930302, 34.860234322217877]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816729829460257, 34.860311160456781], [136.816735604406404, 34.860311361245785], [136.816734968183539, 34.860309567530656], [136.816730857204931, 34.86030935335571], [136.816729829460257, 34.860311160456781]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816734380900925, 34.860313315592087], [136.816735620719868, 34.860311361245785], [136.816729862087101, 34.860311160456781], [136.81673046568315, 34.860313208504621], [136.816734380900925, 34.860313315592087]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816712364632878, 34.860389976796817], [136.816718139579024, 34.860390177585629], [136.816717503356159, 34.860388383872213], [136.816713392377551, 34.86038816969748], [136.816712364632878, 34.860389976796817]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816716916073517, 34.860392131930062], [136.81671815589246, 34.860390177585629], [136.816712397259693, 34.860389976796817], [136.816713000855771, 34.860392024842696], [136.816716916073517, 34.860392131930062]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81666694810724, 34.860383374594129], [136.816672723053358, 34.860383575382961], [136.816672086830465, 34.860381781669403], [136.816667975851885, 34.860381567494649], [136.81666694810724, 34.860383374594129]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816547145709109, 34.861018727298891], [136.81666230891409, 34.860538282112898], [136.816659505315471, 34.860538105152827], [136.816544557771891, 34.861018727298891], [136.816547145709109, 34.861018727298891]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816557713119323, 34.861020673848266], [136.816672876324304, 34.860540228673642], [136.816670072725685, 34.860540051713571], [136.816555125182134, 34.861020673848266], [136.816557713119323, 34.861020673848266]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816572602109062, 34.860779306766851], [136.816578377055208, 34.86077950755471], [136.816577740832344, 34.860777713849778], [136.816573629853735, 34.860777499676061], [136.816572602109062, 34.860779306766851]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816553411990213, 34.86085847657705], [136.81655918693636, 34.860858677364725], [136.816558550713467, 34.860856883661533], [136.816554439734858, 34.860856669488008], [136.816553411990213, 34.86085847657705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816557963430824, 34.860860631698017], [136.816559203249767, 34.860858677364725], [136.816553444617, 34.86085847657705], [136.816554048213106, 34.860860524611262], [136.816557963430824, 34.860860631698017]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816533756299407, 34.860938354147898], [136.816539531245553, 34.860938554935373], [136.81653889502266, 34.860936761233916], [136.816534784044052, 34.860936547060611], [136.816533756299407, 34.860938354147898]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816538307740018, 34.860940509266776], [136.816539547558961, 34.860938554935373], [136.816533788926193, 34.860938354147898], [136.8165343925223, 34.860940402180113], [136.816538307740018, 34.860940509266776]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816519117621169, 34.861018971085677], [136.816520357440112, 34.861017016756151], [136.816514598807345, 34.86101681596886], [136.816515202403451, 34.861018863999128], [136.816519117621169, 34.861018971085677]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81659701251823, 34.860866037966694], [136.816602787464348, 34.860866238754348], [136.816602151241455, 34.860864445051313], [136.816598040262875, 34.860864230877816], [136.81659701251823, 34.860866037966694]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816601563958841, 34.860868193087455], [136.816602803777783, 34.860866238754348], [136.816597045145016, 34.860866037966694], [136.816597648741123, 34.860868086000714], [136.816601563958841, 34.860868193087455]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816577572488853, 34.860945030734221], [136.816583347434971, 34.86094523152169], [136.816582711212078, 34.860943437820367], [136.816578600233498, 34.860943223647077], [136.816577572488853, 34.860945030734221]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816582123929464, 34.860947185852922], [136.816583363748407, 34.86094523152169], [136.81657760511564, 34.860945030734221], [136.816578208711746, 34.860947078766273], [136.816582123929464, 34.860947185852922]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816560107661445, 34.861023846466878], [136.816565882607591, 34.861024047254134], [136.816565246384698, 34.861022253554545], [136.81656113540609, 34.86102203938146], [136.816560107661445, 34.861023846466878]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816564659102056, 34.861026001583504], [136.816565898920999, 34.861024047254134], [136.816560140288232, 34.861023846466878], [136.816560743884338, 34.861025894496962], [136.816564659102056, 34.861026001583504]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816514691135779, 34.861017244315065], [136.816520466081926, 34.861017445102348], [136.816519829859033, 34.86101565140261], [136.816515718880453, 34.861015437229504], [136.816514691135779, 34.861017244315065]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816526657872913, 34.861015365077144], [136.816641677236248, 34.860535337706423], [136.816639017479361, 34.860535450751733], [136.816524501258584, 34.861014303322875], [136.816526657872913, 34.861015365077144]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816537225283213, 34.86101731162659], [136.816652388488194, 34.860536866432334], [136.816649584889575, 34.860536689472248], [136.816534637345995, 34.86101731162659], [136.816537225283213, 34.86101731162659]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816649182131357, 34.860461201585039], [136.816654957077503, 34.86046140237368], [136.816654320854639, 34.86045960866182], [136.81665020987603, 34.860459394487265], [136.816649182131357, 34.860461201585039]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816653733571997, 34.860463356716416], [136.816654973390939, 34.86046140237368], [136.816649214758172, 34.860461201585039], [136.816649818354279, 34.860463249629149], [136.816653733571997, 34.860463356716416]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816629968050137, 34.860541472786274], [136.816635742996255, 34.860541673574723], [136.816635106773361, 34.860539879864611], [136.816630995794782, 34.860539665690268], [136.816629968050137, 34.860541472786274]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816634519490748, 34.860543627915547], [136.81663575930969, 34.860541673574723], [136.816630000676923, 34.860541472786274], [136.81663060427303, 34.860543520828379], [136.816634519490748, 34.860543627915547]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816610777931288, 34.860620642825403], [136.816616552877406, 34.86062084361366], [136.816615916654513, 34.860619049905274], [136.816611805675933, 34.860618835731138], [136.816610777931288, 34.860620642825403]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816615329371899, 34.860622797952608], [136.816616569190842, 34.86062084361366], [136.816610810558075, 34.860620642825403], [136.816611414154181, 34.86062269086554], [136.816615329371899, 34.860622797952608]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816591122240453, 34.860700520627219], [136.816596897186571, 34.860700721415277], [136.816596260963706, 34.860698927708633], [136.816592149985098, 34.860698713534703], [136.816591122240453, 34.860700520627219]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816595673681093, 34.860702675752322], [136.816596913500035, 34.860700721415277], [136.816591154867268, 34.860700520627219], [136.816591758463346, 34.86070256866536], [136.816595673681093, 34.860702675752322]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816577130546506, 34.860781137798106], [136.816578370365448, 34.860779183462924], [136.816572611732681, 34.860778982675058], [136.816573215328759, 34.860781030711252], [136.816577130546506, 34.860781137798106]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816673568578096, 34.860548149404821], [136.816679343524243, 34.860548350193248], [136.816678707301378, 34.860546556483285], [136.81667459632277, 34.860546342308957], [136.816673568578096, 34.860548149404821]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816678120018736, 34.860550304533916], [136.81667935983765, 34.860548350193248], [136.816673601204911, 34.860548149404821], [136.816674204800989, 34.860550197446756], [136.816678120018736, 34.860550304533916]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816654378459276, 34.86062820423691], [136.816660153405422, 34.860628405025146], [136.816659517182529, 34.860626611316924], [136.816655406203921, 34.860626397142802], [136.816654378459276, 34.86062820423691]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816658929899887, 34.86063035936391], [136.81666016971883, 34.860628405025146], [136.816654411086063, 34.86062820423691], [136.816655014682169, 34.860630252276856], [136.816658929899887, 34.86063035936391]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816634938429871, 34.860707197232848], [136.816640713376017, 34.860707398020885], [136.816640077153153, 34.86070560431439], [136.816635966174545, 34.860705390140481], [136.816634938429871, 34.860707197232848]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816639489870511, 34.86070935235778], [136.816640729689453, 34.860707398020885], [136.816634971056686, 34.860707197232848], [136.816635574652793, 34.860709245270826], [136.816639489870511, 34.86070935235778]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816617473602491, 34.860786013193398], [136.816623248548638, 34.86078621398125], [136.816622612325745, 34.86078442027646], [136.816618501347136, 34.860784206102757], [136.816617473602491, 34.860786013193398]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816622025043131, 34.860788168316262], [136.816623264862045, 34.86078621398125], [136.816617506229278, 34.860786013193398], [136.816618109825384, 34.860788061229414], [136.816622025043131, 34.860788168316262]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816691765407796, 34.860468867429461], [136.816697540353942, 34.860469068218073], [136.816696904131078, 34.860467274506384], [136.81669279315247, 34.86046706033185], [136.816691765407796, 34.860468867429461]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816696316848407, 34.860471022560631], [136.81669755666735, 34.860469068218073], [136.816691798034611, 34.860468867429461], [136.816692401630689, 34.860470915473364], [136.816696316848407, 34.860471022560631]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816394673076246, 34.861652592123477], [136.816509836281227, 34.861172150640044], [136.81650703268258, 34.861171973681337], [136.816392085139057, 34.861652592123477], [136.816394673076246, 34.861652592123477]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816405240486432, 34.861654538657852], [136.816520403691442, 34.861174097185788], [136.816517600092794, 34.861173920227081], [136.816402652549243, 34.861654538657852], [136.816405240486432, 34.861654538657852]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816420129476199, 34.861413173436532], [136.816425904422317, 34.861413374222849], [136.816425268199453, 34.861411580531751], [136.816421157220844, 34.861411366359675], [136.816420129476199, 34.861413173436532]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816400939357351, 34.861492342636616], [136.816406714303469, 34.861492543422749], [136.816406078080604, 34.86149074973337], [136.816401967101996, 34.861490535561501], [136.816400939357351, 34.861492342636616]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816405490797962, 34.861494497740978], [136.816406730616904, 34.861492543422749], [136.816400971984166, 34.861492342636616], [136.816401575580215, 34.861494390655047], [136.816405490797962, 34.861494497740978]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816381283666516, 34.861572219591885], [136.816387058612662, 34.861572420377804], [136.816386422389797, 34.861570626690181], [136.816382311411189, 34.861570412518525], [136.816381283666516, 34.861572219591885]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816385835107155, 34.861574374694143], [136.816387074926098, 34.861572420377804], [136.816381316293331, 34.861572219591885], [136.816381919889409, 34.861574267608319], [136.816385835107155, 34.861574374694143]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816444539885339, 34.861499903967989], [136.816450314831485, 34.8615001047541], [136.81644967860862, 34.861498311064892], [136.816445567630012, 34.861498096893037], [136.816444539885339, 34.861499903967989]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81644909132595, 34.861502059072151], [136.816450331144893, 34.8615001047541], [136.816444572512154, 34.861499903967989], [136.816445176108232, 34.861501951986227], [136.81644909132595, 34.861502059072151]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816425099855962, 34.861578896126758], [136.81643087480208, 34.861579096912678], [136.816430238579215, 34.861577303225182], [136.816426127600636, 34.86157708905354], [136.816425099855962, 34.861578896126758]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816429651296573, 34.861581051228853], [136.816430891115516, 34.861579096912678], [136.816425132482777, 34.861578896126758], [136.816425736078855, 34.861580944143036], [136.816429651296573, 34.861581051228853]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816407635028554, 34.861657711252015], [136.8164134099747, 34.861657912037728], [136.816412773751836, 34.861656118351959], [136.816408662773227, 34.861655904180523], [136.816407635028554, 34.861657711252015]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816412186469165, 34.861659866352028], [136.816413426288108, 34.861657912037728], [136.816407667655369, 34.861657711252015], [136.816408271251447, 34.861659759266324], [136.816412186469165, 34.861659866352028]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816374185240051, 34.861649229927643], [136.816489204603386, 34.861169206256257], [136.81648654484647, 34.8611693193007], [136.816372028625722, 34.86164816818156], [136.816374185240051, 34.861649229927643]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81638475265035, 34.86165117646209], [136.816499915855303, 34.861170734970379], [136.816497112256684, 34.861170558011672], [136.816382164713161, 34.86165117646209], [136.81638475265035, 34.86165117646209]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816496709498495, 34.861095070706213], [136.816502484444641, 34.861095271493305], [136.816501848221748, 34.861093477795258], [136.816497737243168, 34.861093263622358], [136.816496709498495, 34.861095070706213]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816501260939106, 34.861097225820963], [136.816502500758048, 34.861095271493305], [136.81649674212531, 34.861095070706213], [136.816497345721388, 34.86109711873452], [136.816501260939106, 34.861097225820963]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816477495417246, 34.861175341288835], [136.816483270363392, 34.861175542075728], [136.816482634140527, 34.861173748379443], [136.816478523161919, 34.861173534206749], [136.816477495417246, 34.861175341288835]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816482046857857, 34.861177496401496], [136.816483286676799, 34.861175542075728], [136.816477528044061, 34.861175341288835], [136.816478131640139, 34.861177389315152], [136.816482046857857, 34.861177496401496]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816458305298397, 34.861254510717842], [136.816464080244543, 34.861254711504543], [136.81646344402165, 34.861252917809985], [136.816459333043071, 34.86125270363749], [136.816458305298397, 34.861254510717842]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816462856739008, 34.861256665828435], [136.816464096557951, 34.861254711504543], [136.816458337925212, 34.861254510717842], [136.81645894152129, 34.861256558742184], [136.816462856739008, 34.861256665828435]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816438649607591, 34.86133438790408], [136.816444424553708, 34.861334588690582], [136.816443788330815, 34.861332794997764], [136.816439677352264, 34.861332580825483], [136.816438649607591, 34.86133438790408]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816443201048202, 34.861336543012563], [136.816444440867144, 34.861334588690582], [136.816438682234377, 34.86133438790408], [136.816439285830484, 34.861336435926432], [136.816443201048202, 34.861336543012563]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816424657913643, 34.86141500445369], [136.816425897732586, 34.861413050133564], [136.816420139099819, 34.861412849347246], [136.816420742695897, 34.861414897367652], [136.816424657913643, 34.86141500445369]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816521095945234, 34.861182017855917], [136.816526870891352, 34.86118221864281], [136.816526234668487, 34.86118042494666], [136.816522123689879, 34.861180210773988], [136.816521095945234, 34.861182017855917]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816525647385873, 34.861184172968414], [136.816526887204816, 34.86118221864281], [136.816521128572049, 34.861182017855917], [136.816521732168098, 34.861184065882078], [136.816525647385873, 34.861184172968414]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816501905826385, 34.861262072071071], [136.816507680772531, 34.861262272857758], [136.816507044549667, 34.861260479163363], [136.816502933571059, 34.861260264990896], [136.816501905826385, 34.861262072071071]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816506457267025, 34.861264227181465], [136.816507697085967, 34.861262272857758], [136.8165019384532, 34.861262072071071], [136.816502542049278, 34.861264120095228], [136.816506457267025, 34.861264227181465]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816482465797009, 34.861341064458252], [136.816488240743155, 34.861341265244747], [136.816487604520262, 34.861339471552071], [136.816483493541682, 34.861339257379811], [136.816482465797009, 34.861341064458252]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81648701723762, 34.861343219566578], [136.816488257056562, 34.861341265244747], [136.816482498423795, 34.861341064458252], [136.816483102019902, 34.861343112480448], [136.81648701723762, 34.861343219566578]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816465000969629, 34.861419879811407], [136.816470775915747, 34.861420080597711], [136.816470139692882, 34.861418286906755], [136.816466028714274, 34.861418072734693], [136.816465000969629, 34.861419879811407]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81646955241024, 34.861422034917659], [136.816470792229183, 34.861420080597711], [136.816465033596444, 34.861419879811407], [136.816465637192522, 34.861421927831636], [136.81646955241024, 34.861422034917659]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816539292774905, 34.861102736491539], [136.816545067721052, 34.861102937278616], [136.816544431498187, 34.861101143580747], [136.816540320519579, 34.861100929407861], [136.816539292774905, 34.861102736491539]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816543844215545, 34.861104891606104], [136.816545084034487, 34.861102937278616], [136.81653932540172, 34.861102736491539], [136.816539928997798, 34.861104784519668], [136.816543844215545, 34.861104891606104]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816364030751913, 34.861650788837622], [136.816369805698031, 34.861650989623357], [136.816369169475166, 34.861649195937446], [136.816365058496586, 34.861648981765988], [136.816364030751913, 34.861650788837622]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816368582192553, 34.861652943937827], [136.816369822011495, 34.861650989623357], [136.816364063378728, 34.861650788837622], [136.816364666974806, 34.861652836852102], [136.816368582192553, 34.861652943937827]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816350039057966, 34.861731405077116], [136.816351278876908, 34.861729450764521], [136.81634552024417, 34.861729249978971], [136.816346123840219, 34.86173129799149], [136.816350039057966, 34.861731405077116]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816390382113951, 34.861736280416082], [136.816396157060069, 34.861736481201611], [136.816395520837204, 34.861734687517554], [136.816391409858596, 34.861734473346324], [136.816390382113951, 34.861736280416082]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816394933554591, 34.861738435514049], [136.816396173373533, 34.861736481201611], [136.816390414740766, 34.861736280416082], [136.816391018336844, 34.861738328428437], [136.816394933554591, 34.861738435514049]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816345289080431, 34.86172967832146], [136.816351064026549, 34.861729879107003], [136.816350427803684, 34.861728085422804], [136.816346316825104, 34.861727871251553], [136.816345289080431, 34.86172967832146]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81700485624873, 34.858978227555859], [136.817010631194876, 34.858978428348124], [136.817009994972011, 34.858976634603927], [136.817005883993403, 34.858976420425506], [136.81700485624873, 34.858978227555859]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817009407689341, 34.858980382726088], [136.817010647508283, 34.858978428348124], [136.817004888875545, 34.858978227555859], [136.817005492471623, 34.858980275636888], [136.817009407689341, 34.858980382726088]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817048445672725, 34.858985509621483], [136.8170542206189, 34.858985710413734], [136.817053584396007, 34.858983916669686], [136.817049473417399, 34.858983702491301], [136.817048445672725, 34.858985509621483]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817052997113365, 34.858987664791528], [136.817054236932307, 34.858985710413734], [136.81704847829954, 34.858985509621483], [136.817049081895647, 34.858987557702335], [136.817052997113365, 34.858987664791528]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816984246933572, 34.859059160291274], [136.816990021879718, 34.859059361083347], [136.816989385656854, 34.859057567340912], [136.816985274678245, 34.859057353162704], [136.816984246933572, 34.859059160291274]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816988798374183, 34.859061315459385], [136.816990038193126, 34.859059361083347], [136.816984279560387, 34.859059160291274], [136.816984883156465, 34.859061208370292], [136.816988798374183, 34.859061315459385]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817028097372116, 34.859066013993385], [136.817033872318262, 34.859066214785429], [136.817033236095398, 34.859064421043151], [136.81702912511679, 34.859064206864964], [136.817028097372116, 34.859066013993385]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817032648812727, 34.859068169161311], [136.81703388863167, 34.859066214785429], [136.817028129998931, 34.859066013993385], [136.817028733595009, 34.859068062072218], [136.817032648812727, 34.859068169161311]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816966204437364, 34.859137174446673], [136.816971979383482, 34.859137375238554], [136.816971343160617, 34.859135581497803], [136.816967232182037, 34.859135367319816], [136.816966204437364, 34.859137174446673]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816970755878003, 34.859139329612738], [136.816971995696946, 34.859137375238554], [136.816966237064179, 34.859137174446673], [136.816966840660257, 34.859139222523737], [136.816970755878003, 34.859139329612738]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817008787713803, 34.85914484041448], [136.817014562659921, 34.859145041206332], [136.817013926437056, 34.859143247465767], [136.817009815458476, 34.859143033287786], [136.817008787713803, 34.85914484041448]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817013339154414, 34.859146995580346], [136.817014578973357, 34.859145041206332], [136.817008820340618, 34.85914484041448], [136.817009423936696, 34.859146888491352], [136.817013339154414, 34.859146995580346]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817052224784334, 34.858911472105], [136.817150566397402, 34.858499765330016], [136.817148409783044, 34.85849923443665], [136.817049636847145, 34.858911472105], [136.817052224784334, 34.858911472105]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817062792194577, 34.858913418704248], [136.817161565130448, 34.858501004081198], [136.817158761531829, 34.858500827116735], [136.817060204257388, 34.858913418704248], [136.817062792194577, 34.858913418704248]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817080269121504, 34.858661781521668], [136.817086044067651, 34.858661982314693], [136.817085407844786, 34.858660188563597], [136.817081296866178, 34.858659974384359], [136.817080269121504, 34.858661781521668]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817061079002656, 34.85874095337001], [136.817066853948802, 34.85874115416285], [136.817066217725909, 34.858739360413473], [136.817062106747301, 34.858739146234441], [136.817061079002656, 34.85874095337001]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817065630443267, 34.858743108546449], [136.817066870262209, 34.85874115416285], [136.817061111629442, 34.85874095337001], [136.817061715225549, 34.858743001456943], [136.817065630443267, 34.858743108546449]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817041423311849, 34.858820832997218], [136.817047198257995, 34.858821033789859], [136.817046562035102, 34.858819240042216], [136.817042451056494, 34.858819025863397], [136.817041423311849, 34.858820832997218]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81704597475246, 34.858822988171568], [136.817047214571403, 34.858821033789859], [136.817041455938636, 34.858820832997218], [136.817042059534742, 34.858822881082162], [136.81704597475246, 34.858822988171568]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817027431617902, 34.858901452010393], [136.817028671436844, 34.858899497630546], [136.817022912804077, 34.858899296838096], [136.817023516400184, 34.858901344921087], [136.817027431617902, 34.858901452010393]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817104679530672, 34.858748514954307], [136.81711045447679, 34.858748715747133], [136.817109818253897, 34.85874692199792], [136.817105707275317, 34.858746707818902], [136.817104679530672, 34.858748514954307]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817109230971283, 34.858750670130554], [136.817110470790226, 34.858748715747133], [136.817104712157459, 34.858748514954307], [136.817105315753565, 34.858750563041049], [136.817109230971283, 34.858750670130554]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817085239501267, 34.858827509755422], [136.817091014447413, 34.858827710548049], [136.81709037822452, 34.858825916800569], [136.817086267245912, 34.858825702621758], [136.817085239501267, 34.858827509755422]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817089790941907, 34.858829664929608], [136.817091030760849, 34.858827710548049], [136.817085272128082, 34.858827509755422], [136.81708587572416, 34.858829557840203], [136.817089790941907, 34.858829664929608]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817067774673887, 34.858906327517104], [136.817073549620005, 34.858906528309532], [136.817072913397141, 34.858904734563765], [136.817068802418561, 34.85890452038516], [136.817067774673887, 34.858906327517104]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817072326114499, 34.858908482689209], [136.817073565933441, 34.858906528309532], [136.817067807300674, 34.858906327517104], [136.817068410896781, 34.85890837559991], [136.817072326114499, 34.858908482689209]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817022358148222, 34.858899725195322], [136.81702813309434, 34.858899925987778], [136.817027496871475, 34.858898132241869], [136.817023385892867, 34.858897918063242], [136.817022358148222, 34.858899725195322]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817031736948167, 34.85890810979712], [136.817130509884151, 34.858496049076365], [136.817127706285532, 34.858495518182977], [136.817029580333838, 34.858907048015652], [136.817031736948167, 34.85890810979712]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817042304358438, 34.858910056396432], [136.817141077294366, 34.858497818720984], [136.817138273695718, 34.858497641756522], [136.817039716421249, 34.858910056396432], [136.817042304358438, 34.858910056396432]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817137635062579, 34.858423941418351], [136.817143410008697, 34.858424142211966], [136.817142773785804, 34.858422348455683], [136.817138662807196, 34.85842213427582], [136.817137635062579, 34.858423941418351]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81714218650319, 34.858426096603104], [136.817143426322133, 34.858424142211966], [136.817137667689366, 34.858423941418351], [136.817138271285444, 34.858425989513179], [136.81714218650319, 34.858426096603104]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817118444943702, 34.858503113495615], [136.817124219889848, 34.858503314289031], [136.817123583666955, 34.858501520534475], [136.817119472688347, 34.858501306354817], [136.817118444943702, 34.858503113495615]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817122996384342, 34.858505268678293], [136.817124236203284, 34.858503314289031], [136.817118477570517, 34.858503113495615], [136.817119081166595, 34.858505161588475], [136.817122996384342, 34.858505268678293]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817098789252896, 34.858582993353778], [136.817104564199042, 34.858583194147009], [136.817103927976149, 34.858581400394186], [136.817099816997541, 34.858581186214742], [136.817098789252896, 34.858582993353778]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817103340693507, 34.85858514853436], [136.817104580512449, 34.858583194147009], [136.817098821879682, 34.858582993353778], [136.817099425475789, 34.858585041444648], [136.817103340693507, 34.85858514853436]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817084797558948, 34.858663612600061], [136.817086037377891, 34.858661658214565], [136.817080278745124, 34.85866145742154], [136.817080882341202, 34.858663505510449], [136.817084797558948, 34.858663612600061]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817181235590539, 34.858430618208779], [136.817187010536685, 34.858430819002372], [136.81718637431382, 34.858429025246238], [136.817182263335212, 34.858428811066389], [136.817181235590539, 34.858430618208779]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81718578703115, 34.858432773393361], [136.817187026850092, 34.858430819002372], [136.817181268217354, 34.858430618208779], [136.817181871813432, 34.858432666303443], [136.81718578703115, 34.858432773393361]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817162045471719, 34.858510675101776], [136.817167820417865, 34.858510875895178], [136.817167184194972, 34.858509082140792], [136.817163073216364, 34.858508867961149], [136.817162045471719, 34.858510675101776]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81716659691233, 34.858512830284262], [136.817167836731272, 34.858510875895178], [136.817162078098505, 34.858510675101776], [136.817162681694612, 34.858512723194444], [136.81716659691233, 34.858512830284262]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817142605442314, 34.858589670131295], [136.81714838038846, 34.858589870924504], [136.817147744165567, 34.858588077171831], [136.817143633186987, 34.858587862992408], [136.817142605442314, 34.858589670131295]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817147156882953, 34.858591825311713], [136.817148396701896, 34.858589870924504], [136.817142638069129, 34.858589670131295], [136.817143241665235, 34.858591718221994], [136.817147156882953, 34.858591825311713]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817125140614934, 34.85866848812087], [136.81713091556108, 34.85866868891388], [136.817130279338187, 34.858666895162926], [136.817126168359579, 34.85866668098371], [136.817125140614934, 34.85866848812087]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817129692055545, 34.858670643299206], [136.817130931874487, 34.85866868891388], [136.81712517324172, 34.85866848812087], [136.817125776837827, 34.858670536209601], [136.817129692055545, 34.858670643299206]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817155750622902, 34.858343245512771], [136.817161525569048, 34.858343446306584], [136.817160889346184, 34.858341652548539], [136.817156778367575, 34.85834143836847], [136.817155750622902, 34.858343245512771]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817160302063513, 34.858345400699648], [136.817161541882456, 34.858343446306584], [136.817155783249717, 34.858343245512771], [136.817156386845795, 34.858345293609609], [136.817160302063513, 34.858345400699648]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81719935115089, 34.858349922309742], [136.817205126097036, 34.858350123103541], [136.817204489874143, 34.858348329345645], [136.817200378895535, 34.858348115165597], [136.81719935115089, 34.858349922309742]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81720390259153, 34.858352077496441], [136.817205142410472, 34.858350123103541], [136.817199383777705, 34.858349922309742], [136.817199987373783, 34.858351970406417], [136.81720390259153, 34.858352077496441]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817205775724346, 34.858276878296671], [136.817320938929328, 34.857796417095571], [136.817318135330709, 34.857796240129602], [136.817203187787158, 34.858276878296671], [136.817205775724346, 34.858276878296671]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817216343134561, 34.858278824910933], [136.817331506339571, 34.857798363721201], [136.817328702740923, 34.857798186755232], [136.817213755197372, 34.858278824910933], [136.817216343134561, 34.858278824910933]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817233820061517, 34.858027185787051], [136.817239595007635, 34.858027386581639], [136.81723895878477, 34.858025592816695], [136.817234847806191, 34.858025378635809], [136.817233820061517, 34.858027185787051]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817214629942669, 34.858106358246175], [136.817220404888786, 34.858106559040571], [136.817219768665893, 34.858104765277346], [136.817215657687314, 34.858104551096673], [136.817214629942669, 34.858106358246175]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81721918138328, 34.858108513439255], [136.817220421202222, 34.858106559040571], [136.817214662569455, 34.858106358246175], [136.817215266165562, 34.858108406348912], [136.81721918138328, 34.858108513439255]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817194974251862, 34.858186238489623], [136.81720074919798, 34.85818643928382], [136.817200112975087, 34.858184645522343], [136.817196001996479, 34.858184431341876], [136.817194974251862, 34.858186238489623]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817199525692473, 34.858188393680607], [136.817200765511416, 34.85818643928382], [136.817195006878649, 34.858186238489623], [136.817195610474727, 34.858188286590369], [136.817199525692473, 34.858188393680607]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817180982557915, 34.858266858124757], [136.817182222376829, 34.85826490372984], [136.817176463744062, 34.858264702935827], [136.817177067340168, 34.858266751034627], [136.817180982557915, 34.858266858124757]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817258230470657, 34.858113919888815], [136.817264005416803, 34.85811412068319], [136.81726336919391, 34.858112326920143], [136.817259258215302, 34.858112112739462], [136.817258230470657, 34.858113919888815]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817262781911268, 34.858116075081689], [136.81726402173021, 34.85811412068319], [136.817258263097443, 34.858113919888815], [136.81725886669355, 34.858115967991353], [136.817262781911268, 34.858116075081689]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81723879044128, 34.858192915299341], [136.817244565387426, 34.858193116093524], [136.817243929164533, 34.858191322332196], [136.817239818185925, 34.858191108151736], [136.81723879044128, 34.858192915299341]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817243341881891, 34.858195070490154], [136.817244581700834, 34.858193116093524], [136.817238823068067, 34.858192915299341], [136.817239426664173, 34.858194963399917], [136.817243341881891, 34.858195070490154]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817221325613872, 34.858271733669071], [136.817227100560018, 34.858271934463062], [136.817226464337125, 34.858270140703453], [136.817222353358545, 34.858269926523192], [136.817221325613872, 34.858271733669071]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817225877054511, 34.858273888857816], [136.817227116873454, 34.858271934463062], [136.817221358240687, 34.858271733669071], [136.817221961836765, 34.858273781767693], [136.817225877054511, 34.858273888857816]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817175909088206, 34.85826513129637], [136.817181684034352, 34.858265332090369], [136.817181047811488, 34.858263538330618], [136.81717693683288, 34.858263324150343], [136.817175909088206, 34.85826513129637]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817185287888151, 34.858273515962843], [136.817300307251514, 34.857793472590949], [136.817297647494598, 34.857793585640025], [136.817183131273822, 34.858272454173182], [136.817185287888151, 34.858273515962843]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817195855298451, 34.858275462577183], [136.817311018503432, 34.857795001367805], [136.817308214904813, 34.857794824401836], [136.817193267361262, 34.858275462577183], [136.817195855298451, 34.858275462577183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817292911294032, 34.857789697780859], [136.81729868624015, 34.857789898576023], [136.817298050017286, 34.857788104805906], [136.817293939038677, 34.857787890624401], [136.817292911294032, 34.857789697780859]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817297462734643, 34.857791852982231], [136.817298702553586, 34.857789898576023], [136.817292943920847, 34.857789697780859], [136.817293547516925, 34.857791745891475], [136.817297462734643, 34.857791852982231]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817271995883715, 34.857868516536939], [136.817277770829861, 34.857868717331911], [136.817277134606968, 34.857866923563506], [136.81727302362836, 34.857866709382208], [136.817271995883715, 34.857868516536939]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817276547324326, 34.857870671736244], [136.817277787143269, 34.857868717331911], [136.817272028510502, 34.857868516536939], [136.817272632106608, 34.857870564645594], [136.817276547324326, 34.857870671736244]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81725234019288, 34.857948397011349], [136.817258115139026, 34.857948597806121], [136.817257478916162, 34.857946804039464], [136.817253367937553, 34.857946589858372], [136.81725234019288, 34.857948397011349]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817256891633519, 34.857950552208557], [136.817258131452462, 34.857948597806121], [136.817252372819695, 34.857948397011349], [136.817252976415801, 34.857950445118007], [136.817256891633519, 34.857950552208557]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817238348498961, 34.858029016879577], [136.817239588317904, 34.858027062479003], [136.817233829685136, 34.858026861684415], [136.817234433281214, 34.858028909789134], [136.817238348498961, 34.858029016879577]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817336511821992, 34.857796374622765], [136.817342286768138, 34.857796575417915], [136.817341650545274, 34.857794781647939], [136.817337539566665, 34.857794567466449], [136.817336511821992, 34.857796374622765]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817341063262631, 34.85779852982396], [136.817342303081574, 34.857796575417915], [136.817336544448807, 34.857796374622765], [136.817337148044885, 34.857798422733218], [136.817341063262631, 34.85779852982396]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817315596411731, 34.857876078201436], [136.817321371357849, 34.857876278996386], [136.817320735134956, 34.857874485228152], [136.817316624156348, 34.857874271046867], [136.817315596411731, 34.857876078201436]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817320147852342, 34.857878233400541], [136.817321387671285, 34.857876278996386], [136.817315629038518, 34.857876078201436], [136.817316232634596, 34.857878126309906], [136.817320147852342, 34.857878233400541]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817296156382298, 34.857955073840365], [136.817301931328444, 34.857955274635131], [136.81730129510558, 34.857953480868623], [136.817297184126971, 34.857953266687545], [136.817296156382298, 34.857955073840365]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817300707822966, 34.857957229037403], [136.81730194764188, 34.857955274635131], [136.817296189009113, 34.857955073840365], [136.817296792605219, 34.85795712194686], [136.817300707822966, 34.857957229037403]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817278691554947, 34.858033892437994], [136.817284466501064, 34.858034093232554], [136.817283830278171, 34.858032299467766], [136.817279719299592, 34.858032085286894], [136.817278691554947, 34.858033892437994]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817283242995558, 34.858036047632964], [136.8172844828145, 34.858034093232554], [136.817278724181733, 34.858033892437994], [136.81727932777784, 34.858035940542536], [136.817283242995558, 34.858036047632964]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817311889500104, 34.857708293388463], [136.817317664446222, 34.857708494183825], [136.817317028223357, 34.857706700411931], [136.817312917244777, 34.857706486230221], [136.817311889500104, 34.857708293388463]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817316440940715, 34.857710448591966], [136.817317680759658, 34.857708494183825], [136.817311922126919, 34.857708293388463], [136.817312525722997, 34.857710341501111], [136.817316440940715, 34.857710448591966]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817355490028064, 34.85771497023697], [136.81736126497421, 34.857715171032325], [136.817360628751345, 34.857713377260581], [136.817356517772737, 34.857713163078877], [136.817355490028064, 34.85771497023697]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817360041468703, 34.85771712544031], [136.817361281287646, 34.857715171032325], [136.817355522654879, 34.85771497023697], [136.817356126250957, 34.857717018349454], [136.817360041468703, 34.85771712544031]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817309616703909, 34.857569900344068], [136.817315223901147, 34.857571493042158], [136.817721209218121, 34.855883057862343], [136.817715203552524, 34.855881688917272], [136.817309616703909, 34.857569900344068]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817326985800435, 34.857628304646155], [136.817332760746581, 34.85762850544171], [136.817332124523688, 34.857626711668075], [136.817328013545108, 34.857626497486145], [136.817326985800435, 34.857628304646155]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817331537241046, 34.857630459851755], [136.817332777059988, 34.85762850544171], [136.81732701842725, 34.857628304646155], [136.817327622023328, 34.857630352760793], [136.817331537241046, 34.857630459851755]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817370586328394, 34.857634981501164], [136.81737636127454, 34.857635182296704], [136.817375725051676, 34.857633388523212], [136.817371614073068, 34.857633174341302], [136.817370586328394, 34.857634981501164]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817375137769034, 34.857637136706579], [136.817376377588005, 34.857635182296704], [136.817370618955238, 34.857634981501164], [136.817371222551287, 34.857637029615638], [136.817375137769034, 34.857637136706579]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817355876081479, 34.857640155997046], [136.817471039286488, 34.857159691077015], [136.817468235687841, 34.857159514109675], [136.81735328814429, 34.857640155997046], [136.817355876081479, 34.857640155997046]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817366443491693, 34.857642102626379], [136.817481606696674, 34.857161637717716], [136.817478803098027, 34.857161460750376], [136.817363855554504, 34.857642102626379], [136.817366443491693, 34.857642102626379]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817383920418621, 34.857390461554722], [136.817389695364767, 34.857390662350852], [136.817389059141902, 34.857388868572031], [136.817384948163294, 34.857388654389482], [136.817383920418621, 34.857390461554722]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817364730299772, 34.857469634626675], [136.817370505245918, 34.857469835422613], [136.817369869023054, 34.857468041645518], [136.817365758044446, 34.857467827463175], [136.817364730299772, 34.857469634626675]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817369281740383, 34.857471789836424], [136.817370521559326, 34.857469835422613], [136.817364762926587, 34.857469634626675], [136.817365366522665, 34.857471682745256], [136.817369281740383, 34.857471789836424]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817345074608966, 34.857549515488415], [136.817350849555112, 34.857549716284161], [136.817350213332247, 34.857547922508807], [136.817346102353639, 34.85754770832667], [136.817345074608966, 34.857549515488415]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817349626049577, 34.857551670696076], [136.817350865868519, 34.857549716284161], [136.817345107235781, 34.857549515488415], [136.817345710831859, 34.857551563605014], [136.817349626049577, 34.857551670696076]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817331082915018, 34.85763013574757], [136.817332322733961, 34.857628181337525], [136.817326564101222, 34.85762798054197], [136.8173271676973, 34.857630028656615], [136.817331082915018, 34.85763013574757]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817408330827789, 34.857477196327835], [136.817414105773906, 34.857477397123759], [136.817413469551042, 34.857475603346828], [136.817409358572462, 34.857475389164506], [136.817408330827789, 34.857477196327835]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8174128822684, 34.857479351537393], [136.817414122087342, 34.857477397123759], [136.817408363454604, 34.857477196327835], [136.817408967050682, 34.857479244446225], [136.8174128822684, 34.857479351537393]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817388890798384, 34.857556192349819], [136.81739466574453, 34.857556393145551], [136.817394029521665, 34.857554599370339], [136.817389918543057, 34.857554385188216], [136.817388890798384, 34.857556192349819]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817393442239023, 34.857558347557294], [136.817394682057966, 34.857556393145551], [136.817388923425227, 34.857556192349819], [136.817389527021277, 34.857558240466247], [136.817393442239023, 34.857558347557294]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817371425971004, 34.857635011329627], [136.81737720091715, 34.857635212125174], [136.817376564694285, 34.857633418351675], [136.817372453715677, 34.857633204169765], [136.817371425971004, 34.857635011329627]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817375977411615, 34.857637166535056], [136.817377217230586, 34.857635212125174], [136.817371458597819, 34.857635011329627], [136.817372062193897, 34.857637059444102], [136.817375977411615, 34.857637166535056]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817326009445367, 34.857628408905818], [136.817331784391484, 34.857628609701372], [136.817331148168591, 34.857626815927738], [136.817327037190012, 34.857626601745807], [136.817326009445367, 34.857628408905818]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817335388245311, 34.857636793637191], [136.817450407608646, 34.857156746549599], [136.81744774785173, 34.857156859599556], [136.817333231630982, 34.857635731839316], [136.817335388245311, 34.857636793637191]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817345955655583, 34.857638740266601], [136.817461118860592, 34.857158275338293], [136.817458315261945, 34.857158098370959], [136.817343367718394, 34.857638740266601], [136.817345955655583, 34.857638740266601]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817443011651136, 34.857152971710299], [136.817448786597282, 34.857153172507019], [136.817448150374418, 34.85715137872301], [136.817444039395809, 34.857151164539843], [136.817443011651136, 34.857152971710299]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817447563091775, 34.857155126928347], [136.817448802910718, 34.857153172507019], [136.817443044277951, 34.857152971710299], [136.817443647874029, 34.857155019836767], [136.817447563091775, 34.857155126928347]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817422096240847, 34.857231791076458], [136.817427871186965, 34.857231991872979], [136.8174272349641, 34.857230198090697], [136.817423123985492, 34.857229983907729], [136.817422096240847, 34.857231791076458]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817426647681458, 34.857233946292439], [136.817427887500401, 34.857231991872979], [136.817422128867662, 34.857231791076458], [136.81742273246374, 34.857233839200966], [136.817426647681458, 34.857233946292439]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817402440550012, 34.85731167216916], [136.817408215496158, 34.857311872965489], [136.817407579273294, 34.857310079184955], [136.817403468294685, 34.857309865002193], [136.817402440550012, 34.85731167216916]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817406991990651, 34.857313827383059], [136.817408231809594, 34.857311872965489], [136.817402473176827, 34.85731167216916], [136.817403076772905, 34.857313720291678], [136.817406991990651, 34.857313827383059]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817388448856065, 34.857392292661416], [136.817389688675007, 34.857390338245715], [136.81738393004224, 34.857390137449585], [136.817384533638347, 34.857392185570149], [136.817388448856065, 34.857392292661416]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817486612179152, 34.85715964860389], [136.81749238712527, 34.857159849400581], [136.817491750902377, 34.857158055616729], [136.817487639923797, 34.857157841433583], [136.817486612179152, 34.85715964860389]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817491163619763, 34.857161803821768], [136.817492403438706, 34.857159849400581], [136.817486644805939, 34.85715964860389], [136.817487248402045, 34.857161696730188], [136.817491163619763, 34.857161803821768]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817465696768835, 34.857239352799482], [136.817471471714981, 34.857239553595988], [136.817470835492117, 34.85723775981387], [136.817466724513508, 34.857237545630937], [136.817465696768835, 34.857239352799482]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817470248209446, 34.857241508015271], [136.817471488028389, 34.857239553595988], [136.81746572939565, 34.857239352799482], [136.817466332991728, 34.857241400923805], [136.817470248209446, 34.857241508015271]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817446256739458, 34.857318349049862], [136.817452031685605, 34.857318549846177], [136.817451395462712, 34.857316756065785], [136.817447284484132, 34.857316541883044], [136.817446256739458, 34.857318349049862]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81745080818007, 34.857320504263583], [136.817452047999012, 34.857318549846177], [136.817446289366274, 34.857318349049862], [136.817446892962352, 34.857320397172217], [136.81745080818007, 34.857320504263583]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81742879191205, 34.85739716825757], [136.817434566858196, 34.857397369053693], [136.817433930635332, 34.857395575275014], [136.817429819656724, 34.857395361092486], [136.81742879191205, 34.85739716825757]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817433343352661, 34.857399323469217], [136.817434583171604, 34.857397369053693], [136.817428824538865, 34.85739716825757], [136.817429428134943, 34.857399216377956], [136.817433343352661, 34.857399323469217]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817468028377306, 34.857071920622865], [136.817473803323452, 34.857072121419783], [136.817473167100587, 34.857070327634013], [136.817469056121979, 34.857070113450639], [136.817468028377306, 34.857071920622865]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817472579817974, 34.857074075843038], [136.817473819636888, 34.857072121419783], [136.817468061004121, 34.857071920622865], [136.817468664600199, 34.857073968751358], [136.817472579817974, 34.857074075843038]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817511628905322, 34.857078597523035], [136.817517403851468, 34.857078798319932], [136.817516767628575, 34.857077004534311], [136.817512656649967, 34.857076790350945], [136.817511628905322, 34.857078597523035]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817516180345933, 34.85708075274303], [136.817517420164876, 34.857078798319932], [136.817511661532109, 34.857078597523035], [136.817512265128215, 34.857080645651351], [136.817516180345933, 34.85708075274303]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817483987323385, 34.856992993067962], [136.817489762269531, 34.856993193865065], [136.817489126046638, 34.856991400077575], [136.81748501506803, 34.856991185893989], [136.817483987323385, 34.856992993067962]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817488538763996, 34.856995148290203], [136.817489778582939, 34.856993193865065], [136.817484019950172, 34.856992993067962], [136.817484623546278, 34.856995041198417], [136.817488538763996, 34.856995148290203]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817527587851373, 34.856999669974535], [136.817533362797519, 34.856999870771624], [136.817532726574626, 34.856998076984276], [136.817528615596046, 34.856997862800711], [136.817527587851373, 34.856999669974535]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817532139291984, 34.857001825196598], [136.817533379110927, 34.856999870771624], [136.817527620478188, 34.856999669974535], [136.817528224074266, 34.857001718104826], [136.817532139291984, 34.857001825196598]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817512877604457, 34.857004844510378], [136.817628040809439, 34.856524375879715], [136.817625237210791, 34.856524198911018], [136.81751028966724, 34.857004844510378], [136.817512877604457, 34.857004844510378]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817523445014643, 34.857006791154738], [136.817638608219653, 34.856526322535444], [136.817635804621005, 34.856526145566747], [136.817520857077454, 34.857006791154738], [136.817523445014643, 34.857006791154738]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8175409219416, 34.856755148139655], [136.817546696887717, 34.856755348937341], [136.817546060664853, 34.856753555144671], [136.817541949686273, 34.856753340960466], [136.8175409219416, 34.856755148139655]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817521731822751, 34.856834321823058], [136.817527506768869, 34.856834522620559], [136.817526870546004, 34.856832728829602], [136.817522759567396, 34.856832514645603], [136.817521731822751, 34.856834321823058]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817526283263362, 34.856836477049463], [136.817527523082305, 34.856834522620559], [136.817521764449566, 34.856834321823058], [136.817522368045644, 34.85683636995747], [136.817526283263362, 34.856836477049463]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817502076131916, 34.856914203301727], [136.817507851078062, 34.85691440409903], [136.817507214855198, 34.85691261030982], [136.817503103876589, 34.856912396126027], [136.817502076131916, 34.856914203301727]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817506627572556, 34.856916358526036], [136.817507867391498, 34.85691440409903], [136.81750210875876, 34.856914203301727], [136.817502712354809, 34.856916251434143], [136.817506627572556, 34.856916358526036]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817565332350739, 34.856841883582625], [136.817571107296885, 34.856842084380098], [136.817570471074021, 34.856840290589311], [136.817566360095412, 34.856840076405334], [136.817565332350739, 34.856841883582625]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81756988379135, 34.856844038808816], [136.817571123610293, 34.856842084380098], [136.817565364977554, 34.856841883582625], [136.817565968573632, 34.856843931716838], [136.81756988379135, 34.856844038808816]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817545892321363, 34.856920880214695], [136.817551667267509, 34.856921081011976], [136.817551031044616, 34.856919287222908], [136.817546920066036, 34.856919073039137], [136.817545892321363, 34.856920880214695]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817550443761974, 34.856923035438825], [136.817551683580916, 34.856921081011976], [136.817545924948178, 34.856920880214695], [136.817546528544256, 34.85692292834694], [136.817550443761974, 34.856923035438825]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817492389768262, 34.857001482124559], [136.817607409131597, 34.856521431329568], [136.817604749374681, 34.856521544380385], [136.817490233153933, 34.857000420318485], [136.817492389768262, 34.857001482124559]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817502957178561, 34.857003428768998], [136.817618120383543, 34.856522960130064], [136.817615316784895, 34.85652278316136], [136.817500369241344, 34.857003428768998], [136.817502957178561, 34.857003428768998]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817600013174115, 34.856517656461115], [136.817605788120233, 34.856517857259377], [136.817605151897368, 34.85651606346152], [136.81760104091876, 34.856515849276697], [136.817600013174115, 34.856517656461115]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817604564614754, 34.856519811695797], [136.817605804433697, 34.856517857259377], [136.81760004580093, 34.856517656461115], [136.817600649396979, 34.8565197046034], [136.817604564614754, 34.856519811695797]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817579097763797, 34.856596476435982], [136.817584872709944, 34.856596677234059], [136.81758423648705, 34.856594883437921], [136.817580125508471, 34.856594669253305], [136.817579097763797, 34.856596476435982]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817583649204408, 34.856598631668611], [136.817584889023351, 34.856596677234059], [136.817579130390584, 34.856596476435982], [136.81757973398669, 34.856598524576313], [136.817583649204408, 34.856598631668611]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817559442072991, 34.856676358145606], [136.817565217019109, 34.856676558943491], [136.817564580796244, 34.856674765149094], [136.817560469817636, 34.856674550964684], [136.817559442072991, 34.856676358145606]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817563993513602, 34.856678513376146], [136.817565233332544, 34.856676558943491], [136.817559474699777, 34.856676358145606], [136.817560078295884, 34.85667840628394], [136.817563993513602, 34.856678513376146]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817545450379043, 34.856756979260496], [136.817546690197986, 34.856755024829702], [136.817540931565219, 34.856754824032009], [136.817541535161325, 34.856756872168397], [136.817545450379043, 34.856756979260496]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817643613702103, 34.856524333406256], [136.817649388648249, 34.85652453420451], [136.817648752425356, 34.856522740406803], [136.817644641446748, 34.856522526221994], [136.817643613702103, 34.856524333406256]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817648165142714, 34.856526488640782], [136.817649404961657, 34.85652453420451], [136.817643646328889, 34.856524333406256], [136.817644249924996, 34.856526381548385], [136.817648165142714, 34.856526488640782]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817622698291785, 34.856604038217412], [136.817628473237932, 34.856604239015468], [136.817627837015038, 34.856602445219494], [136.817623726036459, 34.856602231034891], [136.817622698291785, 34.856604038217412]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817627249732425, 34.856606193449835], [136.817628489551367, 34.856604239015468], [136.8176227309186, 34.856604038217412], [136.817623334514678, 34.856606086357552], [136.817627249732425, 34.856606193449835]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817603258262409, 34.856683035077879], [136.817609033208555, 34.856683235875742], [136.817608396985662, 34.856681442081488], [136.817604286007054, 34.856681227897099], [136.817603258262409, 34.856683035077879]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81760780970302, 34.856685190308241], [136.817609049521963, 34.856683235875742], [136.817603290889195, 34.856683035077879], [136.817603894485302, 34.856685083216057], [136.81760780970302, 34.856685190308241]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817585793435029, 34.856761854894309], [136.817591568381147, 34.856762055691974], [136.817590932158254, 34.856760261899446], [136.817586821179702, 34.856760047715262], [136.817585793435029, 34.856761854894309]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81759034487564, 34.856764010122603], [136.817591584694583, 34.856762055691974], [136.817585826061844, 34.856761854894309], [136.817586429657922, 34.856763903030512], [136.81759034487564, 34.856764010122603]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817625892545976, 34.856436250809928], [136.817631667492094, 34.856436451608396], [136.817631031269229, 34.856434657808762], [136.81762692029065, 34.856434443623733], [136.817625892545976, 34.856436250809928]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817630443986616, 34.856438406046763], [136.817631683805558, 34.856436451608396], [136.817625925172791, 34.856436250809928], [136.817626528768869, 34.856438298954245], [136.817630443986616, 34.856438406046763]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817669493073964, 34.856442927761691], [136.81767526802011, 34.856443128560137], [136.817674631797217, 34.856441334760653], [136.817670520818638, 34.856441120575639], [136.817669493073964, 34.856442927761691]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817674044514575, 34.856445082998349], [136.817675284333518, 34.856443128560137], [136.817669525700751, 34.856442927761691], [136.817670129296857, 34.856444975905838], [136.817674044514575, 34.856445082998349]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817641851492056, 34.856357322645124], [136.817647626438173, 34.856357523443791], [136.817646990215309, 34.856355729642438], [136.817642879236701, 34.856355515457203], [136.817641851492056, 34.856357322645124]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817646402932667, 34.856359477884027], [136.817647642751609, 34.856357523443791], [136.817641884118871, 34.856357322645124], [136.81764248771492, 34.856359370791409], [136.817646402932667, 34.856359477884027]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817685452020015, 34.856363999603303], [136.817691226966161, 34.856364200401941], [136.817690590743297, 34.856362406600738], [136.817686479764689, 34.856362192415517], [136.817685452020015, 34.856363999603303]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817690003460655, 34.856366154842014], [136.817691243279597, 34.856364200401941], [136.81768548464683, 34.856363999603303], [136.817686088242908, 34.856366047749411], [136.817690003460655, 34.856366154842014]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8176707417731, 34.856369174179129], [136.817785904978081, 34.855888701835795], [136.817783101379433, 34.855888524865733], [136.817668153835911, 34.856369174179129], [136.8176707417731, 34.856369174179129]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817681309183314, 34.856371120838531], [136.817796472388295, 34.855890648506573], [136.817793668789648, 34.855890471536512], [136.817678721246097, 34.856371120838531], [136.817681309183314, 34.856371120838531]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817698786110242, 34.856119475878948], [136.817704561056388, 34.85611967667819], [136.817703924833523, 34.856117882871651], [136.817699813854915, 34.856117668685791], [136.817698786110242, 34.856119475878948]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817679595991393, 34.856198650174143], [136.817685370937539, 34.856198850973193], [136.817684734714675, 34.85619705716838], [136.817680623736067, 34.856196842982726], [136.817679595991393, 34.856198650174143]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817684147432004, 34.856200805417203], [136.817685387250975, 34.856198850973193], [136.817679628618208, 34.856198650174143], [136.817680232214286, 34.856200698324372], [136.817684147432004, 34.856200805417203]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817659940300587, 34.856278532270075], [136.817665715246704, 34.856278733068926], [136.81766507902384, 34.856276939265854], [136.81766096804526, 34.856276725080413], [136.817659940300587, 34.856278532270075]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817664491741198, 34.856280687511031], [136.81766573156014, 34.856278733068926], [136.817659972927402, 34.856278532270075], [136.81766057652348, 34.856280580418314], [136.817664491741198, 34.856280687511031]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817723196519381, 34.856206211992145], [136.817728971465527, 34.856206412791167], [136.817728335242663, 34.856204618986524], [136.817724224264055, 34.856204404800891], [136.817723196519381, 34.856206211992145]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817727747960021, 34.856208367234991], [136.817728987778963, 34.856206412791167], [136.817723229146196, 34.856206211992145], [136.817723832742274, 34.856208260142182], [136.817727747960021, 34.856208367234991]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817703756490005, 34.856285209234635], [136.817709531436151, 34.856285410033472], [136.817708895213286, 34.856283616230549], [136.817704784234678, 34.856283402045115], [136.817703756490005, 34.856285209234635]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817708307930616, 34.856287364475413], [136.817709547749558, 34.856285410033472], [136.81770378911682, 34.856285209234635], [136.817704392712898, 34.856287257382704], [136.817708307930616, 34.856287364475413]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817650253936932, 34.856365811767319], [136.817765273300267, 34.85588575726289], [136.817762613543351, 34.855885870314594], [136.817648097322575, 34.856364749953045], [136.817650253936932, 34.856365811767319]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817660821347204, 34.856367758426813], [136.817775984552185, 34.855887286075202], [136.817773180953537, 34.855887109105126], [136.817658233410015, 34.856367758426813], [136.817660821347204, 34.856367758426813]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817757877342757, 34.855881982365261], [136.817763652288875, 34.855882183165086], [136.81776301606601, 34.855880389353374], [136.81775890508743, 34.855880175166902], [136.817757877342757, 34.855881982365261]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817762428783396, 34.85588413761662], [136.817763668602339, 34.855882183165086], [136.817757909969572, 34.855881982365261], [136.81775851356565, 34.855884030523377], [136.817762428783396, 34.85588413761662]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817736961932439, 34.855960802949198], [136.817742736878586, 34.855961003748824], [136.817742100655721, 34.855959209938824], [136.817737989677113, 34.855958995752559], [136.817736961932439, 34.855960802949198]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817741513373051, 34.855962958198482], [136.817742753191993, 34.855961003748824], [136.817736994559255, 34.855960802949198], [136.817737598155333, 34.855962851105346], [136.817741513373051, 34.855962958198482]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817717306241633, 34.856040685276085], [136.817723081187779, 34.856040886075519], [136.817722444964915, 34.856039092267252], [136.817718333986306, 34.856038878081193], [136.817717306241633, 34.856040685276085]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817721857682244, 34.856042840523273], [136.817723097501187, 34.856040886075519], [136.817717338868448, 34.856040685276085], [136.817717942464526, 34.856042733430243], [136.817721857682244, 34.856042840523273]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817703314547686, 34.856121307013943], [136.817704554366628, 34.856119352568044], [136.817698795733861, 34.856119151768802], [136.817699399329967, 34.856121199921013], [136.817703314547686, 34.856121307013943]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817801477870745, 34.855888659362016], [136.817807252816891, 34.855888860161812], [136.817806616593998, 34.855887066350242], [136.817802505615418, 34.855886852163792], [136.817801477870745, 34.855888659362016]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817806029311384, 34.855890814613183], [136.817807269130299, 34.855888860161812], [136.817801510497532, 34.855888659362016], [136.817802114093638, 34.855890707519961], [136.817806029311384, 34.855890814613183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817780562460456, 34.855968364789057], [136.817786337406574, 34.855968565588661], [136.817785701183709, 34.855966771778832], [136.817781590205129, 34.855966557592581], [136.817780562460456, 34.855968364789057]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817785113901067, 34.855970520038142], [136.81778635372001, 34.855968565588661], [136.817780595087271, 34.855968364789057], [136.817781198683349, 34.85597041294502], [136.817785113901067, 34.855970520038142]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817761122431079, 34.85604736225995], [136.817766897377197, 34.856047563059363], [136.817766261154333, 34.856045769251253], [136.817762150175724, 34.856045555065201], [136.817761122431079, 34.85604736225995]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817765673871691, 34.85604951750696], [136.817766913690633, 34.856047563059363], [136.817761155057894, 34.85604736225995], [136.817761758653944, 34.856049410413945], [136.817765673871691, 34.85604951750696]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817743657603671, 34.856126182685422], [136.817749432549817, 34.856126383484643], [136.817748796326953, 34.856124589678259], [136.817744685348345, 34.856124375492413], [136.817743657603671, 34.856126182685422]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817748209044282, 34.856128337930372], [136.817749448863225, 34.856126383484643], [136.817743690230486, 34.856126182685422], [136.817744293826564, 34.856128230837463], [136.817748209044282, 34.856128337930372]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817034324885242, 34.859196648402971], [136.817077888494623, 34.859016853843116], [136.817072281297357, 34.859016145989742], [136.817028717687975, 34.859196648402971], [136.817034324885242, 34.859196648402971]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817228101546789, 34.858365454737019], [136.817235825080076, 34.858367303226927], [136.817382294606773, 34.85775314890482], [136.817376687409507, 34.857752794972697], [136.817228101546789, 34.858365454737019]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817541991897627, 34.857088992594953], [136.817548461740614, 34.857088992594953], [136.817698885589749, 34.856471196766563], [136.817694572361091, 34.856469073140708], [136.817541991897627, 34.857088992594953]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817935840558164, 34.855887916941228], [136.817937374150546, 34.8558785834079], [136.817889639822653, 34.855871067211837], [136.817887789709431, 34.85588034503089], [136.817935840558164, 34.855887916941228]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817943125122099, 34.855852155869599], [136.817896629139256, 34.855844920625231], [136.817893134480954, 34.855856728762141], [136.817940441335367, 34.855864111185589], [136.817943125122099, 34.855852155869599]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817935840558164, 34.855887602327762], [136.817944083617306, 34.855853676501994], [136.817933220267491, 34.855850824693889], [136.817924791973894, 34.85588591172182], [136.817935840558164, 34.855887602327762]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817716246335209, 34.856398108611799], [136.817718834272398, 34.856402709805053], [136.817870983413002, 34.856425715767429], [136.81787098341303, 34.856422176389017], [136.817716246335209, 34.856398108611799]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817848723614929, 34.855874029104633], [136.817848554624163, 34.855876933325057], [136.818076724419711, 34.855915689767265], [136.818080175002649, 34.855913566127072], [136.817848723614929, 34.855874029104633]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817713265747159, 34.856402883607878], [136.817720301584671, 34.856400370429924], [136.81785631843556, 34.855874632713714], [136.817849848592573, 34.855873924833297], [136.817713265747159, 34.856402883607878]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817774698934528, 34.855800399114798], [136.817780473880646, 34.855800599914822], [136.817779837657753, 34.855798806101326], [136.817775726679145, 34.855798591914628], [136.817774698934528, 34.855800399114798]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817779250375139, 34.855802554368289], [136.817780490194082, 34.855800599914822], [136.817774731561315, 34.855800399114798], [136.817775335157393, 34.855802447274939], [136.817779250375139, 34.855802554368289]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817818299462488, 34.855807076118168], [136.817824074408634, 34.855807276918163], [136.817823438185769, 34.855805483104817], [136.817819327207161, 34.855805268918147], [136.817818299462488, 34.855807076118168]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817822850903099, 34.855809231371474], [136.817824090722041, 34.855807276918163], [136.817818332089303, 34.855807076118168], [136.817818935685381, 34.855809124278146], [136.817822850903099, 34.855809231371474]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818024103030154, 34.855826850772132], [136.818018927155777, 34.855822603487084], [136.817739861261941, 34.85577765304032], [136.817739861261941, 34.855781546387071], [136.818024103030154, 34.855826850772132]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818003399532643, 34.855901178224819], [136.818007712761272, 34.855900824284724], [136.818014182604259, 34.855870031490916], [136.818010732021321, 34.855870031490916], [136.818003399532643, 34.855901178224819]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818090958074293, 34.855870031490916], [136.818091389397154, 34.855867553909299], [136.818014182604259, 34.855855519940405], [136.818012457312818, 34.855858351462658], [136.818090958074293, 34.855870031490916]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818075430451131, 34.85591392006711], [136.8180793123569, 34.855916043707303], [136.818099584531581, 34.855836053222291], [136.818096133948643, 34.855834991401174], [136.818075430451131, 34.85591392006711]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817785740201032, 34.856483949991386], [136.817787273793442, 34.856474616525688], [136.817739539465549, 34.856467100384073], [136.817737689352299, 34.856476378135916], [136.817785740201032, 34.856483949991386]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817793024764995, 34.856448189178856], [136.817746528782152, 34.856440953986898], [136.817743034123822, 34.856452762038266], [136.817790340978263, 34.856460144408231], [136.817793024764995, 34.856448189178856]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817785740201032, 34.856483635380201], [136.817793983260231, 34.856449709800231], [136.817783119910388, 34.856446858012788], [136.817774691616762, 34.856481944786516], [136.817785740201032, 34.856483635380201]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817698623257797, 34.856470062255411], [136.817698454267031, 34.856472966454788], [136.817926624062608, 34.856511722616204], [136.817930074645517, 34.856509598991394], [136.817698623257797, 34.856470062255411]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817853299175511, 34.856497211178898], [136.817857612404168, 34.85649685724136], [136.817864082247155, 34.856466064670656], [136.817860631664246, 34.856466064670656], [136.817853299175511, 34.856497211178898]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81794085771719, 34.856466064670656], [136.817941289040078, 34.856463587106994], [136.817864082247155, 34.856451553225284], [136.817862356955686, 34.856454384727023], [136.81794085771719, 34.856466064670656]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817925330094027, 34.856509952928867], [136.817929211999797, 34.856512076553678], [136.817949484174449, 34.856432086648219], [136.817946033591539, 34.856431024834784], [136.817925330094027, 34.856509952928867]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817545442480537, 34.857079082414906], [136.817548030417754, 34.857083683570082], [136.817700179558358, 34.857106689342018], [136.817700179558358, 34.857103149992902], [136.817545442480537, 34.857079082414906]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817614936346388, 34.857164923083914], [136.817616469938798, 34.857155589695473], [136.817568735610877, 34.857148073616081], [136.817566885497655, 34.857157351291114], [136.817614936346388, 34.857164923083914]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817622220910323, 34.857129162567404], [136.817575724927508, 34.857121927435344], [136.817572230269178, 34.85713373538897], [136.817619537123619, 34.857141117697815], [136.817622220910323, 34.857129162567404]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817614936346388, 34.85716460847533], [136.817623179405587, 34.857130683176194], [136.817612316055744, 34.857127831412356], [136.817603887762118, 34.857162917895636], [136.817614936346388, 34.85716460847533]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817527819403153, 34.857151035462898], [136.817527650412387, 34.857153939638238], [136.817755820207935, 34.857192695478837], [136.817759270790873, 34.857190571871605], [136.817527819403153, 34.857151035462898]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817682495320867, 34.857178184161647], [136.817686808549524, 34.857177830227052], [136.817693278392511, 34.857147037911233], [136.817689827809573, 34.857147037911233], [136.817682495320867, 34.857178184161647]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817770053862546, 34.857147037911233], [136.817770485185434, 34.857144560368077], [136.817693278392511, 34.857132526585985], [136.817691553101042, 34.857135358064291], [136.817770053862546, 34.857147037911233]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817754526239383, 34.857190925806137], [136.817758408145153, 34.857193049413368], [136.817778680319805, 34.857113060170065], [136.817775229736867, 34.857111998365426], [136.817754526239383, 34.857190925806137]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817385853020397, 34.857684309078117], [136.817388440957586, 34.857688910199435], [136.81754059009819, 34.857711915802113], [136.817540590098218, 34.857708376479032], [136.817385853020397, 34.857684309078117]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817470011863662, 34.857768025523235], [136.817471545456044, 34.85775869220322], [136.817423811128151, 34.857751176178937], [136.817421961014929, 34.857760453785957], [136.817470011863662, 34.857768025523235]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817477296427597, 34.857732265268908], [136.817430800444782, 34.857725030189897], [136.817427305786453, 34.857736838056944], [136.817474612640893, 34.857744220311673], [136.817477296427597, 34.857732265268908]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817470011863662, 34.85776771091696], [136.817478254922861, 34.85773378586655], [136.817467391573018, 34.857730934123616], [136.817458963279392, 34.857766020349665], [136.817470011863662, 34.85776771091696]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817382894920428, 34.857754138004047], [136.817382725929662, 34.857757042158092], [136.81761089572521, 34.857795797714545], [136.817614346308147, 34.857793674122888], [136.817382894920428, 34.857754138004047]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817537570838141, 34.857781286503752], [136.817541884066799, 34.857780932571742], [136.817548353909785, 34.857750140481684], [136.817544903326876, 34.857750140481684], [136.817537570838141, 34.857781286503752]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81762512937982, 34.857750140481684], [136.81762556070268, 34.85774766295669], [136.817548353909785, 34.857735629262827], [136.817546628618317, 34.85773846072037], [136.81762512937982, 34.857750140481684]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817609601756658, 34.857794028054833], [136.817613483662399, 34.85779615164649], [136.817632420469664, 34.857722540825741], [136.817628558703035, 34.857721484537514], [136.817609601756658, 34.857794028054833]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817229066988432, 34.858361229617074], [136.817226263560229, 34.858365272234039], [136.817378412700862, 34.858388277647556], [136.817378412700862, 34.858384738353571], [136.817229066988432, 34.858361229617074]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817303089914788, 34.858438724040759], [136.817304623507198, 34.858429390796836], [136.817256889179276, 34.858421874833837], [136.817255039066026, 34.858431152365206], [136.817303089914788, 34.858438724040759]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817310374478751, 34.858402964077989], [136.817263878495908, 34.858395729057975], [136.817260383837606, 34.858407536828743], [136.81730769069199, 34.858414919023282], [136.817310374478751, 34.858402964077989]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817303089914788, 34.858438409437042], [136.817311332973958, 34.858404484663239], [136.817300469624143, 34.858401632943561], [136.817292041330546, 34.858436718883532], [136.817303089914788, 34.858438409437042]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817215972971553, 34.858424836634789], [136.817215803980787, 34.858427740765165], [136.817443973776363, 34.858466496005633], [136.817447424359273, 34.858464372431278], [136.817215972971553, 34.858424836634789]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817370648889266, 34.858451984913138], [136.817374962117896, 34.858451630984021], [136.817381431960911, 34.858420839145019], [136.817377981377973, 34.858420839145019], [136.817370648889266, 34.858451984913138]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817458207430917, 34.858420839145019], [136.817458638753806, 34.85841836164024], [136.817381431960911, 34.858406328044488], [136.817379706669442, 34.858409159478938], [136.817458207430917, 34.858420839145019]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817442679807755, 34.858464726360339], [136.817446561713552, 34.858466849934679], [136.817463468178772, 34.858400410994491], [136.817459284598272, 34.858398826575211], [136.817442679807755, 34.858464726360339]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81708651495191, 34.858956686284543], [136.817089102889099, 34.858961287334687], [136.817241252029731, 34.858984292581525], [136.817241252029731, 34.85898075331319], [136.81708651495191, 34.858956686284543]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81716290998358, 34.859036508242234], [136.81716444357599, 34.859027175066139], [136.817116709248069, 34.859019659157752], [136.817114859134847, 34.859028936621705], [136.81716290998358, 34.859036508242234]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817170194547543, 34.859000748539344], [136.8171236985647, 34.858993513571896], [136.817120203906399, 34.859005321256859], [136.817167510760811, 34.859012703397752], [136.817170194547543, 34.859000748539344]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81716290998358, 34.859036193640797], [136.817171153042779, 34.859002269113532], [136.817160289692936, 34.85899941741458], [136.817151861399338, 34.859034503099579], [136.81716290998358, 34.859036193640797]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817075793040345, 34.859022620937182], [136.817075624049579, 34.859025525046448], [136.817303793845156, 34.859064280005278], [136.817307244428065, 34.859062156446356], [136.817075793040345, 34.859022620937182]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817230468958059, 34.859049769018249], [136.817234782186716, 34.859049415091704], [136.817241252029731, 34.859018623476473], [136.817237801446794, 34.859018623476473], [136.817230468958059, 34.859049769018249]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817318027499738, 34.859018623476473], [136.817318458822626, 34.859016145989692], [136.817241252029731, 34.859004112481387], [136.817239526738234, 34.85900694389526], [136.817318027499738, 34.859018623476473]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817302499876575, 34.859062510372844], [136.817306381782345, 34.859064633931759], [136.817324766393227, 34.858996150481026], [136.817320582812698, 34.858995622345091], [136.817302499876575, 34.859062510372844]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817864126468493, 34.85575544457911], [136.817950993804516, 34.855402829067209], [136.8179423673472, 34.855402829067209], [136.817857904733359, 34.855754388265623], [136.817864126468493, 34.85575544457911]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818096958816227, 34.85583975889049], [136.818168380528618, 34.855851626597108], [136.818168380528618, 34.855845963552049], [136.818097387901418, 34.85583494680057], [136.818096958816227, 34.85583975889049]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8181518817197, 34.855906893383917], [136.818159033139437, 34.855907597591596], [136.818171831111528, 34.855850210835889], [136.81816665523715, 34.855845963552049], [136.8181518817197, 34.855906893383917]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818154578196925, 34.855902593985128], [136.818152167776475, 34.855906423912131], [136.818577274604877, 34.855976213489853], [136.818577274604877, 34.855970550453371], [136.818154578196925, 34.855902593985128]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818577820278506, 34.85597614044385], [136.818608714411681, 34.855857598831157], [136.818601276935169, 34.855855486206806], [136.818573243369883, 34.855971210994056], [136.818577820278506, 34.85597614044385]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818657555825553, 34.855893628503281], [136.818659558223061, 34.855887290632843], [136.818597197843076, 34.855877196986327], [136.818594909388764, 34.855883065385612], [136.818657555825553, 34.855893628503281]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818648311668426, 34.855889135705318], [136.818658186193346, 34.855890823821419], [136.818744591386434, 34.85553718351823], [136.818734276127827, 34.855537327006594], [136.818648311668426, 34.855889135705318]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818736001419296, 34.855544405839417], [136.818743733216081, 34.855536714044334], [136.818596252810948, 34.855516090504466], [136.818596252810948, 34.855524585105975], [136.818736001419296, 34.855544405839417]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818592802228039, 34.855523169339115], [136.818599703393886, 34.855524585105975], [136.818611861036345, 34.855472630831628], [136.818606604559733, 34.855472201716033], [136.818592802228039, 34.855523169339115]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818606712014144, 34.855478499259782], [136.818608329851173, 34.855472201716033], [136.818433737049475, 34.855443435395344], [136.818432610700881, 34.855450433498341], [136.818606712014144, 34.855478499259782]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818432217372873, 34.855451035012443], [136.818440226962963, 34.855452678172796], [136.818455960086339, 34.855390238056231], [136.818446152453845, 34.855392918684018], [136.818432217372873, 34.855451035012443]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818451328328223, 34.855395750222193], [136.81845477891116, 34.85539008714575], [136.818025181337333, 34.855322130198026], [136.818025181337333, 34.855327793279145], [136.818451328328223, 34.855395750222193]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818007995154801, 34.855415589688235], [136.818028631920271, 34.855322130198026], [136.818018280171486, 34.855324961738631], [136.81799912739433, 34.855413007577916], [136.818007995154801, 34.855415589688235]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818006564870842, 34.855416293900113], [136.818006850927617, 34.85540972125559], [136.817941629979742, 34.855400566499846], [136.8179423673472, 34.855407076373908], [136.818006564870842, 34.855416293900113]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817761446017357, 34.856369584922646], [136.817849108318967, 34.856014166620824], [136.817840481861651, 34.856014166620824], [136.817754044297942, 34.856366680082232], [136.817761446017357, 34.856369584922646]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817947111898746, 34.85643668783954], [136.817982049050784, 34.856444828408243], [136.817982049050784, 34.856439165404012], [136.817946249253026, 34.85643385633719], [136.817947111898746, 34.85643668783954]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817969109364896, 34.856498272991431], [136.817972559947805, 34.856498980866476], [136.817985499633721, 34.856443412657228], [136.817980323759372, 34.856439165404012], [136.817969109364896, 34.856498272991431]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817968246719118, 34.856495795428749], [136.817964796136209, 34.8565028741791], [136.818390943127099, 34.856569414402614], [136.818390943127099, 34.856563751406966], [136.817968246719118, 34.856495795428749]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818410727356536, 34.856517439181665], [136.818424243539823, 34.856461455067091], [136.818411370984336, 34.856459342458251], [136.818396567545477, 34.856515326574261], [136.818410727356536, 34.856517439181665]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818566770407244, 34.856499635957213], [136.818568057662816, 34.856492769981465], [136.818414069717591, 34.856473096317018], [136.818411977927298, 34.856481018598572], [136.818566770407244, 34.856499635957213]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818556560873958, 34.856492466191362], [136.818565446467005, 34.856499931848852], [136.818643486334764, 34.856166799327589], [136.818632939845912, 34.856148524291143], [136.818556560873958, 34.856492466191362]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818641110388938, 34.856157430451582], [136.818641110388938, 34.856148935915414], [136.818501361780591, 34.856129115327619], [136.818501361780591, 34.85613760986584], [136.818641110388938, 34.856157430451582]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818497911197682, 34.856136194109524], [136.8185048123635, 34.85613760986584], [136.818514995056091, 34.85608514576117], [136.818507915150576, 34.856084617606548], [136.818497911197682, 34.856136194109524]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818510811475505, 34.85609267196417], [136.818513438820816, 34.856085226866234], [136.818340411021978, 34.856057813755065], [136.818337459091794, 34.856065406263092], [136.818510811475505, 34.85609267196417]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818338319231685, 34.856062831225465], [136.818346686392772, 34.85606454772843], [136.818359887880774, 34.856005944425014], [136.818351674508051, 34.856001961366502], [136.818338319231685, 34.856062831225465]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818360041669138, 34.856008431266886], [136.818360524389959, 34.855999584668275], [136.817929240144963, 34.855929604078604], [136.81792773834681, 34.855935061686488], [136.818360041669138, 34.856008431266886]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817908000428446, 34.856029469442852], [136.817933740889885, 34.855935156466508], [136.817927845618101, 34.855933433206758], [136.817899096910878, 34.856027884977905], [136.817908000428446, 34.856029469442852]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817903280491322, 34.856027400835821], [136.817905747731118, 34.856020534820672], [136.817848572130515, 34.856012920585229], [136.817847476316842, 34.856020102009403], [136.817903280491322, 34.856027400835821]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817597554515544, 34.857051721530596], [136.81768208694848, 34.856697909828455], [136.817673460491193, 34.856697909828455], [136.817592083679472, 34.857051457456379], [136.817597554515544, 34.857051721530596]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817773374952452, 34.857119926191999], [136.817815532571728, 34.857126528041434], [136.817816819827272, 34.857121510635906], [136.817774340394095, 34.857114380638045], [136.817773374952452, 34.857119926191999]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817797189180141, 34.857181719482014], [136.817805556341199, 34.857181455408224], [136.817816421070546, 34.857125801824616], [136.817811245196168, 34.857121554606636], [136.817797189180141, 34.857181719482014]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817799168155972, 34.857178184161612], [136.817795717573034, 34.85718526285325], [136.818221864563895, 34.857251802524807], [136.818221864563895, 34.857246139576134], [136.817799168155972, 34.857178184161612]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81824000508945, 34.857197827981608], [136.81825416490048, 34.857145277290947], [136.818246441367194, 34.857141052108325], [136.818233568811706, 34.857196507612912], [136.81824000508945, 34.857197827981608]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81840171656782, 34.857169572086754], [136.818402360195591, 34.857164290610008], [136.818247889529545, 34.857142108403977], [136.818245315018402, 34.857147389882151], [136.81840171656782, 34.857169572086754]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81839206215119, 34.857168515791436], [136.818399785684477, 34.857169043939095], [136.818477825552236, 34.856832613191102], [136.818470306534294, 34.856832743269869], [136.81839206215119, 34.857168515791436]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818476055575871, 34.856838026726592], [136.818478308273086, 34.856831424854001], [136.818332204768041, 34.856813863870329], [136.818332283217416, 34.856820001569879], [136.818476055575871, 34.856838026726592]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818331882954169, 34.856819541482004], [136.818339445580534, 34.856819673519482], [136.818349582718014, 34.85676897111307], [136.8183426349662, 34.856767619004799], [136.818331882954169, 34.856819541482004]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818345238230535, 34.856775044840482], [136.81834436025764, 34.856767619004799], [136.818170105820087, 34.856742135582699], [136.818168380528618, 34.856747798566069], [136.818345238230535, 34.856775044840482]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818171831111528, 34.856746382820269], [136.818180457568843, 34.856747798566069], [136.818190809317628, 34.856688337221236], [136.818182182860312, 34.856688337221236], [136.818171831111528, 34.856746382820269]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818187358734718, 34.856691168714818], [136.818190809317628, 34.856685505727548], [136.8177612117438, 34.856617549849908], [136.8177612117438, 34.856623212841853], [136.818187358734718, 34.856691168714818]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817742159005206, 34.856708761964875], [136.817764662326709, 34.856617549849908], [136.817754310577953, 34.856620381345934], [136.817731539146905, 34.856707177513016], [136.817742159005206, 34.856708761964875]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81773533237191, 34.856709573420751], [136.817740871749635, 34.856702160081909], [136.817678397753667, 34.8566940002083], [136.817678397753667, 34.856702494688193], [136.81773533237191, 34.856709573420751]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817445446600971, 34.857657315002612], [136.817535267557673, 34.857289753720153], [136.817526627348343, 34.85728772801528], [136.817438366695455, 34.857659427580693], [136.817445446600971, 34.857657315002612]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817622757104061, 34.85772359556718], [136.817657694256127, 34.857731736008532], [136.817657694256127, 34.857726073092891], [136.81762189445837, 34.857720764109125], [136.817622757104061, 34.85772359556718]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81764475457021, 34.857785179755652], [136.81764820515312, 34.857785887619613], [136.817661144839036, 34.85773032027965], [136.817655968964658, 34.857726073092891], [136.81764475457021, 34.857785179755652]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817643891924433, 34.857782702231709], [136.817640441341524, 34.857789780871322], [136.818066588332385, 34.857856320053912], [136.818066588332385, 34.857850657146841], [136.817643891924433, 34.857782702231709]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818069121915187, 34.857855897104749], [136.818096154281733, 34.857749212098831], [136.818088430748446, 34.857747099523067], [136.818063329265186, 34.857852728245177], [136.818069121915187, 34.857855897104749]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818242257786693, 34.857776147435168], [136.81824449894475, 34.857769564740181], [136.8180874974218, 34.857746913085712], [136.818085772130331, 34.857751160271398], [136.818242257786693, 34.857776147435168]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818235177881178, 34.857775619291402], [136.818242901414465, 34.85777509114763], [136.818322143765698, 34.857424462982031], [136.818315972187634, 34.857423956556651], [136.818235177881178, 34.857775619291402]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818317989909872, 34.857431175490028], [136.818317989909872, 34.857422681085396], [136.818178241301553, 34.857402860804491], [136.818178241301553, 34.857411355211177], [136.818317989909872, 34.857431175490028]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818178963153741, 34.857410283070109], [136.818184517574025, 34.857409776644644], [136.818197992186242, 34.857358712060339], [136.818187294784195, 34.857357361591561], [136.818178963153741, 34.857410283070109]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818188924951528, 34.857366519848902], [136.818192375534466, 34.857356609702279], [136.818018121096884, 34.857331126462647], [136.818016395805444, 34.857336789405473], [136.818188924951528, 34.857366519848902]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818016554880046, 34.85735090406353], [136.818025181337333, 34.857352319798927], [136.818035533086118, 34.857292858891043], [136.818026906628774, 34.857292858891043], [136.818016554880046, 34.85735090406353]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818032648231195, 34.857288262779591], [136.818035533086118, 34.857290027418173], [136.817606652144406, 34.857214844808972], [136.817606501240277, 34.857220307399878], [136.818032648231195, 34.857288262779591]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817589731734444, 34.857303933652837], [136.817610817959604, 34.857215646651198], [136.817601097724122, 34.857213958562291], [136.81758140010399, 34.857299882243744], [136.817589731734444, 34.857303933652837]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817588805997701, 34.857305959357312], [136.81759281752349, 34.857300388669891], [136.817527398795619, 34.857287474802163], [136.817525855901067, 34.857293298703553], [136.817588805997701, 34.857305959357312]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81728711416838, 34.858336241989214], [136.817379229491252, 34.857966673970616], [136.817370566285859, 34.857963881360398], [136.817285562213186, 34.858314708286024], [136.81728711416838, 34.858336241989214]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817461537295486, 34.858405164252147], [136.817502085845291, 34.858411765998447], [136.817503694914762, 34.858406484601453], [136.817462824551001, 34.858400939134249], [136.817461537295486, 34.858405164252147]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817482777012003, 34.858470125412623], [136.817489478338729, 34.858468265650849], [136.817502418024617, 34.858412698771836], [136.817497242150239, 34.858408451620299], [136.817482777012003, 34.858470125412623]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817485165110014, 34.858465080289371], [136.817481714527105, 34.858472158870264], [136.817907861517966, 34.858538697500876], [136.817907861517966, 34.858533034640793], [136.817485165110014, 34.858465080289371]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817912398551954, 34.858538519424521], [136.817938304569566, 34.858427082047761], [136.817929937408479, 34.858424441349804], [136.817904031390867, 34.858535878730137], [136.817912398551954, 34.858538519424521]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818083120819011, 34.858452432743881], [136.818086982585669, 34.858444510652191], [136.817928650152936, 34.858424969489405], [136.817928006525193, 34.858431307164324], [136.818083120819011, 34.858452432743881]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818077328169011, 34.858450848325603], [136.818085051702326, 34.858454017162131], [136.818160551278993, 34.858102394966402], [136.818151088192565, 34.858104758265341], [136.818077328169011, 34.858450848325603]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818152811868003, 34.858110485009604], [136.818156673634689, 34.858103883239139], [136.818015719151902, 34.858084606066321], [136.818013144640815, 34.858090679696602], [136.818152811868003, 34.858110485009604]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818012501013015, 34.858090415625725], [136.818020224546331, 34.858090151554855], [136.81803020077686, 34.858039714002544], [136.818023442685217, 34.85803944993151], [136.818012501013015, 34.858090415625725]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818026982637974, 34.858046051707149], [136.818027948079646, 34.858038657718389], [136.817856421277526, 34.858009873970111], [136.817855777649754, 34.858017267961465], [136.818026982637974, 34.858046051707149]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817854311144885, 34.85802754348223], [136.817862678305971, 34.858028599766527], [136.81788200738734, 34.857957895988058], [136.817874190055079, 34.857959921676425], [136.817854311144885, 34.85802754348223]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817875824320026, 34.857965918333143], [136.817879274902936, 34.857960255433632], [136.817449677329108, 34.857892300609038], [136.817449677329108, 34.857897963513224], [136.817875824320026, 34.857965918333143]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81743107074729, 34.857976127181566], [136.817453288428482, 34.857894762008961], [136.817444236780631, 34.857892061088982], [136.817419550468202, 34.857980516171978], [136.81743107074729, 34.857976127181566]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817424209395739, 34.857981284222205], [136.817424029721934, 34.857973796437712], [136.817367274777524, 34.857965711250515], [136.817367274777524, 34.857974205599078], [136.817424209395739, 34.857981284222205]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817151469614771, 34.858931981940472], [136.817238448431056, 34.858579753224831], [136.817229821973768, 34.858579753224831], [136.817144389709256, 34.858930661599608], [136.817151469614771, 34.858931981940472]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817319617371027, 34.858998527092673], [136.817367889454175, 34.859007505402737], [136.817368211268075, 34.859003016247826], [136.817317686487712, 34.858995094209149], [136.817319617371027, 34.858998527092673]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817348902434816, 34.859064015920062], [136.817354905604731, 34.859062864299311], [136.817367845290619, 34.859007297821961], [136.817362669416269, 34.859003050701126], [136.817348902434816, 34.859064015920062]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817350592376044, 34.859059678960861], [136.817347141793135, 34.859066757490581], [136.817782063927552, 34.859134257910831], [136.817781420299752, 34.859127920290064], [136.817350592376044, 34.859059678960861]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817786569321981, 34.859135314180911], [136.81780008550524, 34.859076163035546], [136.817788500205296, 34.859072466087547], [136.81777627127758, 34.859130560965447], [136.817786569321981, 34.859135314180911]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817951981660201, 34.859051340667214], [136.817951338032401, 34.859045003040059], [136.81780008550524, 34.859021765069656], [136.817801372760783, 34.859029158970046], [136.817951981660201, 34.859051340667214]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817943614499086, 34.859047115582506], [136.817951981660201, 34.859047115582506], [136.81803208250318, 34.858719908817733], [136.818021730754396, 34.85871424597012], [136.817943614499086, 34.859047115582506]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818024278922962, 34.858713221850806], [136.818024278922962, 34.858704727578562], [136.817884530314615, 34.858684907606566], [136.817884530314615, 34.858693401880856], [136.818024278922962, 34.858713221850806]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817881079731677, 34.85869198616853], [136.817887980897524, 34.858693401880856], [136.817900057937749, 34.858642436221913], [136.817894882063371, 34.85864102050872], [136.817881079731677, 34.85869198616853]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817895583616121, 34.858644173739869], [136.817896607354839, 34.85864102050872], [136.817724816526209, 34.858613232330967], [136.817722216012157, 34.858617144463764], [136.817895583616121, 34.858644173739869]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817725683364245, 34.858617500112189], [136.817732704666042, 34.85862120052137], [136.817743056414798, 34.858561740530682], [136.817734429957483, 34.858561740530682], [136.817725683364245, 34.858617500112189]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817738782954791, 34.858563896735305], [136.8177422335377, 34.858558233876956], [136.817312635963901, 34.858490279546345], [136.817312635963901, 34.858495942409377], [136.817738782954791, 34.858563896735305]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817297108340739, 34.858580885308008], [136.817316086546811, 34.858490279546345], [136.817305734798055, 34.858493110977911], [136.81728538441871, 34.858580607867445], [136.817297108340739, 34.858580885308008]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817295360649268, 34.858582456352522], [136.81729568246314, 34.858576382758564], [136.817229821973768, 34.858566728164327], [136.817229821973768, 34.858575222450824], [136.817295360649268, 34.858582456352522]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816884285688189, 34.859804795138949], [136.816884116697395, 34.859807699220589], [136.817112286492971, 34.859846453810903], [136.817115737075909, 34.859844330272168], [136.816884285688189, 34.859804795138949]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81702882551852, 34.859852470503611], [136.817032707424261, 34.859851762657428], [136.817037667637322, 34.859829819422757], [136.817034217054385, 34.859829819422757], [136.81702882551852, 34.859852470503611]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817104091358573, 34.859879368654113], [136.817104522681461, 34.859876891193252], [136.817027315888538, 34.859864857810884], [136.817025590597069, 34.85986768919512], [136.817104091358573, 34.859879368654113]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817089947036408, 34.859918335113285], [136.817096286350022, 34.859919019552216], [136.817112717815832, 34.859845392041542], [136.817109267232922, 34.859844330272168], [136.817089947036408, 34.859918335113285]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816822811904331, 34.860458749993249], [136.816824345496741, 34.860449416978533], [136.816776611168819, 34.860441901200097], [136.816774761055598, 34.860451178503638], [136.816822811904331, 34.860458749993249]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816830096468294, 34.860422990908653], [136.816783600485422, 34.86041575606631], [136.816780105827121, 34.860427563547113], [136.816827412681533, 34.860434945560364], [136.816830096468294, 34.860422990908653]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816822811904331, 34.860458435397256], [136.816831054963501, 34.860424511456564], [136.816820191613658, 34.86042165980691], [136.816811763320061, 34.860456744885255], [136.816822811904331, 34.860458435397256]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816741086496904, 34.860405046857785], [136.816740917506138, 34.860407950918244], [136.816969087301715, 34.860446705225726], [136.816972537884624, 34.860444581702502], [136.816741086496904, 34.860405046857785]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816885626327235, 34.860452721874537], [136.816889508233004, 34.860452014033513], [136.816894468446037, 34.860430070958977], [136.816891017863099, 34.860430070958977], [136.816885626327235, 34.860452721874537]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816960892167288, 34.860479619828752], [136.816961323490148, 34.860477142385967], [136.816884116697253, 34.860465109091407], [136.816882391405812, 34.860467940454988], [136.816960892167288, 34.860479619828752]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816947736819827, 34.860511472657798], [136.816952912694205, 34.860510410897021], [136.816969518624575, 34.860445643464132], [136.816966068041637, 34.860444581702502], [136.816947736819827, 34.860511472657798]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816728193481424, 34.860470063977573], [136.816727762158536, 34.860476080624714], [136.816830011080185, 34.860492971231764], [136.816832774097719, 34.860485413953022], [136.816728193481424, 34.860470063977573]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816661065829834, 34.861132257912196], [136.816662599422244, 34.861122924973905], [136.816614865094351, 34.86111540925701], [136.816613014981101, 34.861124686484594], [136.816661065829834, 34.861132257912196]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816668350393797, 34.861096499120414], [136.816621854410954, 34.861089264337309], [136.816618359752653, 34.861101071721436], [136.816665666607037, 34.861108453674227], [136.816668350393797, 34.861096499120414]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816661065829834, 34.861131943318782], [136.816669308889004, 34.861098019655863], [136.81665844553919, 34.861095168029578], [136.816650017245564, 34.861130252820637], [136.816661065829834, 34.861131943318782]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816579340422436, 34.861078555216487], [136.816579171431641, 34.861081459253157], [136.816807341227218, 34.861120213243318], [136.816810791810155, 34.861118089737474], [136.816579340422436, 34.861078555216487]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816723880252738, 34.861126229842846], [136.816727762158507, 34.861125522007633], [136.81673272237154, 34.861103579112772], [136.816729271788603, 34.861103579112772], [136.816723880252738, 34.861126229842846]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816799146092791, 34.86115312757682], [136.816799577415679, 34.861150650154322], [136.816722370622756, 34.861138616958293], [136.816720645331316, 34.861141448298696], [136.816799146092791, 34.86115312757682]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816784696776836, 34.861188165401181], [136.816789872651213, 34.861188519318517], [136.816807772550078, 34.861119151490399], [136.816804321967169, 34.861118089737474], [136.816784696776836, 34.861188165401181]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816571191958445, 34.861151004071772], [136.816570760635557, 34.861157020669111], [136.816671828325951, 34.861171989893215], [136.816675512349349, 34.861166510911417], [136.816571191958445, 34.861151004071772]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816531237647382, 34.861744179137851], [136.816532771239821, 34.861734846268995], [136.816485036911899, 34.861727330608012], [136.816483186798649, 34.861736607766574], [136.816531237647382, 34.861744179137851]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816538522211374, 34.861708420612111], [136.816492026228531, 34.861701185882829], [136.816488531570229, 34.861712993179111], [136.816535838424613, 34.861720375076992], [136.816538522211374, 34.861708420612111]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816531237647382, 34.861743864546781], [136.816539480706581, 34.861709941136255], [136.816528617356767, 34.861707089531166], [136.816520189063169, 34.861742174061199], [136.816531237647382, 34.861743864546781]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816443089067548, 34.861689664747288], [136.816440501130359, 34.861692849983903], [136.816677513044795, 34.861732134558572], [136.816680963627704, 34.861730011068538], [136.816443089067548, 34.861689664747288]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816594052070286, 34.86173815111335], [136.816597933976084, 34.861737443283388], [136.816602894189089, 34.861715500551782], [136.816599443606179, 34.861715500551782], [136.816594052070286, 34.86173815111335]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816584094576001, 34.86178376004969], [136.816589270450379, 34.86178269830534], [136.81659531374666, 34.861751242199581], [136.816591476793633, 34.861750420891141], [136.816584094576001, 34.86178376004969]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817520937142575, 34.859473062844415], [136.817522680075939, 34.859470593699697], [136.817091428082762, 34.859399152052404], [136.817091407886863, 34.859401983404197], [136.817520937142575, 34.859473062844415]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817508461440042, 34.859527508274304], [136.817510204373377, 34.859525039131213], [136.817078952380228, 34.859453597531214], [136.817078932184302, 34.859456428881124], [136.817508461440042, 34.859527508274304]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817507101856904, 34.859536703938431], [136.817508844790211, 34.859534234795611], [136.817077592797119, 34.859462793203612], [136.817077572601193, 34.859465624553195], [136.817507101856904, 34.859536703938431]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818014370500435, 34.859553757641635], [136.818016113433771, 34.85955128849934], [136.817584861440622, 34.859479846922135], [136.817584841244724, 34.859482678271142], [136.818014370500435, 34.859553757641635]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818001894797902, 34.859608203018105], [136.818003637731209, 34.859605733877444], [136.817572385738089, 34.859534292347526], [136.817572365542134, 34.859537123694658], [136.818001894797902, 34.859608203018105]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818000535214765, 34.859617398673208], [136.818002278148072, 34.85961492953281], [136.817571026154951, 34.859543488010893], [136.817571005959024, 34.859546319357705], [136.818000535214765, 34.859617398673208]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818013010917298, 34.859562953302827], [136.818014753850633, 34.859560484160802], [136.817583501857513, 34.859489042591591], [136.817583481661586, 34.859491873940279], [136.818013010917298, 34.859562953302827]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817727490880031, 34.855831903327882], [136.817731658897515, 34.855832587376824], [136.81774531657706, 34.855778458271793], [136.817740140702682, 34.855777986350972], [136.817727490880031, 34.855831903327882]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817733742906256, 34.855832587376824], [136.817357199154657, 34.855777375236407], [136.817357866450834, 34.855779565551288], [136.817732909302777, 34.855836349645848], [136.817733742906256, 34.855832587376824]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817718873681486, 34.855885248174395], [136.817721209218121, 34.855882510284324], [136.817347189711995, 34.855824466993234], [136.817346188767743, 34.855826657306842], [136.817718873681486, 34.855885248174395]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816563437450554, 34.855694927792051], [136.816564013079187, 34.855690676574881], [136.815627332252944, 34.855543960339951], [136.815624509064918, 34.855550910426381], [136.816563437450554, 34.855694927792051]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816570623788152, 34.855629939532754], [136.816009591914792, 34.855540253626941], [136.816009027277204, 34.855545350357282], [136.816569956491975, 34.855636510488964], [136.816570623788152, 34.855629939532754]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816019190754218, 34.855484189572365], [136.816023292727976, 34.855464995762269], [136.816014956692982, 34.855462943606291], [136.816010156552466, 34.855484189572365], [136.816019190754218, 34.855484189572365]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816369853533274, 34.855577476028529], [136.816369623281844, 34.855573886106697], [136.816014109015725, 34.855519403363175], [136.816012979740492, 34.855523573416349], [136.816369853533274, 34.855577476028529]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815627332252944, 34.855550910426381], [136.815736536039481, 34.855077270203708], [136.815731117616764, 34.855079322369313], [136.815621685876863, 34.855549057070057], [136.815627332252944, 34.855550910426381]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81573548100954, 34.855082785398515], [136.815736731414802, 34.855077654984626], [136.815454361255348, 34.855036568908673], [136.81545352765184, 34.85504101526962], [136.81573548100954, 34.855082785398515]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815441857202813, 34.854796123032209], [136.815670681363088, 34.853804746658888], [136.815661928526367, 34.853806798856226], [136.815436855581851, 34.854797149118525], [136.815441857202813, 34.854796123032209]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816269208674896, 34.855508223856916], [136.816579309176262, 34.854183205186828], [136.816566805123784, 34.854184573312097], [136.816260039036393, 34.855508907908536], [136.816269208674896, 34.855508223856916]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81656950130963, 34.854189746535482], [136.816575336534129, 34.854182905909305], [136.815683185415594, 34.85404434035302], [136.81567985100159, 34.854052549118194], [136.81656950130963, 34.854189746535482]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815689854243573, 34.85404844473571], [136.816057890188091, 34.852438997973202], [136.816052888567071, 34.852434893510228], [136.815681518208606, 34.854049812863231], [136.815689854243573, 34.85404844473571]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81566401253508, 34.853833819449832], [136.8159085579357, 34.852749055355055], [136.815904806719971, 34.852747345168623], [136.815659010914118, 34.853820480170555], [136.81566401253508, 34.853833819449832]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815884696035766, 34.852852735341472], [136.815885946441028, 34.852848972936002], [136.815805086901662, 34.852835633497051], [136.815802169289441, 34.85283973794003], [136.815884696035766, 34.852852735341472]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815805920505198, 34.852841448124551], [136.815827177394397, 34.852748071997929], [136.815823426178639, 34.852747045886055], [136.815802169289441, 34.852841790161456], [136.815805920505198, 34.852841448124551]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81581759095414, 34.852773040716166], [136.815820508566389, 34.852770646455838], [136.815405022348358, 34.85270583038217], [136.815399187123859, 34.852710618906514], [136.81581759095414, 34.852773040716166]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816061224602066, 34.852437971857476], [136.815751016985644, 34.852072626519927], [136.815747265769886, 34.852074678760474], [136.816056222981103, 34.852440024088907], [136.816061224602066, 34.852437971857476]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81574794598734, 34.852079044342716], [136.816003454541317, 34.85097926729631], [136.815996840924072, 34.850976907554511], [136.815744403172459, 34.852076992102276], [136.81574794598734, 34.852079044342716]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815652289985792, 34.851641060868367], [136.815800984009996, 34.851006570716279], [136.81579348157851, 34.851006570716279], [136.815647016527095, 34.851643185039926], [136.815652289985792, 34.851641060868367]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815478952558607, 34.852347502604104], [136.815483120576118, 34.852348870759897], [136.815639004430238, 34.851689759068115], [136.815635878417112, 34.851688219880501], [136.815478952558607, 34.852347502604104]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815398392594972, 34.852709892076788], [136.81540360261684, 34.852710918189118], [136.815482703774336, 34.852379312220592], [136.815476451748111, 34.852378628142951], [136.815398392594972, 34.852709892076788]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815803707206811, 34.850957862659811], [136.81581060837263, 34.85095975045369], [136.815945197415232, 34.850369510317563], [136.815941446199503, 34.85036882622321], [136.815803707206811, 34.850957862659811]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816155460872125, 34.850292806202525], [136.816007040374728, 34.850930594040506], [136.816011208392212, 34.850930594040506], [136.8161587952861, 34.850294174392502], [136.816155460872125, 34.850292806202525]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816047509219118, 34.850356769059168], [136.816049593227859, 34.850351980397825], [136.815632179301645, 34.850289813287013], [136.815633157395553, 34.850292546183795], [136.816047509219118, 34.850356769059168]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816044396231547, 34.850353092051492], [136.816047730645522, 34.850353092051492], [136.816078938676469, 34.850229228100012], [136.816073937055478, 34.850228372980609], [136.816044396231547, 34.850353092051492]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816184597919857, 34.850249408915317], [136.816187932333861, 34.850245646390803], [136.816078334929529, 34.850229637166635], [136.816078334929529, 34.850235300598293], [136.816184597919857, 34.850249408915317]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816181651995322, 34.850301215233756], [136.8164254963053, 34.85027159925513], [136.816422891294366, 34.850269204922014], [136.816181651995322, 34.850297439615716], [136.816181651995322, 34.850301215233756]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816490829979472, 34.8503175191303], [136.816516411186853, 34.850195664656709], [136.816513649875276, 34.850195322608812], [136.816488641770292, 34.85031760464215], [136.816490829979472, 34.8503175191303]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816430497926262, 34.85027100067186], [136.81642778871489, 34.85027100067186], [136.816418827477293, 34.850308198338347], [136.816422370292173, 34.850308283850204], [136.816430497926262, 34.85027100067186]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816490725779005, 34.850317946689543], [136.816491038380349, 34.850316236452571], [136.816419348479485, 34.850305718494432], [136.816419140078608, 34.850308283850204], [136.816490725779005, 34.850317946689543]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816516163711356, 34.850197716944194], [136.816517088489718, 34.850195921192636], [136.816444825486457, 34.850184548099257], [136.816444043983125, 34.850187198970794], [136.816516163711356, 34.850197716944194]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816444512885141, 34.850184462587265], [136.81643648945149, 34.850220805173407], [136.816438365059355, 34.850221403757054], [136.816447534697829, 34.850185146683152], [136.816444512885141, 34.850184462587265]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81642054678457, 34.85021584548025], [136.816183764316349, 34.850246330486186], [136.81618293071287, 34.850250435058335], [136.816423881198574, 34.850217897767109], [136.81642054678457, 34.85021584548025]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815635513715648, 34.85029015533452], [136.815630928896411, 34.850289129191999], [136.815615271299293, 34.850354281293889], [136.815621022270818, 34.850355225197767], [136.815635513715648, 34.85029015533452]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815943933984443, 34.850372332206611], [136.815944559187102, 34.850368740711311], [136.815690948348276, 34.850326070314772], [136.81569219875351, 34.850330174882949], [136.815943933984443, 34.850372332206611]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815683497228235, 34.850365703473358], [136.815688098005438, 34.850363815665844], [136.815697299559929, 34.850328891219], [136.815691548588376, 34.850328891219], [136.815683497228235, 34.850365703473358]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815689281141289, 34.850362327326557], [136.815614121105, 34.850351449582206], [136.815614121105, 34.850354281293889], [136.815686780330765, 34.850365747798541], [136.815689281141289, 34.850362327326557]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815966965642531, 34.850976021238417], [136.816264207053962, 34.851022433029698], [136.816265249058318, 34.851020038718431], [136.815967311019733, 34.850971769777402], [136.815966965642531, 34.850976021238417]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816321725695332, 34.851066727775553], [136.816351110218676, 34.850945130945071], [136.816347775804672, 34.850944275833108], [136.816315473669079, 34.851065701642717], [136.816321725695332, 34.851066727775553]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81626649946358, 34.851020038718431], [136.816263790252179, 34.851019354629486], [136.816254422206299, 34.851056149533939], [136.816258735434985, 34.851056857455795], [136.81626649946358, 34.851020038718431]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816322350897991, 34.851063820399148], [136.81625729769209, 34.85105308187245], [136.816255572400621, 34.851056621481845], [136.81632172569536, 34.851066556753409], [136.816322350897991, 34.851063820399148]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816350693416894, 34.850948209348054], [136.81635152702043, 34.850945130945071], [136.816282847388891, 34.850932410954726], [136.816282847388891, 34.850936186543635], [136.816350693416894, 34.850948209348054]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816282847388891, 34.850933354851975], [136.816273168291559, 34.850973349634828], [136.816277544709919, 34.850974204746478], [136.81628744816615, 34.850935242646422], [136.816282847388891, 34.850933354851975]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816277127908165, 34.85097078429979], [136.815977407571097, 34.850923297094702], [136.815978254527522, 34.85092677233316], [136.81627816991255, 34.850974204746478], [136.816277127908165, 34.85097078429979]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81554376523053, 34.85096856100936], [136.815828219398725, 34.851013582628653], [136.815827802596999, 34.851010504228114], [136.815544807234943, 34.85096565362948], [136.81554376523053, 34.85096856100936]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815839889847723, 34.850960565714317], [136.815556894485667, 34.850915544066019], [136.815556477683884, 34.850918451447662], [136.815839473045969, 34.850964328206146], [136.815839889847723, 34.850960565714317]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815386630971119, 34.851652134473888], [136.815673585949838, 34.851698010823299], [136.815673169148056, 34.851694248365028], [136.815387464574599, 34.851648372013528], [136.815386630971119, 34.851652134473888]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815680254777817, 34.851645336391847], [136.815399343424446, 34.851600828181311], [136.815398093019212, 34.851602880433632], [136.815680254777817, 34.851647730684924], [136.815680254777817, 34.851645336391847]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815208448223416, 34.852335531239873], [136.815479564735597, 34.852380552111221], [136.815481023541707, 34.852377131722982], [136.815208031421662, 34.85233176881075], [136.815208448223416, 34.852335531239873]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815518535699084, 34.852349896876603], [136.815168018453761, 34.852297906940947], [136.815166768048499, 34.852300643254175], [136.815517702095548, 34.852353317265965], [136.815518535699084, 34.852349896876603]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815155514401255, 34.852376917948817], [136.81517176966949, 34.852298248980105], [136.815167601651979, 34.852297564901782], [136.815150512780264, 34.852377602026479], [136.815155514401255, 34.852376917948817]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815211782637391, 34.852333821044837], [136.815207197818154, 34.852332452888788], [136.815195527369156, 34.852384442802759], [136.815199278584913, 34.852385810957941], [136.815211782637391, 34.852333821044837]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815198861783159, 34.852382048531119], [136.815151346383743, 34.852375207754648], [136.815150929582018, 34.852377944065303], [136.815200112188421, 34.852386837074313], [136.815198861783159, 34.852382048531119]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815496666632896, 34.850870736171139], [136.815468949316568, 34.850992504133856], [136.815472492131448, 34.850992675156149], [136.815501043051285, 34.850871933328953], [136.815496666632896, 34.850870736171139]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815556686084761, 34.85091810940277], [136.815560854102245, 34.85091810940277], [136.81556831731686, 34.850881603584092], [136.815563986369654, 34.850880972705347], [136.815556686084761, 34.85091810940277]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81549708343465, 34.85087569582484], [136.815566774103843, 34.8508857479152], [136.815568405308426, 34.850882178382349], [136.815497708637281, 34.850872275374051], [136.81549708343465, 34.85087569582484]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815469222790739, 34.850988891435044], [136.815468072596445, 34.850993138969635], [136.815538138406936, 34.85100379160442], [136.815538972010415, 34.851000542181232], [136.815469222790739, 34.850988891435044]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815538972010415, 34.851004133648964], [136.81554730804541, 34.850966166696523], [136.815544182032284, 34.850965995674173], [136.815535220794686, 34.851002936493067], [136.815538972010415, 34.851004133648964]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81534224158483, 34.851556704744105], [136.815313690665022, 34.851677787608175], [136.815317650281628, 34.851678300670784], [136.815345575998833, 34.851556875765219], [136.81534224158483, 34.851556704744105]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815398509820966, 34.851602709412603], [136.815401635834064, 34.851603393496696], [136.815408929864674, 34.851566281926281], [136.81540622065333, 34.851566452947381], [136.815398509820966, 34.851602709412603]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815342658386555, 34.851558414955285], [136.815408304662071, 34.851569702348222], [136.815408304662071, 34.851566623968488], [136.815344117192694, 34.851556191680736], [136.815342658386555, 34.851558414955285]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815315357872009, 34.851674196169853], [136.815312857061514, 34.851677274545573], [136.815381629350128, 34.851687706817962], [136.815381420949251, 34.8516851415052], [136.815315357872009, 34.851674196169853]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815382254552731, 34.851687193755424], [136.815391424191233, 34.851649056097237], [136.815387256173722, 34.851648372013514], [136.815378711737878, 34.851686509672021], [136.815382254552731, 34.851687193755424]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816062058205631, 34.855302666086452], [136.816069560637089, 34.855303350139792], [136.816070811042351, 34.855298219739652], [136.816063725412619, 34.8552968516329], [136.816062058205631, 34.855302666086452]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815854751435637, 34.85269283295753], [136.815856418642625, 34.852689412582279], [136.815842247383159, 34.852670942553488], [136.815812654458995, 34.852666838102088], [136.81581307126072, 34.852670258478263], [136.815839746572692, 34.852674362929498], [136.815854751435637, 34.85269283295753]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815784728741789, 34.852667864214958], [136.815785979147023, 34.852664785876307], [136.81572074967292, 34.852654268218252], [136.815724084086895, 34.852658030632618], [136.815784728741789, 34.852667864214958]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815748258588343, 34.852583808426438], [136.815754510614596, 34.852585176578309], [136.815763888654345, 34.852533785358133], [136.815760137438588, 34.852531391090842], [136.815748258588343, 34.852583808426438]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815773475094545, 34.852576882157365], [136.815777226310303, 34.852576882157365], [136.815780977526032, 34.852560464331752], [136.815777226310303, 34.852559780255611], [136.815773475094545, 34.852576882157365]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816000358521137, 34.852506593318274], [136.815999108115903, 34.852500778667121], [136.815679590500423, 34.852454090423798], [136.815677923293435, 34.85245853692431], [136.816000358521137, 34.852506593318274]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816182357610472, 34.850301100853969], [136.816181524006964, 34.850297338331821], [136.816157766307271, 34.850293917856987], [136.816157766307271, 34.85029802242677], [136.816182357610472, 34.850301100853969]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816421171987173, 34.850218154302965], [136.816438469259765, 34.850221061709256], [136.816439511264178, 34.850218923910525], [136.816420442584132, 34.85021584548025], [136.816421171987173, 34.850218154302965]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815436855581851, 34.854795781003446], [136.815436438780097, 34.854798175204827], [136.815508545482658, 34.854808436067138], [136.815508545482658, 34.854806383894775], [136.815436855581851, 34.854795781003446]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815458112471077, 34.85503964715857], [136.815510629491456, 34.854806383894775], [136.815507295077452, 34.854806383894775], [136.815453944453566, 34.855037594991984], [136.815458112471077, 34.85503964715857]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81601579029649, 34.855463969684287], [136.816010371873745, 34.855482097060133], [136.816267538552978, 34.855522114083236], [136.816272123372215, 34.855502276587032], [136.81601579029649, 34.855463969684287]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816083315094744, 34.855478125579857], [136.816095819147222, 34.855411772521087], [136.816090817526231, 34.855413824678351], [136.81607414545627, 34.855477441527974], [136.816083315094744, 34.855478125579857]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816305613375448, 34.855495285673683], [136.816384819878323, 34.855505299675329], [136.816385740884158, 34.85550246552404], [136.816307455387147, 34.855490751031027], [136.816305613375448, 34.855495285673683]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816366860264253, 34.855576342369019], [136.816370774539138, 34.855576909198767], [136.81638597113556, 34.855502654467458], [136.816382747615137, 34.855503032354306], [136.816366860264253, 34.855576342369019]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81630400161518, 34.855508700656735], [136.816430467231442, 34.854937995112614], [136.816427013459531, 34.854937428278454], [136.816300778094728, 34.855507944883108], [136.81630400161518, 34.855508700656735]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815878248634277, 34.855583127475882], [136.815929932051176, 34.855355338176416], [136.815925764033665, 34.855356706282201], [136.815873247013286, 34.855582443424872], [136.815878248634277, 34.855583127475882]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816160423418467, 34.854906598251894], [136.816167925849953, 34.854907282308531], [136.816169176255187, 34.854902151883685], [136.816162090625454, 34.854900783770347], [136.816160423418467, 34.854906598251894]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816128746485532, 34.855036568908666], [136.816136248917019, 34.855037252964209], [136.816137499322252, 34.855032122547485], [136.81613041369252, 34.855030754436306], [136.816128746485532, 34.855036568908666]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816092067931606, 34.855170643686797], [136.816099570363065, 34.855171327741232], [136.816100820768327, 34.855166197332863], [136.816093735138594, 34.855164829223916], [136.816092067931606, 34.855170643686797]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815925347231911, 34.855279408269766], [136.815932849663398, 34.855280092323291], [136.815934100068603, 34.855274961921708], [136.815927014438898, 34.855273593814566], [136.815925347231911, 34.855279408269766]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816023712444746, 34.854883340323248], [136.816031214876205, 34.854884024380063], [136.816032465281467, 34.854878893953781], [136.816025379651705, 34.854877525840052], [136.816023712444746, 34.854883340323248]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815992035511812, 34.855013311016748], [136.81599953794327, 34.85501399507249], [136.816000788348504, 34.855008864654309], [136.815993702718799, 34.855007496542747], [136.815992035511812, 34.855013311016748]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815955356957886, 34.855147385832794], [136.815962859389344, 34.85514806988742], [136.815964109794578, 34.855142939477602], [136.815957024164874, 34.855141571368272], [136.815955356957886, 34.855147385832794]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816453630423354, 34.854689623753785], [136.816456131233849, 34.854683467227652], [136.816322559298897, 34.85466033749703], [136.816322559298897, 34.854669914317945], [136.816453630423354, 34.854689623753785]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816330895333891, 34.854664441848982], [136.816322559298897, 34.854663073731686], [136.816288954657665, 34.854796678828933], [136.816300625106663, 34.854798731001537], [136.816330895333891, 34.854664441848982]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816190016342631, 34.854788940427646], [136.81627671110644, 34.854431861581929], [136.816269208674981, 34.854433229703069], [136.816183347514652, 34.854786204197161], [136.816190016342631, 34.854788940427646]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81609331833684, 34.854769102754645], [136.816174177876178, 34.854417496308578], [136.816170009858695, 34.854417496308578], [136.816085815905353, 34.854769786812419], [136.81609331833684, 34.854769102754645]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815964109794606, 34.854745844787118], [136.815963276191098, 34.854749265076876], [136.816190655536758, 34.854787079318186], [136.8161885832736, 34.854782828054098], [136.815964109794606, 34.854745844787118]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816099153561311, 34.85440655133673], [136.816098319957831, 34.854405183215142], [136.816069977438872, 34.854508476330764], [136.816074979059891, 34.854510528510552], [136.816099153561311, 34.85440655133673]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815752322405785, 34.855254055531944], [136.815846571701343, 34.854845717189413], [136.81584240368386, 34.854845033132278], [136.815749040092044, 34.855254825092388], [136.815752322405785, 34.855254055531944]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81621980464206, 34.855328702862295], [136.816227723875301, 34.855331439074718], [136.81631505686741, 34.854924383722306], [136.816310888849898, 34.854923699665818], [136.81621980464206, 34.855328702862295]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815929098447668, 34.855361494652243], [136.815927431240681, 34.855357390335065], [136.815839069269856, 34.855344393329304], [136.815839069269856, 34.855348497647142], [136.815929098447668, 34.855361494652243]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815810726750954, 34.854798517233576], [136.815885751065792, 34.854479061748108], [136.815882156150508, 34.854479275516915], [136.815807809138704, 34.854797491147274], [136.815810726750954, 34.854798517233576]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815796555491431, 34.855296851632886], [136.815799264702832, 34.855297364672921], [136.815808017539553, 34.855261109835823], [136.81580530832818, 34.855259228688183], [136.815796555491431, 34.855296851632886]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815806350332537, 34.855264017063917], [136.815804683125549, 34.855261280849241], [136.815752426606224, 34.855253670751708], [136.815750915699908, 34.855256150446493], [136.815806350332537, 34.855264017063917]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816169749357556, 34.855356235995828], [136.816178085392579, 34.855316560918645], [136.816172250168052, 34.855315876865419], [136.816165581340073, 34.855356920048713], [136.816169749357556, 34.855356235995828]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816175623656648, 34.855322546383988], [136.816227307073547, 34.85532972894196], [136.816226890271793, 34.855324598543476], [136.816172706044398, 34.855316047878617], [136.816175623656648, 34.855322546383988]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816090557025234, 34.855417116680499], [136.816248941689906, 34.855441058510443], [136.816248941689906, 34.855435586092796], [136.816095558646197, 34.855414380470918], [136.816090557025234, 34.855417116680499]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816244773672423, 34.855439006353862], [136.816251442500402, 34.855440374458261], [136.816268114570363, 34.855364444630098], [136.816261445742356, 34.855361708418755], [136.816244773672423, 34.855439006353862]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816266447363375, 34.855365812735734], [136.816270615380859, 34.855363076524441], [136.816168121225189, 34.855350934585651], [136.816168954828669, 34.855354012823788], [136.816266447363375, 34.855365812735734]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815910915471221, 34.854742980294318], [136.815912582678237, 34.854738875946289], [136.815824220707412, 34.854723826668398], [136.81582088629338, 34.85472793101718], [136.815910915471221, 34.854742980294318]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816073051351623, 34.854512110399085], [136.816072217748143, 34.854508690099472], [136.815884656961003, 34.854479959577098], [136.815886324167991, 34.854485432058326], [136.816073051351623, 34.854512110399085]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81609805945655, 34.85440813322726], [136.81609805945655, 34.854404712923326], [136.816013865503237, 34.854390347645243], [136.816013865503237, 34.854395820132424], [136.81609805945655, 34.85440813322726]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816171416564401, 34.854418394138229], [136.816457173238234, 34.85446354213115], [136.816458423643468, 34.854460976904939], [136.816168915753934, 34.85441565789543], [136.816171416564401, 34.854418394138229]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816017199917269, 34.854395136071538], [136.816069716937648, 34.854174184110192], [136.816065548920164, 34.854173500047466], [136.816014699106773, 34.854393767949759], [136.816017199917269, 34.854395136071538]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816454008150373, 34.854462174010592], [136.816459009771364, 34.854462174010592], [136.816514861205746, 34.854208387238998], [136.816512360395251, 34.854207019114121], [136.816454008150373, 34.854462174010592]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816514483478727, 34.854209242316955], [136.816514066677001, 34.8542065060672], [136.815636243118547, 34.854070206511288], [136.815635409515039, 34.854074310892678], [136.816514483478727, 34.854209242316955]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816067672003669, 34.854177946454968], [136.816066421598435, 34.854174526141463], [136.815599564564593, 34.854100989366756], [136.815598730961113, 34.854105093746618], [136.816067672003669, 34.854177946454968]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815872986512034, 34.854806939691429], [136.815872152908526, 34.854803519404065], [136.815809632646136, 34.854794626656279], [136.815809632646136, 34.854795994771386], [136.815872986512034, 34.854806939691429]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815854647235085, 34.854851403414138], [136.815861316063064, 34.854851403414138], [136.815872986512034, 34.854804203461555], [136.815867151287534, 34.854802151289093], [136.815854647235085, 34.854851403414138]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815842976786087, 34.854847983128622], [136.815902996237952, 34.854858928041757], [136.815902996237952, 34.854856191813603], [136.815839642372111, 34.854843878785829], [136.815842976786087, 34.854847983128622]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816312295555633, 34.854925281546421], [136.816318130780104, 34.854923229376965], [136.81626311294923, 34.854913652585552], [136.816263946552738, 34.854919125037931], [136.816312295555633, 34.854925281546421]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816346367885075, 34.854873234284753], [136.816347922082429, 34.854869549859757], [136.816277284208695, 34.854859612098785], [136.816277284208695, 34.854864400497775], [136.816346367885075, 34.854873234284753]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816359810955049, 34.854806939691429], [136.81636481257604, 34.854803519404065], [136.816290621864709, 34.854791890426], [136.816289788261201, 34.854796678828933], [136.816359810955049, 34.854806939691429]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816341471678072, 34.854869188896494], [136.816347306902543, 34.854871241067286], [136.816363982121914, 34.854803088472785], [136.816357247266637, 34.854803230181552], [136.816341471678072, 34.854869188896494]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816265613759697, 34.854915704755243], [136.816270615380716, 34.854915704755243], [136.816283953036674, 34.854862348326812], [136.816278117812203, 34.854860980212806], [136.816265613759697, 34.854915704755243]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815637076722027, 34.854070206511274], [136.815641283814244, 34.854071574638347], [136.815825471112646, 34.853272584507145], [136.815820469491683, 34.853275320787958], [136.815637076722027, 34.854070206511274]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815825262711741, 34.853273610612455], [136.816014529780631, 34.852443658248518], [136.816010361763148, 34.85244160601718], [136.815819635888175, 34.853279767244082], [136.815825262711741, 34.853273610612455]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816009792414434, 34.852444391871416], [136.816011195366627, 34.852441948055734], [136.815857831845193, 34.852261502400061], [136.815859127853656, 34.852266396577278], [136.816009792414434, 34.852444391871416]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815683758517935, 34.852456484693334], [136.815717350133468, 34.852315393691008], [136.815712348512477, 34.852314709612827], [136.815678756896943, 34.852457168770329], [136.815683758517935, 34.852456484693334]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815815799074215, 34.852211036656556], [136.815771192703011, 34.852204549645897], [136.815770910384231, 34.852207329793387], [136.815811564292147, 34.852213816803825], [136.815815799074215, 34.852211036656556]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815782203136422, 34.852179759993319], [136.815784179368052, 34.85217674816586], [136.815640202735182, 34.852153095984384], [136.815641453140415, 34.852155490262675], [136.815782203136422, 34.852179759993319]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815690218945036, 34.852160278819049], [136.815768577673879, 34.851816870211984], [136.815764826458121, 34.851816186129668], [136.815685634125799, 34.852158910660116], [136.815690218945036, 34.852160278819049]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815767744070371, 34.851818580417763], [136.815767327268645, 34.851816186129668], [136.815716477455254, 34.85180797714137], [136.815716477455254, 34.851809687347327], [136.815767744070371, 34.851818580417763]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815636907395827, 34.852169129096538], [136.815818177082036, 34.851387265394642], [136.815813175461045, 34.85138521313695], [136.815632114175713, 34.852167076858343], [136.815636907395827, 34.852169129096538]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815812341857594, 34.85161917018452], [136.815810674650578, 34.85161574976464], [136.815762325647682, 34.851607540756355], [136.815760658440695, 34.851610961176569], [136.815812341857594, 34.85161917018452]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815829013927498, 34.851556234435968], [136.815829847531006, 34.851552129928955], [136.815777330510628, 34.851545289083475], [136.815777330510628, 34.851548025421735], [136.815829013927498, 34.851556234435968]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81580984104707, 34.85161917018452], [136.815814009064553, 34.851620538352435], [136.815830681134486, 34.851550761759903], [136.815824845910043, 34.851549393590822], [136.81580984104707, 34.85161917018452]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815868193291948, 34.851399578939741], [136.815866526084903, 34.851392738081586], [136.815814009064553, 34.851383844965127], [136.815814009064553, 34.851389317652284], [136.815868193291948, 34.851399578939741]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815859023653417, 34.851393422167412], [136.815864025274436, 34.851396842596536], [136.815907372656341, 34.851208034696263], [136.815899036621346, 34.851205298346706], [136.815859023653417, 34.851393422167412]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815901537431841, 34.851213507395123], [136.815901537431841, 34.851208034696263], [136.81585068761845, 34.851198457472385], [136.81585068761845, 34.851205298346706], [136.815901537431841, 34.851213507395123]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815924835020553, 34.850897029648401], [136.815979896160712, 34.850664525477725], [136.815969892918758, 34.850662473202007], [136.815918848482539, 34.850893250568333], [136.815924835020553, 34.850897029648401]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815895741282105, 34.850998874713909], [136.815896991687339, 34.850995112223671], [136.815484318880948, 34.850932689065068], [136.81548265167396, 34.850936793602983], [136.815895741282105, 34.850998874713909]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816331676836995, 34.851007596849769], [136.816333344043983, 34.851004176404459], [136.815974584383042, 34.850949361379577], [136.815971761194987, 34.850951678204495], [136.816331676836995, 34.851007596849769]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816027411560128, 34.850861885753837], [136.816024910749604, 34.850857097121889], [136.815990733006174, 34.850852308489664], [136.815988232195707, 34.850857097121889], [136.816027411560128, 34.850861885753837]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815995734627165, 34.850855044850981], [136.815990733006174, 34.850854360760664], [136.815975728143201, 34.850924137943771], [136.815979896160712, 34.850925848168096], [136.815995734627165, 34.850855044850981]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815983647376441, 34.851050010359728], [136.815984897781703, 34.851047616049264], [136.815949886434765, 34.851041117206215], [136.815948219227778, 34.851044537649983], [136.815983647376441, 34.851050010359728]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815951970443507, 34.851044537649983], [136.81596989291873, 34.850972024211657], [136.815965724901247, 34.850971340122314], [136.815948636029503, 34.851043169472504], [136.815951970443507, 34.851044537649983]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815838600367755, 34.850962789005031], [136.815840267574742, 34.850963815139139], [136.815857356446458, 34.85089232776469], [136.815854438834208, 34.850892669809696], [136.815838600367755, 34.850962789005031]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81585568923947, 34.850894722079673], [136.81585568923947, 34.85089232776469], [136.815824012306535, 34.850886855044472], [136.815823178702999, 34.850889933449643], [136.81585568923947, 34.850894722079673]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815781915329808, 34.851074979593257], [136.81578316573507, 34.851076689814455], [136.815812758659263, 34.851081136389404], [136.815814009064496, 34.851079768212529], [136.815781915329808, 34.851074979593257]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815811925055755, 34.851080452300963], [136.815814009064496, 34.851080452300963], [136.81582901392747, 34.851011359339438], [136.815826513116974, 34.851011017294923], [136.815811925055755, 34.851080452300963]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816067125028354, 34.850422762600616], [136.816108687901135, 34.850258857981736], [136.816102852676693, 34.85026022617226], [136.816063671256416, 34.850422195735355], [136.816067125028354, 34.850422762600616]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816103686280201, 34.850262278458011], [136.81618454581951, 34.850274592171466], [136.816188713836993, 34.850271855790858], [136.816103686280201, 34.850259542076998], [136.816103686280201, 34.850262278458011]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816503438232388, 34.850256976719663], [136.816503855034142, 34.850253898290809], [136.816428791644512, 34.850243123788971], [136.81642754123925, 34.850245860170538], [136.816503438232388, 34.850256976719663]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816428882819565, 34.850245945682424], [136.816428466017811, 34.850242867253158], [136.81618454581951, 34.850271171695688], [136.81618454581951, 34.850275276266608], [136.816428882819565, 34.850245945682424]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815677298090748, 34.851645849454691], [136.815679798901243, 34.851647217622165], [136.815697304574684, 34.851576414925781], [136.815694803764188, 34.851576072883624], [136.815677298090748, 34.851645849454691]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815698138178192, 34.851578467178705], [136.815698138178192, 34.851576072883624], [136.815663543633008, 34.851570600208873], [136.815663543633008, 34.851572652461947], [136.815698138178192, 34.851578467178705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815331040037591, 34.851614809149133], [136.815330623235837, 34.851618229569056], [136.815678170769075, 34.851672015653548], [136.815678170769075, 34.851668595235864], [136.815331040037591, 34.851614809149133]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815654894996698, 34.851763939325572], [136.815656979005439, 34.851764623408336], [136.815675318282388, 34.851694162854656], [136.815672400670138, 34.851693820812983], [136.815654894996698, 34.851763939325572]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815656562203685, 34.851762913201433], [136.815621068929403, 34.851756499925209], [136.815618151317182, 34.85175957829783], [136.815656979005439, 34.851764623408336], [136.815656562203685, 34.851762913201433]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815822252598878, 34.852241246272939], [136.815801529967388, 34.852335343773312], [136.815808212914845, 34.852335745014145], [136.815829068058065, 34.852241386592482], [136.815822252598878, 34.852241246272939]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81585746064664, 34.852261608027078], [136.815841622180216, 34.852340277030827], [136.815845790197699, 34.852341987225728], [136.815861628664152, 34.852267422695149], [136.81585746064664, 34.852261608027078]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815794313489221, 34.852209613142776], [136.815791929238571, 34.852218816503424], [136.81582723807179, 34.852223535697952], [136.815829192999217, 34.852214265498617], [136.815794313489221, 34.852209613142776]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815785992023137, 34.852238822373828], [136.815822641755801, 34.852243524554375], [136.81582493659576, 34.85223525220168], [136.815789608915509, 34.852230232504482], [136.815785992023137, 34.852238822373828]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815794305788216, 34.852209652509671], [136.815786408790444, 34.852238817974815], [136.815798088995194, 34.852239378825075], [136.815804002899227, 34.852211250251329], [136.815794305788216, 34.852209652509671]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815844956594191, 34.852338908874891], [136.815808694842019, 34.852333094211865], [136.81580786123854, 34.852335830523927], [136.815844539792465, 34.852341645186755], [136.815844956594191, 34.852338908874891]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815760971041726, 34.852254083161917], [136.815782203136422, 34.852178138240077], [136.815776556760312, 34.852176979844899], [136.815756803024243, 34.852252030925833], [136.815760971041726, 34.852254083161917]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815758405105811, 34.852249551140481], [136.8156186461473, 34.852227034606123], [136.815622592860962, 34.852231508562269], [136.815758405105811, 34.852252458474993], [136.815758405105811, 34.852249551140481]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815670564137008, 34.852549989415074], [136.815666812921279, 34.852554435910399], [136.815756594623338, 34.85256910079238], [136.815757845028571, 34.852563970221745], [136.815670564137008, 34.852549989415074]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815761179442575, 34.852627247237265], [136.815764930658332, 34.852629299463985], [136.815769515477569, 34.852612539610973], [136.81576451385655, 34.8526108294217], [136.815761179442575, 34.852627247237265]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815625302072363, 34.852644691162716], [136.815626135675842, 34.852641612823199], [136.815531521678793, 34.852626905199465], [136.815530271273559, 34.852629983539543], [136.815625302072363, 34.852644691162716]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815610297209389, 34.852695996804378], [136.815612798019856, 34.852697364954381], [136.815625718874088, 34.852643323011833], [136.815623218063593, 34.852642296898658], [136.815610297209389, 34.852695996804378]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815643641349311, 34.852702837554183], [136.815646558961532, 34.852703863666605], [136.815660313419301, 34.85264982172832], [136.815657395807023, 34.852649137652925], [136.815643641349311, 34.852702837554183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815688649787035, 34.852656318223303], [136.815688649787035, 34.852652917123976], [136.815657812608777, 34.852648453577523], [136.815657812608777, 34.852650847841403], [136.815688649787035, 34.852656318223303]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815512765600062, 34.852724385912353], [136.815534439291042, 34.852628957426198], [136.815530688075285, 34.852627931312838], [136.815509014384304, 34.852723017762784], [136.815512765600062, 34.852724385912353]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815631970900341, 34.852534212905731], [136.815633638107329, 34.852531818638454], [136.815552361766237, 34.852518479148081], [136.815551528162757, 34.852521557492203], [136.815631970900341, 34.852534212905731]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815555005852133, 34.852507747697473], [136.815545276136532, 34.852547210355425], [136.815550694559249, 34.852548236469787], [136.815559799072275, 34.852508089735764], [136.815555005852133, 34.852507747697473]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815622384460113, 34.852553367041402], [136.81562780288283, 34.8525543931557], [136.815635448589717, 34.852523994514506], [136.815632114175713, 34.852523652476286], [136.815622384460113, 34.852553367041402]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815645308556327, 34.852701127366778], [136.815609463605881, 34.852695654766869], [136.815609880407607, 34.852697022916885], [136.815642390944078, 34.852702837554183], [136.815645308556327, 34.852701127366778]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815651339156545, 34.852453833894877], [136.815653423165315, 34.852447335162907], [136.815647171139091, 34.852445967008748], [136.815645503932075, 34.852452807779343], [136.815651339156545, 34.852453833894877]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81642997692407, 34.850273052957341], [136.816429351721467, 34.850270915159953], [136.816421640889104, 34.850269290433907], [136.816420911486034, 34.850271342719438], [136.81642997692407, 34.850273052957341]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815779245193283, 34.852275033068885], [136.815778411589775, 34.85227195471554], [136.815724227362352, 34.852262719654803], [136.815723393758873, 34.852266482087082], [136.815779245193283, 34.852275033068885]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815777994788021, 34.852271612676262], [136.815780912400271, 34.852275717147386], [136.815796925536944, 34.852209072454983], [136.815793299076404, 34.852208647315258], [136.815777994788021, 34.852271612676262]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815425693109546, 34.852616857838697], [136.815428819122673, 34.852617370895445], [136.815429444325304, 34.852614292554911], [136.815426318312177, 34.85261395051706], [136.815425693109546, 34.852616857838697]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815480085737732, 34.852381706492174], [136.815512596274175, 34.852387179112959], [136.815512596274175, 34.852385126880208], [136.815480085737732, 34.852377602026337], [136.815480085737732, 34.852381706492174]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815511033267569, 34.852373497560293], [136.81549030749153, 34.852460071526274], [136.815494058707287, 34.852458703372328], [136.815513742478942, 34.852373668579716], [136.815511033267569, 34.852373497560293]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815494256997226, 34.852458665188649], [136.815463413667771, 34.852453876649633], [136.815461746460784, 34.852455928880673], [136.81549258979021, 34.852460033342602], [136.815494256997226, 34.852458665188649]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815567718305488, 34.852442760397288], [136.815573136728204, 34.852443615493669], [136.81557469973481, 34.852437800838047], [136.815569385512475, 34.852436603703019], [136.815567718305488, 34.852442760397288]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815625653748612, 34.852192387792485], [136.815625445347735, 34.852196492267552], [136.815677962368113, 34.852203675098437], [136.815678379169867, 34.852199912663288], [136.815625653748612, 34.852192387792485]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815601270846287, 34.852320652541756], [136.815600854044533, 34.852323730893282], [136.81567296074715, 34.852333992064203], [136.815673377548876, 34.852330571674031], [136.815601270846287, 34.852320652541756]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815633364580947, 34.852178193147942], [136.815630238567849, 34.852177509068625], [136.815624820145104, 34.852196492267552], [136.815629404964341, 34.852196663287344], [136.815633364580947, 34.852178193147942]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815685047997846, 34.852160749123584], [136.815675461557618, 34.85220299101934], [136.815678587570773, 34.852203675098437], [136.815688590812726, 34.8521616042229], [136.815685047997846, 34.852160749123584]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815623778140719, 34.852230012139657], [136.815618776519727, 34.852227275824085], [136.815602625451902, 34.852299617136559], [136.815607627072922, 34.852301327332292], [136.815623778140719, 34.852230012139657]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815634302384865, 34.852306457919312], [136.815638053600594, 34.852307826075794], [136.815656288677161, 34.852232748455144], [136.815652120659649, 34.852232064376281], [136.815634302384865, 34.852306457919312]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815680733237599, 34.852283931388918], [136.815669616308242, 34.852281628506503], [136.815661430521203, 34.852313624147357], [136.815673462074585, 34.852315581038553], [136.815680733237599, 34.852283931388918]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815645868633396, 34.852277128059256], [136.815637367414496, 34.852309710364757], [136.815647732278791, 34.852311633155779], [136.815655455073625, 34.852278752745654], [136.815645868633396, 34.852277128059256]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815680685591161, 34.852283923586491], [136.815645556032081, 34.852277128059256], [136.815643888825065, 34.852285764549762], [136.815678373658642, 34.852291815265225], [136.815680685591161, 34.852283923586491]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815513534078093, 34.852360842122053], [136.815516451690314, 34.852361355180399], [136.815519160901687, 34.852350067896069], [136.81551603488856, 34.852349725857124], [136.815513534078093, 34.852360842122053]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815801023084504, 34.852474740996151], [136.815795187860033, 34.8524709785734], [136.815791019842521, 34.852490816800518], [136.815795604661758, 34.852491500877228], [136.815801023084504, 34.852474740996151]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815665562516017, 34.852574274117394], [136.815679316973757, 34.852516469673333], [136.815675565758028, 34.852515101520304], [136.81566097769678, 34.852572905965339], [136.815665562516017, 34.852574274117394]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815498008212785, 34.852456612957639], [136.815498008212785, 34.852453876649598], [136.815493840195273, 34.852453192572575], [136.815492589790011, 34.852455244803629], [136.815498008212785, 34.852456612957639]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815926177920431, 34.855332973623121], [136.815932846748382, 34.855330921463846], [136.815933680351918, 34.855328185251402], [136.815927845127419, 34.855326133091999], [136.815926177920431, 34.855332973623121]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816010997076404, 34.855484491241548], [136.816013497886871, 34.855481584021248], [136.815979528544347, 34.855476453632228], [136.815978486539962, 34.855479702878654], [136.816010997076404, 34.855484491241548]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815903253824217, 34.855468416022141], [136.815931596343148, 34.85547252033377], [136.815932846748382, 34.855469442100059], [136.815902837022463, 34.855463627658295], [136.815903253824217, 34.855468416022141]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815939098774663, 34.855456958151045], [136.815939515576417, 34.855452511812587], [136.815908255445208, 34.855446868382664], [136.815907838643483, 34.855450972695373], [136.815939098774663, 34.855456958151045]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816015373494764, 34.855468758048112], [136.816018707908739, 34.855464653736284], [136.81598369656183, 34.855459181320185], [136.815982862958322, 34.855463969684294], [136.816015373494764, 34.855468758048112]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816012979740464, 34.855545350357282], [136.816016932203752, 34.855519866702423], [136.816014109015697, 34.855518940023913], [136.81600789800197, 34.855543960339951], [136.816012979740464, 34.855545350357282]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815789383246965, 34.851731054406279], [136.815790672700587, 34.851722655995438], [136.815786720237298, 34.851722655995438], [136.815784866146117, 34.851731054406279], [136.815789383246965, 34.851731054406279]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815797687645897, 34.851692141280957], [136.815806753797148, 34.851649201888442], [136.815802350237959, 34.851649343602645], [136.815792852365206, 34.851691290996179], [136.815797687645897, 34.851692141280957]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815810552946289, 34.851635243038189], [136.815814524784003, 34.851617528757814], [136.815810034880485, 34.851616961900781], [136.815806408419974, 34.851634109324358], [136.815810552946289, 34.851635243038189]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815802177549415, 34.85165727959766], [136.81580622923164, 34.851654706729306], [136.815753153296299, 34.85164729295267], [136.815753479365242, 34.851649343602645], [136.815802177549415, 34.85165727959766]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815776187915702, 34.85178928625956], [136.81577661963712, 34.851786239409307], [136.81572619456702, 34.851778870282665], [136.815725849189846, 34.851781704562221], [136.815776187915702, 34.85178928625956]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815764539192145, 34.851821873031582], [136.815767985207344, 34.851823085032663], [136.815770834569236, 34.851809976495474], [136.815767380797297, 34.851809834781548], [136.815764539192145, 34.851821873031582]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815769625749056, 34.851798214238705], [136.815773597586741, 34.851799064522382], [136.815787671707312, 34.85174117435524], [136.815784217935374, 34.851740324070967], [136.815769625749056, 34.851798214238705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810987705013417, 34.858561516818305], [136.811046427324868, 34.858330782079229], [136.811040780948758, 34.858332635372882], [136.810982058637336, 34.858561516818305], [136.810987705013417, 34.858561516818305]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810989963563827, 34.85855966352981], [136.810415162477028, 34.858468389019578], [136.810415162477028, 34.858471632278025], [136.810986575738184, 34.858562906784655], [136.810989963563827, 34.85855966352981]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810417421027552, 34.858471632278032], [136.81041403320188, 34.858469778987505], [136.810394835523169, 34.858562443462532], [136.810398223348813, 34.858566150039373], [136.810417421027552, 34.858471632278032]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811124629633753, 34.858401670531904], [136.811926415039721, 34.858530474230555], [136.811926415039721, 34.858525841007484], [136.811124629633781, 34.858397963947674], [136.811124629633753, 34.858401670531904]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81106364877192, 34.858641671506035], [136.811123500358548, 34.85839889059374], [136.811120112532933, 34.858396110655484], [136.811058002395811, 34.858636111645822], [136.81106364877192, 34.858641671506035]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811042192542743, 34.858332172049586], [136.811045580368415, 34.858330318755925], [136.810469650006411, 34.858238580667276], [136.810468520731177, 34.858242287258697], [136.811042192542743, 34.858332172049586]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811971586048486, 34.858531400875137], [136.811971586048486, 34.858528620941364], [136.811122371083343, 34.858394257363265], [136.811122371083343, 34.858395184009375], [136.811971586048486, 34.858531400875137]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811972715323719, 34.858518427850029], [136.811972715323719, 34.858515647915809], [136.811123500358576, 34.858381284316515], [136.811123500358576, 34.85838221096278], [136.811972715323719, 34.858518427850029]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812897591728557, 34.858677810588198], [136.812897591728557, 34.858675030659363], [136.812048376763414, 34.858540667320405], [136.812048376763414, 34.858541593964873], [136.812897591728557, 34.858677810588198]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812898721003791, 34.858664837586169], [136.812898721003791, 34.858662057656893], [136.812049506038647, 34.858527694296754], [136.812049506038647, 34.858528620941364], [136.812898721003791, 34.858664837586169]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813602259465597, 34.858789007664498], [136.813601694828009, 34.858787617701971], [136.813143773726438, 34.858714876297], [136.813143773726438, 34.8587158029395], [136.813602259465597, 34.858789007664498]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813605082653652, 34.858776498000914], [136.813604518016035, 34.858774644717272], [136.813144903001671, 34.858701903300819], [136.813144903001671, 34.858702829943468], [136.813605082653652, 34.858776498000914]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814864858892264, 34.858635515582662], [136.814868914845675, 34.858631610074283], [136.814855008719661, 34.858629164886324], [136.8148569953091, 34.858634394871579], [136.814864858892264, 34.858635515582662]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814854967332366, 34.858629164886324], [136.814868956232914, 34.858631644035221], [136.814866801592984, 34.858626345668071], [136.814859183663202, 34.858625137664042], [136.814854967332366, 34.858629164886324]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814801054842349, 34.85862717579159], [136.81480511079576, 34.858623270282806], [136.814791204669746, 34.858620825094604], [136.814793191259184, 34.858626055080393], [136.814801054842349, 34.85862717579159]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814791163282479, 34.858620825094604], [136.814805152182998, 34.85862330424375], [136.814802997543097, 34.858618005876068], [136.814795379613287, 34.858616797871917], [136.814791163282479, 34.858620825094604]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814929792217413, 34.85864385537289], [136.814933848170796, 34.858639949864894], [136.814919942044781, 34.858637504677191], [136.81492192863422, 34.85864273466192], [136.814929792217413, 34.85864385537289]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814919900657515, 34.858637504677191], [136.814933889558034, 34.858639983825832], [136.814931734918133, 34.858634685459229], [136.814924116988323, 34.858633477455314], [136.814919900657515, 34.858637504677191]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814994160904888, 34.858652195162264], [136.814998216858299, 34.858648289654674], [136.814984310732285, 34.858645844467212], [136.814986297321724, 34.858651074451416], [136.814994160904888, 34.858652195162264]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814984269345018, 34.858645844467212], [136.814998258245538, 34.858648323615611], [136.814996103605637, 34.858643025249528], [136.814988485675826, 34.85864181724574], [136.814984269345018, 34.858645844467212]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814886315121413, 34.858540997901031], [136.814890371074824, 34.858537092388154], [136.81487646494881, 34.858534647197395], [136.814878451538249, 34.858539877188655], [136.814886315121413, 34.858540997901031]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814876423561543, 34.858534647197395], [136.814890412462091, 34.858537126349134], [136.814888257822162, 34.858531827975895], [136.814880639892351, 34.858530619970473], [136.814876423561543, 34.858534647197395]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814822511071526, 34.858532658100373], [136.814826567024937, 34.858528752587105], [136.814812660898923, 34.858526307396083], [136.814814647488362, 34.85853153738789], [136.814822511071526, 34.858532658100373]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814812619511628, 34.858526307396083], [136.814826608412147, 34.858528786548085], [136.814824453772246, 34.858523488174313], [136.814816835842464, 34.858522280168764], [136.814812619511628, 34.858526307396083]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814951248446562, 34.858549337700836], [136.814955304399973, 34.858545432188357], [136.814941398273959, 34.858542986997847], [136.814943384863398, 34.858548216988574], [136.814951248446562, 34.858549337700836]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814941356886692, 34.858542986997847], [136.814955345787212, 34.858545466149337], [136.81495319114731, 34.858540167776638], [136.8149455732175, 34.85853895977133], [136.814941356886692, 34.858542986997847]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815015617134065, 34.858557677499803], [136.815019673087477, 34.858553771987715], [136.815005766961463, 34.858551326797446], [136.815007753550901, 34.858556556787661], [136.815015617134065, 34.858557677499803]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815005725574167, 34.858551326797446], [136.815019714474715, 34.858553805948695], [136.815017559834786, 34.858548507576529], [136.815009941905004, 34.858547299571356], [136.815005725574167, 34.858551326797446]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813006511488311, 34.858927945841089], [136.813005604873211, 34.858932037476578], [136.813243138036654, 34.85896774628678], [136.813244044651753, 34.85896365465306], [136.813006511488311, 34.858927945841089]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813079106520718, 34.858862023560775], [136.813081215210332, 34.858849858945725], [136.8130302232627, 34.858840840350616], [136.813027731175026, 34.858853634171261], [136.813079106520718, 34.858862023560775]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813087030081505, 34.858824690770902], [136.813037188328224, 34.858816720847273], [136.81303469624055, 34.858826578384274], [136.813084474094154, 34.858833919102558], [136.813087030081505, 34.858824690770902]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813039159009435, 34.858849402779676], [136.813046645481421, 34.858817140316958], [136.813035782535167, 34.858816301377573], [136.813028242372496, 34.858847551863335], [136.813039159009435, 34.858849402779676]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813106209126005, 34.858755459793578], [136.813108317815619, 34.85874329516276], [136.813057325867987, 34.858734276555985], [136.813054696666427, 34.85874774512957], [136.813106209126005, 34.858755459793578]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813114132686792, 34.858718126955338], [136.813064290933482, 34.858710157021392], [136.813061798845837, 34.858720014571162], [136.813111576699441, 34.85872735529896], [136.813114132686792, 34.858718126955338]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813068487132512, 34.858745809735169], [136.813075116755641, 34.858711356220006], [136.813062885140425, 34.858709737551159], [136.813055344977784, 34.858740988077386], [136.813068487132512, 34.858745809735169]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813074040981661, 34.858639586558532], [136.813075170256866, 34.85864236648856], [136.813290097869327, 34.858675705418513], [136.813291004484483, 34.858671985738283], [136.813074040981661, 34.858639586558532]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813314576603432, 34.858653949529348], [136.813314011965815, 34.858650242956472], [136.813080816632976, 34.85861456718402], [136.813080816632976, 34.858617347114894], [136.813314576603432, 34.858653949529348]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813834911362221, 34.858440561261745], [136.813869603093082, 34.858462274183836], [136.813875710572063, 34.858455622207615], [136.81384106495463, 34.858433927899974], [136.813834911362221, 34.858440561261745]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813851722099884, 34.858482648698697], [136.813876243990308, 34.858455595175755], [136.813863928909171, 34.858447403036259], [136.813840171589419, 34.85847516581434], [136.813851722099884, 34.858482648698697]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813816727305749, 34.858460390452066], [136.813825850582703, 34.858466094484299], [136.813849820612376, 34.8584400538764], [136.813841353376773, 34.858433961661014], [136.813816727305749, 34.858460390452066]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813777014805879, 34.858450551096013], [136.813775885530646, 34.858448697805009], [136.813688112160662, 34.858540471474001], [136.813689156218743, 34.858543041628565], [136.813777014805879, 34.858450551096013]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813795083209385, 34.85846074419576], [136.813789436833304, 34.858457500936879], [136.813700640857405, 34.858548181937451], [136.813704817089643, 34.858551608809861], [136.813795083209385, 34.85846074419576]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813808634512043, 34.858469084003708], [136.813802988135933, 34.858465377422498], [136.813714213612201, 34.858557605836239], [136.813718389844439, 34.858561032708259], [136.813808634512043, 34.858469084003708]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813808870413794, 34.858470291448512], [136.813773372439783, 34.858448016753279], [136.813769196207517, 34.85845144362986], [136.813803650123504, 34.858474575043068], [136.813808870413794, 34.858470291448512]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813879214213273, 34.858454952661866], [136.813614963811887, 34.858724374382227], [136.813623433375994, 34.858725764345813], [136.813884860589383, 34.858460512534315], [136.813879214213273, 34.858454952661866]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813884860589354, 34.858459817550383], [136.813884860589354, 34.858456574291473], [136.813808634512043, 34.858407925392477], [136.813804682048755, 34.858410242007366], [136.813884860589354, 34.858459817550383]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813623998013611, 34.858726227667013], [136.813627385839311, 34.858718814527613], [136.813315141241077, 34.858669239140688], [136.813309494864967, 34.858678968891887], [136.813623998013611, 34.858726227667013]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813323046167596, 34.858678042248968], [136.813326998630885, 34.858646999705073], [136.813319093704337, 34.858646999705073], [136.813312318053022, 34.858678042248968], [136.813323046167596, 34.858678042248968]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813320787617158, 34.85865116959971], [136.813325304718035, 34.858646999705073], [136.813088156921907, 34.858610397287542], [136.813086463009085, 34.858615030505845], [136.813320787617158, 34.85865116959971]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813079687357771, 34.858641439845229], [136.813088721559524, 34.858607617356419], [136.813083639821031, 34.858606227390837], [136.813074040981661, 34.858642366488567], [136.813079687357771, 34.858641439845229]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813087027646674, 34.858613177218551], [136.813091544747579, 34.858609470643842], [136.813079122720154, 34.858591864411679], [136.813074605619278, 34.858594644343327], [136.813087027646674, 34.858613177218551]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813075170256894, 34.858598350918705], [136.813082510545797, 34.858596497631041], [136.813095497210838, 34.858543678914927], [136.813088721559524, 34.85854228894825], [136.813075170256894, 34.858598350918705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813077428807361, 34.858638196593496], [136.812283548327997, 34.858511246353146], [136.81228467760323, 34.858514026287516], [136.813076299532128, 34.858641903166912], [136.813077428807361, 34.858638196593496]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812291453254517, 34.858514026287516], [136.812309521658051, 34.858449161127773], [136.812303875281941, 34.858449161127773], [136.812288065428874, 34.858512172997955], [136.812291453254517, 34.858514026287516]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813949120669321, 34.85837508481648], [136.813940115233038, 34.858369256052271], [136.813915616451084, 34.858394963216973], [136.813923958006029, 34.858401171079784], [136.813949120669321, 34.85837508481648]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813914585627373, 34.858352348174947], [136.813889514363893, 34.85837906053068], [136.813901660312894, 34.858387420968981], [136.813925981606246, 34.858359988942482], [136.813914585627373, 34.858352348174947]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81392980926833, 34.858393522475289], [136.813895566103554, 34.858371335262568], [136.813889323554577, 34.858377902277333], [136.81392352099283, 34.858400070240229], [136.81392980926833, 34.858393522475289]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813935677974257, 34.858403755485512], [136.813856628708891, 34.858357423171668], [136.813853240883248, 34.858362983050704], [136.813928902322942, 34.858412095299244], [136.813935677974257, 34.858403755485512]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813934548699052, 34.858409315361428], [136.814189764898686, 34.85814336754288], [136.814180730696933, 34.85814058759599], [136.813925514497271, 34.858404682131528], [136.813934548699052, 34.858409315361428]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814182989247371, 34.858149854085269], [136.81423493590745, 34.857949697684482], [136.814228160256164, 34.85795525759108], [136.814180730696933, 34.858149854085269], [136.814182989247371, 34.858149854085269]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814008465975974, 34.858218234963019], [136.814012521929385, 34.858214329434809], [136.813998615803371, 34.858211884234457], [136.814000602392809, 34.858217114246244], [136.814008465975974, 34.858218234963019]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813998574416104, 34.858211884234457], [136.814012563316652, 34.858214363395923], [136.814010408676722, 34.858209065001901], [136.814002790746912, 34.85820785699174], [136.813998574416104, 34.858211884234457]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814094290892655, 34.85811908355592], [136.814098346846066, 34.858115178023013], [136.814084440720052, 34.858112732819706], [136.814086427309491, 34.858117962837795], [136.814094290892655, 34.85811908355592]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814084399332785, 34.858112732819706], [136.814098388233333, 34.85811521198417], [136.814096233593403, 34.85810991358376], [136.814088615663593, 34.858108705572143], [136.814084399332785, 34.858112732819706]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814184632910241, 34.858025491931159], [136.814188688863652, 34.858021586393825], [136.814174782737638, 34.858019141187725], [136.814176769327076, 34.858024371211769], [136.814184632910241, 34.858025491931159]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814174741350342, 34.858019141187725], [136.814188730250919, 34.858021620355011], [136.814186575610989, 34.858016321948568], [136.814178957681179, 34.858015113935572], [136.814174741350342, 34.858019141187725]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814378636179129, 34.857760892299801], [136.814373554440635, 34.857763672259523], [136.814427759651181, 34.857771548811577], [136.814427759651181, 34.857767378872353], [136.814378636179129, 34.857760892299801]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814367343426937, 34.857826221328537], [136.81438230632358, 34.857762050616437], [136.814376659947499, 34.857760197309922], [136.814360003137978, 34.857825294676012], [136.814367343426937, 34.857826221328537]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814355434803531, 34.857842210760559], [136.8143530539746, 34.857851417529972], [136.814391992236409, 34.85785805751847], [136.814395081288382, 34.857849171299563], [136.814355434803531, 34.857842210760559]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814345885121213, 34.857877687878471], [136.81438617522636, 34.857884303909302], [136.814389931547879, 34.857871665448037], [136.814349187207057, 34.857865919892561], [136.814345885121213, 34.857877687878471]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81435538107192, 34.857842336617047], [136.814346302464827, 34.857877355683094], [136.814356242436645, 34.857879040289987], [136.814365320261942, 34.857844063390715], [136.81435538107192, 34.857842336617047]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814407997334882, 34.857834561200818], [136.814413926029715, 34.85783479286399], [136.814430865158045, 34.857767610535717], [136.814425783419551, 34.857765757229323], [136.814407997334882, 34.857834561200818]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814341652415749, 34.857905218418175], [136.814443287185497, 34.857505830595223], [136.814436511534183, 34.857505830595223], [136.814336006039639, 34.857907071721421], [136.814341652415749, 34.857905218418175]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814338264590049, 34.857905681743993], [136.814337699952432, 34.857900121834049], [136.814289141117996, 34.858119274666663], [136.814292528943696, 34.858122054614284], [136.814338264590049, 34.857905681743993]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814648235666994, 34.858179033628105], [136.814649482262809, 34.858174569984868], [136.814294222856489, 34.858119274666663], [136.814293658218901, 34.858122054614292], [136.814648235666994, 34.858179033628105]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814442722547881, 34.857506293923286], [136.81449241065755, 34.857295016055275], [136.814487893556645, 34.857287602786897], [136.814435946896566, 34.857508147235521], [136.814442722547881, 34.857506293923286]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814494669207988, 34.857295016055275], [136.814650509188283, 34.857320962489354], [136.814650509188283, 34.857313549223299], [136.814490152107112, 34.857289456104056], [136.814494669207988, 34.857295016055275]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814600821078614, 34.857538726881309], [136.814601950353818, 34.85753131363488], [136.814442722547881, 34.857506293923294], [136.814443851823086, 34.857511853859862], [136.814600821078614, 34.857538726881309]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814346734154242, 34.857881588798172], [136.814344475603775, 34.857888075361195], [136.814606467454723, 34.857924214774449], [136.814607596729957, 34.857919581517223], [136.814346734154242, 34.857881588798172]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814651638463516, 34.857735177667955], [136.814658414114831, 34.857727764439247], [136.814562425721164, 34.857714791287385], [136.81456129644593, 34.857721277863568], [136.814651638463516, 34.857735177667955]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814588399051217, 34.857595252863277], [136.814585011225546, 34.857590619587526], [136.814534193840672, 34.857827842970515], [136.814539840216781, 34.857829696275502], [136.814588399051217, 34.857595252863277]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81449466920796, 34.85782320970786], [136.814517254712371, 34.857702744787403], [136.814512737611494, 34.857702744787403], [136.814485635006235, 34.857818576444956], [136.81449466920796, 34.85782320970786]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814512737611523, 34.857705524749093], [136.814516125437137, 34.857701818133478], [136.814397551539088, 34.857680505090478], [136.814395292988678, 34.85768513836117], [136.814512737611523, 34.857705524749093]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813241738351877, 34.858963447775118], [136.81324495126691, 34.858966258419997], [136.813253031104068, 34.858937501859117], [136.813249078640808, 34.858936111899098], [136.813241738351877, 34.858963447775118]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813536479184194, 34.858937965179109], [136.813534785271372, 34.858936111899091], [136.813116953440101, 34.858870320431443], [136.813116388802513, 34.858872637033301], [136.813536479184194, 34.858937965179109]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81353704382181, 34.858947231578568], [136.813537608459427, 34.8589449149788], [136.813115824164896, 34.858879123518193], [136.813115259527308, 34.858881440119809], [136.81353704382181, 34.858947231578568]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813251297572805, 34.858940964680585], [136.813553982950111, 34.858988930363225], [136.813553982950111, 34.858984297165975], [136.813250730938336, 34.858937245012363], [136.813251297572805, 34.858940964680585]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813713210756049, 34.859461515111896], [136.813718857132159, 34.859462441745997], [136.813553982950111, 34.858986150444906], [136.813549465849235, 34.858985223805441], [136.813713210756049, 34.859461515111896]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813831784654127, 34.859463368380084], [136.81368610815079, 34.859050088542475], [136.813682720325147, 34.859050088542475], [136.813826138278017, 34.859464295014156], [136.813831784654127, 34.859463368380084]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813665781196846, 34.858998196756943], [136.813670298297723, 34.858992636920846], [136.813655180450041, 34.858951379751076], [136.813650534047497, 34.858950728809226], [136.813665781196846, 34.858998196756943]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813683849600352, 34.859050088542475], [136.813780967269253, 34.859064914760907], [136.813780967269253, 34.859060281567928], [136.813684978875614, 34.859043602071075], [136.813683849600352, 34.859050088542475]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813771933067471, 34.859061208206548], [136.813777579443581, 34.859061208206548], [136.813797906397554, 34.858976884049831], [136.813792260021444, 34.858974104131107], [136.813771933067471, 34.859061208206548]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813792260021444, 34.858975957410266], [136.81379677712232, 34.858975957410266], [136.813652229894217, 34.858950938138065], [136.813654727142477, 34.858954541468535], [136.813792260021444, 34.858975957410266]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813803129295451, 34.85973197599526], [136.813809904946766, 34.859725489577642], [136.813730047023142, 34.859498052840635], [136.813726307235726, 34.859498052840635], [136.813803129295451, 34.85973197599526]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813764733938001, 34.859933054688078], [136.813768121763644, 34.859933981316864], [136.813813292772437, 34.859719929790693], [136.813806517121122, 34.859719003159505], [136.813764733938001, 34.859933054688078]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813756565515604, 34.859581186818346], [136.813653038442851, 34.859599889061911], [136.813655296993318, 34.859608228754354], [136.813760985264366, 34.859586208331081], [136.813756565515604, 34.859581186818346]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813357508080628, 34.858626439804908], [136.813557389794482, 34.858659798964403], [136.813560777620182, 34.858656092391804], [136.8133608959063, 34.858624586517877], [136.813357508080628, 34.858626439804908]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81579406052623, 34.851710145257307], [136.815794625163846, 34.851701804764161], [136.815791801975791, 34.851700878042649], [136.815790672700587, 34.851709681896594], [136.81579406052623, 34.851710145257307]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815792366613408, 34.851705975010844], [136.815793495888641, 34.851703194846415], [136.81574776024226, 34.85169809787805], [136.81574776024226, 34.85169995132113], [136.815792366613408, 34.851705975010844]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815787284874915, 34.851727289601669], [136.815787284874915, 34.85172589951982], [136.815739290678067, 34.851718949110257], [136.815738726040479, 34.851721729274161], [136.815787284874915, 34.851727289601669]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815797448351901, 34.851471977509384], [136.815848265736747, 34.851478927939802], [136.815849395011981, 34.85147429431958], [136.815801965452778, 34.851469197337046], [136.815797448351901, 34.851471977509384]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815844877911104, 34.85147522104365], [136.815849959649597, 34.851477074491754], [136.815857864576117, 34.851442785695184], [136.815852218200035, 34.851440932246319], [136.815844877911104, 34.85147522104365]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811658654672073, 34.858766131195068], [136.811103898220409, 34.858677173521308], [136.811103898220409, 34.858679953450078], [136.811657525396868, 34.858768447799868], [136.811658654672073, 34.858766131195068]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811664301048182, 34.858748524996429], [136.811077642571576, 34.858654470766247], [136.811077642571576, 34.858657250695778], [136.811663171772949, 34.858750841601733], [136.811664301048182, 34.858748524996429]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811072278514246, 34.858693389771105], [136.811081877353644, 34.858655860731027], [136.811079054165589, 34.858655860731027], [136.811070019963836, 34.85869199980695], [136.811072278514246, 34.858693389771105]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811851478415775, 34.858811999958007], [136.811861077255145, 34.858774470972051], [136.811858254067118, 34.858774470972051], [136.811849219865337, 34.858810609995871], [136.811851478415775, 34.858811999958007]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812437854573602, 34.858884741277095], [136.811883098121939, 34.858795783731608], [136.811883098121939, 34.858798563656364], [136.812436725298369, 34.858887057878547], [136.812437854573602, 34.858884741277095]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812440395442849, 34.85886852506502], [136.811857124791914, 34.858777250897518], [136.81185599551668, 34.858778640860216], [136.812438136892382, 34.858871304987318], [136.812440395442849, 34.85886852506502]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812574355715839, 34.858514721271185], [136.812574355715839, 34.858512404659237], [136.812286249375404, 34.858466419898569], [136.812284273143746, 34.858468852342469], [136.812574355715839, 34.858514721271185]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812287783109952, 34.85845078275733], [136.812578308179098, 34.85849642003501], [136.812579155135495, 34.858494566745058], [136.812288912385156, 34.858448929466341], [136.812287783109952, 34.85845078275733]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81235075922234, 34.858478582117371], [136.81235075922234, 34.8584762655044], [136.812072392880737, 34.85843190235353], [136.81207182824312, 34.858434450629169], [136.81235075922234, 34.858478582117371]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812354147048012, 34.858460975857191], [136.812354147048012, 34.858458659243738], [136.812106553456118, 34.858419624297113], [136.812104859543297, 34.858421477588763], [136.812354147048012, 34.858460975857191]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81207408679353, 34.858433987306327], [136.812080580126064, 34.858411284484141], [136.812078321575626, 34.858411284484141], [136.812072392880737, 34.858433292322069], [136.81207408679353, 34.858433987306327]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813225689863913, 34.858934832954624], [136.813226819139118, 34.85893158971443], [136.812928655914277, 34.858886657507917], [136.812920383051221, 34.858888145376142], [136.813225689863913, 34.858934832954624]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813228751686864, 34.858943061698952], [136.813229316324481, 34.858941208419047], [136.813116953440129, 34.858924528898044], [136.813116388802513, 34.858926845498395], [136.813228751686864, 34.858943061698952]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813111871701523, 34.858934721939036], [136.813113000976756, 34.858937501859096], [136.813225363861136, 34.858954644697363], [136.813224799223548, 34.858951401457965], [136.813111871701523, 34.858934721939036]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812921469509604, 34.858888833784484], [136.812932395701665, 34.858887215458502], [136.812718661185329, 34.858854203375628], [136.812720474415613, 34.858858295014784], [136.812921469509604, 34.858888833784484]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812804024886702, 34.85889393030736], [136.812805154161936, 34.858896710228791], [136.812917517046344, 34.858913853075563], [136.812916952408727, 34.85891060983456], [136.812804024886702, 34.85889393030736]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812920904872072, 34.858902270071411], [136.812921469509689, 34.858900416790597], [136.812809106625338, 34.858883737261316], [136.812808541987721, 34.858886053862811], [136.812920904872072, 34.858902270071411]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812627290491662, 34.858934316534132], [136.812652931588616, 34.858842300424193], [136.812647491897849, 34.858843044358707], [136.812625031941224, 34.858932926574063], [136.812627290491662, 34.858934316534132]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813211853419233, 34.859014683058241], [136.81265709696757, 34.858925725653286], [136.81265709696757, 34.858928505573651], [136.813210724144028, 34.859016999656049], [136.813211853419233, 34.859014683058241]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813214394288451, 34.858998466871789], [136.812631123637544, 34.858907192848463], [136.812629994362311, 34.858908582808979], [136.813212135738013, 34.859001246789695], [136.813214394288451, 34.858998466871789]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815006830353127, 34.859982511768948], [136.815001395274123, 34.860005514743733], [136.81500339767166, 34.860005514743733], [136.815009404864242, 34.85998298121747], [136.815006830353127, 34.859982511768948]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81501140726175, 34.86000668836472], [136.815001395274123, 34.860004341122718], [136.815001395274123, 34.860006218916332], [136.815011979375328, 34.860007627261496], [136.81501140726175, 34.86000668836472]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814981085242124, 34.860015138435351], [136.814981943412477, 34.86001631205621], [136.814985662150718, 34.860002698053286], [136.814983945809985, 34.860002463329081], [136.814981085242124, 34.860015138435351]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817858119275911, 34.855754740370124], [136.817959812464409, 34.855771113227462], [136.817961528805142, 34.855766359817601], [136.817858762903683, 34.855749986959317], [136.817858119275911, 34.855754740370124]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817948012621855, 34.855811253122006], [136.817952518016284, 34.855813541799613], [136.817962601518104, 34.855766887974269], [136.817957452495875, 34.855766183765382], [136.817948012621855, 34.855811253122006]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818049275333721, 34.856457570895628], [136.818045413567035, 34.856462852417749], [136.818407776004506, 34.85652094913867], [136.81841035051562, 34.856515667620279], [136.818049275333721, 34.856457570895628]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817802016388157, 34.856414713583767], [136.817807809038158, 34.85641576988877], [136.817821325221416, 34.85637510213644], [136.817812314432558, 34.856372989525383], [136.817802016388157, 34.856414713583767]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817766616860496, 34.856369556532293], [136.81781810708253, 34.856378799205658], [136.817818428896402, 34.856372725448992], [136.817763720535538, 34.856363218698519], [136.817766616860496, 34.856369556532293]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818394797569141, 34.856568933970372], [136.818409601007971, 34.856509252827657], [136.818404451985771, 34.856507668371954], [136.81838900491914, 34.856567349515821], [136.818394797569141, 34.856568933970372]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818228615293748, 34.85586919729527], [136.818224753527119, 34.855874478855171], [136.818587115964561, 34.855932575991602], [136.818589690475648, 34.855927294435446], [136.818228615293748, 34.85586919729527]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817599273639161, 34.857053115445858], [136.817653982000053, 34.857062886190768], [136.817655269255624, 34.857058661003926], [136.817597664569718, 34.857047569887413], [136.817599273639161, 34.857053115445858]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817651407488938, 34.857058661003926], [136.817643362141752, 34.857096951751814], [136.817648832977852, 34.857097743973995], [136.817657521952839, 34.857058661003926], [136.817651407488938, 34.857058661003926]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817889191267284, 34.857141289504867], [136.817885329500626, 34.857147099130906], [136.818236106637812, 34.857203742963073], [136.818238681148927, 34.857198461488515], [136.817889191267284, 34.857141289504867]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817720919288462, 34.857740893831341], [136.817717057521833, 34.857746175271068], [136.818079419959275, 34.857804271085669], [136.81808199447039, 34.857798989649673], [136.817720919288462, 34.857740893831341]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817570310389073, 34.858413746522153], [136.817566448622415, 34.858419027918686], [136.817927201990784, 34.858476727153715], [136.817929132874099, 34.858471445760891], [136.817570310389073, 34.858413746522153]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817442228462227, 34.859016483711827], [136.817440297578884, 34.859023349476928], [136.817796223738583, 34.85907933184798], [136.817801372760812, 34.859074578629269], [136.817442228462227, 34.859016483711827]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818233890625606, 34.85719597946543], [136.818217156303433, 34.857248794197282], [136.818227454347834, 34.857250906785843], [136.818242901414436, 34.85719597946543], [136.818233890625606, 34.85719597946543]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817439332137127, 34.857658635363919], [136.81748921328969, 34.857667085675764], [136.817491787800805, 34.857661012014219], [136.81744061939267, 34.857652825774025], [136.817439332137127, 34.857658635363919]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817477306175846, 34.857702735419323], [136.817482455198046, 34.857703263563565], [136.817493718684119, 34.857660219797459], [136.817487926034147, 34.857659427580693], [136.817477306175846, 34.857702735419323]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817279712448965, 34.858335977919133], [136.817332168112642, 34.858344956301508], [136.817332811740414, 34.858339410830162], [136.817281965146179, 34.858329904306963], [136.817279712448965, 34.858335977919133]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817319295557127, 34.858376644702084], [136.817323800951556, 34.858378229121783], [136.817334098995957, 34.858339674900229], [136.817327984532113, 34.858338882690006], [136.817319295557127, 34.858376644702084]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817790431088611, 34.859074050493838], [136.817798798249697, 34.859075106764692], [136.817812314432956, 34.859022821341178], [136.817802660016326, 34.859021765069656], [136.817790431088611, 34.859074050493838]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817145033337027, 34.858929077190545], [136.817144711523127, 34.858931981940472], [136.817205534347892, 34.85894254466664], [136.817206499789563, 34.858939903985224], [136.817145033337027, 34.858929077190545]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817194270861819, 34.858975025041097], [136.817197810814605, 34.858976345381258], [136.817206821603435, 34.858940432121521], [136.817203281650677, 34.858939903985224], [136.817194270861819, 34.858975025041097]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817382239664624, 34.858549684532761], [136.817380939407599, 34.858553241019877], [136.817736342995715, 34.858608722199072], [136.817737209833751, 34.858606588308263], [136.817382239664624, 34.858549684532761]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817506306110175, 34.857950265703572], [136.817502444343489, 34.857955547129848], [136.817863197711858, 34.858013246689978], [136.817865128595201, 34.858007965267397], [136.817506306110175, 34.857950265703572]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817667571552562, 34.85727297675237], [136.817663709785904, 34.857278258222131], [136.818026072223375, 34.857336354367199], [136.818028646734462, 34.857331072901154], [136.817667571552562, 34.85727297675237]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817832910521673, 34.856686579636033], [136.817829048755016, 34.856692389294174], [136.81817982589223, 34.856749033439435], [136.818182400403316, 34.856743751935696], [136.817832910521673, 34.856686579636033]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817988668443007, 34.856001169133762], [136.817984806676321, 34.856006450685179], [136.818347169113764, 34.856064547728415], [136.818349743624879, 34.856059266180729], [136.817988668443007, 34.856001169133762]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818085212609276, 34.855387450549877], [136.81808135084259, 34.8553927321407], [136.818443713280061, 34.855450829617354], [136.818446287791147, 34.855445548030254], [136.818085212609276, 34.855387450549877]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816823827192849, 34.860274034978808], [136.816910806009133, 34.859921812009958], [136.816902179551818, 34.859921812009958], [136.816816747287334, 34.86027271465948], [136.816823827192849, 34.860274034978808]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816844426526472, 34.860380332048216], [136.816850429696387, 34.860379180445889], [136.816874410371923, 34.860278622987614], [136.816869739298681, 34.860275885243759], [136.816844426526472, 34.860380332048216]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81684611646773, 34.860375995158414], [136.816842665884792, 34.860383073574866], [136.817277588019209, 34.860450572914921], [136.817276944391409, 34.860444235395569], [136.81684611646773, 34.860375995158414]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817282093413638, 34.860451629168097], [136.817295609596897, 34.860392478969302], [136.817284024296953, 34.86038878208047], [136.817271795369237, 34.860446876028696], [136.817282093413638, 34.860451629168097]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817618333572682, 34.860391749114122], [136.81761768994491, 34.860385411590251], [136.817466437417721, 34.860362173998517], [136.817467724673264, 34.860369567778413], [136.817618333572682, 34.860391749114122]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817609966411595, 34.860387524098265], [136.817618333572682, 34.860387524098265], [136.817698434415661, 34.860060322665532], [136.817688082666905, 34.860054659910197], [136.817609966411595, 34.860387524098265]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817689807958345, 34.860061738354304], [136.817689807958345, 34.860053244221305], [136.817550059349998, 34.86003342457424], [136.817550059349998, 34.860041918709278], [136.817689807958345, 34.860061738354304]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817541270397697, 34.860040503020166], [136.817548171563544, 34.860041918709278], [136.817560248603769, 34.85999095388587], [136.817555072729391, 34.859989538195883], [136.817541270397697, 34.860040503020166]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817555096695514, 34.859991299974645], [136.81756372315283, 34.859992715664603], [136.817574074901586, 34.859933256665329], [136.817565448444299, 34.859933256665329], [136.817555096695514, 34.859991299974645]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817573069467102, 34.859939689605753], [136.817574404059428, 34.859934214095396], [136.817148001803645, 34.859867412839627], [136.817145332618935, 34.859873435905897], [136.817573069467102, 34.859939689605753]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816969465918845, 34.859931721847538], [136.816979175871381, 34.85988931489667], [136.816966497244039, 34.85989752816657], [136.816957741996816, 34.859931444411529], [136.816969465918845, 34.859931721847538]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816967718227346, 34.859933292866245], [136.816968040041218, 34.859927219372018], [136.816902179551818, 34.859917564936339], [136.816902179551818, 34.859926059083342], [136.816967718227346, 34.859933292866245]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817284463871431, 34.860382383412514], [136.817284463871374, 34.860392239277189], [136.817457237281644, 34.860415359456049], [136.817462386303873, 34.860410606314552], [136.817284463871431, 34.860382383412514]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817451444631672, 34.860410078187705], [136.817459811792759, 34.860411134441399], [136.817473327976046, 34.860358849867119], [136.817463673559416, 34.860357793612749], [136.817451444631672, 34.860410078187705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817091281628734, 34.859912038274686], [136.817090113860445, 34.859917787562111], [136.817473642337035, 34.859982398574068], [136.817474309633212, 34.859976923066547], [136.817091281628734, 34.859912038274686]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816813686419977, 34.860274790146185], [136.816869739298681, 34.860284646023771], [136.816871073891036, 34.860278075438849], [136.81681835749319, 34.860268219560467], [136.816813686419977, 34.860274790146185]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817127982918379, 34.859924358175832], [136.817133988583976, 34.85992654838028], [136.817152005580681, 34.859865770185117], [136.817144665322758, 34.859864675082093], [136.817127982918379, 34.859924358175832]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816866736465812, 34.860319962908719], [136.817211061292113, 34.860372527546637], [136.817209726699758, 34.860365956968742], [136.816864067281102, 34.860313392326624], [136.816866736465812, 34.860319962908719]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816682360403718, 34.860863195369312], [136.816769339220002, 34.860510974923372], [136.816760712762715, 34.860510974923372], [136.816675280498202, 34.860861875059449], [136.816682360403718, 34.860863195369312]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81670295973737, 34.860969491677338], [136.816708962907285, 34.860968340083261], [136.816732943582792, 34.860867783345256], [136.816728272509579, 34.860865045621011], [136.81670295973737, 34.860969491677338]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81670464967857, 34.860965154818601], [136.816701199095661, 34.860972233184356], [136.817136121230078, 34.861039732040915], [136.817135477602307, 34.861033394566959], [136.81670464967857, 34.860965154818601]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817140626624507, 34.86104078828653], [136.817154142807766, 34.860981638511419], [136.81714255750785, 34.860977941649068], [136.817130328580106, 34.861036035181172], [136.817140626624507, 34.86104078828653]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817476866783551, 34.860980908661475], [136.817476223155751, 34.860974571182986], [136.81732497062859, 34.860951333757704], [136.817326257884133, 34.860958727484643], [136.817476866783551, 34.860980908661475]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817468499622464, 34.860976683675872], [136.817476866783551, 34.860976683675872], [136.81755696762653, 34.860649484586823], [136.817546615877745, 34.860643821872053], [136.817468499622464, 34.860976683675872]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817548341169243, 34.860650900265448], [136.817548341169243, 34.860642406193293], [136.817408592560867, 34.860622586688194], [136.817408592560867, 34.860631080762396], [136.817548341169243, 34.860650900265448]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817399803608538, 34.860629665083422], [136.817406704774385, 34.860631080762388], [136.817418781814609, 34.860580116304043], [136.81741360594026, 34.860578700624188], [136.817399803608538, 34.860629665083422]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817413629906412, 34.860580462390338], [136.817422256363699, 34.86058187807015], [136.817432608112483, 34.86052241949676], [136.817423981655168, 34.86052241949676], [136.817413629906412, 34.860580462390338]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817431602677971, 34.86052885239112], [136.817432937270326, 34.860523376919971], [136.817006535014514, 34.860456576142688], [136.817003865829832, 34.860462599165821], [136.817431602677971, 34.86052885239112]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816827999129686, 34.860520884689961], [136.816835537115281, 34.860484658225111], [136.816825030454908, 34.860486691253925], [136.816816275207657, 34.860520607255957], [136.816827999129686, 34.860520884689961]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816826251438187, 34.860522455697428], [136.816826573252087, 34.860516382246708], [136.816760712762715, 34.860506727880178], [136.816760712762715, 34.860515221966338], [136.816826251438187, 34.860522455697428]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8171429970823, 34.860971543026942], [136.817142997082243, 34.860981398821032], [136.817315770492542, 34.861004518834278], [136.817320919514742, 34.860999765726831], [136.8171429970823, 34.860971543026942]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817309977842569, 34.86099923760375], [136.817318345003628, 34.861000293849884], [136.817331861186915, 34.860948009650109], [136.817322206770285, 34.860946953403321], [136.817309977842569, 34.86099923760375]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816947812951128, 34.860505855409869], [136.816948480247305, 34.860512425976601], [136.817332175547904, 34.860571561053526], [136.817332842844081, 34.860566085585205], [136.816947812951128, 34.860505855409869]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816672219630874, 34.860863950531289], [136.816728272509579, 34.860873806338276], [136.816729607101905, 34.860867235800413], [136.816676890704088, 34.860857379992638], [136.816672219630874, 34.860863950531289]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816986516129276, 34.860513521071006], [136.816992521794845, 34.860515711259765], [136.81701053879155, 34.860454933499938], [136.817003198533655, 34.860453838404766], [136.816986516129276, 34.860513521071006]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816725269676681, 34.860909122970256], [136.81706959450301, 34.860961687231665], [136.817068259910656, 34.860955116700829], [136.816722600491971, 34.860902552435221], [136.816725269676681, 34.860909122970256]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816530216875833, 34.861539958044823], [136.816617195692089, 34.861187740496952], [136.816608569234802, 34.861187740496952], [136.816523136970289, 34.861538637745809], [136.816530216875833, 34.861539958044823]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816550816209457, 34.861646253478227], [136.816556819379372, 34.861645101893629], [136.816580800054908, 34.861544545983001], [136.816576128981666, 34.861541808281288], [136.816550816209457, 34.861646253478227]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816552506150686, 34.861641916655174], [136.816549055567748, 34.861648994962685], [136.816983977702165, 34.861716493263856], [136.816983334074393, 34.861710155842054], [136.816552506150686, 34.861641916655174]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816988483096594, 34.861717549500781], [136.817001999279853, 34.861658400212363], [136.816990413979937, 34.861654703380417], [136.816978185052193, 34.861712796434539], [136.816988483096594, 34.861717549500781]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817324723255638, 34.861657670368416], [136.817324079627866, 34.861651332942081], [136.817172827100705, 34.861628095707999], [136.817174114356249, 34.861635489374102], [136.817324723255638, 34.861657670368416]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817316356094551, 34.861653445417581], [136.817324723255638, 34.861653445417581], [136.817404824098617, 34.861326249020742], [136.817394472349861, 34.861320586352569], [136.817316356094551, 34.861653445417581]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81739619764133, 34.861327664687728], [136.81739619764133, 34.861319170685462], [136.817256449032982, 34.861299351343433], [136.817256449032982, 34.861307845347746], [136.81739619764133, 34.861327664687728]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817247660080653, 34.861306429680411], [136.8172545612465, 34.861307845347746], [136.817266638286725, 34.861256881308719], [136.817261462412347, 34.861255465640518], [136.817247660080653, 34.861306429680411]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817261486378499, 34.861257227392173], [136.817270112835814, 34.861258643060346], [136.81728046458457, 34.861199184976186], [136.817271838127255, 34.861199184976186], [136.817261486378499, 34.861257227392173]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817279459150086, 34.861205617817603], [136.817280793742412, 34.861200142391503], [136.816854391486601, 34.861133342163868], [136.816851722301919, 34.861139365137433], [136.817279459150086, 34.861205617817603]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816675855601801, 34.861197650182007], [136.816682419893198, 34.861165944120181], [136.816672749331786, 34.861165566259352], [136.816664131679772, 34.861197372750276], [136.816675855601801, 34.861197650182007]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816674107910302, 34.861199221176548], [136.816674429724173, 34.861193147775786], [136.816608569234802, 34.861183493488703], [136.816608569234802, 34.861191987504974], [136.816674107910302, 34.861199221176548]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816990853554387, 34.861648304810949], [136.81699085355433, 34.861658160523945], [136.817163626964629, 34.86168128034695], [136.817168775986829, 34.861676527278611], [136.816990853554387, 34.861648304810949]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817157834314656, 34.861675999159893], [136.817166201475715, 34.86167705539733], [136.817179717659002, 34.86162477162776], [136.817170063242372, 34.861623715389655], [136.817157834314656, 34.861675999159893]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816790177577275, 34.861182381064573], [136.816788335565604, 34.861188426836392], [136.817180032019991, 34.861248326128596], [136.817180699316168, 34.861242850705345], [136.816790177577275, 34.861182381064573]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816520076102961, 34.861540713200583], [136.816576128981666, 34.861550568926468], [136.816577463573992, 34.861543998442663], [136.816524747176175, 34.861534142715989], [136.816520076102961, 34.861540713200583]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816834372601363, 34.861190286623632], [136.816840378266932, 34.861192476794379], [136.816858395263637, 34.861131699534631], [136.816851055005742, 34.861130604448462], [136.816834372601363, 34.861190286623632]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816573126148796, 34.861585885267871], [136.816917450975097, 34.861638449096773], [136.816916116382743, 34.861631878619995], [136.816570456964058, 34.861579314786901], [136.816573126148796, 34.861585885267871]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816306005361042, 34.862129109367586], [136.816392984177327, 34.861776894342668], [136.81638435772004, 34.861776894342668], [136.816298925455527, 34.862127789078045], [136.816306005361042, 34.862129109367586]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816326604694666, 34.862235404039595], [136.816332607864609, 34.862234252463253], [136.816356588540089, 34.862133697272917], [136.816351917466875, 34.862130959590807], [136.816326604694666, 34.862235404039595]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816328294635895, 34.862231067247613], [136.816324844052986, 34.862238145504421], [136.816759766187403, 34.862305643322102], [136.816759122559631, 34.86229930594569], [136.816328294635895, 34.862231067247613]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816764271581832, 34.862306699551453], [136.816777787765091, 34.862247550686725], [136.816766202465146, 34.862243853881267], [136.816753973537431, 34.862301946519253], [136.816764271581832, 34.862306699551453]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817100511740875, 34.862246820848014], [136.817099868113075, 34.862240483467069], [136.816948615585915, 34.862217246399439], [136.816949902841458, 34.86222464001257], [136.817100511740875, 34.862246820848014]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81709481376447, 34.862246976212823], [136.817103180925557, 34.862246976212823], [136.817183281768536, 34.861919782177132], [136.817172930019751, 34.861914119549816], [136.81709481376447, 34.862246976212823]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81717198612651, 34.861916817531167], [136.81717198612651, 34.861908323589745], [136.817032237518191, 34.861888504389682], [136.817032237518191, 34.861896998333151], [136.81717198612651, 34.861916817531167]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817023448565863, 34.861895582675963], [136.817030349731709, 34.861896998333151], [136.817042426771934, 34.861846034659187], [136.817037250897556, 34.861844619001126], [136.817023448565863, 34.861895582675963]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817037274863708, 34.861846380740161], [136.817045901321023, 34.861847796398187], [136.817056253069779, 34.86178833873992], [136.817047626612492, 34.86178833873992], [136.817037274863708, 34.861846380740161]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.817055247635295, 34.861794771535266], [136.817056582227622, 34.861789296148388], [136.816630179971838, 34.861722496399238], [136.816627510787129, 34.861728519329667], [136.817055247635295, 34.861794771535266]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81653909404389, 34.861802710845573], [136.816543097820926, 34.861772048677373], [136.816537092155357, 34.86176931098322], [136.816529751897434, 34.861801615768336], [136.81653909404389, 34.861802710845573]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816537092155357, 34.861803805922804], [136.81653642485918, 34.861796687920581], [136.81638435772004, 34.861772647364845], [136.81638435772004, 34.861781141320279], [136.816537092155357, 34.861803805922804]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816766642039596, 34.862237455357629], [136.816766642039539, 34.862247311000033], [136.816939415449838, 34.862270430657432], [136.816944564472067, 34.862265677623135], [136.816766642039596, 34.862237455357629]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816933622799894, 34.862265149508197], [136.816941989960952, 34.862266205738074], [136.816955506144211, 34.86221392234301], [136.81694585172761, 34.862212866112472], [136.816933622799894, 34.862265149508197]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816585804776139, 34.861774238832623], [136.816584470183784, 34.86178190437554], [136.816955820505228, 34.861837479540341], [136.816956487801406, 34.861832004156312], [136.816585804776139, 34.861774238832623]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816295864588199, 34.862129864517946], [136.816351917466875, 34.862139720173239], [136.81635325205923, 34.862133149736508], [136.816300535661412, 34.862123294080419], [136.816295864588199, 34.862129864517946]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816656871818822, 34.861785463377373], [136.81666287748439, 34.861787653532268], [136.816680894481124, 34.861726876712325], [136.8166735542232, 34.861725781634071], [136.816656871818822, 34.861785463377373]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816348914634005, 34.862175036261661], [136.816693239460307, 34.862227599714046], [136.81669190486798, 34.862221029284335], [136.816346245449296, 34.86216846582775], [136.816348914634005, 34.862175036261661]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813708839885606, 34.8595901658868], [136.813751557525535, 34.859581036486603], [136.813749313748303, 34.859575118085644], [136.813706765893215, 34.859583854337551], [136.813708839885606, 34.8595901658868]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813708552410361, 34.859590082296094], [136.813721605985393, 34.859587328684526], [136.813712591781467, 34.85955974955138], [136.81369963100866, 34.859562662903954], [136.813708552410361, 34.859590082296094]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813751365875362, 34.859580980759475], [136.813742844600256, 34.859554341486451], [136.813730337057876, 34.859557121118719], [136.813738664666602, 34.859583792578846], [136.813751365875362, 34.859580980759475]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813654914856585, 34.859492373429582], [136.81362455288064, 34.859399289197647], [136.813620215455501, 34.85939956297495], [136.813651578375698, 34.859494563645541], [136.813654914856585, 34.859492373429582]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813637024511564, 34.859496459299486], [136.813640360992423, 34.859495090414548], [136.813609331720301, 34.859400637299181], [136.813606996183722, 34.859400911076477], [136.813637024511564, 34.859496459299486]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813581512277466, 34.859373554126726], [136.813674266445702, 34.859354389706951], [136.813675601038057, 34.859347271492751], [136.81357483931572, 34.859367531023857], [136.813581512277466, 34.859373554126726]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813640027344633, 34.859512885916921], [136.813643363825491, 34.859512338363061], [136.813605327943264, 34.85940118485378], [136.813602658758583, 34.85940118485378], [136.813640027344633, 34.859512885916921]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813662588762867, 34.859505788501409], [136.813665925243754, 34.859505240947506], [136.813629891250031, 34.85939874164302], [136.813625553824892, 34.859398467865709], [136.813662588762867, 34.859505788501409]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813639782154496, 34.859509420998585], [136.81364046211587, 34.859512489703526], [136.813666592539647, 34.859507704940036], [136.813665925243498, 34.859504419616599], [136.813639782154496, 34.859509420998585]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81041404553099, 34.858482793282356], [136.810299302053835, 34.858463613634441], [136.810299302053835, 34.858466403401685], [136.810412005646924, 34.858486141002253], [136.81041404553099, 34.858482793282356]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810304061783256, 34.858465845448244], [136.810319530903911, 34.858403563871661], [136.810314771174461, 34.858404121825515], [136.810300661976527, 34.85846919316883], [136.810304061783256, 34.858465845448244]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810339249782942, 34.858363391183559], [136.810329730324099, 34.858349721306055], [136.810327350459403, 34.858351674145837], [136.810337549879563, 34.858363949137697], [136.810339249782942, 34.858363391183559]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810338569821596, 34.858362833229421], [136.810340269724946, 34.858365344023021], [136.810354208932523, 34.858367017885371], [136.810356248816561, 34.858365623000076], [136.810338569821596, 34.858362833229421]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810355908835902, 34.858367017885371], [136.810374267792241, 34.858358648573251], [136.810372227908204, 34.858356695733633], [136.810355228874556, 34.858365623000076], [136.810355908835902, 34.858367017885371]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81037120796617, 34.858357532664904], [136.810372907869549, 34.858359206527417], [136.810376987637625, 34.858342188923594], [136.810374607772928, 34.858341909946461], [136.81037120796617, 34.858357532664904]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810374267792241, 34.85834414176356], [136.810375627714933, 34.858341630969313], [136.810364748333399, 34.858328519042566], [136.810363388410707, 34.858330192905676], [136.810374267792241, 34.85834414176356]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810364748333399, 34.858331029837217], [136.810363388410707, 34.858329913928493], [136.810347069338405, 34.858327682111003], [136.810346389377088, 34.858329076996938], [136.810364748333399, 34.858331029837217]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810347749299751, 34.858329355974128], [136.810346729357747, 34.858327403133814], [136.810331430227478, 34.858334656540457], [136.810333130130829, 34.858337725289232], [136.810347749299751, 34.858329355974128]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810330070304786, 34.858350279260279], [136.810333130130829, 34.858336051426271], [136.810330070304786, 34.858336051426271], [136.81032633051737, 34.858351116191621], [136.810330070304786, 34.858350279260279]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810318170981219, 34.858408027502456], [136.810360328584636, 34.858413049086813], [136.810360328584636, 34.858409701363946], [136.810314771174461, 34.858403005917793], [136.810318170981219, 34.858408027502456]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810881178975421, 34.858355579825265], [136.810402486188082, 34.858282487793829], [136.810402486188082, 34.858286393476547], [136.810881178975421, 34.858360601412826], [136.810881178975421, 34.858355579825265]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810885938704871, 34.858327124156624], [136.810888658550255, 34.858322102567016], [136.810409285801541, 34.858246778686159], [136.810407925878849, 34.858249568460749], [136.810885938704871, 34.858327124156624]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81087981905273, 34.858358369596168], [136.810889338511572, 34.858322102567016], [136.810883898820805, 34.858321544612593], [136.810875059323337, 34.858355579825265], [136.81087981905273, 34.858358369596168]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810412005646924, 34.858476097842136], [136.810418805260383, 34.858475539888765], [136.810458922979763, 34.858291973022951], [136.810454843211687, 34.858291973022951], [136.810412005646924, 34.858476097842136]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810461642825146, 34.858257937783961], [136.810467762477259, 34.858256821874249], [136.81048748135629, 34.858195446816715], [136.810481361704149, 34.85819600477199], [136.810461642825146, 34.858257937783961]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810474467914929, 34.858237704320217], [136.810483040664707, 34.858200784645618], [136.810471922119717, 34.858199526227139], [136.81046348736146, 34.858235863053224], [136.810474467914929, 34.858237704320217]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810474913418574, 34.858239070426627], [136.810477645130049, 34.858227508711423], [136.810441989103026, 34.85822127560872], [136.810440072112499, 34.85823323058041], [136.810474913418574, 34.858239070426627]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810483971195225, 34.858202084729463], [136.810448123472611, 34.858196579148526], [136.810446973278317, 34.858208691426107], [136.810481479107551, 34.858213410494805], [136.810483971195225, 34.858202084729463]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811267397019776, 34.858288067340517], [136.811249718024783, 34.858350558237404], [136.811260597406317, 34.858352790054269], [136.811276916478619, 34.85828583552189], [136.811267397019776, 34.858288067340517]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811388430139317, 34.858303690069185], [136.811372111067016, 34.858363949137704], [136.81138299044855, 34.858365065045959], [136.81139794959816, 34.858304805978257], [136.811388430139317, 34.858303690069185]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812171958094893, 34.858450792326344], [136.812160866228027, 34.858497858020819], [136.812171745609533, 34.85850008983369], [136.81218351743783, 34.858451350279886], [136.812171958094893, 34.858450792326344]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812299578342504, 34.858450989936514], [136.812283259270231, 34.858511248897138], [136.812294138651765, 34.858512364803396], [136.812309097801347, 34.858452105843597], [136.812299578342504, 34.858450989936514]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811183081812914, 34.858627303067273], [136.81116540281792, 34.858689793706453], [136.811176282199455, 34.858692025514124], [136.811192601271756, 34.858625071257855], [136.811183081812914, 34.858627303067273]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811304114932426, 34.85864292573153], [136.811287795860125, 34.85870318455153], [136.811298675241659, 34.858704300455194], [136.811313634391297, 34.858644041635991], [136.811304114932426, 34.85864292573153]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811943278597397, 34.858751168395152], [136.811925599602404, 34.858813658940228], [136.811936478983938, 34.858815890744538], [136.81195279805624, 34.858748936589095], [136.811943278597397, 34.858751168395152]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812069170605326, 34.858750610443273], [136.812047992644608, 34.858827049765146], [136.812058872026142, 34.85882816566712], [136.81207914337179, 34.858751726346298], [136.812069170605326, 34.858750610443273]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811372111067016, 34.858367296862433], [136.811372111067016, 34.858370644587033], [136.812066224105251, 34.858483432602348], [136.81206656408591, 34.858479247952303], [136.811372111067016, 34.858367296862433]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811888881689725, 34.858660780201312], [136.8110906070699, 34.858539146549141], [136.811087887224517, 34.85854584198421], [136.81188480192165, 34.858666359722321], [136.811888881689725, 34.858660780201312]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811320434004756, 34.85864850525374], [136.811947358365472, 34.858752284298156], [136.811947358365472, 34.858747820686041], [136.811306834777838, 34.85864292573153], [136.811320434004756, 34.85864850525374]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811066128461448, 34.858635114399775], [136.811063408616064, 34.858630650781286], [136.810590155519463, 34.858558116947094], [136.810592875364847, 34.858563696475073], [136.811066128461448, 34.858635114399775]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.810595595210231, 34.858564812380628], [136.810564316988319, 34.8585904782041], [136.810571116601778, 34.858592710014456], [136.810605114669073, 34.858564812380628], [136.810595595210231, 34.858564812380628]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813014444370708, 34.858930735592587], [136.813020790676603, 34.858901350205429], [136.8130158042934, 34.85890023430445], [136.813008551372377, 34.858929991658869], [136.813014444370708, 34.858930735592587]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812707328496231, 34.858920413511584], [136.812721834338305, 34.858855691244429], [136.812718207877765, 34.858854203375628], [136.812703248728155, 34.858918553677029], [136.812707328496231, 34.858920413511584]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812073703680966, 34.858758049796435], [136.81265575059507, 34.858848809850969], [136.81265575059507, 34.858839882636929], [136.81207189045071, 34.858752098313957], [136.812073703680966, 34.858758049796435]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813644981029796, 34.858950170859067], [136.813683852153531, 34.858809381314991], [136.81367659923248, 34.858807893445338], [136.813640561281062, 34.85894942692552], [136.813644981029796, 34.858950170859067]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813681585615711, 34.858810125249811], [136.813726463064711, 34.858769580792526], [136.813721476681479, 34.858769952760113], [136.813677052540072, 34.858808637380172], [136.813681585615711, 34.858810125249811]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813719663451195, 34.858772556533189], [136.813937902711842, 34.858807381990054], [136.813938582673188, 34.858804406250655], [136.813721476681479, 34.858769208824938], [136.813719663451195, 34.858772556533189]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813533014061136, 34.858923296255313], [136.813562025745341, 34.858798687251252], [136.813558852592394, 34.858798315283806], [136.813528480985497, 34.858924412156], [136.813533014061136, 34.858923296255313]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813539133713277, 34.85892422617254], [136.813540493635998, 34.858925900023536], [136.813640901261721, 34.858941708614537], [136.813642261184413, 34.858940406730689], [136.813539133713277, 34.85892422617254]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813291712777499, 34.858659617800299], [136.813196971496325, 34.858645483011777], [136.813196064881197, 34.858646970884365], [136.813290806162371, 34.858661105672631], [136.813291712777499, 34.858659617800299]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815296937382413, 34.856343690634134], [136.815284536850044, 34.856341567004982], [136.815275371239153, 34.856379969290316], [136.815287448279378, 34.856382004433982], [136.815296937382413, 34.856343690634134]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815392906720007, 34.855956569831008], [136.815394636823896, 34.855945744607112], [136.815364476173158, 34.85594165463484], [136.815362714119402, 34.855951968612793], [136.815392906720007, 34.855956569831008]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815255746048791, 34.856337054292858], [136.815245775839315, 34.856375540958666], [136.815258765308812, 34.856377403239556], [136.815268254411848, 34.856339000953021], [136.815255746048791, 34.856337054292858]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815400515594774, 34.85592351193489], [136.815369287563584, 34.855918455069116], [136.815368182354831, 34.855926867810325], [136.815397576209335, 34.855932006494328], [136.815400515594774, 34.85592351193489]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815297153043844, 34.85634333669595], [136.815255853879506, 34.856337054292858], [136.815253373773032, 34.856344752448692], [136.815295104260258, 34.856351565758423], [136.815297153043844, 34.85634333669595]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815392847632751, 34.855956651198838], [136.815400886192975, 34.855923741903119], [136.815391953037192, 34.855922148610453], [136.815384157275759, 34.855955183003879], [136.815392847632751, 34.855956651198838]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815288086892537, 34.856373838932278], [136.815246787728171, 34.856367556531509], [136.815244307621697, 34.856375254684494], [136.815286038108923, 34.856382067991696], [136.815288086892537, 34.856373838932278]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815542543205027, 34.855878901123695], [136.81554425954576, 34.855877023235877], [136.81520556830759, 34.855821156053558], [136.81520556830759, 34.855823972887194], [136.815542543205027, 34.855878901123695]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815369335819298, 34.856611391472853], [136.815031788808284, 34.856557872129663], [136.815031788808284, 34.856559750001942], [136.815369335819298, 34.856612330408396], [136.815369335819298, 34.856611391472853]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815364758910647, 34.85661326934391], [136.815369335819298, 34.856614208279424], [136.815487191216363, 34.85612877718966], [136.815483758534867, 34.856125960366469], [136.815364758910647, 34.85661326934391]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815029500353972, 34.856558811065803], [136.815031788808284, 34.856560688938075], [136.815134769252325, 34.856123143543179], [136.815129048116546, 34.856123143543179], [136.815029500353972, 34.856558811065803]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815480897967007, 34.856134410835764], [136.815483758534896, 34.856136758188192], [136.815544974687754, 34.855877140603859], [136.815542114119836, 34.855876201659939], [136.815480897967007, 34.856134410835764]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815133052911619, 34.856131594012766], [136.815206283449584, 34.855820803949356], [136.815202278654539, 34.855821273421633], [136.8151287620598, 34.856131359277505], [136.815133052911619, 34.856131594012766]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814616050829898, 34.85831517463064], [136.814620583905537, 34.858318150387746], [136.814650728858624, 34.85817670881395], [136.814644042572013, 34.858174012029444], [136.814616050829898, 34.85831517463064]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814684046964686, 34.85833079735422], [136.814689486655482, 34.858333029171632], [136.81472484464561, 34.858185357122288], [136.814718045032095, 34.858183869241351], [136.814684046964686, 34.85833079735422]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814688580040354, 34.858328565536752], [136.814615144214741, 34.85831517463064], [136.814614237599613, 34.858320382205491], [136.814686766810098, 34.858333029171632], [136.814688580040354, 34.858328565536752]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814809159852757, 34.858203583661606], [136.814810973083013, 34.858198376079351], [136.814718498339687, 34.858184241211589], [136.814717138416995, 34.858191029668113], [136.814809159852757, 34.858203583661606]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814805760045999, 34.858199770967495], [136.814813012967022, 34.858202002788467], [136.81483749157556, 34.858106034432176], [136.814828425424281, 34.858106034432176], [136.814805760045999, 34.858199770967495]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814901861249893, 34.858207954310743], [136.814908207555817, 34.858213161892387], [136.814931779549198, 34.858123145077542], [136.814922713397891, 34.858123145077542], [136.814901861249893, 34.858207954310743]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814922713397891, 34.858129096605531], [136.814929059703815, 34.858123145077542], [136.814831145269665, 34.85810529049099], [136.814831145269665, 34.858111985961408], [136.814922713397891, 34.858129096605531]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815251814690413, 34.858271933148103], [136.815254534535825, 34.858266725570175], [136.814904581095277, 34.85820721037048], [136.814902767865021, 34.858212417952174], [136.815251814690413, 34.858271933148103]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815174752404289, 34.858580667535925], [136.815179738787577, 34.858578435725235], [136.815258160996336, 34.858267469509897], [136.815249094845001, 34.858265981630446], [136.815174752404289, 34.858580667535925]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815336129897617, 34.858602241702847], [136.815337036512744, 34.8585985220193], [136.8151783788648, 34.858571740292795], [136.815177925557322, 34.858574716040607], [136.815336129897617, 34.858602241702847]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815311651289136, 34.858721271487553], [136.815144834105013, 34.858695605704796], [136.81514528741252, 34.858697279560438], [136.81531074467398, 34.858722387390976], [136.815311651289136, 34.858721271487553]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815091797119806, 34.858913764603017], [136.815094516965161, 34.858915996404612], [136.815149820488159, 34.858695047752896], [136.815146194027619, 34.858695047752896], [136.815091797119806, 34.858913764603017]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815093610350033, 34.858914508536891], [136.814570493419495, 34.858825980358887], [136.814570493419495, 34.858828956097511], [136.815092703734905, 34.858917484272304], [136.815093610350033, 34.858914508536891]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814571400034623, 34.858826724293557], [136.814603131564212, 34.858700999239517], [136.814598598488544, 34.858700255303702], [136.814567773574083, 34.858825236424224], [136.814571400034623, 34.858826724293557]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814686540156259, 34.858722201407062], [136.814686540156231, 34.85871736582547], [136.814599505103672, 34.858700999239517], [136.814597691873416, 34.85870695072569], [136.814686540156259, 34.858722201407062]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814681100465464, 34.858720341568024], [136.814685633541103, 34.858720341568024], [136.814708752226949, 34.858627349562369], [136.814704219151309, 34.858627349562369], [136.814681100465464, 34.858720341568024]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81470625903529, 34.858629581371744], [136.81470625903529, 34.858626605625915], [136.814542161696579, 34.858599823908548], [136.814543068311707, 34.858604287528713], [136.81470625903529, 34.858629581371744]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814495924324916, 34.85879994264134], [136.814501364015712, 34.858802918380903], [136.814547601387375, 34.858602799655351], [136.814542161696579, 34.85860131178196], [136.814495924324916, 34.85879994264134]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814174982568545, 34.858753074728988], [136.814174982568545, 34.858757538340839], [136.8144986441703, 34.858805150185518], [136.814499550785428, 34.858802174446026], [136.814174982568545, 34.858753074728988]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814177702413929, 34.858756794405551], [136.81421215378893, 34.858602799655351], [136.814206714098134, 34.858602055718656], [136.814173169338289, 34.858756050470248], [136.814177702413929, 34.858756794405551]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81421215378893, 34.858602799655351], [136.81421215378893, 34.858598336035101], [136.814152317190263, 34.858589408793883], [136.814150503960008, 34.858593128477843], [136.81421215378893, 34.858602799655351]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814141437808701, 34.858631813181056], [136.814147784114652, 34.858634044990303], [136.814157756881059, 34.858589408793883], [136.814151410575136, 34.858588664857066], [136.814141437808701, 34.858631813181056]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814148860720053, 34.858630697276411], [136.814052532862405, 34.858618794292582], [136.81405168291073, 34.858623629879972], [136.814148180758707, 34.858636648767678], [136.814148860720053, 34.858630697276411]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814058029216653, 34.858622142006951], [136.814066188752861, 34.858578249740994], [136.814059842446909, 34.858578249740994], [136.814052589525858, 34.85862139807044], [136.814058029216653, 34.858622142006951]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.814061655677193, 34.858583457299197], [136.814064375522577, 34.858578993677895], [136.813988219851581, 34.858567834623592], [136.813988219851581, 34.858571554308533], [136.814061655677193, 34.858583457299197]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81393744940425, 34.858805894120366], [136.813992752927248, 34.858567834623592], [136.813986406621297, 34.858567834623592], [136.813931103098327, 34.858804406250641], [136.81393744940425, 34.858805894120366]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.8139881631881, 34.858573228166705], [136.813995416109151, 34.858569508481843], [136.813923793513794, 34.85855686155206], [136.813920167053283, 34.858563556985722], [136.8139881631881, 34.858573228166705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813935579510485, 34.858559837300419], [136.813930139819718, 34.85855760548916], [136.813769668941546, 34.858717551809391], [136.813776921862598, 34.858722759358763], [136.813935579510485, 34.858559837300419]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.811164397035554, 34.858281418378425], [136.811165756958218, 34.858278442619991], [136.810671651711829, 34.858198097101663], [136.810674824864805, 34.858202188773475], [136.811164397035554, 34.858281418378425]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81116349042037, 34.858280860423719], [136.811170290033886, 34.858282348302907], [136.811175049763307, 34.858268027464625], [136.811168250149819, 34.858266539585188], [136.81116349042037, 34.858280860423719]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.812077443467501, 34.858434053718234], [136.812073703680085, 34.858432379857234], [136.812062484317835, 34.858480921812344], [136.812066224105251, 34.858481200789029], [136.812077443467501, 34.858434053718234]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81361955330766, 34.858937518175694], [136.813635872380019, 34.858873911823132], [136.813639272186748, 34.858858289202701], [136.813631792611943, 34.858872237971084], [136.813614113616865, 34.858938076125931], [136.81361955330766, 34.858937518175694]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813726307239335, 34.858613906396805], [136.813727667162027, 34.858610558682095], [136.813675310138194, 34.858576523574804], [136.813673270254156, 34.858578197432863], [136.813726307239335, 34.858613906396805]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81365967102721, 34.858681976566373], [136.813608673926097, 34.858641804014098], [136.813603914196648, 34.858645151727536], [136.81365967102721, 34.858684208374264], [136.81365967102721, 34.858681976566373]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813677214029951, 34.858578476409207], [136.813675582122727, 34.858577583684912], [136.813605954080685, 34.858644537980084], [136.813609217895134, 34.858646769788976], [136.813677214029951, 34.858578476409207]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813777848309513, 34.858558836472504], [136.813763161144379, 34.858546784690837], [136.81376098526809, 34.85854812377778], [136.813774040525971, 34.858561068283727], [136.813777848309513, 34.858558836472504]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813833877124608, 34.858504380259831], [136.813837684908179, 34.858502594809629], [136.813815926144997, 34.858490543019727], [136.813812662330548, 34.858491435744959], [136.813833877124608, 34.858504380259831]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.813817558052222, 34.858493667558015], [136.813815926144997, 34.8584900966571], [136.813760441298996, 34.858545891966195], [136.813762617175314, 34.858548570140087], [136.813817558052222, 34.858493667558015]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818520850698008, 34.859555892108261], [136.818513507115455, 34.859554776216157], [136.818494196213152, 34.859625077389488], [136.818505347579276, 34.859626862815333], [136.818520850698008, 34.859555892108261]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818563008301624, 34.859562141103801], [136.818555664719071, 34.859561025211775], [136.818536353816768, 34.85963132637977], [136.818547505182863, 34.859633111805472], [136.818563008301624, 34.859562141103801]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.818557840595332, 34.859634450874722], [136.818558384564426, 34.85962909459758], [136.818538801677619, 34.859625077389488], [136.818537169770394, 34.859631103201551], [136.818557840595332, 34.859634450874722]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815802883293486, 34.855293403299186], [136.815705486925168, 34.855278949089367], [136.815705486925168, 34.855284050575477], [136.815798738767199, 34.85529680428936], [136.815802883293486, 34.855293403299186]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815688908819908, 34.855384379737977], [136.815708595319876, 34.855280649584778], [136.815704450793589, 34.855280649584778], [136.815680619767278, 34.855383529491348], [136.815688908819908, 34.855384379737977]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815774907740888, 34.855396283189812], [136.815778016135624, 34.855392031957216], [136.81568683655675, 34.85537757776472], [136.815682692030407, 34.85538182899807], [136.815774907740888, 34.855396283189812]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815768690951444, 34.855431993534985], [136.81577283547773, 34.855428592550403], [136.815719992767242, 34.855419239842064], [136.815718956635664, 34.85542349107326], [136.815768690951444, 34.855431993534985]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815724137293557, 34.85542349107326], [136.81573035408303, 34.855388630970971], [136.815725173425136, 34.855388630970971], [136.815717920504113, 34.855422640827037], [136.815724137293557, 34.85542349107326]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815822569793482, 34.855436244765528], [136.815905460319755, 34.85544899845582], [136.815907532582912, 34.855445597471935], [136.815824642056668, 34.855432843781117], [136.815822569793482, 34.855436244765528]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81589302674081, 34.855500013197215], [136.81589302674081, 34.855494911724492], [136.815756257372499, 34.855474505830465], [136.815750040583026, 34.855478757058805], [136.81589302674081, 34.855500013197215]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815757293504049, 34.855480457550087], [136.815768690951444, 34.85542944279657], [136.815762474161943, 34.855428592550403], [136.815751076714577, 34.855478757058805], [136.815757293504049, 34.855480457550087]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815858834398739, 34.855439645749797], [136.81586479215531, 34.85544092111887], [136.81587670766848, 34.85539309476539], [136.815870231846105, 34.855391181710665], [136.815858834398739, 34.855439645749797]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815876966701353, 34.855395432943311], [136.81587774380003, 34.855392669642114], [136.815832413043466, 34.855385867669547], [136.815831635944789, 34.855390331464115], [136.815876966701353, 34.855395432943311]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815833708207947, 34.855390544025745], [136.815843292425058, 34.855346118631118], [136.815839147898743, 34.855346118631118], [136.815829822714534, 34.85538948121755], [136.815833708207947, 34.855390544025745]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816019693826291, 34.855465153127355], [136.816026946747343, 34.855432843781117], [136.816022802220999, 34.855432843781117], [136.816016585431555, 34.855469404356171], [136.816019693826291, 34.855465153127355]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816251787299819, 34.854540930765957], [136.816252823431398, 34.854533278467564], [136.816145065747236, 34.854517973868624], [136.816142993484107, 34.854523925657439], [136.816251787299819, 34.854540930765957]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815824383023795, 34.85275282435822], [136.81582490108957, 34.852749848399803], [136.815701083365951, 34.852729866961944], [136.815701083365951, 34.852734118332101], [136.815824383023795, 34.85275282435822]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815704191760688, 34.852732417784061], [136.815725432458038, 34.852655467948487], [136.815721805997498, 34.852654617673657], [136.815701083365951, 34.852733693195098], [136.815704191760688, 34.852732417784061]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815686059458073, 34.852676724816334], [136.815690203984389, 34.852653342261398], [136.815687613655427, 34.852652491986547], [136.815681914931787, 34.852675449404416], [136.815686059458073, 34.852676724816334]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815685023326495, 34.852675874541724], [136.815683987194916, 34.852677149953635], [136.815715589208054, 34.852682676738354], [136.815715589208054, 34.852680551051961], [136.815685023326495, 34.852675874541724]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815716107273857, 34.852317908150951], [136.815728022786999, 34.852264765739264], [136.815724396326459, 34.852264765739264], [136.815712480813318, 34.852316632733469], [136.815716107273857, 34.852317908150951]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815557795003031, 34.852021455721392], [136.815556240805648, 34.852024006565472], [136.815589051638625, 34.852029297204787], [136.815589051638625, 34.852026462933743], [136.815557795003031, 34.852021455721392]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815585597866715, 34.852027974544974], [136.815589742393001, 34.852028541399179], [136.815601254966111, 34.851978091359761], [136.815597340691255, 34.851976390796089], [136.815585597866715, 34.852027974544974]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815600103708789, 34.851978091359761], [136.815601254966111, 34.851976768699139], [136.81556809875562, 34.851971667007945], [136.81556648699538, 34.851974690232396], [136.815600103708789, 34.851978091359761]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815755334711213, 34.85136222415521], [136.815744217781855, 34.851359921247003], [136.815736031994817, 34.851391917246282], [136.815748063548199, 34.851393874159392], [136.815755334711213, 34.85136222415521]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81572047010701, 34.851355420749336], [136.815711968888138, 34.851388003419849], [136.815722333752404, 34.851389926232407], [136.815730056547238, 34.851357045453945], [136.81572047010701, 34.851355420749336]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815755287064775, 34.851362216352712], [136.815720157505694, 34.851355420749336], [136.815718490298678, 34.851364057336589], [136.815752975132284, 34.851370108119845], [136.815755287064775, 34.851362216352712]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81570147191519, 34.851422666502508], [136.815699917717808, 34.851425217365154], [136.815741881046733, 34.851432019665175], [136.815743435244087, 34.851429468802742], [136.81570147191519, 34.851422666502508]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815742917178312, 34.851431169377705], [136.815745507507245, 34.851432444808907], [136.815762085612505, 34.851362721207416], [136.81575794108619, 34.851360595486938], [136.815742917178312, 34.851431169377705]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815758977217769, 34.851362721207416], [136.815760531415123, 34.851361445775133], [136.815719604217804, 34.851352517748587], [136.815720122283579, 34.851355493757545], [136.815758977217769, 34.851362721207416]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815954271974221, 34.850498326820599], [136.815943155044863, 34.850496023888212], [136.815934969257853, 34.850528020223422], [136.815947000811207, 34.850529977157088], [136.815954271974221, 34.850498326820599]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815919407370046, 34.850491523343294], [136.815910906151146, 34.850524106355891], [136.815921271015412, 34.850526029188643], [136.815928993810246, 34.850493148064949], [136.815919407370046, 34.850491523343294]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815954224327811, 34.850498319018016], [136.815919094768702, 34.850491523343294], [136.815917427561715, 34.850500160021227], [136.815951912395263, 34.850506210868005], [136.815954224327811, 34.850498319018016]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815900409178198, 34.85055876980249], [136.815898854980844, 34.850561320691931], [136.815940818309741, 34.850568123063368], [136.815942372507124, 34.850565572174141], [136.815900409178198, 34.85055876980249]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81594185444132, 34.850567272766966], [136.815944444770281, 34.850568548211562], [136.815961022875513, 34.850498823878027], [136.815956878349198, 34.850496698135224], [136.81594185444132, 34.850567272766966]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815957914480776, 34.850498823878027], [136.815959468678159, 34.850497548432344], [136.815918541480812, 34.850488620312063], [136.815919059546616, 34.850491596352263], [136.815957914480776, 34.850498823878027]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816130414342524, 34.850396716252511], [136.816119297413138, 34.850394413317282], [136.816111111626128, 34.850426409691998], [136.816123143179482, 34.850428366628087], [136.816130414342524, 34.850396716252511]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816095549738321, 34.850389912766808], [136.816087048519421, 34.850422495819636], [136.816097413383716, 34.850424418654761], [136.816105136178521, 34.850391537490474], [136.816095549738321, 34.850389912766808]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816130366696086, 34.850396708449914], [136.816095237136977, 34.850389912766808], [136.81609356992999, 34.850398549455406], [136.816128054763539, 34.850404600309659], [136.816130366696086, 34.850396708449914]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816133020717444, 34.850396363012379], [136.81613353878322, 34.850393599543281], [136.816093129651676, 34.85038637200828], [136.816094165783284, 34.850389560626745], [136.816133020717444, 34.850396363012379]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816075515414894, 34.850456734160275], [136.816078105743856, 34.850458009606584], [136.816094683849116, 34.850388285179378], [136.816090539322801, 34.850386159433704], [136.816075515414894, 34.850456734160275]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816116960677988, 34.850465237135289], [136.816117478743791, 34.850462473668507], [136.816077069612248, 34.85045524613956], [136.816078105743827, 34.850458434755346], [136.816116960677988, 34.850465237135289]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815970332013706, 34.851084604593332], [136.815959215084348, 34.851082301677351], [136.81595102929731, 34.851114297784584], [136.815963060850692, 34.851116254704309], [136.815970332013706, 34.851084604593332]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815935467409503, 34.8510778011645], [136.815926966190631, 34.851110383944942], [136.815937331054897, 34.851112306763994], [136.815945053849731, 34.851079425874595], [136.815935467409503, 34.8510778011645]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815970284367268, 34.851084596790805], [136.815935154808187, 34.8510778011645], [136.815933487601171, 34.8510864377809], [136.815967972434777, 34.85109248858457], [136.815970284367268, 34.851084596790805]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815972938388626, 34.851084251356156], [136.815973456454429, 34.851081487910164], [136.815933047322915, 34.851074260435581], [136.815934083454465, 34.851077449027393], [136.815972938388626, 34.851084251356156]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815915433086104, 34.851144621999339], [136.815918023415037, 34.851145897434989], [136.815934601520297, 34.851076173590677], [136.81593045699401, 34.851074047862795], [136.815915433086104, 34.851144621999339]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815956878349198, 34.85115312490327], [136.815957396414973, 34.851150361459588], [136.81591698728343, 34.851143133991066], [136.815918023415009, 34.851146322580199], [136.815956878349198, 34.85115312490327]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815926312467582, 34.850442916823994], [136.815958950612298, 34.850447168312328], [136.815959468678074, 34.850444617419356], [136.815926830533357, 34.850439940782032], [136.815926312467582, 34.850442916823994]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815956360283337, 34.850445467717023], [136.815960504809681, 34.850445892865849], [136.815977082914912, 34.85037574327918], [136.815973456454401, 34.850374892980803], [136.815956360283337, 34.850445467717023]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81597552871753, 34.850378294174284], [136.81597552871753, 34.850376168428369], [136.81594237250701, 34.850369366041058], [136.81594237250701, 34.850372342085585], [136.81597552871753, 34.850378294174284]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815995977326708, 34.850424746629024], [136.816000467230197, 34.85042559692689], [136.816017492046456, 34.850350234323749], [136.816014383651719, 34.850349384025094], [136.815995977326708, 34.850424746629024]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816030961756979, 34.8504318629533], [136.81603199788853, 34.85042888691094], [136.815998323612234, 34.850422084527985], [136.81599676941488, 34.850425485719533], [136.816030961756979, 34.8504318629533]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816081214138507, 34.850367665444153], [136.816109707756908, 34.850371491787158], [136.816109707756908, 34.850368940891848], [136.81608173220431, 34.850365114548715], [136.816081214138507, 34.850367665444153]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816107635493751, 34.850370216339513], [136.816110743888487, 34.850369791190289], [136.816136647177956, 34.850265629564952], [136.816131984585837, 34.850264779265423], [136.816107635493751, 34.850370216339513]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815566947498382, 34.851973934426312], [136.815561191211799, 34.851998687072495], [136.815571322276128, 34.85200019868423], [136.815576848311224, 34.851974879183935], [136.815566947498382, 34.851973934426312]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815599643205928, 34.851979791923405], [136.815566947498382, 34.851973934426312], [136.81556579624106, 34.851981114583992], [136.81559780119423, 34.851986783129071], [136.815599643205928, 34.851979791923405]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815599412954469, 34.851980736680957], [136.815590433147463, 34.851978280311293], [136.81558421635799, 34.852002277150333], [136.815593886919402, 34.852003977713451], [136.815599412954469, 34.851980736680957]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816149790698631, 34.855424101202267], [136.816155316733727, 34.855422589653443], [136.816166368803891, 34.855374597963561], [136.816160842768795, 34.855373842188691], [136.816149790698631, 34.855424101202267]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816191696464699, 34.855429769510138], [136.816201367026082, 34.855381022049663], [136.816196761996849, 34.85538026627485], [136.816188933447137, 34.855426746412661], [136.816191696464699, 34.855429769510138]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816164066289275, 34.855378376837791], [136.81616498729511, 34.855375353738424], [136.816151632710358, 34.855373464301259], [136.816151172207412, 34.855375353738424], [136.816164066289275, 34.855378376837791]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816093609341976, 34.855415409796102], [136.816103740406305, 34.855368173876968], [136.816098214371209, 34.855368929651881], [136.816088543809826, 34.855418432894005], [136.816093609341976, 34.855415409796102]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816127686558303, 34.855421078104577], [136.81613827812555, 34.855372708526382], [136.816134133599235, 34.85537308641382], [136.816124463037852, 34.855417299232307], [136.816127686558303, 34.855421078104577]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816106963926757, 34.855416543457828], [136.816110187447237, 34.85541767711954], [136.816118015996921, 34.855369685426801], [136.816115713482304, 34.855368173876968], [136.816106963926757, 34.855416543457828]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816043875026338, 34.855364395002269], [136.816045717038008, 34.855360616127392], [136.816040191002941, 34.855359482464891], [136.816039269997077, 34.855363261339825], [136.816043875026338, 34.855364395002269]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815914703956224, 34.85093368671604], [136.815909177921128, 34.850933308808202], [136.815848391535241, 34.851200867117839], [136.815853457067419, 34.851202378744226], [136.815914703956224, 34.85093368671604]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816298546091758, 34.855520501227979], [136.816311793610822, 34.855521514504836], [136.816315644671818, 34.85549662897931], [136.816303018540907, 34.855495570354599], [136.816298546091758, 34.855520501227979]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816339088246963, 34.855525502522504], [136.816344076790926, 34.855499705511001], [136.816333790548498, 34.855499114304386], [136.816328633671418, 34.855523410318867], [136.816339088246963, 34.855525502522504]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816300747305405, 34.855501561482491], [136.81634112459156, 34.855506920694509], [136.816342730291268, 34.855499535651703], [136.816302209758248, 34.855494380224492], [136.816300747305405, 34.855501561482491]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816338559059659, 34.855525973639999], [136.816351806578723, 34.855526986916786], [136.816355657639718, 34.855502101392901], [136.816343031508808, 34.855501042768267], [136.816338559059659, 34.855525973639999]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816379101214892, 34.855530974934183], [136.816384089758856, 34.8555051779244], [136.816373803516399, 34.855504586717814], [136.816368646639347, 34.855528882730681], [136.816379101214892, 34.855530974934183]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816340760273334, 34.855507033895762], [136.816381137559489, 34.855512393107418], [136.816382743259169, 34.855505008065101], [136.816342222726149, 34.855499852638239], [136.816340760273334, 34.855507033895762]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815796651514319, 34.851692424709221], [136.815796996891521, 34.851691007567915], [136.815772820488036, 34.851687039572127], [136.815772475110833, 34.851689023570053], [136.815796651514319, 34.851692424709221]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815786635575762, 34.851742874923772], [136.815788017084515, 34.851740890927154], [136.815759696154714, 34.851736922933782], [136.815759005400338, 34.851739190358586], [136.815786635575762, 34.851742874923772]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815748212363047, 34.851767887448453], [136.815747521608671, 34.851765620024437], [136.815729561994658, 34.851763636028373], [136.815729561994658, 34.851765620024437], [136.815748212363047, 34.851767887448453]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815749421183227, 34.851761652032245], [136.815748730428822, 34.851759384608059], [136.815730770814838, 34.851757400611838], [136.815730770814838, 34.851759384608059], [136.815749421183227, 34.851761652032245]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815779296310382, 34.851771288584366], [136.815778605556005, 34.851769021160443], [136.815760645941992, 34.851767037164457], [136.815760645941992, 34.851769021160443], [136.815779296310382, 34.851771288584366]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815745967411317, 34.851773981150203], [136.815745276656912, 34.851771713726343], [136.815727317042928, 34.851769729730421], [136.815727317042928, 34.851771713726343], [136.815745967411317, 34.851773981150203]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815768934994594, 34.851680024721979], [136.815768244240218, 34.851677757295541], [136.815750284626205, 34.851675773297345], [136.815750284626205, 34.851677757295541], [136.815768934994594, 34.851680024721979]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815770143814774, 34.851673789299106], [136.815769453060398, 34.851671521872504], [136.815751493446385, 34.851669537874166], [136.815751493446385, 34.851671521872504], [136.815770143814774, 34.851673789299106]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815771870700758, 34.851666420162388], [136.815771179946353, 34.851664152735573], [136.815753220332368, 34.851662168737057], [136.815753220332368, 34.851664152735573], [136.815771870700758, 34.851666420162388]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815802091205086, 34.851664152735573], [136.815801400450709, 34.851661885308701], [136.815783440836697, 34.851659901310128], [136.815783440836697, 34.851661885308701], [136.815802091205086, 34.851664152735573]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.81577325220951, 34.851660043024317], [136.815772561455105, 34.851657775597324], [136.815754601841121, 34.851655791598652], [136.815754601841121, 34.851657775597324], [136.81577325220951, 34.851660043024317]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815777742112999, 34.851779082853632], [136.815777051358623, 34.851776815429929], [136.81575909174461, 34.851774831434128], [136.81575909174461, 34.851776815429929], [136.815777742112999, 34.851779082853632]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815780850507736, 34.851764344598401], [136.815780159753359, 34.851762077174278], [136.815762200139346, 34.851760093178122], [136.815762200139346, 34.851762077174278], [136.815780850507736, 34.851764344598401]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815783268148067, 34.85175725889782], [136.815782577393662, 34.851754991473513], [136.815764617779678, 34.851753007477186], [136.815764617779678, 34.851754991473513], [136.815783268148067, 34.85175725889782]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815747176231497, 34.851759384608059], [136.81574855774025, 34.851760234892133], [136.81575097538061, 34.851750881766783], [136.815748903117452, 34.851750598338725], [136.815747176231497, 34.851759384608059]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.815986997519673, 34.850618897748255], [136.81602429825648, 34.850463576889517], [136.816020844484541, 34.850461876294553], [136.815982852993358, 34.850618330884345], [136.815986997519673, 34.850618897748255]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816049165414341, 34.850354738740805], [136.816044330133622, 34.850350770680578], [136.81602775202839, 34.850431265579147], [136.816031205800329, 34.850432399309547], [136.816049165414341, 34.850354738740805]]]] } },
    { "type": "Feature", "properties": { "id": null }, "geometry": { "type": "MultiPolygon", "coordinates": [[[[136.816085084642339, 34.85042559692689], [136.816085775396743, 34.850421062004806], [136.816062980502011, 34.850417660813086], [136.816061598993258, 34.850422195735355], [136.816085084642339, 34.85042559692689]]]] } }
  ]
};

const layerStyle = {
  id: 'point',
  type: 'fill',
  paint: {
    'fill-color': '#007cbf',
    'fill-opacity': 0.75,
  }
};

export const Centrair2WallSource = () => (
  <Source id="centrair_2Wall_geojson-data" type="geojson" data={centrair_2Wall_geojson}>
    <Layer {...layerStyle} />
  </Source>
);
