import * as React from 'react';
import Grid from '@mui/material/Grid';

import Map, { NavigationControl, ScaleControl, FullscreenControl, GeolocateControl, Marker } from 'react-map-gl';
import { MAPBOX_TOKEN, MAX_POINTS_SHOW_MARKERS } from '../config';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

import PolylineOverlay from '../components/PolyLineOverlay';

import { MarkerProp, TimelineChartData } from '../types';

const strokeColors = scaleOrdinal(schemeCategory10).range();


type Props = {
  mapRoutes: TimelineChartData[][];
  markers: MarkerProp[];
}

const LHMapMarers: React.FC<{markers: MarkerProp[]}> = (props: {markers: MarkerProp[]}) => {
  return (
    <>
    {
      props.markers.map(marker => {
        const bgColor = strokeColors[marker.active % strokeColors.length];
        return (
          <Marker
            key={String(marker.id) + '_' + String(marker.lat) + '_' + String(marker.lon)}
            latitude={marker.lat}
            longitude={marker.lon}
            onClick={() => { console.log(marker.id) }}
            style={{ cursor: 'pointer', zIndex: 0 }}
          >
            <div
              className="locDotSmall"
              style={{ backgroundColor: bgColor }}
            >
              <div
                className="idTag"
                style={{ backgroundColor: bgColor }}
              >
                {marker.name}<br />{marker.time}
              </div>
            </div>
          </Marker>
        );
      })
    }
    </>
  )
}

export const LHMap: React.FC<Props> = (props) => {
  const [viewState, setViewState] = React.useState({
    longitude: 136.9641331,
    latitude: 35.1547924,
    zoom: 13,
    showMarkers: true,
  });

  React.useEffect(() => {
    let avg_lat = 0, avg_lon = 0, totalPoints = 0;
    props.mapRoutes?.forEach((mapRoute, i) => {
      mapRoute.forEach(point => {
        avg_lat += point.lat!!;
        avg_lon += point.lon!!;
        totalPoints++;
      });
    });
    if(totalPoints) {
      avg_lat /= totalPoints;
      avg_lon /= totalPoints;
      setViewState({ latitude: avg_lat, longitude: avg_lon, zoom: viewState.zoom, showMarkers: totalPoints < MAX_POINTS_SHOW_MARKERS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapRoutes]);

  return (
    <Grid item xs>
      <Map
        // initialViewState={{
        //   longitude: avg_lon,
        //   latitude: avg_lat,
        //   zoom: 16,
        // }}
        {...viewState}
        mapStyle="mapbox://styles/mapbox/dark-v10"
        mapboxAccessToken={MAPBOX_TOKEN}
        onMouseEnter={(e) => { console.log(e) }}
        onMove={evt => setViewState({...evt.viewState, showMarkers: viewState.showMarkers})}
      >
        {
          props.mapRoutes?.map((mapRoute, i) => <PolylineOverlay key={mapRoute && mapRoute.length ? mapRoute[0].id : Math.random()} id={mapRoute && mapRoute.length ? mapRoute[0].id : Math.random()} points={mapRoute} color={strokeColors[i % strokeColors.length]} />)
        }
        {viewState.showMarkers ? <LHMapMarers markers={props.markers} /> : <></>}
        <NavigationControl />
        <ScaleControl />
        <FullscreenControl />
        <GeolocateControl />
      </Map>
    </Grid>
  )
};