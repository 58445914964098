import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
// import LoadingButton from '@mui/lab/LoadingButton';
// import GetAppIcon from '@mui/icons-material/GetApp';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LineAxisIcon from '@mui/icons-material/LineAxis';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import Title from '../Title';
import { GlobalStateContextType, useAuthUserContext, useGlobalStateContext } from "../providers";

import Map, { Marker, NavigationControl, ScaleControl, FullscreenControl, GeolocateControl } from 'react-map-gl';
import { MAPBOX_TOKEN } from '../config';
import { ACChart } from '../elements/ACChart';
import { Box, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, ToggleButton, ToggleButtonGroup, Toolbar } from '@mui/material';
import { TimelineChartData, MarkerProp, isMarkerActive } from '../types';
import { Centrair2WallSource } from '../objects/centrair_2-wall';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

const strokeColors = scaleOrdinal(schemeCategory10).range();

const PcounterApp = require('pcounter_app');

const columns: GridColDef[] = [
  { field: 'data_source_id', headerName: 'ソースID', maxWidth: 80 },
  { field: 'data_source_name', headerName: 'ソース名', minWidth: 200, flex: 1.2 },
  { field: 'data_source_group_name', headerName: 'グループ名', minWidth: 200, flex: 0.8 },
  { field: 'data_source_latitude', headerName: '緯度', minWidth: 120, flex: 0.2, hide: true },
  { field: 'data_source_longitude', headerName: '経度', minWidth: 120, flex: 0.2, hide: true },
  { field: 'granularity_time', headerName: '時間粒度', hide: true },
  { field: 'granularity_mesh', headerName: 'メッシュ粒度', hide: true },
];

export default function AC() {
  let { aids, s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute, g_time, g_mesh, view_type, view_div } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const globalState: GlobalStateContextType = useGlobalStateContext();
  const { setMenuAcUrl, acData, setAcData } = globalState;
  const { t } = useTranslation();

  const user = useAuthUserContext().user;
  const rows = user?.perms?.filter(row => row.data_source_type === 2)!!;

  // calc latlon av, parse url sid list
  let avg_lat = 0, avg_lon = 0;
  const aidSelections: GridSelectionModel = [];
  const markers: MarkerProp[] = [];
  const aid_list = aids?.split(",").map(v => parseInt(v)).filter(v => !isNaN(v));
  rows.forEach((row, _) => {
    avg_lat += row.data_source_latitude!!;
    avg_lon += row.data_source_longitude!!;
    const marker: MarkerProp = {
      id: row.id!!,
      lat: row.data_source_latitude!!,
      lon: row.data_source_longitude!!,
      name: row.data_source_name!!,
      active: -1,
    };
    if (aid_list?.includes(row.data_source_id!!)) {
      aidSelections.push(row.id!!);
      marker.active = aid_list?.indexOf(row.data_source_id!!)!!;
    }
    markers.push(marker);
  });
  avg_lat /= rows.length;
  avg_lon /= rows.length;

  // parse url date
  let sTime = dayjs().hour(0).minute(0).second(0).millisecond(0);
  if (s_year !== void 0) sTime = sTime.year(parseInt(s_year));
  if (s_month !== void 0) sTime = sTime.month(parseInt(s_month) - 1);
  if (s_day !== void 0) sTime = sTime.date(parseInt(s_day));
  if (s_hour !== void 0) sTime = sTime.hour(parseInt(s_hour));
  if (s_minute !== void 0) sTime = sTime.minute(parseInt(s_minute));
  let eTime = dayjs().hour(23).minute(59).second(59).millisecond(999);
  if (e_year !== void 0) eTime = eTime.year(parseInt(e_year));
  if (e_month !== void 0) eTime = eTime.month(parseInt(e_month) - 1);
  if (e_day !== void 0) eTime = eTime.date(parseInt(e_day));
  if (e_hour !== void 0) eTime = eTime.hour(parseInt(e_hour));
  if (e_minute !== void 0) eTime = eTime.minute(parseInt(e_minute));

  const gTime = parseInt(g_time || '600');  // デフォルト精度 (10分)
  const gMesh = parseInt(g_mesh || '10');   // 最大精度(緯度経度 下10桁まで)
  const viewType = view_type || 'line';     // デフォルト：折れ線ビュー
  const viewDiv = view_div || 'on';         // デフォルト：div (f/b) 分割ビュー

  console.log(aidSelections, sTime.format("YYYY/M/D HH:mm"), eTime.format("YYYY/M/D HH:mm"), gTime, gMesh, viewType, viewDiv);

  const calcPath = (sid_list_n: number[], newSTime: dayjs.Dayjs, newETime: dayjs.Dayjs, gTime: number, gMesh: number, viewType: string, viewDiv: string) => {
    return '/ac/' + (sid_list_n.length > 0 ? sid_list_n.join(',') : '-') + '/' + newSTime.format("YYYY/M/D/H/m") + '/' + newETime.format("YYYY/M/D/H/m") + '/' + String(gTime) + '/' + String(gMesh) + '/' + viewType + '/' + viewDiv;
  }

  const navToCurrentSelection = (newSelectionModel: GridSelectionModel, newSTime: Dayjs, newETime: Dayjs, gTime: number, gMesh: number, viewType: string, viewDiv: string) => {
    const aid_list_n: number[] = [];
    newSelectionModel.forEach(v => aid_list_n.push(rows.filter(row => row.id === Number(v))[0].data_source_id!!));
    aid_list_n.sort();
    const newUrl = calcPath(aid_list_n, newSTime, newETime, gTime, gMesh, viewType, viewDiv);
    setMenuAcUrl(newUrl);
    navigate(newUrl);
  }

  const fetchData = () => {
    setLoading(true);
    const dataBlock: { [key: string | number]: any; } = {};
    aid_list?.forEach(aid => {
      dataBlock[aid] = 0;
    })
    const newAcData: TimelineChartData[] = [];
    const gTimeM = gTime * 1000;
    const sTimeUnixM = sTime.valueOf();
    const eTimeUnixM = eTime.valueOf() + gTimeM;
    for (let time_step = sTimeUnixM; time_step < eTimeUnixM-gTimeM; time_step += gTimeM) {
      const acDataBlock = Object.assign({}, dataBlock);
      acDataBlock.time = time_step;
      acDataBlock.total = 0;
      aid_list?.forEach(aid => {
        acDataBlock[aid] = 0;
      })
      newAcData.push(acDataBlock);
    }
    setAcData(newAcData);
    console.log(newAcData);
    const api = new PcounterApp.AcApi();
    console.log("stime", sTimeUnixM, "gtime", gTimeM);
    let fetchingCount = aid_list?.length!!;
    const callback = (sid: number) => (error: any, data: any, response: any) => {
      fetchingCount--;
      if (error) {
        console.error(error);
        setLoading(false);
      } else {
        console.log('[AC] API called successfully.', sid) // Returned data: ')
        // console.log(response.text);
        response.text.split('\n').forEach((d: string) => {
          if (d.length > 0) {
            const dataArray = d.split(',');
            const index = Math.round((Date.parse(dataArray[0]) - sTimeUnixM) / gTimeM);
            // console.log(dataArray[0], Date.parse(dataArray[0]), index, sid);
            newAcData[index][sid] = parseInt(dataArray[1]);
            newAcData[index].total += parseInt(dataArray[1]);
          }
        });
        // console.log("AC", sid, newPcData);
      }
      if (fetchingCount === 0) {
        setAcData(newAcData);
        setLoading(false);
      }
    };
    aid_list?.forEach(sid => {
      api.getacById(sid, s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute, g_time, g_mesh, callback(sid));
    });
  };

  React.useEffect(() => {
    if (aid_list && aid_list.length) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcPath(aid_list || [], sTime, eTime, gTime, gMesh, viewType, viewDiv)]);

  if (rows.length === 0) {
    return <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>{t("etc.no_datasources")}</Title>
      </div>
    </React.Fragment>;
  }

  const showColmuns = columns.map(col => {
    col.headerName = t("ac.columns." + col.field);
    return col;
  });

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>{t("app.menu.ac")}</Title>
        <div style={{ width: '100%', flexGrow: 1 }}>
          <Grid style={{ height: '100%' }} container spacing={3}>
            <Grid item container direction="column" spacing={2} xs={12} md={5}>
              <Grid item xs={6}>
                <DataGrid
                  style={{ height: '100%' }}
                  rows={rows}
                  rowHeight={25}
                  columns={showColmuns}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  checkboxSelection
                  onSelectionModelChange={(newSelectionModel) => {
                    navToCurrentSelection(newSelectionModel, sTime, eTime, gTime, gMesh, viewType, viewDiv);
                  }}
                  selectionModel={aidSelections}
                />
              </Grid>
              <Grid item xs>
                <Map
                  initialViewState={{
                    longitude: avg_lon,
                    latitude: avg_lat,
                    zoom: 16,
                  }}
                  mapStyle="mapbox://styles/mapbox/dark-v10"
                  mapboxAccessToken={MAPBOX_TOKEN}
                  onMouseEnter={(e) => { console.log(e) }}
                >
                  <Centrair2WallSource />
                  {markers.map(marker => {
                    const bgColor = isMarkerActive(marker.active) ? strokeColors[marker.active % strokeColors.length] : '#222222';
                    return (
                      <Marker
                        key={isMarkerActive(marker.active) ? String(marker.id) + '_' + String(aidSelections.indexOf(marker.id)) : marker.id}
                        latitude={marker.lat}
                        longitude={marker.lon}
                        // scale={0.5}
                        onClick={() => {
                          if (aidSelections.includes(marker.id)) {
                            navToCurrentSelection(aidSelections.filter(v => v !== marker.id), sTime, eTime, gTime, gMesh, viewType, viewDiv);
                          } else {
                            aidSelections.push(marker.id);
                            navToCurrentSelection(aidSelections, sTime, eTime, gTime, gMesh, viewType, viewDiv);
                          }
                        }}
                        style={{ cursor: 'pointer', zIndex: isMarkerActive(marker.active) ? 2 : 0 }}
                      >
                        <div
                          className="locDot"
                          style={{ backgroundColor: bgColor }}
                        >
                          <div
                            className="idTag"
                            style={{ backgroundColor: bgColor }}
                          >
                            {marker.name}
                          </div>
                        </div>
                      </Marker>
                    );
                  })}
                  <NavigationControl />
                  <ScaleControl />
                  <FullscreenControl />
                  <GeolocateControl />
                </Map>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2} xs={12} md={7}>
              <Grid item xs="auto">
                <Toolbar
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    '& hr': {
                      mx: 1.5,
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl sx={{ width: 160, marginRight: 1 }}>
                      <MobileDateTimePicker
                        label={t("ac.control.start_time")}
                        value={sTime}
                        onChange={(v) => {
                          const newETime = (v as dayjs.Dayjs).add(eTime.diff(sTime));
                          navToCurrentSelection(aidSelections, v!!, newETime, gTime, gMesh, viewType, viewDiv);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                    </FormControl>
                    ～
                    <FormControl sx={{ width: 160, marginLeft: 1 }}>
                      <MobileDateTimePicker
                        label={t("ac.control.end_time")}
                        value={eTime}
                        onChange={(v) => {
                          navToCurrentSelection(aidSelections, sTime, v!!, gTime, gMesh, viewType, viewDiv);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                      </FormControl>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <FormControl sx={{ width: 100 }}>
                      <InputLabel>{t("ac.control.time_interval")}</InputLabel>
                      <Select
                        value={String(gTime)}
                        onChange={(event: SelectChangeEvent) => {
                          const newGTime = parseInt(event.target.value);
                          navToCurrentSelection(aidSelections, sTime, eTime, newGTime, gMesh, viewType, viewDiv);
                        }}
                        label={t("ac.control.time_interval")}
                      >
                        <MenuItem value={60}>1{t("units.minute")}</MenuItem>
                        <MenuItem value={120}>2{t("units.minute")}</MenuItem>
                        <MenuItem value={300}>5{t("units.minute")}</MenuItem>
                        <MenuItem value={600}>10{t("units.minute")}</MenuItem>
                        <MenuItem value={900}>15{t("units.minute")}</MenuItem>
                        <MenuItem value={1800}>30{t("units.minute")}</MenuItem>
                        <MenuItem value={3600}>1{t("units.hour")}</MenuItem>
                        <MenuItem value={7200}>2{t("units.hour")}</MenuItem>
                        <MenuItem value={10800}>3{t("units.hour")}</MenuItem>
                        <MenuItem value={14400}>4{t("units.hour")}</MenuItem>
                        <MenuItem value={21600}>6{t("units.hour")}</MenuItem>
                        <MenuItem value={86400}>1{t("units.day")}</MenuItem>
                        <MenuItem value={604800}>1{t("units.week")}</MenuItem>
                      </Select>
                    </FormControl>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ToggleButtonGroup
                      color="primary"
                      value={viewType}
                      exclusive
                      onChange={(_, v) => {
                        navToCurrentSelection(aidSelections, sTime, eTime, gTime, gMesh, v, viewDiv);
                      }}
                      aria-label="グラフ形式"
                    >
                      <ToggleButton value="line"><LineAxisIcon/></ToggleButton>
                      <ToggleButton value="stack"><StackedLineChartIcon/></ToggleButton>
                    </ToggleButtonGroup>
                    {/* <Divider orientation="vertical" variant="middle" flexItem />
                    <LoadingButton
                      variant="contained"
                      onClick={() => fetchData()}
                      endIcon={<GetAppIcon />}
                      loading={loading}
                      loadingPosition="end"
                      disabled={!sidSelections.length}
                    >
                      取得
                    </LoadingButton> */}
                  </LocalizationProvider>
                </Toolbar>
              </Grid>
              <Grid item xs>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <ACChart data={acData} keys={aid_list} type={viewType} loading={loading} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
