import React from "react";
import { useTheme } from '@mui/material/styles';
import { Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, Legend, Brush, Layer, Area, ComposedChart, ReferenceLine } from 'recharts';
import { TimelineChartData } from '../types';
import dayjs from "dayjs";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const strokeColors = scaleOrdinal(schemeCategory10).range();

type Key = number | string;

type Props = {
  data: TimelineChartData[];
  keys: Key[] | undefined;
  type: string;
  div: boolean;
  loading?: boolean;
}

class CustomizedAxisTick extends React.Component<any> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fill="#666" className="recharts-text recharts-cartesian-axis-tick-value" style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: '0.875rem', letterSpacing: '0.01071em' }}>
          <tspan textAnchor="middle" x="0">{dayjs(payload.value).format("YYYY/M/D")}</tspan>
          <tspan textAnchor="middle" x="0" dy="16">{dayjs(payload.value).format("HH:mm")}</tspan>
        </text>
      </g>
    );
  }
}

const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    let total = 0, totalIN = 0, totalOUT = 0;
    return (
      <Grid container style={{ backgroundColor: "#FFFFFFCC", color: "#666", padding: "4px 8px", fontSize: '0.875rem', width: props.div ? '220px' : '140px' }}>
        <Grid item xs={12}>{dayjs(label).format("YYYY/M/D HH:mm")}</Grid>
        {payload.map((v: any, i: number) => {
          if (typeof v.name === "string") {
            if (v.name.indexOf('OUT') !== -1) totalOUT -= v.value;
            if (v.name.indexOf('IN') !== -1) totalIN += v.value;
          } else {
            total += v.value;
          }
          return (<Grid item xs={props.div ? 6 : 12} key={Math.random()}>
            <span style={{ color: getColor(i, props.div) }}>{v.name}</span>:&nbsp;
            <span>{typeof v.name === "string" && v.name.indexOf('OUT') !== -1 ? -v.value : v.value}</span>
          </Grid>);
        })}
        {props.div ? <Grid item xs={12}>{t("chart.in_total")}: {totalIN}<br/>{t("chart.out_total")}: {totalOUT}<br/>{t("chart.diff")}: {totalIN-totalOUT}<br/>{t("chart.total")}: {totalIN+totalOUT}</Grid> : <Grid item xs={12}>{t("chart.total")}: {total}</Grid>}
      </Grid>
    );
  }

  return null;
};

class CustomizedBrush extends Brush {

  renderText() {
    const { startIndex, endIndex, y, height, travellerWidth, stroke, data, dataKey } = this.props;
    const { startX, endX } = this.state;
    const offset = 8;
    const attrs = {
      pointerEvents: 'none',
      fill: stroke,
    };

    const startData = data!![startIndex!!][dataKey as string];
    const endData = data!![endIndex!!][dataKey as string];

    return (
      <Layer className="recharts-brush-texts">
        <text textAnchor="start" x={Math.min(startX!!, endX!!) + offset} y={y!! + height / 2 - 2} style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: '0.875rem', letterSpacing: '0.01071em' }} {...attrs}>
          <tspan>{dayjs(startData).format("YYYY/M/D")}</tspan>
          <tspan dx="-48" dy="16">{dayjs(startData).format("HH:mm")}</tspan>
        </text>
        <text textAnchor="end" x={Math.max(startX!!, endX!!) + travellerWidth!! - offset} y={y!! + height / 2 - 2} style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: '0.875rem', letterSpacing: '0.01071em' }} {...attrs}>
          <tspan>{dayjs(endData).format("YYYY/M/D")}</tspan>
          <tspan dx="-48" dy="16">{dayjs(endData).format("HH:mm")}</tspan>
        </text>
      </Layer>
    );
  }
}

const getColor = (i: number, div: boolean) => {
  return strokeColors[Math.floor(i / (div ? 2 : 1)) % strokeColors.length];
}


export const PCChart: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (props.loading) {
    return (<Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      height='100%'
    >
      <CircularProgress />
    </Stack>)
  }

  console.log(props);

  if (props.keys === void 0 || props.keys.length === 0) {
    return (<Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      height='100%'
    >
      <Typography component="h4" variant="h4" color='gray' align='center' gutterBottom>
        {/* データソースを選択すると<br />ここにグラフが表示されます */}
        {t("chart.select_datasource")}
      </Typography>
    </Stack>)
  }

  const keys = props.keys.concat();
  if (props.div) {
    keys.splice(0);
    props.keys.forEach(v => {
      keys.push(String(v) + ' IN');
      keys.push(String(v) + ' OUT');
    });
  }

  return (
    <React.Fragment>
      <ResponsiveContainer width={'99.9%'} height={'99.9%'}>
        <ComposedChart
          data={props.data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
          syncId="anyId"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            domain={['dataMin', 'dataMax']}
            stroke={theme.palette.text.secondary}
            tick={<CustomizedAxisTick />}
            style={theme.typography.body2}
            interval={"preserveStartEnd"}
            height={60}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              {t("chart.number_of_people")}
            </Label>
          </YAxis>
          <Tooltip content={<CustomTooltip div={props.div} />} />
          <Legend />
          {keys ? (props.type === 'line' ?
            keys.map((key, i) => <Line
              animationDuration={500}
              type="monotone"
              dataKey={key}
              stroke={getColor(i, props.div)}
              dot={false}
              strokeWidth={1.5}
              key={Math.random()}
            />)
            :
            keys.map((key, i) => <Area
              animationDuration={500}
              type="monotone"
              dataKey={key}
              stroke={getColor(i, props.div)}
              fill={getColor(i, props.div)}
              dot={false}
              key={Math.random()}
              stackId={typeof key === "string" && key.indexOf('IN') !== -1 ? 'IN1' : 'OUT1'}
            />)) : (<></>)
          }
          {props.div ? <ReferenceLine y={0} stroke="black" /> : <></>}
          <CustomizedBrush
            dataKey="time"
            alwaysShowText
          >
            <ComposedChart
              data={props.data}
            >
              <XAxis
                hide
                dataKey="time"
                domain={['dataMin', 'dataMax']}
                stroke={theme.palette.text.secondary}
                interval={"preserveStartEnd"}
              />
              <YAxis
                hide
              />
              {keys ? (props.type === 'line' ?
                keys.map((key, i) => <Line
                  animationDuration={500}
                  type="monotone"
                  dataKey={key}
                  stroke={getColor(i, props.div)}
                  dot={false}
                  opacity={0.5}
                  key={Math.random()}
                />)
                :
                keys.map((key, i) => <Area
                  animationDuration={500}
                  type="monotone"
                  dataKey={key}
                  stroke={getColor(i, props.div)}
                  fill={getColor(i, props.div)}
                  dot={false}
                  key={Math.random()}
                  stackId={typeof key === "string" && key.indexOf('IN') !== -1 ? 'IN2' : 'OUT2'}
                />)) : (<></>)
              }
              {props.div ? <ReferenceLine y={0} stroke="gray" /> : <></>}
            </ComposedChart>
          </CustomizedBrush>
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

