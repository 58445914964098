import React from "react";
import { useAuthUserContext } from "../providers"
import { Navigate, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type Props = {
  component: React.ReactNode;
  redirect: string;
  reqireAdmin?: boolean; 
}

export const RouteAuthGuard: React.FC<Props> = (props) => {
  const userContext = useAuthUserContext();
  const authUser = userContext.user;
  const appLoaded = userContext.appLoaded;
  const location = useLocation();

  if (!appLoaded) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  let allowRoute = false;
  if ( authUser ) {
    allowRoute = props.reqireAdmin ? authUser.perms.some((v) => v.opt === "administrator") : true;
  }

  return allowRoute ? <>{props.component}</> : <Navigate to={props.redirect} state={{from:location}} replace={false} />;

}
