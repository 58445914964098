import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import PlaceIcon from '@mui/icons-material/Place';
// import MapIcon from '@mui/icons-material/Map';
import SensorsIcon from '@mui/icons-material/Sensors';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GlobalStateContextType, useGlobalStateContext } from "./providers";


export function MainListItems() {
  const navigate = useNavigate();
  const location = useLocation();
  const globalState: GlobalStateContextType = useGlobalStateContext();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate(`/`)} selected={location.pathname.length < 2}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t("app.menu.dashboard")} />
      </ListItemButton>
      {/* <ListItemButton onClick={() => navigate(`/map_test/35.1538553/136.9660515`)}>
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <ListItemText primary="Test Map" />
      </ListItemButton> */}
      <ListItemButton onClick={() => navigate(globalState.menuPcUrl)} selected={location.pathname.startsWith('/pc')}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={t("app.menu.pc")} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate(globalState.menuAcUrl)} selected={location.pathname.startsWith('/ac')}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={t("app.menu.ac")} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate(globalState.menuPfUrl)} selected={location.pathname.startsWith('/pf') && !location.pathname.startsWith('/pfwt')}>
        <ListItemIcon>
          <ForkRightIcon />
        </ListItemIcon>
        <ListItemText primary={t("app.menu.pf")} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate(globalState.menuPfwtUrl)} selected={location.pathname.startsWith('/pfwt')}>
        <ListItemIcon>
          <ForkRightIcon />
        </ListItemIcon>
        <ListItemText primary={t("app.menu.pfwt")} />
      </ListItemButton>
      <ListItemButton onClick={() => navigate(globalState.menuLhUrl)} selected={location.pathname.startsWith('/lh')}>
        <ListItemIcon>
          <PlaceIcon />
        </ListItemIcon>
        <ListItemText primary={t("app.menu.lh")} />
      </ListItemButton>
    </React.Fragment>
  );
};

export function SecondaryListItems() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        {t("app.menu.tools")}
      </ListSubheader>
      <ListItemButton onClick={() => navigate(`/data_sources`)} selected={location.pathname.startsWith('/data_sources')}>
        <ListItemIcon>
          <SensorsIcon />
        </ListItemIcon>
        <ListItemText primary={t("app.menu.datasources")} />
      </ListItemButton>
    </React.Fragment>
  );
}
