import * as React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Marker } from 'react-leaflet/Marker';
import { Popup } from 'react-leaflet/Popup';

function Map(props) {
  const MapContainerStyle = {
    width: "100%",
    height: "100%"
  };
  const center = [parseFloat(props.lat), parseFloat(props.lon)];
  console.log(props, center);
  return (
    <MapContainer center={center} zoom={props.zoom || 13} style={MapContainerStyle}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.markers ? props.markers.map(marker => {
        return (
          <Marker key={marker.id} position={marker.pos}>
            <Popup>
              {marker.name}
            </Popup>
          </Marker>
        );
      }) : <></>}
    </MapContainer>
  );
}

export default Map;
