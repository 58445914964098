import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthUserContextType, getUserFromJson, useAuthUserContext } from "../providers";
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoginIcon from '@mui/icons-material/Login';

// import PcounterApp from 'pcounter_app';
const PcounterApp = require('pcounter_app');

type CustomLocation = {
  state: { from: { pathname: string } }
};

function Login() {
  const navigate = useNavigate();
  const location: CustomLocation = useLocation() as CustomLocation;
  const fromPathName: string = location.state && location.state.from ? location.state.from.pathname : '/';
  const authUser: AuthUserContextType = useAuthUserContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const signin = () => {
    setLoading(true);
    const api = new PcounterApp.UserApi();
    const loginInfo = {
      'username': username,
      'password': password,
    }; // {Number} End minute of data to return
    const callback = function (error: any, data: any, response: any) {
      if (error) {
        console.error(error);
        setLoading(false);
      } else {
        console.log('[Login] API called successfully. Returned data: ')
        const responseJson = JSON.parse(response.text);
        console.log(responseJson);
        if (responseJson.result) {
          const user = getUserFromJson(responseJson);
          authUser.signin(user, () => {
            navigate(fromPathName, { replace: true })
          });
        } else {
          setOpen(true);
        }
        setLoading(false);
      }
    };
    api.loginUser(loginInfo, callback);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      height='100vh'
      component="form"
      onSubmit={() => signin()}
    >
      <Typography component="h2" variant="h2" color="primary" align='center' gutterBottom>
        {t("app.title")} 
      </Typography>
      <TextField
        id="username"
        label={t("login.username")}
        variant="filled"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        autoFocus
        required
      />
      <TextField
        id="password"
        label={t("login.password")}
        type="password"
        variant="filled"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <LoadingButton
        type="submit"
        variant="contained"
        onClick={() => signin()}
        endIcon={<LoginIcon />}
        loading={loading}
        loadingPosition="end"
      >
        {t("login.login")}
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Login Failed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ログインできませんでした。
            ユーザー名とパスワードをご確認ください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Login;
