import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import i18n from "i18next";
import "./i18n/config"; //i18

import NotFound from './pages/NotFound';
import MapTest from './pages/MapTest';
import Sensors from './pages/Sensors';
import { AuthUserProvider, GlobalStateProvider } from "./providers";
import { RouteAuthGuard } from "./components";
import Login from './pages/Login';
import Home from './pages/Home';
import DataSources from './pages/DataSources';
import PC from './pages/PC';
import PF from './pages/PF';
import AC from './pages/AC';
import PFWT from './pages/PFWT';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Button, ButtonGroup } from '@mui/material';
import Flag from 'react-world-flags';
import LH from './pages/LH';

const PcounterApp = require('pcounter_app');
const defaultClient = PcounterApp.ApiClient.instance;
defaultClient.basePath = (process.env.REACT_APP_API_PATH || 'http://localhost:10082') + '/api/v0';
defaultClient.enableCookies = true;
defaultClient.defaultHeaders = [];
console.log(defaultClient);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthUserProvider>
      <BrowserRouter>
        <GlobalStateProvider>
          <Routes>
            <Route path="/" element={<RouteAuthGuard component={<App />} redirect="/login" />}>
              <Route index element={<Home />} />
              <Route path="map_test/:lat/:lon" element={<MapTest />} />
              <Route path="data_sources" element={<DataSources />} />
              <Route path="pc" element={<PC />} />
              <Route path="pc/:sids" element={<PC />} />
              <Route path="pc/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute" element={<PC />} />
              <Route path="pc/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute" element={<PC />} />
              <Route path="pc/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute/:g_time/:g_mesh" element={<PC />} />
              <Route path="pc/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute/:g_time/:g_mesh/:view_type/:view_div" element={<PC />} />
              <Route path="ac" element={<AC />} />
              <Route path="ac/:aids" element={<AC />} />
              <Route path="ac/:aids/:s_year/:s_month/:s_day/:s_hour/:s_minute" element={<AC />} />
              <Route path="ac/:aids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute" element={<AC />} />
              <Route path="ac/:aids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute/:g_time/:g_mesh" element={<AC />} />
              <Route path="ac/:aids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute/:g_time/:g_mesh/:view_type/:view_div" element={<AC />} />
              <Route path="pfwt" element={<PFWT />} />
              <Route path="pfwt/:sids" element={<PFWT />} />
              <Route path="pfwt/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute" element={<PFWT />} />
              <Route path="pfwt/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute" element={<PFWT />} />
              <Route path="pfwt/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute/:g_time/:g_mesh" element={<PFWT />} />
              <Route path="pf" element={<PF />} />
              <Route path="pf/:sids" element={<PF />} />
              <Route path="pf/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute" element={<PF />} />
              <Route path="pf/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute" element={<PF />} />
              <Route path="pf/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute/:g_time/:g_mesh" element={<PF />} />
              <Route path="lh" element={<LH />} />
              <Route path="lh/:sids" element={<LH />} />
              <Route path="lh/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute" element={<LH />} />
              <Route path="lh/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute" element={<LH />} />
              <Route path="lh/:sids/:s_year/:s_month/:s_day/:s_hour/:s_minute/:e_year/:e_month/:e_day/:e_hour/:e_minute/:view_type/:colmun_key" element={<LH />} />
              {Sensors}
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <div style={{ position: 'absolute', bottom: '4px', left: '4px', zIndex: '10000000', backgroundColor: '#EEEEEE' }}>
            <ButtonGroup variant="text" aria-label="outlined button group">
              <Button onClick={() => { i18n.changeLanguage("en"); }}><Flag code={'GB'} /></Button>
              <Button onClick={() => { i18n.changeLanguage("ja"); }}><Flag code={'JP'} /></Button>
            </ButtonGroup>
          </div>
        </GlobalStateProvider>
      </BrowserRouter>
    </AuthUserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
