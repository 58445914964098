import * as React from 'react';
import {
  Route,
  Outlet,
} from "react-router-dom";

function SensorsHome() {
  return (
    <div>sensors home</div>
  );
}


const Sensors = (
  <React.Fragment>
    <Route path="sensors" element={<Outlet />}>
      <Route index element={<SensorsHome />} />
      <Route path="testtest" element={<div>sensors test</div>} />
      <Route path=":invoiceId" element={<div>sensors child</div>} />
    </Route>
  </React.Fragment>
);

export default Sensors;
