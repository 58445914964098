import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
// import LoadingButton from '@mui/lab/LoadingButton';
// import GetAppIcon from '@mui/icons-material/GetApp';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LineAxisIcon from '@mui/icons-material/LineAxis';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import Title from '../Title';
import { GlobalStateContextType, useAuthUserContext, useGlobalStateContext } from "../providers";

import { LHChart } from '../elements/LHChart';
import { Box, Divider, FormControl, ToggleButton, ToggleButtonGroup, Toolbar, MenuItem, Select, SelectChangeEvent, InputLabel } from '@mui/material';
import { MarkerProp, TimelineChartData, TimelineChartDataList } from '../types';

import { colmunKeys } from '../objects/lhColmunKeys';
import { LHMap } from '../elements/LHMap';


const PcounterApp = require('pcounter_app');

const columns: GridColDef[] = [
  { field: 'data_source_id', headerName: 'ソースID', maxWidth: 80 },
  { field: 'data_source_name', headerName: 'ソース名', minWidth: 200, flex: 1.2 },
  { field: 'data_source_group_name', headerName: 'グループ名', minWidth: 200, flex: 0.8 },
  { field: 'granularity_time', headerName: '時間粒度', hide: true },
  { field: 'granularity_mesh', headerName: 'メッシュ粒度', hide: true },
];

export default function LH() {
  let { sids, s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute, view_type, colmun_key } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const globalState: GlobalStateContextType = useGlobalStateContext();
  const { setMenuLhUrl, lhData, setLhData } = globalState;
  const { t } = useTranslation();

  const user = useAuthUserContext().user;
  const rows = user?.perms?.filter(row => row.data_source_type === 3)!!;

  // parse url sid list
  const sidSelections: GridSelectionModel = [];
  const sid_list = sids?.split(",").map(v => parseInt(v)).filter(v => !isNaN(v));
  rows.forEach((row, _) => {
    if (sid_list?.includes(row.data_source_id!!)) {
      sidSelections.push(row.id!!);
    }
  });

  // parse url date
  let sTime = dayjs().hour(0).minute(0).second(0).millisecond(0);
  if (s_year !== void 0) sTime = sTime.year(parseInt(s_year));
  if (s_month !== void 0) sTime = sTime.month(parseInt(s_month) - 1);
  if (s_day !== void 0) sTime = sTime.date(parseInt(s_day));
  if (s_hour !== void 0) sTime = sTime.hour(parseInt(s_hour));
  if (s_minute !== void 0) sTime = sTime.minute(parseInt(s_minute));
  let eTime = dayjs().hour(23).minute(59).second(59).millisecond(999);
  if (e_year !== void 0) eTime = eTime.year(parseInt(e_year));
  if (e_month !== void 0) eTime = eTime.month(parseInt(e_month) - 1);
  if (e_day !== void 0) eTime = eTime.date(parseInt(e_day));
  if (e_hour !== void 0) eTime = eTime.hour(parseInt(e_hour));
  if (e_minute !== void 0) eTime = eTime.minute(parseInt(e_minute));

  const viewType = view_type || 'line';     // デフォルト：折れ線ビュー
  const colmunKey = colmun_key || 'lat';    // デフォルト：井戸

  console.log(sidSelections, sTime.format("YYYY/M/D HH:mm"), eTime.format("YYYY/M/D HH:mm"), viewType);

  const calcPath = (sid_list_n: number[], newSTime: dayjs.Dayjs, newETime: dayjs.Dayjs, viewType: string, newColmunKey: string) => {
    return '/lh/' + (sid_list_n.length > 0 ? sid_list_n.join(',') : '-') + '/' + newSTime.format("YYYY/M/D/H/m") + '/' + newETime.format("YYYY/M/D/H/m") + '/' + viewType + '/' + newColmunKey;
  }

  const navToCurrentSelection = (newSelectionModel: GridSelectionModel, newSTime: Dayjs, newETime: Dayjs, viewType: string, newColmunKey: string) => {
    const sid_list_n: number[] = [];
    newSelectionModel.forEach(v => sid_list_n.push(rows.filter(row => row.id === Number(v))[0].data_source_id!!));
    sid_list_n.sort();
    const newUrl = calcPath(sid_list_n, newSTime, newETime, viewType, newColmunKey);
    setMenuLhUrl(newUrl);
    navigate(newUrl);
  }

  const fetchData = () => {
    setLoading(true);
    const dataBlock: { [key: string | number]: any; } = {};
    sid_list?.forEach(sid => {
      dataBlock[sid] = 0;
    })
    const newLhData: TimelineChartDataList = [];
    const sTimeUnixM = sTime.valueOf();

    setLhData(newLhData);
    console.log(newLhData);
    const api = new PcounterApp.LhApi();
    console.log("stime", sTimeUnixM);
    let fetchingCount = sid_list?.length!!;
    const callback = (sid: number) => (error: any, data: any, response: any) => {
      fetchingCount--;
      if (error) {
        console.error(error);
        setLoading(false);
      } else {
        console.log('[LH] API called successfully.', sid) // Returned data: ')
        // console.log(response.text);
        response.text.split('\n').forEach((d: string) => {
          if (d.length > 0) {
            const dataArray = d.split(',');
            const lhDataBlock: TimelineChartData = {
              id: sid,
              time: Date.parse(dataArray[0]),
              lat: parseFloat(dataArray[1]),
              lon: parseFloat(dataArray[2]),
              alt: parseFloat(dataArray[3]),
              spd: parseFloat(dataArray[4]),
              acc: parseFloat(dataArray[5]),
              dir_s: parseFloat(dataArray[6]),
              dir_a: parseFloat(dataArray[7]),
              rssi: parseFloat(dataArray[8]),
            };
            newLhData.push(lhDataBlock);
          }
        });
        // console.log("LH", sid, newLhData);
      }
      if (fetchingCount === 0) {
        newLhData.sort((a: TimelineChartData, b: TimelineChartData) => a.time - b.time);
        // console.log("LH fetch finished", newLhData);
        setLhData(newLhData);
        setLoading(false);
      }
    };
    sid_list?.forEach(sid => {
      api.getlhById(sid, s_year, s_month, s_day, s_hour, s_minute, e_year, e_month, e_day, e_hour, e_minute, 0, 0, callback(sid));
    });
  };

  React.useEffect(() => {
    if (sid_list && sid_list.length) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcPath(sid_list || [], sTime, eTime, viewType, colmunKey)]);

  if (rows.length === 0) {
    return <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>{t("etc.no_datasources")}</Title>
      </div>
    </React.Fragment>;
  }

  const showColmuns = columns.map(col => {
    col.headerName = t("lh.columns." + col.field);
    return col;
  });

  const mapRoutes = sid_list?.map(sid => lhData.filter(lhDataBlock => lhDataBlock.id === sid));
  console.log(mapRoutes);

  const markers: MarkerProp[] = [];

  mapRoutes?.forEach((mapRoute, i) => {
    mapRoute.forEach(point => {
      const marker: MarkerProp = {
        id: point.id!!,
        lat: point.lat!!,
        lon: point.lon!!,
        name: point.id!!,
        time: dayjs(point.time).format("YYYY/M/D HH:mm:ss"),
        active: i,
      };
      markers.push(marker);
    });
  });

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Title>{t("app.menu.lh")}</Title>
        <div style={{ width: '100%', flexGrow: 1 }}>
          <Grid style={{ height: '100%' }} container spacing={3}>
            <Grid item container direction="column" spacing={2} xs={12} md={5}>
              <Grid item xs={6}>
                <DataGrid
                  style={{ height: '100%' }}
                  rows={rows}
                  rowHeight={25}
                  columns={showColmuns}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  checkboxSelection
                  onSelectionModelChange={(newSelectionModel) => {
                    navToCurrentSelection(newSelectionModel, sTime, eTime, viewType, colmunKey);
                  }}
                  selectionModel={sidSelections}
                />
              </Grid>
              {mapRoutes?.length ? <LHMap markers={markers} mapRoutes={mapRoutes} /> : <></>}
            </Grid>
            <Grid item container direction="column" spacing={2} xs={12} md={7}>
              <Grid item xs="auto">
                <Toolbar
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    '& hr': {
                      mx: 1.5,
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl sx={{ width: 160, marginRight: 1 }}>
                      <MobileDateTimePicker
                        label={t("lh.control.start_time")}
                        value={sTime}
                        onChange={(v) => {
                          const newETime = (v as dayjs.Dayjs).add(eTime.diff(sTime));
                          navToCurrentSelection(sidSelections, v!!, newETime, viewType, colmunKey);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                    </FormControl>
                    ～
                    <FormControl sx={{ width: 160, marginLeft: 1 }}>
                      <MobileDateTimePicker
                        label={t("lh.control.end_time")}
                        value={eTime}
                        onChange={(v) => {
                          navToCurrentSelection(sidSelections, sTime, v!!, viewType, colmunKey);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="YYYY/MM/DD HH:mm"
                      />
                    </FormControl>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <FormControl sx={{ width: 160 }}>
                      <InputLabel>{t("lh.control.colmun_key")}</InputLabel>
                      <Select
                        value={colmunKey}
                        onChange={(event: SelectChangeEvent) => {
                          navToCurrentSelection(sidSelections, sTime, eTime, viewType, String(event.target.value));
                        }}
                        label={t("lh.control.colmun_key")}
                      >
                        {Object.keys(colmunKeys).map(key => <MenuItem value={key} key={key}>{colmunKeys[key]}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ToggleButtonGroup
                      color="primary"
                      value={viewType}
                      exclusive
                      onChange={(_, v) => {
                        navToCurrentSelection(sidSelections, sTime, eTime, v, colmunKey);
                      }}
                      aria-label="グラフ形式"
                    >
                      <ToggleButton value="line"><LineAxisIcon /></ToggleButton>
                      <ToggleButton value="stack"><StackedLineChartIcon /></ToggleButton>
                    </ToggleButtonGroup>
                  </LocalizationProvider>
                </Toolbar>
              </Grid>
              <Grid item xs>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <LHChart data={lhData} keys={sid_list} type={viewType} loading={loading} colmunKey={colmunKey} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
