import * as React from 'react';
import * as d3 from 'd3';
import { PermType } from '../types';

type WTNode = d3.layout.tree.Node & {
  id?: number;
  data_source_id: number;
  total: number;
  children: WTNode[];
  level: number;
  backColor: string;
  x0?: number;
  y0?: number;
  url?: string;
}

type WHSize = { width: number; height: number };

const svgSize: WHSize = { width: 1600, height: 800 };

const _wt_treeData: WTNode[] = [];
let _wt_i = 0;
const _wt_duration = 750;
let _wt_svg: any = null;
const _wt_diagonal = d3.svg.diagonal()
  .projection(function (d) {
    return [d.y, d.x];
  });

const cellWidth = 140;

function WeightedTreeView(props: { area: any; areas: PermType[]; sensors: any; wtData: any; }) {
  const { area, areas, sensors, wtData } = props;
  const rootElm = React.useRef<HTMLDivElement>(null);
  const childElm = React.useRef<HTMLDivElement>(null);
  const [childSize, setChildSize] = React.useState<WHSize>({ width: 100, height: 100 });
  const [firstRun, setFirstRun] = React.useState(true);

  React.useEffect(() => {
    if (firstRun) {
      const rootElemBounding = rootElm.current?.getBoundingClientRect();
      console.log(rootElemBounding?.width!!, rootElemBounding?.height!!);
      setChildSize({ width: rootElemBounding?.width!!, height: rootElemBounding?.height!! });
      setFirstRun(false);
    }

    const getAreaNameFromDataSourceId = (data_source_id: number): string => {
      if (data_source_id === -1) {
        return "INVALID FLOW";
      }
      return areas.filter((area: PermType) => area.data_source_id === data_source_id)[0].data_source_name!!;
    }

    const addOrUpdate = (node: WTNode, element: any, keyIndex: number) => {
      node.total++;
      if (keyIndex >= element.length)
        return;
      const match = node.children.find((item: WTNode) => item.data_source_id === element[keyIndex]);
      if (match) {
        addOrUpdate(match, element, keyIndex + 1);
      } else {
        const child = {
          data_source_id: element[keyIndex],
          backColor: node.backColor,
          parent: node.data_source_id,
          level: keyIndex + 1,
          total: 0,
          children: [],
        } as WTNode;
        node.children.push(child);
        addOrUpdate(child, element, keyIndex + 1);
      }
    }

    const _wt_update = (_wt_root: WTNode) => {

      // Compute the new tree layout.
      const _wt_tree = d3.layout.tree().size([childSize.height - 40, svgSize.width]);
      const nodes = _wt_tree.nodes(_wt_root).reverse() as WTNode[];
      const links = _wt_tree.links(nodes);

      d3.select("#tree").select("svg").remove();

      _wt_svg = d3.select("#tree").append("svg")
        .attr("width", svgSize.width)
        .attr("height", childSize.height - 40)
        .append("g")
        .attr("transform", "translate(24, 0)");

      // Normalize for fixed-depth.
      nodes.forEach(d => {
        d.y = d.depth!! * 180;
      });

      // Update the nodes…
      var node = _wt_svg.selectAll("g.node")
        .data(nodes, (d: WTNode) => {
          return d.id || (d.id = ++_wt_i);
        });

      // Enter any new nodes at the parent's previous position.
      var nodeEnter = node.enter().append("g")
        .attr("class", "node")
        .attr("transform", function (d: any) {
          return "translate(" + _wt_root.y0 + "," + _wt_root.x0 + ")";
        })
        .on("click", click);

      /*nodeEnter.append("circle")
        .attr("r", 1e-6)
        .style("fill", function(d) { return d._children ? "#ccff99" : "#fff"; });*/
      nodeEnter.append("rect")
        .attr("width", cellWidth)
        .attr("height", 34)
        .style("fill", function (d: any) {
          return d.backColor;
        }).on('click', function () {
          //sd3.select(this.style).style('fill', 'green');
        });


      nodeEnter.append("text")
        .attr("x", -20)
        .attr("y", -5)
        .attr("dy", "0em")
        .attr("text-anchor", "start")
        .text(function (d: WTNode) {
          return getAreaNameFromDataSourceId(d.data_source_id)
        })
        .style("fill-opacity", 1e-6)
        .style("fill", "#ffffff")
        .style("font-size", "10px")
        .attr("class", function (d: WTNode) {
          if (d.url != null) {
            return 'hyper';
          }
        })
        .on("click", function (d: WTNode) {
          //d3.select(this.parentNode).style('fill', 'green');
        });

      nodeEnter.append("text")
        .attr("x", -20)
        .attr("y", 5)
        .attr("dy", ".25em")
        .attr("text-anchor", "start")
        .style("fill", "#ffffff")
        .text(function (d: any) {
          if (d.parent?.total)
            return d.total.toFixed(0) + "人 " + (d.total * 100 / d.parent.total).toFixed(2) + "%";
          return "";
        })
        .style("fill-opacity", 1)
        .attr("class", function (d: WTNode) {
          if (d.url != null) {
            return 'hyper';
          }
        })
        .on("click", function (d: WTNode) {
          //
        });

      // Transition nodes to their new position.
      var nodeUpdate = node.transition()
        .duration(_wt_duration)
        .attr("transform", function (d: WTNode) {
          return "translate(" + d.y + "," + d.x + ")";
        });

      //nodeUpdate.select("rect")
      //.attr("r", 10)
      //.style("fill", function(d) { return d._children ? "#ccff99" : "#fff"; });
      // .style("fill", function(d) { return d._children ? "#5CADFF" : "#5CADFF"; });

      nodeUpdate.select("rect")
        .attr("x", -24)
        .attr("y", -18)
        .attr("width", cellWidth)
        .attr("height", 34)
        .style("fill", function (d: any) {
          return d._children ? "lightseagreen" : d.backColor;
        });

      nodeUpdate.select("text")
        .style("fill-opacity", 1);

      // Transition exiting nodes to the parent's new position.
      var nodeExit = node.exit().transition()
        .duration(_wt_duration)
        .attr("transform", function (d: WTNode) {
          return "translate(" + _wt_root.y + "," + _wt_root.x + ")";
        })
        .remove();

      nodeExit.select("circle")
        .attr("r", 1e-6);

      nodeExit.select("text")
        .style("fill-opacity", 1e-6);

      // Update the links…
      var link = _wt_svg.selectAll("path.link")
        .data(links, function (d: any) {
          return d.target.id;
        });

      // Enter any new links at the parent's previous position.
      link.enter().insert("path", "g")
        .attr("class", "link")
        .style("stroke-width", function (d: d3.layout.tree.Link<WTNode>) {
          return 30 * (d.target.total / d.source.total);
        })
        .attr("d", () => {
          var o = {
            x: _wt_root.x0!!,
            y: _wt_root.y0!!,
          };
          return _wt_diagonal({
            source: o,
            target: o,
          });
        });

      // Transition links to their new position.
      link.transition()
        .duration(_wt_duration)
        .attr("d", _wt_diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(_wt_duration)
        .attr("d", () => {
          var o = {
            x: _wt_root.x!!,
            y: _wt_root.y!!,
          };
          return _wt_diagonal({
            source: o,
            target: o
          });
        })
        .remove();

      // Stash the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      console.log(nodes);
    };

    const click = (d: any) => {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      _wt_update(d);
    }

    const showTree = (area: any) => {
      console.log(area);
      if (!area) {
        return;
      }
      const sensorMap: { [key: number]: number } = {};
      sensors.forEach((sensor: PermType, i: number) => {
        sensorMap[sensor.data_source_id!!] = i;
      });
      const routes = wtData.map((pfwt: any) => {
        const route = [area.data_source_id];
        for (const element of pfwt.sensorIds) {
          const sensor = sensors[sensorMap[element]];
          if (sensor !== void 0) {
            if (sensor.data_source_opt.f === route[route.length - 1]) {
              route.push(sensor.data_source_opt.b);
            } else if (sensor.data_source_opt.b === route[route.length - 1]) {
              route.push(sensor.data_source_opt.f);
            } else {
              route.push(-1);
              break;
            }
          } else {
            console.warn("sensor", sensor, "sensors[sensorMap[element]]", sensors[sensorMap[element]], "sensorMap[element]", sensorMap[element], "element", element);
          }
        }
        return route;
      });
      const _wt_root = {
        data_source_id: area.data_source_id!!,
        total: 0,
        children: [],
        // parent: "null",
        level: 1,
        backColor: "#4A8ACC",
      };
      routes.forEach((e: any) => {
        addOrUpdate(_wt_root, e, 1);
      });
      console.log(_wt_treeData);
      _wt_treeData.splice(0, _wt_treeData.length);
      _wt_treeData.push(_wt_root);
      _wt_update(_wt_root);
    }

    showTree(area);
  }, [areas, sensors, wtData, firstRun, area, childSize]);

  return <div style={{ height: '100%', width: '100%', overflow: 'hidden' }} ref={rootElm}>
    <div style={{ height: childSize.height, width: childSize.width, overflow: 'auto' }} ref={childElm} id={"tree"}></div>

  </div>;
}

export default React.memo(WeightedTreeView);
