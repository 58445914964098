import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { GlobalStateContextType, useGlobalStateContext } from '../providers';

function Home() {
  const navigate = useNavigate();
  const globalState: GlobalStateContextType = useGlobalStateContext();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card onClick={() => navigate(globalState.menuPcUrl)}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="480"
              image="/static/pc-cover.png"
              alt={t("app.menu.pc")}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t("app.menu.pc")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("pc.explanation")}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card onClick={() => navigate(globalState.menuPfwtUrl)}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="480"
              image="/static/pfwt-cover.png"
              alt={t("app.menu.pfwt")}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t("app.menu.pfwt")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("pfwt.explanation")}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Home;
