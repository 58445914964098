import * as React from 'react';
import { useParams } from 'react-router-dom';
import Map from '../elements/Map';

function MapTest() {
    let { lat, lon } = useParams();
    return <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
        <p>Lat: {lat}<br/>Lon: {lon}</p>
        <div style={{width: '100%', flexGrow: 1}}>
            <Map lat={lat} lon={lon} />
        </div>
    </div>;
}

export default MapTest;
